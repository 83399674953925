import React from "react";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { actions } from "../state/snackbar/actions";
import { Box, useMediaQuery, Theme } from "@material-ui/core";
import { useSelector } from "../state/store";
import { useDispatch } from "react-redux";
import useStyles from "./SnackbarComponent.styles";

const Alert = (props: AlertProps) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const SnackbarComponent = () => {
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isDrawerOpen = useSelector(state => state.header.isOpen);
  const { type, message, open } = useSelector(state => ({
    type: state.snackbar.type,
    message: state.snackbar.message,
    open: state.snackbar.open
  }));
  const dispatch = useDispatch();

  const handleSnackbarClose = () => dispatch(actions.closeSnackbar());

  return (
    <Box>
      {type !== undefined && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          className={clsx({
            [classes.contentShift]: isDrawerOpen && matches
          })}
        >
          <Alert color={type}>{message}</Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default SnackbarComponent;
