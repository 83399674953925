import { ClaimsEnum, TranslationsEnum } from "../../types/enums";

export const getClaimsTypeTranslationKey = (claimsType: ClaimsEnum) => {
  switch (claimsType) {
    case ClaimsEnum.Admin:
      return TranslationsEnum.Global_Admin;
    case ClaimsEnum.Operator:
      return TranslationsEnum.Global_Operator;
    case ClaimsEnum.Patient:
      return TranslationsEnum.Global_Patient;
  }
};
