import React from "react";
import SnackbarComponent from "../../../Snackbar/SnackbarComponent";
import { Box } from "@material-ui/core";
import PublicRoutes from "../../../Routes/PublicRoutes";
import useStyles from "./PublicPageStructure.styles";

const PublicPageStructure: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <PublicRoutes />
      <SnackbarComponent />
    </Box>
  );
};

export default PublicPageStructure;
