import React, { useState, useMemo, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn
} from "mui-datatables";
import { Box, Button, CircularProgress, IconButton } from "@material-ui/core";
import DataTableFooter from "../../components/DataTableFooter/DataTableFooter";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import FilterChipBar from "../../components/FilterChipBar/FilterChipBar";
import { attachSortToColumns } from "../../utils/helpers/filtersHelpers";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import { useTranslation } from "react-i18next";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import EditIcon from "@material-ui/icons/Edit";

import { useDispatch } from "react-redux";
import useStyles from "./Operators.styles";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import DeleteIcon from "@material-ui/icons/Delete";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import { TranslationsEnum, OperatorsColumnsEnum } from "../../types/enums";
import { getOperatorsQuery } from "../../queries";
import { IFilterConfig } from "../../types/filters/IFilterConfig";
import { IOperatorsTableDTO } from "../../types/tables/IOperatorsTable";
import {
  CreateOperatorModal,
  DeleteOperatorModal,
  EditOperatorModal
} from "../Modals";
import { deleteOperatorMutation } from "../../mutations";

const Operators: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultSorting = {
    sortField: OperatorsColumnsEnum.Name,
    sortDirection: SortDirectionEnum.Ascending
  };

  const [filterConfig, setFilterConfig] = useState<IFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: []
  });

  const { data, loading, error, refetch } = useQuery<{
    operatorsTableDTO: IOperatorsTableDTO;
  }>(getOperatorsQuery, {
    variables: { filterConfig },
    fetchPolicy: "network-only"
  });

  useErrorHandling(error);

  const handleDeleteSuccess = () => {
    refetch();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_OperatorDeletedSuccessfully)
      )
    );
  };

  const [
    deleteOperator,
    { error: deleteOperatorError, loading: deleteLoading }
  ] = useMutation(deleteOperatorMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess
  });

  useErrorHandling(deleteOperatorError);

  // Edit modal state
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [operatorToEditAzureId, setOperatorToEditAzureId] = React.useState<
    null | number
  >(null);

  const handleEditModalClose = useCallback(() => {
    setOperatorToEditAzureId(null);
    setEditModalOpen(false);
  }, [setOperatorToEditAzureId, setEditModalOpen]);

  const handleEditModalOpen = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setEditModalOpen(true);
      setOperatorToEditAzureId(id);
    },
    [setEditModalOpen, setOperatorToEditAzureId]
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: OperatorsColumnsEnum.Name,
      label: t(TranslationsEnum.Global_Name)
    },
    {
      name: OperatorsColumnsEnum.Email,
      label: t(TranslationsEnum.Global_Email)
    },
    {
      name: OperatorsColumnsEnum.ClinicName,
      label: t(TranslationsEnum.Global_Trainer)
    },
    {
      name: OperatorsColumnsEnum.AzureUserId,
      label: " ",
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <>
              <Box display="flex">
                <Box onClick={handleEditModalOpen(value)}>
                  <IconButton aria-label="edit">
                    <EditIcon color="primary" />
                  </IconButton>
                </Box>
                <Box onClick={handleDeleteModalOpen(value)}>
                  <IconButton aria-label="delete">
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </>
          );
        }
      }
    }
  ];

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection: filterConfig.sortDirection,
        name: filterConfig.sortField
      }),
    [filterConfig, columns]
  );

  // Create modal state
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);

  const handleAddModalOpen = () => setAddModalOpen(true);

  const handleAddModalClose = () => setAddModalOpen(false);

  const filterColumns: IFilterColumn[] = useMemo(
    () =>
      columns
        .filter(x => x.name !== OperatorsColumnsEnum.AzureUserId)
        .map(x => ({
          name: x.name,
          label: x.label ?? ""
        })),
    [columns]
  );

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig(x => ({ ...x, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    setFilterConfig(x => ({ ...x, sortField, sortDirection }));
  };

  const hasSorting = useMemo(
    () =>
      !(
        defaultSorting.sortField === filterConfig.sortField &&
        defaultSorting.sortDirection === filterConfig.sortDirection
      ),
    [defaultSorting, filterConfig]
  );

  const actionButton = (
    <Button variant="contained" color="primary" onClick={handleAddModalOpen}>
      {t(TranslationsEnum.Global_AddOperator)}
    </Button>
  );

  const handleFilter = (filters: IWebParametersFilter[]) => {
    setFilterConfig(x => ({
      ...x,
      filters,
      page: 0
    }));
  };

  const resetSorting = () => {
    setFilterConfig(x => ({ ...x, ...defaultSorting }));
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [operatorToDeleteAzureId, setOperatorToDeleteAzureId] = React.useState<
    null | number
  >(null);

  const handleDeleteModalClose = useCallback(() => {
    setOperatorToDeleteAzureId(null);
    setDeleteModalOpen(false);
  }, [setOperatorToDeleteAzureId, setDeleteModalOpen]);

  const handleDeleteModalOpen = useCallback(
    (azureUserId: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setDeleteModalOpen(true);
      setOperatorToDeleteAzureId(azureUserId);
    },
    [setDeleteModalOpen, setOperatorToDeleteAzureId]
  );

  const handleDeleteOperator = useCallback(() => {
    deleteOperator({
      variables: { values: { azureUserId: operatorToDeleteAzureId } }
    });
    handleDeleteModalClose();
  }, [deleteOperator, handleDeleteModalClose, operatorToDeleteAzureId]);

  if (loading && !data) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const { operators, count } = data?.operatorsTableDTO || {
    operators: [],
    count: 0
  };

  const options: MUIDataTableOptions = {
    page: filterConfig.page,
    count,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: filterConfig.takePerPage,
    sort: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange
  };

  return (
    <Box px={3} pt={4}>
      <Box display="flex">
        <FilterChipBar
          filterColumns={filterColumns}
          onFilter={handleFilter}
          hasSorting={hasSorting}
          resetSorting={resetSorting}
          actions={actionButton}
        />
      </Box>
      <MUIDataTable
        title={null}
        data={operators}
        columns={fullColumns}
        options={options}
      />
      <DeleteOperatorModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        loading={deleteLoading}
        onDelete={handleDeleteOperator}
      />
      <CreateOperatorModal
        open={isAddModalOpen}
        handleClose={handleAddModalClose}
        refetchOperators={refetch}
      />
      <EditOperatorModal
        operatorAzureId={operatorToEditAzureId}
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
        refetchOperators={refetch}
      />
    </Box>
  );
};

export default Operators;
