import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles(() => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  checkbox: {
    padding: 0,
    "&:hover ": {
      backgroundColor: "unset !important",
      cursor: "auto"
    }
  },
  warning: {
    color: ColorEnum.Orange,
    height: 14,
    position: "relative",
    top: 2,
    width: 18
  },
  button: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center"
  },
  rejectButton: {
    marginRight: 10,
    color: ColorEnum.Red
  },
  approveButton: {
    color: ColorEnum.Blue
  },
  highlightRow: {
    background: ColorEnum.TableBlue,
    "&:hover": {
      background: `${ColorEnum.TableBlueHover} !important`
    }
  }
}));
