import React, { useCallback, useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Link as MuiLink,
  List,
  Theme,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import { useSelector } from "../../../state/store";
import { useDispatch } from "react-redux";
import { actions } from "../../../state/header/actions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyoSwissLogoWhite from "../../../assets/myoswiss-logo-white.png";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import { ISideMenuListItem } from "../../../types/ISideMenuListItem";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import { TFunction } from "i18next";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import {
  selectHeaderOpen,
  selectUserProfile
} from "../../../utils/helpers/stateSelectorHelpers";
import { getSelectedLanguage } from "../../../utils/helpers/languageHelpers";
import { LanguageEnum } from "../../../types/enums/LanguageEnum";
import {
  DPA_De,
  DPA_En,
  impressum_De,
  impressum_En,
  resourcesOperator_De,
  resourcesOperator_En,
  resourcesPatient_De,
  resourcesPatient_En
} from "../../../constants/contacts";
import { isDesktop } from "../../../utils/helpers/platformHelpers";
import { Forum, GetApp } from "@material-ui/icons";
import { isElectron } from "../../../utils/helpers/electronHelpers";
import { IUserProfile } from "../../../types/IUserProfile";
import {
  ClaimsEnum,
  SnackbarTypeEnum,
  TranslationsEnum
} from "../../../types/enums";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { useQuery } from "@apollo/react-hooks";
import {
  getPendingPatientsCountQuery,
  getIsFirmwareNeededToUpdateQuery
} from "../../../queries";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import BuildIcon from "@material-ui/icons/Build";
import { useMessagesCount } from "../../../utils/helpers/messageHelpers";
import { IIsFirmwareUpdateNeededResponseDto } from "../../../types/IIsFirmwareUpdateNeededResponseDto";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { downloadPDF } from "../../../api/customFiles";
import { CustomFilesEnum } from "../../../types/enums/CustomFilesEnum";
import { actions as snackbarActions } from "../../../state/snackbar/actions";
import useStyles from "./SideMenu.styles";

const sideMenuListItems = (
  t: TFunction,
  userProfile: IUserProfile | undefined | null,
  patientRequestCounter: number | undefined,
  messagesCounter: number | undefined,
  isFirmwareUpdateNeeded: boolean | undefined,
  classes: Record<string, string>
): ISideMenuListItem[] => {
  const items: ISideMenuListItem[] = [];

  if (userProfile && userProfile.claimsType === ClaimsEnum.Admin) {
    items.push({
      text: t(TranslationsEnum.Components_Header_SideMenu_Messages),
      path: PathNamesEnum.Messages,
      icon: <Forum />,
      badgeCounter: messagesCounter
    });

    items.push({
      text: t(TranslationsEnum.Components_Header_SideMenu_Users),
      path: PathNamesEnum.PatientsAdmin,
      icon: <GroupIcon />,
      badgeCounter: patientRequestCounter
    });

    items.push({
      text: t(TranslationsEnum.Global_Operators),
      path: PathNamesEnum.Operators,
      icon: <AssignmentInd />
    });

    items.push({
      text: t(TranslationsEnum.Global_Clinics),
      path: PathNamesEnum.Clinics,
      icon: <LocalHospitalIcon />
    });

    items.push({
      text: t(TranslationsEnum.Global_Units),
      path: PathNamesEnum.Units,
      icon: <BuildIcon />
    });
    if (isDesktop() && !isElectron()) {
      items.push({
        text: t(TranslationsEnum.Components_Header_SideMenu_DesktopApp),
        path: PathNamesEnum.DownloadDesktopApp,
        icon: <GetApp />
      });
    }
    return items;
  }

  items.push({
    text:
      userProfile === null || userProfile?.claimsType === ClaimsEnum.Patient
        ? t(TranslationsEnum.Components_Header_SideMenu_Results)
        : t(TranslationsEnum.Components_Header_SideMenu_Home),
    path: PathNamesEnum.Home,
    icon:
      userProfile?.claimsType === ClaimsEnum.Patient ? (
        <EqualizerIcon />
      ) : (
        <HomeIcon />
      )
  });

  if (userProfile && userProfile.claimsType === ClaimsEnum.Operator) {
    items.push({
      text: t(TranslationsEnum.Components_Header_SideMenu_Users),
      path: PathNamesEnum.Patients,
      icon: <GroupIcon />,
      badgeCounter: patientRequestCounter
    });
  }

  items.push({
    text: t(TranslationsEnum.Components_Header_SideMenu_SyncMyoSuit),
    path: PathNamesEnum.SyncMyoSuit,
    icon: <SyncAltIcon />,
    badgeIcon: isFirmwareUpdateNeeded && (
      <Tooltip
        arrow
        title={t(TranslationsEnum.Components_Header_SideMenu_FirmwareWarning)}
        classes={{
          tooltip: classes.tooltipText
        }}
      >
        <Box className={classes.tooltip}>
          <WarningRoundedIcon className={classes.warningIcon} />
        </Box>
      </Tooltip>
    )
  });

  items.push({
    text: t(TranslationsEnum.Components_Header_SideMenu_Messages),
    path: PathNamesEnum.Messages,
    icon: <Forum />,
    badgeCounter: messagesCounter
  });

  if (isDesktop() && !isElectron()) {
    items.push({
      text: t(TranslationsEnum.Components_Header_SideMenu_DesktopApp),
      path: PathNamesEnum.DownloadDesktopApp,
      icon: <GetApp />
    });
  }

  return items;
};

const SideMenu = () => {
  const selectedLanguage = getSelectedLanguage();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [DPADownloadLink, setDPADownloadLink] = useState("");
  const [QuestionnaireDownloadLink, setQuestionnaireDownloadLink] = useState(
    ""
  );
  const [DPADownloadSuccess, setDPADownloadSuccess] = useState(true);
  const [
    QuestionnaireDownloadSuccess,
    setQuestionnaireDownloadSuccess
  ] = useState(true);

  const { isDrawerOpen, userProfile } = useSelector(state => ({
    ...selectHeaderOpen(state),
    ...selectUserProfile(state)
  }));

  const path = location.pathname;

  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;

  const toggleDrawer = useCallback(() => {
    dispatch(actions.toggleDrawerAction(!isDrawerOpen));
  }, [dispatch, isDrawerOpen]);

  const handleListItemClick = (path: PathNamesEnum) => () => history.push(path);

  const {
    data: dataPendingPatientsCount,
    error: errorPendingPatientsCount
  } = useQuery(getPendingPatientsCountQuery, {
    fetchPolicy: "cache-and-network",
    skip: !isOperator
  });

  useErrorHandling(errorPendingPatientsCount);

  const {
    data: dataMessagesCount,
    error: errorMessagesCount
  } = useMessagesCount();
  useErrorHandling(errorMessagesCount);

  const pendingPatientsCount =
    dataPendingPatientsCount?.pendingPatientsCount?.pendingPatientsCount;

  const messagesCount = dataMessagesCount?.messagesCount?.unreadMessagesCount;

  const { data } = useQuery<{
    isFirmwareUpdateNeededResponseDto: IIsFirmwareUpdateNeededResponseDto;
  }>(getIsFirmwareNeededToUpdateQuery, {
    fetchPolicy: "network-only"
  });

  const isFirmwareUpdateNeeded =
    data?.isFirmwareUpdateNeededResponseDto.isFirmwareUpdateNeeded;

  const getResourcesLink = (): string => {
    switch (selectedLanguage) {
      case LanguageEnum.De:
        return isOperator ? resourcesOperator_De : resourcesPatient_De;
      case LanguageEnum.En:
      default:
        return isOperator ? resourcesOperator_En : resourcesPatient_En;
    }
  };

  useEffect(() => {
    downloadPDF(
      "customFiles/Questionnaire",
      handlePdfResponse,
      CustomFilesEnum.Questionnaire
    );
    downloadPDF("customFiles/DPA", handlePdfResponse, CustomFilesEnum.DPA);
  }, []);

  const handlePdfResponse = (
    response: string | undefined,
    customFileEnum: CustomFilesEnum
  ) => {
    if (response) {
      switch (customFileEnum) {
        case CustomFilesEnum.DPA:
          setDPADownloadSuccess(true);
          setDPADownloadLink(response);
          break;
        case CustomFilesEnum.Questionnaire:
          setQuestionnaireDownloadSuccess(true);
          setQuestionnaireDownloadLink(response);
          break;
      }
    } else {
      switch (customFileEnum) {
        case CustomFilesEnum.DPA:
          setDPADownloadSuccess(false);
          break;
        case CustomFilesEnum.Questionnaire:
          setQuestionnaireDownloadSuccess(false);
          break;
      }
    }
  };

  const handleLinkClickWithNoFile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Error,
        t(TranslationsEnum.Global_FailedFileDownload)
      )
    );
  };

  const emptyFunction = (event: React.MouseEvent<HTMLElement>) => {};

  return (
    <SwipeableDrawer
      className={classes.drawer}
      variant={!matches ? "temporary" : "persistent"}
      anchor="left"
      open={isDrawerOpen}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper
      }}
      PaperProps={{
        elevation: 4
      }}
    >
      <Box className={classes.drawerHeader}>
        <img
          src={MyoSwissLogoWhite}
          alt="myo swiss logo"
          className={classes.logo}
          onClick={handleListItemClick(PathNamesEnum.Home)}
        />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon className={classes.white} />
        </IconButton>
      </Box>
      {userProfile !== undefined ? (
        <List component="nav" className={classes.list}>
          {sideMenuListItems(
            t,
            userProfile,
            pendingPatientsCount,
            messagesCount,
            isFirmwareUpdateNeeded,
            classes
          ).map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              to={item.path}
              className={classes.listItem}
              classes={{
                selected: classes.selected
              }}
              button
              selected={path === item.path}
            >
              {item.icon}
              <ListItemText
                primary={item.text}
                className={classes.listItemText}
              />
              {Boolean(item.badgeCounter) && (
                <Box mr={1}>
                  <Badge
                    classes={{
                      badge: classes.badge
                    }}
                    badgeContent={item.badgeCounter}
                  >
                    {""}
                  </Badge>
                </Box>
              )}{" "}
              {item.badgeIcon && item.badgeIcon}
            </ListItem>
          ))}
          <ListItem
            component="a"
            href={getResourcesLink()}
            target={"_blank"}
            className={classes.listItem}
            button
          >
            <EmojiObjectsIcon />
            <ListItemText
              primary={t(TranslationsEnum.Components_Header_SideMenu_Resources)}
              className={classes.listItemText}
            />
          </ListItem>
          <ListItem
            onClick={
              DPADownloadSuccess ? emptyFunction : handleLinkClickWithNoFile
            }
            component="a"
            href={DPADownloadLink}
            download={isElectron() ? "DPA.pdf" : undefined}
            target={"_blank"}
            className={classes.listItem}
            button
          >
            <VerifiedUserIcon />
            <ListItemText
              primary={t(
                TranslationsEnum.Components_Header_SideMenu_DPADownload
              )}
              className={classes.listItemText}
            />
          </ListItem>
          <ListItem
            onClick={
              QuestionnaireDownloadSuccess
                ? emptyFunction
                : handleLinkClickWithNoFile
            }
            component="a"
            target={"_blank"}
            download={isElectron() ? "Questionnaire.pdf" : undefined}
            href={QuestionnaireDownloadLink}
            className={classes.listItem}
            button
          >
            <AssignmentIcon />
            <ListItemText
              primary={t(
                TranslationsEnum.Components_Header_SideMenu_QuestionnaireDownload
              )}
              className={classes.listItemText}
            />
          </ListItem>
        </List>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={48} color="inherit" />
        </Box>
      )}
      <Box className={classes.linksContainer}>
        <MuiLink
          href={selectedLanguage === LanguageEnum.En ? DPA_En : DPA_De}
          target={"_blank"}
          underline="always"
          className={classes.link}
        >
          {t(TranslationsEnum.Components_Header_SideMenu_DPALink)}
        </MuiLink>
        <MuiLink
          href={
            selectedLanguage === LanguageEnum.En ? impressum_En : impressum_De
          }
          target={"_blank"}
          underline="always"
          className={classes.link}
        >
          {t(TranslationsEnum.Components_Header_SideMenu_Impressum)}
        </MuiLink>
        <span className={classes.version}>
          v{process.env.REACT_APP_VERSION}
        </span>
      </Box>
    </SwipeableDrawer>
  );
};

export default SideMenu;
