import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import clsx from "clsx";
import { Box, IconButton, CircularProgress } from "@material-ui/core";
import "../../../../utils/charts/roundedBarCorners.js";
import { ColorEnum } from "../../../../types/enums/ColorEnum";
import { ISessionData } from "../../../../types/ISessionData";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { normalizeSessionData } from "../../../../utils/helpers/parsingHelper";
import { ITooltipItem } from "../../../../types/ITooltipItem";
import { useDispatch } from "react-redux";
import {
  selectActiveColumns,
  selectSessionsChartConfig,
  selectSessionsChartBarIndex
} from "../../../../utils/helpers/stateSelectorHelpers";
import { actions } from "../../../../state/sessions/actions";
import { useSelector } from "../../../../state/store";
import { format } from "date-fns";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import useStyles from "./ChartBody.styles";

const barThickness = 20;

interface IProps {
  loading: boolean;
  lastPage: number;
  sessions: ISessionData[];
}

const ChartBody = (props: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, lastPage, sessions } = props;

  const {
    activeColumns: { showActiveTime, showStairs, showSteps },
    sessionsChartConfig,
    selectedSessionBarIndex
  } = useSelector(state => ({
    ...selectActiveColumns(state),
    ...selectSessionsChartConfig(state),
    ...selectSessionsChartBarIndex(state)
  }));

  const renderBackgroundColor = useCallback(
    (light: string, dark: string) => {
      const backgroundColors = sessions.map((session, index) =>
        index === selectedSessionBarIndex ? dark : light
      );

      return backgroundColors;
    },
    [selectedSessionBarIndex, sessions]
  );

  const getChartData = useCallback(() => {
    const normalizedSessions = normalizeSessionData(sessions);
    const labels: string[] = [];
    const datasets = [];

    if (sessions.length) {
      sessions.forEach(session =>
        labels.push(
          `${format(new Date(session.sessionDate), "d.L.y")} (${
            session.sessionDisplayId
          })`
        )
      );

      showSteps &&
        datasets.push({
          label: t(TranslationsEnum.Global_Steps),
          backgroundColor: renderBackgroundColor(
            ColorEnum.OrangeLight,
            ColorEnum.OrangeDark
          ),
          hoverBackgroundColor: ColorEnum.OrangeDark,
          borderSkipped: false,
          barThickness: barThickness,
          data: normalizedSessions.map(session => session.sessionSteps)
        });

      showStairs &&
        datasets.push({
          label: t(TranslationsEnum.Global_Stairs),
          backgroundColor: renderBackgroundColor(
            ColorEnum.GreenLight,
            ColorEnum.GreenDark
          ),
          hoverBackgroundColor: ColorEnum.GreenDark,
          borderSkipped: false,
          barThickness: barThickness,
          data: normalizedSessions.map(session => session.sessionStairs)
        });

      showActiveTime &&
        datasets.push({
          label: t(TranslationsEnum.Global_ActiveTime),
          backgroundColor: renderBackgroundColor(
            ColorEnum.PurpleLight,
            ColorEnum.PurpleDark
          ),
          hoverBackgroundColor: ColorEnum.PurpleDark,
          borderSkipped: false,
          barThickness: barThickness,
          data: normalizedSessions.map(session => session.sessionActiveRate)
        });
    }

    return { labels, datasets };
  }, [
    renderBackgroundColor,
    sessions,
    showActiveTime,
    showStairs,
    showSteps,
    t
  ]);

  const data = useMemo(() => getChartData(), [getChartData]);
  const [naturalDatasets, setNaturalDatasets] = useState<
    Array<Array<number | string>>
  >([]);

  useEffect(() => {
    naturalDatasets.length = 0;
    if (showSteps) {
      naturalDatasets.push(sessions.map(session => session.sessionSteps));
    }
    if (showStairs) {
      naturalDatasets.push(sessions.map(session => session.sessionStairs));
    }
    if (showActiveTime) {
      naturalDatasets.push(
        sessions.map(session => `${session.sessionActiveRate * 100}%`)
      );
    }
    setNaturalDatasets(naturalDatasets);
  }, [naturalDatasets, sessions, showActiveTime, showStairs, showSteps]);

  const options = useMemo(
    () => ({
      tooltips: {
        callbacks: {
          label: function(tooltipItem: ITooltipItem) {
            return naturalDatasets[tooltipItem.datasetIndex][tooltipItem.index];
          }
        }
      },
      cornerRadius: 100,
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              display: false
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      }
    }),
    [naturalDatasets]
  );

  // sends the event in type `any` by default
  const handleBarElementClick = useCallback(
    (e: Array<any>) => {
      if (e.length) {
        dispatch(actions.setChartSelectedSessionBarIndexAction(e[0]._index));
        dispatch(
          actions.setChartHighlightedSessionIdAction(sessions[e[0]._index].id)
        );
      }
    },
    [dispatch, sessions]
  );

  const handlePageChange = (step: number) => () => {
    dispatch(actions.setChartSelectedSessionBarIndexAction(0));
    dispatch(
      actions.setChartConfigAction({
        ...sessionsChartConfig,
        page: sessionsChartConfig.page + step
      })
    );
  };

  return (
    <Box className={classes.container}>
      {sessionsChartConfig.page !== 0 && (
        <IconButton
          onClick={handlePageChange(-1)}
          className={classes.arrowLeft}
        >
          <ArrowForwardIosIcon
            className={clsx(classes.rotate180, classes.arrowIcon)}
          />
        </IconButton>
      )}
      <Box position="relative" width={"100%"}>
        <Bar
          key="BarChart"
          height={100}
          options={options}
          data={!loading ? data : { labels: [], datasets: [] }}
          onElementsClick={handleBarElementClick}
        />
      </Box>
      {sessionsChartConfig.page !== lastPage && (
        <IconButton
          onClick={handlePageChange(1)}
          className={classes.arrowRight}
        >
          <ArrowForwardIosIcon className={classes.arrowIcon} />
        </IconButton>
      )}
      {loading && (
        <CircularProgress
          size={110}
          color="primary"
          className={classes.circularProgress}
        />
      )}
    </Box>
  );
};

export default ChartBody;
