import gql from "graphql-tag";
import { LanguageEnum } from "../types/enums/LanguageEnum";

export const getPatientsQuery = gql`
  query getPatients($filterConfig: IFilterConfig!) {
    patientsTableDTO(filterConfig: $filterConfig)
      @rest(
        type: "patientsTableDTO"
        path: "patients/filtered"
        method: "POST"
        bodyKey: "filterConfig"
      ) {
      patients
      count
    }
  }
`;

export const getCostApprovalInfo = (
  patientId: number,
  language: LanguageEnum
) => gql`
query getPendingPatientsCount {
  costApprovalForm
    @rest(
      type: "CostApprovalResponseDto"
      path: "patients/CostApproval/${patientId}/${language}"
    ) {
      firstName,
      pathologyId,
      pathology,
      email,
      dateOfBirth,
      genderType,
      inSanitasTrial,
      sanitasTrialStart,
      questionnaireResults,
      clinicAddress,
      mwT10Timestamp,
      mwT2Timestamp,
      bergBalanceTimestamp,
      tinettiTimestamp,
      otherTimestamp,
      mwT10Result,
      mwT2Result,
      bergBalanceResult,
      tinettiResult,
      tugResult,
      otherResult,
  }
}
`;

export const getPatientsCountQuery = gql`
  query getPatientsCount {
    getPatientsCount @rest(type: "PatientsCountDto", path: "patients/count") {
      count
    }
  }
`;

export const getPendingPatientsCountQuery = gql`
  query getPendingPatientsCount {
    pendingPatientsCount
      @rest(
        type: "GetPendingPatientsCountDto"
        path: "patients/pendingPatientsCount"
      ) {
      pendingPatientsCount
    }
  }
`;

export const getPatientQueryForOperator = (
  patientId: number,
  language: LanguageEnum
) => gql`
  query getPatient {
    getPatient @rest(
      type: "GetPatientResponseDto", 
      path: "patients/${patientId}/${language}"){
      id,
      name,
      pathology,
      height,
      weight,
      dataProcessingAgreement,
      dataProcessingAgreementSendAt,
      email,
      timeSinceDiagnosis,
      dateOfBirth,
      suggestedPathology,
      pathologyId,
      phoneNumber,
      preferredLanguage,
      requestedClinicId,
      requestedClinicStatus,
      genderType,
      inSanitasTrial,
      questionnaire,
      isQuestionnaireDone,
      questionnaireResults,
      sanitasTrialStart,
      sanitasTrialEnd,
      mwT10Timestamp,
      mwT2Timestamp,
      bergBalanceTimestamp,
      tinettiTimestamp,
      tugTimestamp,
      otherTimestamp,
      mwT10Result,
      mwT2Result,
      bergBalanceResult,
      tinettiResult,
      tugResult,
      otherResult,
    }
  }
`;

export const getPatientQueryForPatient = (language: LanguageEnum) => gql`
  query getPatient {
    getPatient @rest(type: "GetPatientResponseDto", path: "patients/getFromClaims/${language}") {
      id,
      name,
      pathology,
      height,
      weight,
      dataProcessingAgreement,
      email,
      timeSinceDiagnosis,
      dateOfBirth,
      suggestedPathology,
      pathologyId,
      phoneNumber,
      preferredLanguage,
      requestedClinicId,
      requestedClinicStatus,
      requestedClinicName,
      clinicId,
      clinicName,
      isNewUser,
      genderType,
      inSanitasTrial,
      questionnaire
    }
  }
`;

export const getPatientsProfileQueryForOperator = (patientId: number) => gql`
query getPatientsProfile {
  getPatientsProfile @rest(type: "GetPatientProfileResponseDto", path: "patientProfile/${patientId}") {
    size01,
    size02,
    size03,
    size04,
    size05,
    size06,
    size07,
    concFR,
    concFL,
    eccFR,
    eccFL,
  }
}
`;

export const getPatientsProfileQueryForPatient = gql`
  query getPatientsProfile {
    getPatientsProfile
      @rest(type: "GetPatientProfileResponseDto", path: "patientProfile") {
      size01
      size02
      size03
      size04
      size05
      size06
      size07
      concFR
      concFL
      eccFR
      eccFL
    }
  }
`;

export const getSuitCalibrationQueryForOperator = (patientId: number) => gql`
query getSuitCalibration {
  getSuitCalibration @rest(type: "getSuitCalibration", path: "patientProfile/calibration/${patientId}") {
    isCalibrated,
    lastCalibrated
  }
}
`;

export const getSuitCalibrationQueryForPatient = gql`
  query getSuitCalibration {
    getSuitCalibration
      @rest(type: "getSuitCalibration", path: "patientProfile/calibration") {
      isCalibrated
      lastCalibrated
    }
  }
`;

export const getPatientDPAQuery = (DPAGUID: string) => gql`
  query getPatientDPA {
    patientDPA @rest(type: "PatientDPADto", path: "patients/DPA/${DPAGUID}") {
      dataProcessingAgreement,
      preferredLanguage
    }
  }
`;

export const getPatientEmailByGuidQuery = (DPAGUID: string) => gql`
  query getPatientEmailByGuid {
   patientEmail @rest(type: "IPatientEmailByGuid", path: "patients/getEmail/${DPAGUID}") {
      email
    }
  }
`;
