import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { TranslationsEnum } from "../../../types/enums/TranslationsEnum";
import { useTranslation } from "react-i18next";
import { IEditSessionGoalsDto } from "../../../types/requestDtos/IEditSessionGoalsDto";
import { Field, Form, Formik } from "formik";
import { ISessionGoal } from "../../../types/ISessionGoal";
import { TextField } from "formik-material-ui";
import { IFileInfo } from "../../../types/files/IFileInfo";

interface IProps {
  infosOfUploadedFiles: IFileInfo[];
  open: boolean;
  handleClose: () => void;
  handleSetSessionGoals: (sessionGoals: IEditSessionGoalsDto) => void;
}

const SessionCommentsModal: React.FC<IProps> = ({
  infosOfUploadedFiles,
  open,
  handleClose,
  handleSetSessionGoals,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<IEditSessionGoalsDto>({
    sessionGoals: [],
  });
  const infosOfUploadedFilesLoaded = infosOfUploadedFiles?.length;
  useEffect(() => {
    setInitialValues({
      sessionGoals: infosOfUploadedFiles
        .filter((infoOfUploadedFile) =>
          infoOfUploadedFile.fileName.includes("Session_")
        )
        .map((infoOfUploadedFile) => {
          const sessionGoal: ISessionGoal = {
            sessionGuid: infoOfUploadedFile.sessionGuid,
            goal: "",
            goalDescription: "",
            startOfSession: new Date(
              infoOfUploadedFile.startOfSession
            ).toLocaleDateString(),
            patientId: infoOfUploadedFile.patientId,
            patientName: infoOfUploadedFile.patientName,
          };
          infoOfUploadedFile.mwT10Timestamp &&
            (sessionGoal.mwT10Result = infoOfUploadedFile.mwT10Result);
          infoOfUploadedFile.mwT2Timestamp &&
            (sessionGoal.mwT2Result = infoOfUploadedFile.mwT2Result);
          infoOfUploadedFile.bergBalanceTimestamp &&
            (sessionGoal.bergBalanceResult =
              infoOfUploadedFile.bergBalanceResult);
          infoOfUploadedFile.tinettiTimestamp &&
            (sessionGoal.tinettiResult = infoOfUploadedFile.tinettiResult);
          infoOfUploadedFile.tugTimestamp &&
            (sessionGoal.tugResult = infoOfUploadedFile.tugResult);
          infoOfUploadedFile.otherTimestamp &&
            (sessionGoal.otherResult = infoOfUploadedFile.otherResult);

          return sessionGoal;
        }),
    });
  }, [infosOfUploadedFilesLoaded, infosOfUploadedFiles]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={(values) => {
          handleSetSessionGoals(values);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <DialogContent>
                <Typography gutterBottom variant="h5" color="primary">
                  {t(
                    TranslationsEnum.Containers_Modals_SessionCommentsModal_AddComments
                  )}
                </Typography>
                {values.sessionGoals.map((value, index) => {
                  const valueName = "sessionGoals[" + index + "].";
                  if (!value) {
                    return <></>;
                  }
                  return (
                    <Box key={valueName} m={2}>
                      <Card>
                        <CardContent>
                          <Typography>
                            {"" +
                              t(TranslationsEnum.Global_Patient) +
                              ": " +
                              value.patientName +
                              ", " +
                              t(TranslationsEnum.Global_ID) +
                              ": " +
                              value.patientId}
                          </Typography>
                          <Typography>
                            {t(
                              TranslationsEnum.Containers_Modals_SessionCommentsModal_SessionDate
                            ) +
                              ": " +
                              value.startOfSession}
                          </Typography>
                          <Divider />
                          <Box m={2}>
                            <Typography variant="h6" color="secondary">
                              {t(
                                TranslationsEnum.Containers_Modals_SessionCommentsModal_AddGoal
                              )}
                            </Typography>
                            <Grid container spacing={2} direction="row">
                              <Grid item>
                                <Field
                                  type="text"
                                  fullWidth
                                  rowsMax={10}
                                  multiline
                                  margin="dense"
                                  variant="outlined"
                                  component={TextField}
                                  name={valueName + "goal"}
                                  label={t(
                                    TranslationsEnum.Containers_Modals_SessionCommentsModal_Goal
                                  )}
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  type="text"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  rowsMax={10}
                                  multiline
                                  component={TextField}
                                  name={valueName + "goalDescription"}
                                  label={t(
                                    TranslationsEnum.Containers_Modals_SessionCommentsModal_Comment
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider />
                          <Box m={2}>
                            {(value.mwT10Result ||
                              value.mwT2Result ||
                              value.bergBalanceResult ||
                              value.tinettiResult ||
                              value.tugResult ||
                              value.otherResult) && (
                              <Typography variant="h6" color="secondary">
                                {t(
                                  TranslationsEnum.Containers_Modals_SessionCommentsModal_AddResults
                                )}
                              </Typography>
                            )}
                            <Grid
                              container
                              spacing={1}
                              alignItems="flex-end"
                              direction="row"
                            >
                              {value.mwT10Result !== 0 && value.mwT10Result && (
                                <Grid item>
                                  <Field
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    component={TextField}
                                    name={valueName + "mwT10Result"}
                                    label={t(
                                      TranslationsEnum.Containers_Modals_SessionCommentsModal_Met10
                                    )}
                                  />
                                </Grid>
                              )}
                              {value.mwT2Result !== 0 && value.mwT2Result && (
                                <Grid item>
                                  <Field
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    component={TextField}
                                    name={valueName + "mwT2Result"}
                                    label={t(
                                      TranslationsEnum.Containers_Modals_SessionCommentsModal_Min2
                                    )}
                                  />
                                </Grid>
                              )}
                              {value.bergBalanceResult !== 0 &&
                                value.bergBalanceResult && (
                                  <Grid item>
                                    <Field
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      component={TextField}
                                      name={valueName + "bergBalanceResult"}
                                      label={t(
                                        TranslationsEnum.Containers_Modals_SessionCommentsModal_Berg
                                      )}
                                    />
                                  </Grid>
                                )}
                              {value.tinettiResult !== 0 &&
                                value.tinettiResult && (
                                  <Grid item>
                                    <Field
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      component={TextField}
                                      name={valueName + "tinettiResult"}
                                      label={t(
                                        TranslationsEnum.Containers_Modals_SessionCommentsModal_Tinetti
                                      )}
                                    />
                                  </Grid>
                                )}
                              {value.tugResult !== 0 && value.tugResult && (
                                <Grid item>
                                  <Field
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    component={TextField}
                                    name={valueName + "tugResult"}
                                    label={t(
                                      TranslationsEnum.Containers_Modals_SessionCommentsModal_Tug
                                    )}
                                  />
                                </Grid>
                              )}
                              {value.otherResult !== 0 && value.otherResult && (
                                <Grid item>
                                  <Field
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    component={TextField}
                                    name={valueName + "otherResult"}
                                    label={t(
                                      TranslationsEnum.Containers_Modals_SessionCommentsModal_Other
                                    )}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  );
                })}
              </DialogContent>
              <Box m={2}>
                <DialogActions>
                  <Grid container direction="row" spacing={2} justify="center">
                    <Grid item>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        onClick={handleClose}
                        color="primary"
                        variant="contained"
                      >
                        {t(TranslationsEnum.Global_Save)}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default SessionCommentsModal;
