import React from "react";
import { Box } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { DropzoneText } from "./DropzoneText/DropzoneText";
import { sessionFilesExtension } from "../../shared-constants/files";
import useStyles from "./Dropzone.styles";

const Dropzone: React.FC<DropzoneOptions> = ({ onDrop }) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: sessionFilesExtension
  });

  return (
    <Box {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <CloudUploadIcon className={classes.blue} />
      <DropzoneText isDragActive={isDragActive} />
    </Box>
  );
};

export default Dropzone;
