import gql from "graphql-tag";

export const getPathologiesListQuery = gql`
  query getPathologiesList($getPathologiesDto: IGetPathologiesDto!) {
    pathologiesDto(getPathologiesDto: $getPathologiesDto)
      @rest(
        type: "pathologiesDto"
        path: "pathologies"
        method: "POST"
        bodyKey: "getPathologiesDto"
      ) {
      pathologies
    }
  }
`;
