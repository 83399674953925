import React, { useState, useEffect } from "react";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { useUtils } from "@material-ui/pickers";
import clsx from "clsx";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { min as minDate, max as maxDate } from "date-fns";
import useStyles from "./DateRangePicker.styles";
import { useTranslation } from "react-i18next";

export interface DateRange {
  begin: MaterialUiPickersDate;
  end: MaterialUiPickersDate;
}

export interface DateRangePickerProps
  extends Omit<DatePickerProps, "onChange" | "value" | "labelFunc"> {
  value?: DateRange;
  onChange: (value: DateRange) => void;
  labelFunc?: (value: DateRange, invalidLabel: string) => string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onOpen,
  onClose,
  open: openForward,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [begin, setBegin] = useState<MaterialUiPickersDate>(
    value?.begin ?? null
  );
  const [end, setEnd] = useState<MaterialUiPickersDate>(value?.end ?? null);
  const [prevBegin, setPrevBegin] = useState<MaterialUiPickersDate>(begin);
  const [prevEnd, setPrevEnd] = useState<MaterialUiPickersDate>(end);
  const [hasClicked, setHasClicked] = useState(false);

  const [hover, setHover] = useState<MaterialUiPickersDate>(null);
  const [accepted, setAccepted] = useState(false);
  const utils = useUtils();

  const min = begin && (end || hover) ? minDate([begin, end || hover!]) : null;
  const max = begin && (end || hover) ? maxDate([begin, end || hover!]) : null;

  const [open, setOpen] = useState(false);

  const isOpen = openForward !== undefined ? openForward : open;

  useEffect(() => {
    //Only way to get to this state is is openForward is used
    if (isOpen && accepted && !prevBegin && !prevEnd) {
      setAccepted(false);
      setPrevBegin(begin);
      setPrevEnd(end);
      return;
    }
    //Closed without accepting, reset to prev state, don't find onChange
    if (!isOpen && !accepted) {
      setBegin(prevBegin);
      setEnd(prevEnd);
      setHover(null);
      setHasClicked(false);
    }
    //Auto ok and hasn't been accepted, but has all the items set, accept and close.
    //This will also triger the on change event by setting isOpen to false
    if (isOpen && autoOk && !accepted && begin && end && hasClicked) {
      setAccepted(true);
      onClose ? onClose() : setOpen(false);
    }
    if (accepted && begin && end && !isOpen && hasClicked) {
      setHasClicked(false);
      onChange({ begin, end });
      onClose ? onClose() : setOpen(false);
    }
  }, [
    begin,
    end,
    autoOk,
    accepted,
    isOpen,
    prevBegin,
    hasClicked,
    prevEnd,
    onChange,
    onClose
  ]);

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    const datePickerElement = dayComponent as React.ReactElement<
      DatePickerProps
    >;
    return React.cloneElement(datePickerElement, {
      onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHasClicked(true);
        event.stopPropagation();
        if (!begin) {
          setBegin(day);
        } else if (!end) {
          if (utils.isBeforeDay(day, begin)) {
            setEnd(begin);
            setBegin(day);
          } else {
            setEnd(day);
          }
          if (autoOk) {
            setPrevBegin(null);
            setPrevEnd(null);
          }
        } else {
          setBegin(day);
          setEnd(null);
        }
      },
      onMouseEnter: () => requestAnimationFrame(() => setHover(day)),
      onFocus: () => requestAnimationFrame(() => setHover(day)),
      className: clsx(classes.day, {
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        [classes.dayDisabled]: dayComponent.props.disabled,
        [classes.focused]:
          (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
          utils.isSameDay(day, min) ||
          utils.isSameDay(day, max),
        [classes.focusedRange]:
          (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
          (utils.isSameDay(day, min) && !utils.isSameDay(day, max)) ||
          (utils.isSameDay(day, max) && !utils.isSameDay(day, min)),
        [classes.focusedFirst]:
          utils.isSameDay(day, min) && !utils.isSameDay(day, max),
        [classes.focusedLast]:
          utils.isSameDay(day, max) && !utils.isSameDay(day, min),
        [classes.beginCap]: utils.isSameDay(day, min),
        [classes.endCap]: utils.isSameDay(day, max)
      })
    });
  };

  const formatDate = (date: Date) =>
    utils.format(date, format || utils.dateFormat);

  return (
    <DatePicker
      {...rest}
      value={begin}
      renderDay={renderDay}
      open={isOpen}
      cancelLabel={t("Global.Cancel")}
      okLabel={t("Global.Ok")}
      onOpen={() => {
        setAccepted(false);
        setPrevBegin(begin);
        setPrevEnd(end);
        onOpen ? onOpen() : setOpen(true);
      }}
      onAccept={() => {
        setHasClicked(true);
        if (!begin || !end) {
          if (hover && utils.isBeforeDay(begin, hover)) {
            setEnd(hover);
          } else {
            setEnd(begin);
            setBegin(hover);
          }
        }
        setPrevBegin(null);
        setPrevEnd(null);
        //TODO: check if needed
        // if (!autoOk) {
        setAccepted(true);
        // }
      }}
      onClose={() => {
        onClose ? onClose() : setOpen(false);
      }}
      onChange={() => {}}
      labelFunc={(date, invalid) =>
        !isOpen
          ? labelFunc
            ? labelFunc({ begin, end }, invalid)
            : date && begin && end
            ? `${formatDate(begin)} - ${formatDate(end)}`
            : emptyLabel || ""
          : prevBegin && prevEnd
          ? labelFunc
            ? labelFunc({ begin: prevBegin, end: prevEnd }, invalid)
            : `${formatDate(prevBegin)} - ${formatDate(prevEnd)}`
          : emptyLabel || ""
      }
      DialogProps={{ className: classes.dateRangePickerDialog }}
    />
  );
};

export default DateRangePicker;
