import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Home from "../containers/Home/Home";
import ErrorPage from "../containers/ErrorPage/ErrorPage";
import Patients from "../containers/Patients/Patients";
import { PathNamesEnum } from "../types/enums/PathNamesEnum";
import PatientsInnerPage from "../containers/PatientsInnerPage/PatientsInnerPage";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PrepareSession from "../containers/PrepareSession/PrepareSession";
import { useQuery } from "@apollo/react-hooks";
import { IUserProfile } from "../types/IUserProfile";
import { getUserProfileQuery } from "../queries";
import { useErrorHandling } from "../utils/helpers/queryHelpers";
import { useDispatch } from "react-redux";
import { actions } from "../state/user/actions";
import { Box, CircularProgress } from "@material-ui/core";
import DownloadDesktopApp from "../containers/DownloadDesktopApp/DownloadDesktopApp";
import { isDesktop } from "../utils/helpers/platformHelpers";
import SyncMyoSuitSwitch from "../containers/SyncMyoSuit/SyncMyoSuitSwitch";
import { isElectron } from "../utils/helpers/electronHelpers";
import { ClaimsEnum, TranslationsEnum } from "../types/enums";
import EnterRegistrationKeyPage from "../containers/EnterRegistrationKeyPage/EnterRegistrationKeyPage";
import { authProviderSignUpSignIn } from "../utils/configs/authProvider";
import { selectUserProfile } from "../utils/helpers/stateSelectorHelpers";
import { useSelector } from "../state/store";
import DownloadSuccessPage from "../containers/DownloadSuccessPage/DownloadSuccessPage";
import NoSuitInstructions from "../containers/NoSuitInstructions/NoSuitInstructions";
import Messages from "../containers/Messages/Messages";
import Clinics from "../containers/Clinics/Clinics";
import Message from "../containers/Message/Message";
import CreateMessage from "../containers/CreateMessage/CreateMessage";
import EditMessage from "../containers/EditMessage/EditMessage";
import Operators from "../containers/Operators/Operators";
import Units from "../containers/Units/Units";
import PatientsAdmin from "../containers/PatientsAdmin/PatientsAdmin";
import CostApproval from "../containers/CostApproval/CostApproval";

const PrivateRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data, error, loading } = useQuery<{
    userProfile: IUserProfile;
  }>(getUserProfileQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => dispatch(actions.setUserProfileAction(data?.userProfile))
  });
  useErrorHandling(error);

  const { userProfile } = useSelector(selectUserProfile);

  if (location.pathname === PathNamesEnum.PatientRegistration) {
    authProviderSignUpSignIn.logout();
    return null;
  }

  if (
    userProfile?.claimsType === ClaimsEnum.Patient &&
    userProfile?.dataProcessingAgreement !== true
  ) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: `${PathNamesEnum.TermsOfAgreements}/${userProfile?.dataProcessingAgreementGuid}`,
            state: { from: location }
          }}
        />
      </Route>
    );
  }

  if (
    userProfile?.claimsType === ClaimsEnum.Patient &&
    location.pathname === PathNamesEnum.Home
  ) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: `${PathNamesEnum.Patients}/${userProfile.id}`,
            search: "?tab=0",
            state: { from: location }
          }}
        />
      </Route>
    );
  }

  if (
    userProfile?.claimsType === ClaimsEnum.Admin &&
    location.pathname === PathNamesEnum.Home
  ) {
    return (
      <Route>
        <Redirect
          to={{
            pathname: PathNamesEnum.Messages,
            state: { from: location }
          }}
        />
      </Route>
    );
  }

  if (loading || userProfile === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={12.5}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  return (
    <Switch>
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Home}
        component={Home}
        userProfile={userProfile}
        claims={ClaimsEnum.Operator}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.PatientsAdmin}
        component={PatientsAdmin}
        userProfile={userProfile}
        claims={ClaimsEnum.Admin}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Patients}
        component={Patients}
        userProfile={userProfile}
        claims={ClaimsEnum.Operator}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Patient}
        component={PatientsInnerPage}
        userProfile={userProfile}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.SyncMyoSuit}
        component={SyncMyoSuitSwitch}
        userProfile={userProfile}
        claims={[ClaimsEnum.Operator, ClaimsEnum.Patient]}
      />
      {isElectron() && (
        <PrivateRoute
          exact={true}
          path={PathNamesEnum.DownloadSuccessPage}
          component={DownloadSuccessPage}
          userProfile={userProfile}
          claims={ClaimsEnum.Patient}
        />
      )}
      {isElectron() && (
        <PrivateRoute
          exact={true}
          path={PathNamesEnum.NoSuitInstructions}
          component={NoSuitInstructions}
          userProfile={userProfile}
          claims={ClaimsEnum.Patient}
        />
      )}
      {isDesktop() && !isElectron() && (
        <PrivateRoute
          exact={true}
          path={PathNamesEnum.DownloadDesktopApp}
          component={DownloadDesktopApp}
          userProfile={userProfile}
          claims={[ClaimsEnum.Operator, ClaimsEnum.Patient, ClaimsEnum.Admin]}
        />
      )}
      {userProfile?.claimsType === ClaimsEnum.Operator &&
        userProfile?.clinicInSanitasTrial && (
          <PrivateRoute
            exact={true}
            path={PathNamesEnum.CostApproval}
            component={CostApproval}
            userProfile={userProfile}
            claims={ClaimsEnum.Patient}
          />
        )}

      <PrivateRoute
        exact={true}
        path={PathNamesEnum.PrepareSession}
        component={PrepareSession}
        userProfile={userProfile}
        claims={ClaimsEnum.Operator}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Operators}
        component={Operators}
        userProfile={userProfile}
        claims={ClaimsEnum.Admin}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Units}
        component={Units}
        userProfile={userProfile}
        claims={ClaimsEnum.Admin}
      />
      {userProfile?.claimsType === ClaimsEnum.Operator && (
        <Route exact={true} path={PathNamesEnum.Contact}>
          <ErrorPage
            titleTextPath={
              TranslationsEnum.Containers_PageContact_PageContactTitle
            }
            subtitleTextPath={
              TranslationsEnum.Containers_PageContact_PageContactSubtitle
            }
            buttonTextPath={
              TranslationsEnum.Containers_PageContact_SupportButton
            }
            hasContactButton={true}
          />
        </Route>
      )}
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Clinics}
        component={Clinics}
        userProfile={userProfile}
        claims={ClaimsEnum.Admin}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Messages}
        component={Messages}
        userProfile={userProfile}
        claims={[ClaimsEnum.Operator, ClaimsEnum.Patient, ClaimsEnum.Admin]}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.CreateMessage}
        component={CreateMessage}
        userProfile={userProfile}
        claims={[ClaimsEnum.Admin]}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.EditMessage}
        component={EditMessage}
        userProfile={userProfile}
        claims={[ClaimsEnum.Admin]}
      />
      <PrivateRoute
        exact={true}
        path={PathNamesEnum.Message}
        component={Message}
        userProfile={userProfile}
        claims={[ClaimsEnum.Operator, ClaimsEnum.Patient, ClaimsEnum.Admin]}
      />
      {userProfile === null && (
        <Route exact={true} path={PathNamesEnum.RegistrationKey}>
          <EnterRegistrationKeyPage />
        </Route>
      )}
      <Route exact={true} path={PathNamesEnum.NoConnection}>
        <ErrorPage
          titleTextPath={
            TranslationsEnum.Containers_PageNoConnection_PageNoConnectionTitle
          }
          subtitleTextPath={
            TranslationsEnum.Containers_PageNoConnection_PageNoConnectionSubtitle
          }
          buttonTextPath={TranslationsEnum.Containers_PageContact_SupportButton}
          hasContactButton={true}
        />
      </Route>
      <Route exact={true} path={PathNamesEnum.Forbidden}>
        <ErrorPage
          titleTextPath={
            TranslationsEnum.Containers_PageUnauthorized_PageUnauthorizedTitle
          }
          subtitleTextPath={
            TranslationsEnum.Containers_PageUnauthorized_PageUnauthorizedSubtitle
          }
        />
      </Route>
      <Route>
        <ErrorPage
          titleTextPath={TranslationsEnum.Containers_PageNotFound_PageTitle404}
          subtitleTextPath={
            TranslationsEnum.Containers_PageNotFound_PageSubTitle404
          }
          buttonTextPath={TranslationsEnum.Containers_PageNotFound_HomeButton}
          hasBackHomeButton={true}
        />
      </Route>
    </Switch>
  );
};

export default PrivateRoutes;
