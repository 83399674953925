import { TFunction } from "i18next";
import { TimeSinceDiagnosisEnum } from "../types/enums/TimeSinceDiagnosisEnum";
import { IOption } from "../types/formInputs/IOption";
import i18n from "../i18n";
import {
  TranslationsEnum,
  GenderEnum,
  UnitStatusEnum,
  UnitStatusSourceEnum
} from "../types/enums";
import { MessageReceiverTypeEnum } from "../types/enums/MessageReceiverTypeEnum";
import { ClinicStatusEnum } from "../types/enums/ClinicStatusEnum";
import { ProfitLevelResultEnum } from "../types/enums/ProfitLevelResultEnum";

export const options5: IOption[] = [
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "5",
    value: "5"
  }
];

export const options2: IOption[] = [
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  }
];

export const optionsSML: IOption[] = [
  {
    label: "S",
    value: "S"
  },
  {
    label: "M",
    value: "M"
  },
  {
    label: "L",
    value: "L"
  }
];

export const optionsPeriods = (t: TFunction): IOption[] => [
  {
    label: t("Options.OptionsPeriods.Months0to3"),
    value: TimeSinceDiagnosisEnum.Months0_3
  },
  {
    label: t("Options.OptionsPeriods.Months3to6"),
    value: TimeSinceDiagnosisEnum.Months3_6
  },
  {
    label: t("Options.OptionsPeriods.Months6to1"),
    value: TimeSinceDiagnosisEnum.Months6_1
  },
  {
    label: t("Options.OptionsPeriods.Years1to2"),
    value: TimeSinceDiagnosisEnum.Years1_2
  },
  {
    label: t("Options.OptionsPeriods.Years2to5"),
    value: TimeSinceDiagnosisEnum.Years2_5
  },
  {
    label: t("Options.OptionsPeriods.Years5to10"),

    value: TimeSinceDiagnosisEnum.Years5_10
  },
  {
    label: t("Options.OptionsPeriods.Years10Plus"),
    value: TimeSinceDiagnosisEnum.Years10
  }
];

export const genderOptions = (t: TFunction): IOption[] => [
  {
    label: t(TranslationsEnum.Global_Male),
    value: GenderEnum.Male
  },
  {
    label: t(TranslationsEnum.Global_Female),
    value: GenderEnum.Female
  },
  {
    label: t(TranslationsEnum.Global_Other),
    value: GenderEnum.Other
  }
];

export const profitLevelOptions: IOption[] = [
  {
    label: i18n.t(
      TranslationsEnum.Containers_Modals_Questionnaire_Results_NoProfit
    ),
    value: ProfitLevelResultEnum.NoProfit
  },
  {
    label: i18n.t(
      TranslationsEnum.Containers_Modals_Questionnaire_Results_NoProfit
    ),
    value: ProfitLevelResultEnum.NormalProfit
  },
  {
    label: i18n.t(
      TranslationsEnum.Containers_Modals_Questionnaire_Results_OptimalProfit
    ),
    value: ProfitLevelResultEnum.OptimalProfit
  }
];

export const messageOptions: IOption[] = [
  {
    label: i18n.t(TranslationsEnum.Containers_CreateEditMessage_PatientGroup),
    value: MessageReceiverTypeEnum.PatientGroup
  },
  {
    label: i18n.t(TranslationsEnum.Containers_CreateEditMessage_OperatorGroup),
    value: MessageReceiverTypeEnum.OperatorGroup
  },
  {
    label: i18n.t(TranslationsEnum.Containers_CreateEditMessage_AllGroups),
    value: MessageReceiverTypeEnum.AllGroups
  },
  {
    label: i18n.t(TranslationsEnum.Containers_CreateEditMessage_Private),
    value: MessageReceiverTypeEnum.Private
  }
];
export const unitStatusOptions: IOption[] = [
  {
    label: i18n.t(TranslationsEnum.Global_Operational),
    value: UnitStatusEnum.Operational
  },
  {
    label: i18n.t(TranslationsEnum.Global_Error),
    value: UnitStatusEnum.Error
  },
  {
    label: i18n.t(TranslationsEnum.Global_Warning),
    value: UnitStatusEnum.Warning
  }
];

export const unitStatusSourceOptions: IOption[] = [
  {
    label: i18n.t(TranslationsEnum.Global_Unknown),
    value: UnitStatusSourceEnum.Unknown
  },
  {
    label: "MyoSuit",
    value: UnitStatusSourceEnum.MyoSuit
  },
  {
    label: i18n.t(TranslationsEnum.Global_Administrator),
    value: UnitStatusSourceEnum.Administrator
  }
];

export const clinicStatusOptions: IOption[] = [
  {
    label: i18n.t(TranslationsEnum.Global_Public),
    value: ClinicStatusEnum.Public
  },
  {
    label: i18n.t(TranslationsEnum.Global_Private),
    value: ClinicStatusEnum.Private
  }
];
