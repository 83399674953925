import { MessageReceiverTypeEnum } from "../../types/enums/MessageReceiverTypeEnum";
import { ClaimsEnum, TranslationsEnum } from "../../types/enums";
import i18n from "../../i18n";
import { useQuery } from "@apollo/react-hooks";
import { getMessagesCount } from "../../queries/messages";
import { useSelector } from "../../state/store";
import { selectUserProfile } from "./stateSelectorHelpers";

export const getMessageReceiverTypeName = (
  messageReceiverType: MessageReceiverTypeEnum
) => {
  switch (messageReceiverType) {
    case MessageReceiverTypeEnum.PatientGroup:
      return i18n.t(TranslationsEnum.Containers_CreateEditMessage_PatientGroup);
    case MessageReceiverTypeEnum.OperatorGroup:
      return i18n.t(
        TranslationsEnum.Containers_CreateEditMessage_OperatorGroup
      );
    case MessageReceiverTypeEnum.AllGroups:
      return i18n.t(TranslationsEnum.Containers_CreateEditMessage_AllGroups);
    case MessageReceiverTypeEnum.Private:
      return i18n.t(TranslationsEnum.Containers_CreateEditMessage_Private);
  }
  return messageReceiverType;
};

export const useMessagesCount = () => {
  const { userProfile } = useSelector(selectUserProfile);
  const skip = !userProfile || userProfile?.claimsType === ClaimsEnum.Admin;
  const { data, error, refetch } = useQuery(getMessagesCount, {
    fetchPolicy: "cache-and-network",
    skip
  });
  return {
    data,
    error,
    refetch
  };
};
