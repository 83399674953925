import React, { useState } from "react";
import { ISvgProps } from "../../types/ISvgProps";
import { ColorEnum } from "../../types/enums/ColorEnum";

interface IProps extends ISvgProps {
  invertColors?: boolean;
}

const InfoIcon = (props: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { invertColors, ...otherProps } = props;

  const handleHover = () => setIsHovered(!isHovered);

  const dark = invertColors ? ColorEnum.White : ColorEnum.DarkGrey;
  const light = invertColors ? ColorEnum.DarkGrey : ColorEnum.White;

  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {isHovered ? (
        <>
          <circle cx={12} cy={12} r={7} fill={dark} />
          <path
            d="M13.88 15.137l-.126.457c-.38.132-.683.233-.909.302a2.69 2.69 0 01-.787.104c-.46 0-.817-.1-1.072-.297a.917.917 0 01-.382-.755c0-.117.01-.239.028-.363.02-.123.05-.263.093-.419l.474-1.483a6.27 6.27 0 00.107-.404c.029-.126.043-.243.043-.348 0-.189-.044-.321-.133-.396-.088-.074-.257-.112-.507-.112-.123 0-.249.017-.377.051-.13.034-.24.066-.332.096l.127-.457c.31-.112.607-.207.891-.287.285-.08.553-.12.807-.12.456 0 .808.098 1.055.293.248.195.371.448.371.76 0 .064-.008.177-.025.34a2.037 2.037 0 01-.095.447l-.473 1.478a3.545 3.545 0 00-.103.406 1.866 1.866 0 00-.047.346c0 .196.05.33.15.401.1.071.271.107.516.107.115 0 .245-.018.39-.054a2.39 2.39 0 00.316-.093zM14 8.934c0 .258-.11.477-.33.658a1.21 1.21 0 01-.796.272c-.31 0-.577-.09-.8-.272-.222-.18-.333-.4-.333-.658 0-.257.111-.477.334-.66.222-.183.488-.274.8-.274.31 0 .575.092.795.274.22.183.33.403.33.66z"
            fill={light}
          />
        </>
      ) : (
        <>
          <circle cx={12} cy={12} r={6.75} stroke={dark} strokeWidth={0.5} />
          <path
            d="M13.88 15.137l-.126.457c-.38.132-.683.233-.909.302a2.69 2.69 0 01-.787.104c-.46 0-.817-.1-1.072-.297a.917.917 0 01-.382-.755c0-.117.01-.239.028-.363.02-.123.05-.263.093-.419l.474-1.483a6.27 6.27 0 00.107-.404c.029-.126.043-.243.043-.348 0-.189-.044-.321-.133-.396-.088-.074-.257-.112-.507-.112-.123 0-.249.017-.377.051-.13.034-.24.066-.332.096l.127-.457c.31-.112.607-.207.891-.287.285-.08.553-.12.807-.12.456 0 .808.098 1.055.293.248.195.371.448.371.76 0 .064-.008.177-.025.34a2.037 2.037 0 01-.095.447l-.473 1.478a3.545 3.545 0 00-.103.406 1.866 1.866 0 00-.047.346c0 .196.05.33.15.401.1.071.271.107.516.107.115 0 .245-.018.39-.054a2.39 2.39 0 00.316-.093zM14 8.934c0 .258-.11.477-.33.658a1.21 1.21 0 01-.796.272c-.31 0-.577-.09-.8-.272-.222-.18-.333-.4-.333-.658 0-.257.111-.477.334-.66.222-.183.488-.274.8-.274.31 0 .575.092.795.274.22.183.33.403.33.66z"
            fill={dark}
          />
        </>
      )}
    </svg>
  );
};

export default InfoIcon;
