import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formItem: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    padding: theme.spacing(2)
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  modalHeader: {
    marginLeft: theme.spacing(1)
  },
  yesButton: {
    background: ColorEnum.Red,
    color: ColorEnum.White,
    "&:hover": {
      background: ColorEnum.DarkRed
    }
  }
}));
