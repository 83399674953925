import React from "react";
import clsx from "clsx";
import { Box, Paper, CircularProgress, Typography } from "@material-ui/core";
import PatientsInnerPageHeader from "./PatientsInnerPageHeader/PatientsInnerPageHeader";
import {
  getPatientQueryForOperator,
  getPatientQueryForPatient
} from "../../queries/patients";
import { IPatient } from "../../types/IPatient";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import MyoSuitSetupTab from "../MyoSuitSetupTab/MyoSuitSetupTab";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { IPatientIdRouteParams } from "../../types/routeParams/IPatientIdRouteParams";
import SessionsTab from "../SessionsTab/SessionsTab";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { useSelector } from "../../state/store";
import { selectUserProfile } from "../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum, TranslationsEnum } from "../../types/enums";
import WarningIcon from "@material-ui/icons/Warning";
import { useTranslation } from "react-i18next";
import {
  cancelClinicJoinMutation,
  unassignFromClinicMutation
} from "../../mutations/patients";
import { actions } from "../../state/snackbar/actions";
import { useDispatch } from "react-redux";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { RequestedClinicStatusEnum } from "../../types/enums/RequestedClinicStatusEnum";
import { defaultClinicId } from "../../constants/defaultValues";
import useStyles from "./PatientsInnerPage.styles";

const PatientsInnerPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { patientId } = useParams<IPatientIdRouteParams>();
  const { tab }: { tab?: string } = queryString.parse(location.search);
  const { userProfile } = useSelector(selectUserProfile);

  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;

  const getPatientQuery = isOperator
    ? getPatientQueryForOperator(Number(patientId)!, getSelectedLanguage())
    : getPatientQueryForPatient(getSelectedLanguage());

  const {
    data: dataGetPatient,
    error: errorGetPatient,
    loading: loadingGetPatient,
    refetch
  } = useQuery<{ getPatient: IPatient }>(getPatientQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [
    cancelClinicJoin,
    { loading: loadingCancelClinicJoin, error: errorCancelClinicJoin }
  ] = useMutation(cancelClinicJoinMutation, {
    onError: () => {},
    onCompleted: () => {
      refetch();
      dispatch(
        actions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_CancelRequestSuccess)
        )
      );
    }
  });

  const [
    unassignFromClinic,
    { loading: loadingUnassignFromClinic, error: errorUnassignFromClinic }
  ] = useMutation(unassignFromClinicMutation, {
    onError: () => {},
    onCompleted: () => {
      refetch();
      dispatch(
        actions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_UnassignFromClinic)
        )
      );
    }
  });

  useErrorHandling(errorGetPatient);
  useErrorHandling(errorCancelClinicJoin);
  useErrorHandling(errorUnassignFromClinic);

  if (loadingGetPatient) {
    return (
      <Box display="flex" justifyContent="center" pt={12.5}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const patient = dataGetPatient?.getPatient;

  if (!patient) {
    return null;
  }

  const handleCancelClick = () =>
    cancelClinicJoin({ variables: { values: {} } });

  const handleUnassigneClick = () =>
    unassignFromClinic({ variables: { values: {} } });

  const renderTab = (tab?: string) => {
    const { id, requestedClinicStatus } = patient;

    switch (Number(tab)) {
      case 1:
        return (
          <MyoSuitSetupTab
            patientRequestedClinicStatus={requestedClinicStatus}
            patientId={id}
          />
        );
      case 0:
      default:
        return <SessionsTab patientId={id} />;
    }
  };

  const renderContent = () => {
    if (dataGetPatient) {
      return (
        <>
          <PatientsInnerPageHeader patient={patient} refetchPatient={refetch} />
          <Paper className={classes.paperContainer}>{renderTab(tab)}</Paper>
        </>
      );
    }

    return null;
  };

  const renderTopBar = () => {
    const {
      clinicId,
      clinicName,
      requestedClinicStatus,
      requestedClinicName
    } = patient;

    if (isPatient && clinicId && clinicId !== defaultClinicId) {
      return (
        <Box
          className={clsx(classes.clinicInfoRow, classes.tealBlueBackground)}
        >
          <Typography
            variant="body2"
            className={clsx(classes.infoText, classes.colorDarkGrey)}
          >
            {t(TranslationsEnum.Containers_PatientsInnerPage_Assigned)}{" "}
            {clinicName}
          </Typography>
          {loadingUnassignFromClinic ? (
            <CircularProgress size={20} />
          ) : (
            <Typography
              variant="body2"
              className={clsx(classes.buttonText, classes.colorMiddleBlack)}
              onClick={handleUnassigneClick}
            >
              {t(TranslationsEnum.Containers_PatientsInnerPage_Unassign)}
            </Typography>
          )}
        </Box>
      );
    }

    if (
      isPatient &&
      requestedClinicStatus === RequestedClinicStatusEnum.Pending
    ) {
      return (
        <Box className={clsx(classes.clinicInfoRow, classes.orangeBackground)}>
          <WarningIcon className={classes.icon} />
          <Typography
            variant="body2"
            className={clsx(classes.infoText, classes.colorWhite)}
          >
            {t(TranslationsEnum.Containers_PatientsInnerPage_Pending)}{" "}
            {requestedClinicName}
          </Typography>
          {loadingCancelClinicJoin ? (
            <CircularProgress size={20} className={classes.colorWhite} />
          ) : (
            <Typography
              variant="body2"
              className={clsx(classes.buttonText, classes.colorWhite)}
              onClick={handleCancelClick}
            >
              {t(TranslationsEnum.Containers_PatientsInnerPage_CancelRequest)}
            </Typography>
          )}
        </Box>
      );
    }

    return null;
  };

  return (
    <Box className={classes.container}>
      {renderTopBar()}
      {renderContent()}
    </Box>
  );
};

export default PatientsInnerPage;
