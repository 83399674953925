import React from "react";
import MaskedInput from "react-text-mask";
import useStyles from "./DataTableFooter.styles";

import {
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  useCallback
} from "react";
import {
  Box,
  Input,
  Hidden,
  Tooltip,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import {
  Check,
  LastPage,
  FirstPage,
  ChevronLeft,
  ChevronRight
} from "@material-ui/icons";
import { createNumberMask } from "text-mask-addons";
import { useTranslation } from "react-i18next";

const integerMask = createNumberMask({
  prefix: "",
  allowLeadingZeroes: false,
  includeThousandsSeparator: false
});

function Masked({ inputRef, mask, ...other }: any) {
  return (
    <MaskedInput
      {...(other as any)}
      mask={integerMask}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      showMask={true}
      placeholderChar={"\u2000"}
    />
  );
}

interface IProps {
  count: number;
  page?: number;
  rowsPerPage: number;
  pagination?: boolean;
  changePage: (page: number) => void;
}

export default function DataTableFooter({
  count,
  page,
  changePage,
  rowsPerPage,
  pagination = true
}: IProps) {
  const [pageText, setPageText] = useState((page ?? 0) + 1 + "");
  const classes = useStyles();
  const loading = false;
  const totalPages = Math.ceil(count / rowsPerPage);
  const parsedPage = parseInt(pageText) - 1;
  const pageTextValid = parsedPage >= 0 && parsedPage < totalPages;
  const { t } = useTranslation();

  const pageTo = (pageNumber: number) => () => changePage(pageNumber);
  const pageToText = useCallback(() => {
    if (pageTextValid) {
      changePage(parsedPage);
    }
  }, [changePage, pageTextValid, parsedPage]);

  const handlePageTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPageText(event.target.value);
    },
    []
  );
  const handleFormSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      pageToText();
    },
    [pageToText]
  );

  useEffect(() => {
    setPageText((page ?? 0) + 1 + "");
  }, [page]);

  const itemFrom = (page || 0) * rowsPerPage + 1;
  const predictedTo = itemFrom + rowsPerPage - 1;
  const itemTo = predictedTo > count ? count : predictedTo;
  const totalItems = count === Infinity ? "∞" : count;

  const hasPrev = (page ?? 0) > 0;
  const hasNext = (page ?? 0) < totalPages - 1;
  const samePage = (page ?? 0) + 1 + "" === pageText;
  return (
    <tfoot>
      <tr>
        <td className={classes.cell}>
          <Box className={classes.wrapper}>
            <Hidden smDown={true}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.text}
              >
                {totalItems === 0
                  ? t("Components.DataTableFooter.0Records")
                  : `${itemFrom}-${itemTo} ${t(
                      "Components.DataTableFooter.Of"
                    )} ${totalItems}`}
              </Typography>
              {loading && (
                <CircularProgress size="1.5em" data-qa="TableProgress" />
              )}
              <div className={classes.spacer} />
              {totalPages > 0 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.text}
                >
                  {`${t("Components.DataTableFooter.Page")} ${(page ?? 0) +
                    1} ${t("Components.DataTableFooter.Of")} ${totalPages}`}
                </Typography>
              )}
            </Hidden>
            <Hidden smUp={true}>
              {loading && (
                <Box className={classes.mobileProgressWrapper}>
                  <CircularProgress size="1.5em" data-qa="TableProgress" />
                </Box>
              )}
            </Hidden>
            <Tooltip title={t("Components.DataTableFooter.First")}>
              <div>
                <IconButton
                  size="small"
                  onClick={pageTo(0)}
                  disabled={!hasPrev}
                >
                  <FirstPage />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={t("Components.DataTableFooter.Previous")}>
              <div>
                <IconButton
                  size="small"
                  onClick={pageTo((page ?? 0) - 1)}
                  disabled={!hasPrev}
                >
                  <ChevronLeft />
                </IconButton>
              </div>
            </Tooltip>
            <Box>
              <form onSubmit={handleFormSubmit}>
                <Input
                  value={pageText ?? 0}
                  className={classes.pageInputWrapper}
                  classes={{ input: classes.pageInput }}
                  onChange={handlePageTextChange}
                  inputComponent={Masked}
                  endAdornment={
                    !samePage && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={pageToText}
                          disabled={!pageTextValid}
                        >
                          <Check />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </form>
            </Box>
            <Tooltip title={t("Components.DataTableFooter.Next")}>
              <div>
                <IconButton
                  size="small"
                  onClick={pageTo((page ?? 0) + 1)}
                  disabled={!hasNext}
                >
                  <ChevronRight />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title={t("Components.DataTableFooter.Last")}>
              <div>
                <IconButton
                  size="small"
                  onClick={pageTo(totalPages - 1)}
                  disabled={!hasNext}
                >
                  <LastPage />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        </td>
      </tr>
    </tfoot>
  );
}
