import translation from "./translation.json";
import translationDPA from "./translationDPA.json";
import translationElectron from "./translationElectron.json";

const translationIT = {
  ...translation,
  ...translationDPA,
  ...translationElectron
};

export default translationIT;
