import React, { useCallback } from "react";
import { Box, Button, Link, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { TranslationsEnum } from "../../types/enums";
import {
  contactEmailAddress,
  myoswissPricingEN,
  myoswissPricingDE
} from "../../constants/contacts";
import useStyles from "./NoSuitInstructions.styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { LanguageEnum } from "../../types/enums/LanguageEnum";

const NoSuitInstructions: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const selectedLanguage = getSelectedLanguage();
  const pricingLink =
    selectedLanguage === LanguageEnum.En
      ? myoswissPricingEN
      : myoswissPricingDE;

  const handleOnHomeClick = useCallback(() => {
    history.push(PathNamesEnum.Home);
  }, [history]);

  const closePatientInstructions = useCallback(() => {
    history.push(PathNamesEnum.SyncMyoSuit);
  }, [history]);

  const renderNoSuitInstructions = useCallback(() => {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart1
          )}{" "}
          <Link href={pricingLink} target={"_blank"} underline="always">
            {pricingLink}
          </Link>
        </Typography>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart2
          )}{" "}
          <Link
            href={`mailto:${contactEmailAddress}`}
            target={"_blank"}
            underline="always"
          >
            {contactEmailAddress}
          </Link>
        </Typography>
      </Box>
    );
  }, [t, classes.darkGrey, classes.instructions, pricingLink]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography
          variant="h6"
          className={clsx(classes.darkGrey, classes.header)}
        >
          {t(TranslationsEnum.Global_NoAvailableMyosuit)}
        </Typography>
        <Box className={classes.instructionsContainer}>
          {renderNoSuitInstructions()}
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            type="submit"
            onClick={closePatientInstructions}
          >
            {t(TranslationsEnum.Global_Back)}
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            type="submit"
            onClick={handleOnHomeClick}
          >
            {t(TranslationsEnum.Containers_SyncMyoSuit_Home)}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default NoSuitInstructions;
