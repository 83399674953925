import { Chip } from "@material-ui/core";
import React, { useState, MouseEvent, useCallback, useMemo } from "react";
import PopoverControl from "../FilterChipPopover/FilterChipPopover";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import { requiredValidator } from "../../utils/validators";
import useStyles from "./FilterChip.styles";

interface Props extends IFilterColumn {
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
}
const FilterChip = ({ value, name, label, onChange, onSubmit }: Props) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const handleClick = ({ currentTarget }: MouseEvent) => {
    setAnchor(currentTarget as HTMLElement);
  };
  const hasValue = useMemo(() => requiredValidator(value) === undefined, [
    value
  ]);
  const classes = useStyles();

  const setAnchorAndSubmit = useCallback(
    (anchor: HTMLElement | null) => {
      setAnchor(anchor);
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <PopoverControl
      anchor={anchor}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      setAnchor={setAnchorAndSubmit}
    >
      <Chip
        label={hasValue ? `${label}: ${value}` : label}
        color="primary"
        onClick={handleClick}
        variant={hasValue ? "default" : "outlined"}
        clickable={true}
        className={classes.chip}
      />
    </PopoverControl>
  );
};

export default FilterChip;
