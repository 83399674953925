import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./CreateMessage.styles";
import { getAzureUsersQuery } from "../../queries";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import { AzureUsersColumnsEnum } from "../../types/enums/AzureUsersColumnsEnum";
import debounce from "lodash/debounce";
import { TranslationsEnum } from "../../types/enums";
import { useTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SelectField from "../../components/SelectField/SelectField";
import { ISelectField } from "../../types/formInputs/ISelectField";
import { MessageReceiverTypeEnum } from "../../types/enums/MessageReceiverTypeEnum";
import draftToHtml from "draftjs-to-html";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { createMessageMutation } from "../../mutations/messages";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { schema } from "./ValidationSchema";
import { Formik, Form } from "formik";
import { ICreateMessage } from "../../types/formInputs/ICreateMessage";
import { Autocomplete } from "@material-ui/lab";
import { getClaimsTypeTranslationKey } from "../../utils/helpers/claimsHelper";
import { IAzureUser } from "../../types/IAzureUser";
import { IMessageAutocompleteEnum } from "../../types/enums/IMessageAutocompleteEnum";
import { messageOptions } from "../../constants/options";
import { SortAzureUsersByProperty } from "../../utils/helpers/sortHelper";

const CreateMessage: React.FC = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultSorting = {
    sortField: AzureUsersColumnsEnum.AzureUserId,
    sortDirection: SortDirectionEnum.Descending
  };

  const handleSuccess = useCallback(() => {
    history.push(PathNamesEnum.Messages);
  }, [history]);

  const [createMessage, { error, loading }] = useMutation(
    createMessageMutation,
    {
      onError: () => {},
      onCompleted: handleSuccess
    }
  );
  useErrorHandling(error);

  const { data, loading: usersLoading, refetch } = useQuery<{
    getAzureUsers: IAzureUser[];
  }>(getAzureUsersQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      filterConfig: {
        ...defaultSorting,
        filters: [
          {
            filterField: AzureUsersColumnsEnum.Name,
            filterValue: ""
          }
        ]
      }
    }
  });

  const refetchUsers = useCallback(
    async (event: ChangeEvent<{}>, input: string) => {
      await refetch({
        filterConfig: {
          ...defaultSorting,
          filters: [
            {
              filterField: AzureUsersColumnsEnum.Name,
              filterValue: input
            }
          ]
        }
      });
    },
    [refetch, defaultSorting]
  );

  const debouncedRefetchUsers = debounce(refetchUsers, 300);

  const onEditorStateChange = useCallback(
    editorState => {
      setEditorState(editorState);
    },
    [setEditorState]
  );

  const selectMessageReceiverType: ISelectField = useMemo(() => {
    return {
      label: t(
        TranslationsEnum.Containers_CreateEditMessage_ChooseMessageReceiverType
      ),
      name: IMessageAutocompleteEnum.MessageReceiverType,
      options: messageOptions
    };
  }, [t]);

  const handleSave = useCallback(
    (values: ICreateMessage) => {
      createMessage({
        variables: {
          message: {
            title: values.title,
            azureUserId: values.azureUser?.azureUserId,
            messageReceiverType: values.messageReceiverType,
            text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
          }
        }
      });
    },
    [editorState, createMessage]
  );

  const initialValues: ICreateMessage = {
    title: "",
    azureUser: null,
    messageReceiverType: MessageReceiverTypeEnum.PatientGroup
  };

  const toolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "image",
      "remove",
      "history"
    ],
    fontFamily: {
      options: [
        "Roboto",
        "Open Sans",
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ]
    }
  };

  const getUserLabel = useCallback((user: IAzureUser) => {
    if (!user.email) {
      return user.name + "";
    }
    return `${user.name} (${user.email})`;
  }, []);

  const usersOptions = useMemo(() => {
    if (usersLoading) {
      return [];
    }
    return SortAzureUsersByProperty(
      data?.getAzureUsers.map(user => {
        return {
          ...user,
          label: getUserLabel(user),
          claimsTypeName: t(getClaimsTypeTranslationKey(user.claimsType))
        };
      }),
      "claimsTypeName"
    );
  }, [usersLoading, data, t, getUserLabel]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography variant="h6" className={clsx(classes.darkGrey)}>
          {t(TranslationsEnum.Containers_CreateEditMessage_CreateMessage)}
        </Typography>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={handleSave}
        >
          {props => {
            const { values, errors, handleChange, setFieldValue } = props;
            return (
              <Form>
                <Box className={classes.form}>
                  <Box className={classes.formItem}>
                    <TextField
                      name="title"
                      label={t(
                        TranslationsEnum.Containers_CreateEditMessage_MessageTitle
                      )}
                      margin="dense"
                      variant="outlined"
                      value={values.title}
                      error={Boolean(errors.title)}
                      helperText={errors.title}
                      onChange={handleChange}
                      className={classes.textField}
                      disabled={false}
                    />
                  </Box>
                  <SelectField
                    selectField={selectMessageReceiverType}
                    onChange={event => {
                      setFieldValue(
                        IMessageAutocompleteEnum.MessageReceiverType,
                        event.target.value
                      );
                    }}
                    value={values.messageReceiverType}
                    error={errors.messageReceiverType}
                    FormControlProps={{
                      variant: "outlined",
                      className: classes.formItem
                    }}
                    SelectProps={{
                      margin: "dense"
                    }}
                    InputLabelProps={{
                      margin: "dense"
                    }}
                  />
                  {values.messageReceiverType ===
                    MessageReceiverTypeEnum.Private && (
                    <Box className={classes.formItem}>
                      <Autocomplete
                        loading={usersLoading}
                        options={usersOptions}
                        groupBy={option => option.claimsTypeName}
                        getOptionLabel={option => option.label}
                        onInputChange={debouncedRefetchUsers}
                        onChange={(
                          event: ChangeEvent<{}>,
                          value: IAzureUser | null
                        ) => {
                          setFieldValue("azureUser", value);
                        }}
                        value={values.azureUser}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              error={Boolean(errors.azureUser)}
                              helperText={errors.azureUser}
                              className={classes.textField}
                              label={t(
                                TranslationsEnum.Containers_CreateEditMessage_SelectUser
                              )}
                              variant="outlined"
                              name="azureUser"
                            />
                          );
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box className={classes.formItem}>
                  <Editor
                    editorState={editorState}
                    editorClassName="editor-wrapper"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={toolbar}
                  />
                </Box>
                <Box className={classes.buttonsContainer}>
                  <Button
                    disabled={loading}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t(TranslationsEnum.Global_Save)
                    )}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Box>
  );
};

export default CreateMessage;
