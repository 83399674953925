export const excludeCountries = [
  "al",
  "ad",
  "by",
  "ba",
  "fo",
  "gi",
  "xk",
  "mk",
  "md",
  "mc",
  "me",
  "rc",
  "sm",
  "rs",
  "ua",
  "tr",
  "va"
];

export const preferredCountries = ["de", "ch", "at"];
