import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Dropzone from "../../../components/Dropzone/Dropzone";
import FilesList from "../reusable/FilesList/FilesList";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import useHandleOnDrop from "../../../utils/helpers/dropzoneHelper";
import useStyles from "./SyncMyoSuitReact.styles";
import ClearSDCardInstructions from "./ClearSDCardInstructions/ClearSDCardInstructions";
import { actions } from "../../../state/syncSuit/actions";
import { useDispatch } from "react-redux";
import { TranslationsEnum, ClaimsEnum } from "../../../types/enums";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import PatientUploadInstructions from "./PatientUploadInstructions/PatientUploadInstructions";
import { getIsFirmwareNeededToUpdateQuery } from "../../../queries";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { IIsFirmwareUpdateNeededResponseDto } from "../../../types/IIsFirmwareUpdateNeededResponseDto";
import SessionCommentsModal from "../reusable/SessionCommentsModal";
import { IEditSessionGoalsDto } from "../../../types/requestDtos/IEditSessionGoalsDto";
import { setSessionGoalsMutation } from "../../../mutations/sessions";
import { SnackbarTypeEnum } from "../../../types/enums/SnackbarTypeEnum";
import { actions as snackbarActions } from "../../../state/snackbar/actions";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";

const SyncMyoSuitReact: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSessionCommentsModal, setShowSessionCommentsModal] = useState(
    false
  );
  const handleCloseSessionCommentsModal = () =>
    setShowSessionCommentsModal(false);
  const [showSDCardReminder, setShowSDCardReminder] = useState(false);
  const [showPatientInstructions, setShowPatientInstructions] = useState(false);
  const [
    showUpdateFirmWareInstructions,
    setShowUpdateFirmWareInstructions
  ] = useState(false);

  const { userProfile } = useSelector(selectUserProfile);
  const handleCloseShowUpdateFirmWareInstructions = () =>
    setShowUpdateFirmWareInstructions(false);
  const history = useHistory();
  const {
    files,
    handleOnDrop,
    isWrongFileType,
    setIsWrongFileType,
    isFinishedUploading,
    infosOfUploadedFiles
  } = useHandleOnDrop();
  const clearError = () => setIsWrongFileType(false);
  const dispatch = useDispatch();

  const setPreselectedUnit = useCallback(
    (unitSerialNumber: string | null) => {
      dispatch(actions.setPreselectedUnit(unitSerialNumber));
    },
    [dispatch]
  );

  const isUploadingFiles = files.filenames.length > 0;

  const handleOnClick = () => {
    if (isUploadingFiles) {
      setShowSDCardReminder(true);
      return;
    } else if (userProfile?.claimsType === ClaimsEnum.Patient) {
      setShowPatientInstructions(true);
      return;
    }
    history.push(PathNamesEnum.PrepareSession);
  };

  useEffect(() => {
    const { filenames, filesByNames } = files;
    if (isFinishedUploading && filenames.length > 0) {
      setShowSessionCommentsModal(true);
      const unitSerialNumbers: string[] = filenames
        .filter(fileName => {
          const hasWarning =
            filesByNames[fileName].warning !== null &&
            filesByNames[fileName].warning !== undefined;
          return filesByNames[fileName].isHeader && !hasWarning;
        })
        .map(fileName => filesByNames[fileName].unitSerialNumber || "");

      const uniqueUnitSerialNumbers = Array.from(new Set(unitSerialNumbers));

      if (uniqueUnitSerialNumbers.length === 1) {
        const [unitSerialNumber] = uniqueUnitSerialNumbers;
        setPreselectedUnit(unitSerialNumber);
        return;
      }

      setPreselectedUnit(null);
    }
  }, [setPreselectedUnit, isFinishedUploading, files]);

  const isDoneButtonDisabled = !isFinishedUploading && isUploadingFiles;
  const closePatientInstructions = () => setShowPatientInstructions(false);
  const closeSDCardInstructions = () => setShowSDCardReminder(false);

  const handleQueryCompletedCallback = (data: {
    isFirmwareUpdateNeededResponseDto: IIsFirmwareUpdateNeededResponseDto;
  }) => {
    setShowUpdateFirmWareInstructions(
      data.isFirmwareUpdateNeededResponseDto?.isFirmwareUpdateNeeded
    );
  };

  useQuery<{
    isFirmwareUpdateNeededResponseDto: IIsFirmwareUpdateNeededResponseDto;
  }>(getIsFirmwareNeededToUpdateQuery, {
    fetchPolicy: "network-only",
    onCompleted: handleQueryCompletedCallback
  });

  const handleSessionCommentUpdateSuccess = () => {
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_UpdateSessionCommentsSuccess)
      )
    );
  };

  const [editSessionGoals, { error }] = useMutation(setSessionGoalsMutation, {
    onError: () => {},
    onCompleted: handleSessionCommentUpdateSuccess
  });

  useErrorHandling(error);

  const handleSetSessionGoals = (sessionGoals: IEditSessionGoalsDto) => {
    editSessionGoals({
      variables: { values: sessionGoals }
    });
  };

  return (
    <Box className={classes.container}>
      <ErrorModal
        title={t(TranslationsEnum.Global_UpdateFirmwareTitle)}
        message={t(TranslationsEnum.Global_UpdateFirmwareText)}
        open={showUpdateFirmWareInstructions}
        icon="warning"
        handleClose={handleCloseShowUpdateFirmWareInstructions}
      />
      {showSDCardReminder ? (
        <ClearSDCardInstructions
          closeSDCardInstructions={closeSDCardInstructions}
        />
      ) : showPatientInstructions ? (
        <PatientUploadInstructions
          closePatientInstructions={closePatientInstructions}
          userProfile={userProfile}
        />
      ) : (
        <Paper className={classes.paperContainer}>
          <Typography
            variant="h6"
            className={clsx(classes.darkGrey, classes.header)}
          >
            {t(TranslationsEnum.Containers_SyncMyoSuit_PageTitle)}
          </Typography>
          <Dropzone onDrop={handleOnDrop} />
          {isUploadingFiles ? (
            <FilesList
              files={files}
              isFinishedUploading={isFinishedUploading}
            />
          ) : (
            <Box className={classes.tipContainer}>
              <ErrorIcon className={classes.blue} />
              <Typography
                variant="subtitle2"
                className={clsx(classes.blue, classes.tipText)}
              >
                {t(TranslationsEnum.Containers_SyncMyoSuit_TipToSkip)}
              </Typography>
            </Box>
          )}
          <Button
            className={classes.button}
            variant={isDoneButtonDisabled ? "outlined" : "contained"}
            color="primary"
            type="submit"
            onClick={handleOnClick}
            disabled={isDoneButtonDisabled}
          >
            {isUploadingFiles
              ? t(TranslationsEnum.Global_Done)
              : t(TranslationsEnum.Global_Skip)}
          </Button>
        </Paper>
      )}

      <SessionCommentsModal
        infosOfUploadedFiles={infosOfUploadedFiles}
        handleSetSessionGoals={handleSetSessionGoals}
        open={
          userProfile?.claimsType === ClaimsEnum.Operator &&
          showSessionCommentsModal &&
          infosOfUploadedFiles.filter(
            f => f.sessionGuid !== null && f.fileUploadWarning === null
          ).length > 0
        }
        handleClose={handleCloseSessionCommentsModal}
      />

      <ErrorModal
        open={isWrongFileType}
        handleClose={clearError}
        message={t(TranslationsEnum.Containers_SyncMyoSuit_PleasUploadMyo)}
        title={t(TranslationsEnum.Containers_SyncMyoSuit_WrongFileType)}
      />
    </Box>
  );
};

export default SyncMyoSuitReact;
