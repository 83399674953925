import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fade,
  Modal,
  Typography
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../../styles/modalForm.styles";
import { TranslationsEnum } from "../../../../types/enums";

interface IProps {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  onDelete: () => void;
}

const DeleteOperatorModal: React.FC<IProps> = ({
  open,
  loading,
  onDelete,
  handleClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <Card className={classes.formItem}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.modalHeader}
            >
              {t(TranslationsEnum.Global_DoYouReallyWantToDeleteThisProfile)}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              {t(TranslationsEnum.Global_No)}
            </Button>
            <Button
              className={classes.yesButton}
              variant="contained"
              onClick={onDelete}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t(TranslationsEnum.Global_Yes)
              )}
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

export default DeleteOperatorModal;
