import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  gridWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(0)
  },
  actionsItem: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end"
  },
  chip: {
    marginRight: theme.spacing(2)
  }
}));
