import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "center"
  },
  logo: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 150
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: theme.spacing(3)
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal"
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(11),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85%"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(14)
    }
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  marginBottom1: {
    marginBottom: theme.spacing(1)
  },
  marginBottom3: {
    marginBottom: theme.spacing(3)
  },
  bulletPoint: {
    fontSize: 10,
    paddingTop: 4,
    paddingRight: theme.spacing(1)
  },
  bottomAppBar: {
    top: "auto",
    bottom: 0,
    background: ColorEnum.White
  },
  disagreeButton: {
    marginRight: theme.spacing(1),
    background: ColorEnum.White,
    color: ColorEnum.Blue,
    "&:hover": {
      background: ColorEnum.White
    }
  },
  agreeButton: {
    marginLeft: theme.spacing(1),
    color: ColorEnum.White,
    background: ColorEnum.SuccessGreen,
    "&:hover": {
      background: ColorEnum.SuccessDarkGreen
    }
  },
  fader: {
    position: "fixed",
    bottom: theme.spacing(8),
    left: 0,
    width: "100%",
    height: theme.spacing(12),
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255,255,255,0))",
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(7)
    }
  },
  languageMenu: {
    position: "absolute",
    top: 12,
    right: 50
  },
  bottomText: {
    color: ColorEnum.DarkGrey,
    textAlign: "center"
  },
  link: {
    marginLeft: theme.spacing(0.5)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "30%",
    minWidth: 300
  },
  modalPaper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  icon: {
    color: ColorEnum.LightGreyIcons,
    fontSize: 96,
    marginTop: 100
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 300,
    marginTop: 30,
    color: ColorEnum.LightGrey
  },
  white: {
    color: ColorEnum.White
  },
  logoutButton: {
    bottom: 10,
    right: 0,
    position: "absolute"
  }
}));
