//@ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ReactToPrint from "react-to-print";
import {
  Box,
  Card,
  Grid,
  Typography,
  TextField,
  MenuItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../types/enums";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useQuery } from "@apollo/react-hooks";
import { ICostApprovalForm } from "../../types/forms/ICostApprovalForm";
import { getCostApprovalInfo } from "../../queries/patients";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { useParams } from "react-router-dom";
import { genderOptions } from "../../constants/options";
import { DatePicker } from "@material-ui/pickers";
import useStyles from "./CostApproval.styles";
import { ProfitLevelResultEnum } from "../../types/enums/ProfitLevelResultEnum";
import { IPatientIdRouteParams } from "../../types/routeParams/IPatientIdRouteParams";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
  const language = getSelectedLanguage();
  const classes = useStyles();
  const { t } = useTranslation();

  const { patientId } = useParams<IPatientIdRouteParams>();
  const { data, error } = useQuery<{ costApprovalForm: ICostApprovalForm }>(
    getCostApprovalInfo(Number(patientId), language),
    {
      fetchPolicy: "network-only"
    }
  );

  useErrorHandling(error);

  const localizedGenderOptions = genderOptions(t);

  useEffect(() => {
    const selectProfitlevelValue = (profitLevel: ProfitLevelResultEnum) => {
      switch (profitLevel) {
        case ProfitLevelResultEnum.NotFit:
          return "";
        case ProfitLevelResultEnum.NoProfit:
          return t(
            TranslationsEnum.Containers_Modals_Questionnaire_Results_NoProfit
          );
        case ProfitLevelResultEnum.NormalProfit:
          return t(
            TranslationsEnum.Containers_Modals_Questionnaire_Results_NormalProfit
          );
        case ProfitLevelResultEnum.OptimalProfit:
          return t(
            TranslationsEnum.Containers_Modals_Questionnaire_Results_OptimalProfit
          );
        default:
          return profitLevel;
      }
    };

    setPlaceAndTime(
      data?.costApprovalForm?.clinicAddress
        ? data.costApprovalForm.clinicAddress + ", " + getDateTime(new Date())
        : getDateTime(new Date())
    );

    setFirstName(data?.costApprovalForm?.firstName ?? "");

    try {
      const date = new Date(data?.costApprovalForm?.dateOfBirth ?? 0);
      date.setDate(date.getDate() + 1);
      handleBirthDateChange(date);
    } catch {
      setBirthDate("");
    }

    setGender((data?.costApprovalForm?.genderType ?? 2).toString());
    setResultOfTest(
      selectProfitlevelValue(
        JSON.parse(data?.costApprovalForm?.questionnaireResults ?? "{}")
          ?.fitLevel as ProfitLevelResultEnum
      )
    );
    setPathology(data?.costApprovalForm?.pathology ?? "");
    setBergBalanceResult(
      (data?.costApprovalForm?.bergBalanceResult ?? "").toString()
    );
    setTugResult((data?.costApprovalForm?.tugResult ?? "").toString());
    setMwT10Result((data?.costApprovalForm?.mwT10Result ?? "").toString());
    setMwT2Result((data?.costApprovalForm?.mwT2Result ?? "").toString());
  }, [data, t]);

  const [nameOfPTPractice, setNameOfPTPractice] = useState("");
  const [street, setStreet] = useState("");
  const [place, setPlace] = useState("");
  const [firstName, setFirstName] = useState("");
  const [placeAndTime, setPlaceAndTime] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [resultOfTest, setResultOfTest] = useState("");
  const [pathology, setPathology] = useState("");
  const [bergBalanceResult, setBergBalanceResult] = useState("");
  const [tugResult, setTugResult] = useState("");
  const [mwT10Result, setMwT10Result] = useState("");
  const [mwT2Result, setMwT2Result] = useState("");
  const [ZSRNumber, setZSRNumber] = useState("");
  const [operatorFirstName, setOperatorFirstName] = useState("");
  const [operatorSurname, setOperatorSurname] = useState("");
  const [operatorAddress, setOperatorAddress] = useState("");
  const [operatorZRSNumber, setOperatorZRSNumber] = useState("");
  const [plannedStartDate, setPlannedStartDate] = useState("");

  const handleSetNameOfPTPractice = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setNameOfPTPractice(e.target.value);
  const handleSetStreet = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setStreet(e.target.value);
  const handleSetPlace = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setPlace(e.target.value);
  const handleSetPlaceAndTime = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setPlaceAndTime(e.target.value);
  const handleSetFirstName = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setFirstName(e.target.value);
  const handleSetSurname = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setSurname(e.target.value);
  const handleSetAddress = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setAddress(e.target.value);
  const handleSetBirthDate = (date: Date | null) => handleBirthDateChange(date);
  const handleSetGender = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setGender(e.target.value);
  const handleSetInsuranceNumber = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setInsuranceNumber(e.target.value);
  const handleSetResultOfTest = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setResultOfTest(e.target.value);
  const handleSetPathology = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setPathology(e.target.value);
  const handleSetBergBalanceResult = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setBergBalanceResult(e.target.value);
  const handleSetTugResult = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setTugResult(e.target.value);
  const handleSetMwT10Result = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setMwT10Result(e.target.value);
  const handleSetMwT2Result = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setMwT2Result(e.target.value);
  const handleSetZSRNumber = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setZSRNumber(e.target.value);
  const handleSetOperatorFirstName = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setOperatorFirstName(e.target.value);
  const handleSetOperatorSurname = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setOperatorSurname(e.target.value);
  const handleSetOperatorAddress = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setOperatorAddress(e.target.value);
  const handleSetOperatorZRSNumber = (e: {
    target: { value: React.SetStateAction<string> };
  }) => setOperatorZRSNumber(e.target.value);
  const handleSetPlannedStartDate = (date: Date | null) =>
    handleStartDateChange(date);

  const handleBirthDateChange = (date: Date | null) => {
    if (date) {
      setBirthDate(date.toDateString());
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setPlannedStartDate(date.toDateString());
    }
  };

  function getDateTime(date: Date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString();
    var day = date.getDate().toString();

    if (month.toString().length === 1) {
      month = `0${month}`;
    }
    if (day.toString().length === 1) {
      day = `0${day}`;
    }

    var dateTime = day + "/" + month + "/" + year;
    return dateTime;
  }

  return (
    <Card
      ref={ref}
      style={{ border: "none", boxShadow: "none", maxWidth: "1000px" }}
    >
      <Box m={2}>
        <Grid container direction="row">
          <Grid xs={6} item>
            <TextField
              label={t(TranslationsEnum.Containers_CostApproval_NameOfPractice)}
              id="standard-basic"
              value={nameOfPTPractice}
              onChange={handleSetNameOfPTPractice}
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <Typography variant="body1">
              Sanitas Grundversicherungen AG
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid xs={6} item>
            <TextField
              label={t(TranslationsEnum.Containers_CostApproval_Street)}
              id="standard-basic"
              value={street}
              onChange={handleSetStreet}
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <Typography variant="body1">Jägergasse 3</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid xs={6} item>
            <TextField
              label={t(TranslationsEnum.Containers_CostApproval_Place)}
              id="standard-basic"
              value={place}
              onChange={handleSetPlace}
              variant="standard"
            />
          </Grid>
          <Grid xs={6} item>
            <Typography variant="body1">8004 Zürich</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid xs={6} item>
            <Typography variant="body1"></Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography variant="body1">casemanagement@sanitas.com</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_PlaceDate)}
        </Typography>
        <TextField
          style={{ width: "60%", minWidth: "500px" }}
          id="standard-basic"
          value={placeAndTime}
          onChange={handleSetPlaceAndTime}
          variant="standard"
        />
      </Box>
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_ObjectOfApproval)}
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          {t(TranslationsEnum.Containers_CostApproval_SirOrMadam)}
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          {t(TranslationsEnum.Containers_CostApproval_Request)}
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_PatientDetails)}
        </Typography>
        <Grid container direction="row">
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_FirstName)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={firstName}
              onChange={handleSetFirstName}
              variant="standard"
            />
          </Grid>
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Surname)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={surname}
              onChange={handleSetSurname}
              variant="standard"
            />
          </Grid>
          <Grid md={6} xs={12} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_AddressLiving)}
            </Typography>
            <TextField
              style={{ margin: "0 0 0.8em 0.6em", width: "75%" }}
              id="standard-basic"
              value={address}
              onChange={handleSetAddress}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Birthdate)}
            </Typography>
            <DatePicker
              name="dateOfBirth"
              inputVariant="standard"
              cancelLabel={t("Global.Cancel")}
              okLabel={t("Global.Ok")}
              disableFuture
              openTo="year"
              format={"dd/MM/yyyy"}
              views={["year", "month", "date"]}
              value={birthDate}
              initialFocusedDate={birthDate}
              onChange={handleSetBirthDate}
              style={{ margin: "0 0 0.8em 0.6em" }}
              className={classes.textField}
              error={false}
              helperText={null}
            />
          </Grid>
          <Grid md={3} xs={6} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Gender)}
            </Typography>
            <TextField
              id="standard-select-gender"
              select
              label={gender === "" ? "Gender" : ""}
              value={gender}
              onChange={handleSetGender}
              InputLabelProps={{ shrink: false }}
              className={classes.textField}
              fullWidth
              style={{ margin: "0 0 0.8em 0.6em" }}
              variant="standard"
            >
              {localizedGenderOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_InsuranceNumber)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={insuranceNumber}
              onChange={handleSetInsuranceNumber}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid xs={10} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_UsabilityTestResults)}
            </Typography>
            <TextField
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              fullWidth
              value={resultOfTest}
              onChange={handleSetResultOfTest}
              variant="standard"
            />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid xs={10} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Pathology)}
            </Typography>
            <TextField
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              fullWidth
              value={pathology}
              onChange={handleSetPathology}
              variant="standard"
            />
          </Grid>
        </Grid>
      </Box>

      {(data?.costApprovalForm?.bergBalanceResult ||
        data?.costApprovalForm?.tugResult ||
        data?.costApprovalForm?.mwT10Result ||
        data?.costApprovalForm?.mwT2Result) && (
        <Box m={2}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {t(TranslationsEnum.Containers_CostApproval_ResultAssessment)}
          </Typography>
          <Grid container direction="row">
            {mwT10Result && (
              <Grid xs={3} item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
                >
                  {t(TranslationsEnum.Containers_CostApproval_Met10)}
                </Typography>
                <TextField
                  style={{ margin: "0 0 0.8em 0.6em" }}
                  className={classes.textField}
                  id="standard-basic"
                  value={mwT10Result}
                  onChange={handleSetMwT10Result}
                  variant="standard"
                />
              </Grid>
            )}
            {mwT2Result && (
              <Grid xs={3} item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
                >
                  {t(TranslationsEnum.Containers_CostApproval_Min2)}
                </Typography>
                <TextField
                  style={{ margin: "0 0 0.8em 0.6em" }}
                  className={classes.textField}
                  id="standard-basic"
                  value={mwT2Result}
                  onChange={handleSetMwT2Result}
                />
              </Grid>
            )}
            {bergBalanceResult && (
              <Grid xs={3} item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
                >
                  {t(TranslationsEnum.Containers_CostApproval_Berg)}
                </Typography>
                <TextField
                  style={{ margin: "0 0 0.8em 0.6em" }}
                  className={classes.textField}
                  id="standard-basic"
                  value={bergBalanceResult}
                  onChange={handleSetBergBalanceResult}
                  variant="standard"
                />
              </Grid>
            )}

            {tugResult && (
              <Grid xs={3} item>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
                >
                  {t(TranslationsEnum.Containers_CostApproval_Tug)}
                </Typography>
                <TextField
                  style={{ margin: "0 0 0.8em 0.6em" }}
                  className={classes.textField}
                  id="standard-basic"
                  value={tugResult}
                  onChange={handleSetTugResult}
                  variant="standard"
                />
              </Grid>
            )}
            {/* {data?.costApprovalForm?.tinettiResult && (
              <Grid xs={3} item>
                <Typography variant='body1'>
                  {t(TranslationsEnum.Containers_CostApproval_Tinetti)}
                </Typography>
                <TextField
                  id='standard-basic'
                  value={data?.costApprovalForm?.tinettiResult}
                  variant='standard'
                  disabled
                />
              </Grid>
            )}
            {data?.costApprovalForm?.otherResult && (
              <Grid xs={3} item>
                <Typography variant='body1'>
                  {t(TranslationsEnum.Containers_CostApproval_Other)}
                </Typography>
                <TextField
                  id='standard-basic'
                  value={data?.costApprovalForm?.otherResult}
                  variant='standard'
                  disabled
                />
              </Grid>
            )} */}
          </Grid>
        </Box>
      )}
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_PatientCheck)}
        </Typography>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                color="primary"
                style={{ padding: "0 9px 0 0", margin: "0 0 0 0.8em" }}
              />
            }
            label={t(TranslationsEnum.Containers_CostApproval_SpeakGerman)}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                color="primary"
                style={{ padding: "0 9px 0 0", margin: "0 0 0 0.8em" }}
              />
            }
            label={t(TranslationsEnum.Containers_CostApproval_ConsentSigned)}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                color="primary"
                style={{ padding: "0 9px 0 0", margin: "0 0 0 0.8em" }}
              />
            }
            label={t(
              TranslationsEnum.Containers_CostApproval_PrecludingConditions
            )}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                color="primary"
                style={{ padding: "0 9px 0 0", margin: "0 0 0 0.8em" }}
              />
            }
            label={
              t(TranslationsEnum.Containers_CostApproval_Prescription) +
              " " +
              t(TranslationsEnum.Containers_CostApproval_PresentAndAttached)
            }
          />
        </Box>
      </Box>
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_ZsrNumberOfPT)}
        </Typography>
        <TextField
          className={classes.textField}
          style={{ margin: "0 0 0.8em 0.6em" }}
          id="standard-basic"
          value={ZSRNumber}
          onChange={handleSetZSRNumber}
          variant="standard"
        />
      </Box>
      <Box m={2}>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t(TranslationsEnum.Containers_CostApproval_DetailsOfPT)}
        </Typography>
        <Grid container direction="row">
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_FirstName)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={operatorFirstName}
              onChange={handleSetOperatorFirstName}
              variant="standard"
            />
          </Grid>
          <Grid md={3} xs={4} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Surname)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={operatorSurname}
              onChange={handleSetOperatorSurname}
              variant="standard"
            />
          </Grid>
          <Grid md={6} xs={12} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_Address)}
            </Typography>
            <TextField
              style={{ margin: "0 0 0.8em 0.6em", width: "75%" }}
              id="standard-basic"
              value={operatorAddress}
              onChange={handleSetOperatorAddress}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid md={12} xs={12} item>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, margin: "0 0 0 0.6em" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_ZrsGln)}
            </Typography>
            <TextField
              className={classes.textField}
              style={{ margin: "0 0 0.8em 0.6em" }}
              id="standard-basic"
              value={operatorZRSNumber}
              onChange={handleSetOperatorZRSNumber}
              variant="standard"
            />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {t(TranslationsEnum.Containers_CostApproval_StartDate)}
            </Typography>
            <DatePicker
              name="dateOfBirth"
              inputVariant="standard"
              cancelLabel={t("Global.Cancel")}
              okLabel={t("Global.Ok")}
              openTo="year"
              format={"dd/MM/yyyy"}
              views={["year", "month", "date"]}
              value={plannedStartDate}
              initialFocusedDate={plannedStartDate}
              onChange={handleSetPlannedStartDate}
              style={{ margin: "0 0 0.8em 0.6em" }}
              className={classes.textField}
              error={false}
              helperText={null}
            />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          {t(TranslationsEnum.Containers_CostApproval_Thanks)}
        </Typography>
      </Box>
    </Card>
  );
});

const CostApproval = () => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const pageStyle = `
  @page {
    size: 297mm 420mm;
  }
  #BrowserPrintDefaults{display:none} 
`;
  return (
    <Box style={{ maxWidth: "1000px", background: "#fff" }}>
      <ComponentToPrint ref={componentRef} />
      <ReactToPrint
        pageStyle={pageStyle}
        trigger={() => (
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              style={{ margin: "1em 3em 3em 0" }}
            >
              {t(TranslationsEnum.Containers_CostApproval_DownloadPdf)}
              <PictureAsPdfIcon style={{ marginLeft: "8px" }} />
            </Button>
          </Box>
        )}
        content={() => componentRef.current}
      />
    </Box>
  );
};

export default CostApproval;
