import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  subtitle: {
    display: "flex",

    marginLeft: theme.spacing(2)
  },
  white: {
    color: ColorEnum.White
  },
  subtitleArrow: {
    fontSize: 15,

    marginLeft: theme.spacing(2)
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  infoIcon: {
    marginTop: -theme.spacing(10)
  }
}));
