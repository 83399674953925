import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "LightGrey",
    borderStyle: "solid"
  },
  tableNoBorder: {
    borderWidth: 0
  },
  tableTopRowBorder: {
    borderWidth: 0,
    borderTopWidth: 1,
    borderColor: "LightGrey",
    borderStyle: "solid"
  },
  tableNoTopBorder: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderColor: "LightGrey",
    borderStyle: "solid"
  },
  tableNoBottomBorder: {
    borderWidth: 0,
    borderTopWidth: 1,
    borderLefttWidth: 1,
    borderColor: "LightGrey",
    borderStyle: "solid"
  }
}));
