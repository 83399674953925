import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  fontWeightBold: {
    fontWeight: 700
  },
  fontWeightRegular: {
    fontWeight: 400
  },
  messageContainer: {
    paddingBottom: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  fileName: {
    color: ColorEnum.LightGrey
  },
  fileNamesContainer: {
    maxHeight: 200,
    overflow: "auto"
  }
}));
