import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createPatientMutation } from "../../../../mutations/patients";
import { actions } from "../../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { IPatientForm } from "../../../../types/forms/IPatientForm";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import PatientForm from "../PatientForm/PatientForm";
import { patientFormSchemaForOperator } from "../PatientForm/patientSchema";
import { getSelectedLanguage } from "../../../../utils/helpers/languageHelpers";
import { IGetPathologiesDto } from "../../../../types/requestDtos/IGetPathologiesDto";
import { getPathologiesListQuery } from "../../../../queries/pathologies";
import { IPathologyDTO } from "../../../../types/IPathology";
import { createOptionsFromPathologiesList } from "../../../../utils/helpers/enumMapperHelper";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { ClaimsEnum, GenderEnum } from "../../../../types/enums";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetchPatients: (variables?: { filterConfig: IFilterConfig }) => void;
  openInviteModal: () => void;
  openQuestionnaireExplanation: () => void;
  handleSetPatientForm: (form: IPatientForm) => void;

  setCreatedUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

const CreatePatientModal: React.FC<IProps> = ({
  open,
  refetchPatients,
  handleClose,
  openInviteModal,
  setCreatedUserId,
  openQuestionnaireExplanation,
  handleSetPatientForm
}) => {
  const { userProfile } = useSelector(selectUserProfile);

  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isClinicInTrial = userProfile?.clinicInSanitasTrial;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreatePatientSuccess = (data: {
    createPatient: {
      id: number;
      inSanitasTrial: boolean;
    };
  }) => {
    setCreatedUserId(data.createPatient.id);
    handleModalClose();
    refetchPatients();
    refetchPathologies({
      variables: { getPathologiesDto }
    });
    if (data.createPatient.inSanitasTrial) {
      openQuestionnaireExplanation();
    }
    openInviteModal();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t("Containers.Modals.Patient.CreatePatientModal.SuccessMessage")
      )
    );
  };

  const language = getSelectedLanguage();

  const getPathologiesDto: IGetPathologiesDto = useMemo(
    () => ({
      language: language
    }),
    [language]
  );

  const {
    data: dataPathologies,
    error: errorPathologies,
    refetch: refetchPathologies
  } = useQuery<{
    pathologiesDto: IPathologyDTO;
  }>(getPathologiesListQuery, {
    variables: { getPathologiesDto },
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorPathologies);

  const [createPatient, { loading, error }] = useMutation(
    createPatientMutation,
    {
      onError: () => {},
      onCompleted: handleCreatePatientSuccess
    }
  );

  useErrorHandling(error);

  const handleFormSubmit = (values: IPatientForm) => {
    handleSetPatientForm(values);
    if (isValid) {
      createPatient({
        variables: { values: { ...values, language: getSelectedLanguage() } }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const initialValues: IPatientForm = {
    name: "",
    weight: 0,
    height: 0,
    pathologyId: 0,
    timeSinceDiagnosis: "",
    suggestedPathology: "",
    email: "",
    dateOfBirth: "1900-01-01",
    phoneNumber: "",
    preferredLanguage: LanguageEnum.De,
    genderType: GenderEnum.Other,
    inSanitasTrial: false,
    questionnaire: null,
    isQuestionnaireDone: false,
    QuestionnaireResults: ""
  };

  const pathologies = dataPathologies?.pathologiesDto.pathologies;
  const { pathologyOptions, noneId } = createOptionsFromPathologiesList(
    pathologies
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: patientFormSchemaForOperator(noneId),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  const props = {
    isOperator: isOperator,
    isClinicInTrial: isClinicInTrial,
    errors,
    values,
    open,
    loading,
    setFieldValue,
    formTitle: t("Containers.Modals.Patient.PatientForm.NewUser"),
    handleSubmit,
    handleChange,
    handleClose: handleModalClose,
    pathologyOptions,
    noneId
  };

  return <PatientForm {...props} />;
};

export default CreatePatientModal;
