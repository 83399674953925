import { IOption } from "../types/formInputs/IOption";
import { LanguageEnum } from "../types/enums/LanguageEnum";

export const defaultError = 10000;
export const defaultLanguage: IOption = {
  label: "English",
  value: LanguageEnum.En
};

// ClinicId 1 means that a patient or operator is not assigned to any clinic
export const defaultClinicId = 1;

// In patients page, this is the index of patients table column, that corresponds to `id` of a patient
export const defaultIdIndex = 5;

// In patient inner page, this is the index of sessions table column, that corresponds to `id` of a session
export const defaultSessionIdIndex = 0;
