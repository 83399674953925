import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Fade,
  Modal,
  Typography,
  CircularProgress
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../types/enums/TranslationsEnum";
import { SelectField } from "../../../components";
import { TFunction } from "i18next";
import { ISelectField } from "../../../types/formInputs/ISelectField";
import { Formik, Form, FormikProps } from "formik";
import { schema } from "./ValidationSchema";
import { IAddTrainer } from "../../../types/formInputs/IAddTrainer";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getClinicsListQuery } from "../../../queries/clinics";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IClinic } from "../../../types/IClinic";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import { askToJoinClinicMutation } from "../../../mutations/patients";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import useStyles from "./AddTrainerModal.styles";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetchPatient: () => void;
}

const clinicSelect = (t: TFunction, clinicsList: IClinic[]): ISelectField => ({
  label: t(TranslationsEnum.Containers_Modals_AddTrainerModal_ChooseClinic),
  name: "clinicId",
  options: clinicsList.map(x => ({
    label: x.name,
    value: x.id
  }))
});

const AddTrainerModal: React.FC<IProps> = ({
  open,
  handleClose,
  refetchPatient
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const { userProfile } = useSelector(state => ({
    ...selectUserProfile(state)
  }));

  const initialValues: IAddTrainer = {
    clinicId: "",
    patientId: userProfile!.id
  };

  const {
    data: clinicListData,
    error: clinicListError,
    loading: clinicListLoading
  } = useQuery<{ clinics: IClinic[] }>(getClinicsListQuery, {
    fetchPolicy: "cache-and-network"
  });

  const [
    askToJoinClinic,
    { loading: loadingAskToJoinClinic, error: errorAskToJoinClinic }
  ] = useMutation(askToJoinClinicMutation, {
    onError: () => {},
    onCompleted: () => setShowSuccess(true)
  });

  useErrorHandling(clinicListError);
  useErrorHandling(errorAskToJoinClinic);

  const handleAddClick = (data: IAddTrainer) => {
    askToJoinClinic({
      variables: { values: { ...data } }
    });
  };

  const handleContinueClick = () => {
    handleModalClose();
    refetchPatient();
  };

  const handleModalClose = () => {
    setShowSuccess(false);
    handleClose();
  };

  //TODO: useMemo
  const renderCardContent = (props: FormikProps<IAddTrainer>) => {
    const {
      values: { clinicId },
      errors,
      handleChange
    } = props;

    const clinicName =
      clinicListData?.clinics[
        clinicListData.clinics.findIndex(x => x.id === clinicId)
      ]?.name || "";

    if (showSuccess) {
      return (
        <>
          <CheckCircleIcon color="primary" />
          <Typography variant="h6" className={classes.text}>
            {t(
              TranslationsEnum.Containers_Modals_AddTrainerModal_RequestSuccessPart1
            )}{" "}
            {clinicName}{" "}
            {t(
              TranslationsEnum.Containers_Modals_AddTrainerModal_RequestSuccessPart2
            )}
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.text, classes.marginBottom)}
          >
            {t(TranslationsEnum.Containers_Modals_AddTrainerModal_Info)}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          variant="h6"
          className={clsx(classes.marginBottom, classes.text)}
        >
          {t(TranslationsEnum.Containers_PatientsInnerPage_AddTrainer)}
        </Typography>
        {clinicListLoading ? (
          <CircularProgress size={48} color="primary" />
        ) : clinicListData?.clinics.length ? (
          <Form id={"AddTrainerForm"} key="AddTrainerForm">
            <SelectField
              selectField={clinicSelect(t, clinicListData.clinics)}
              onChange={handleChange}
              value={clinicId}
              error={errors.clinicId}
              FormControlProps={{
                variant: "outlined",
                className: classes.marginBottom
              }}
              SelectProps={{
                margin: "dense"
              }}
              InputLabelProps={{
                margin: "dense"
              }}
            />
          </Form>
        ) : (
          <Typography
            variant="h6"
            className={clsx(classes.marginBottom, classes.text)}
          >
            {t(
              TranslationsEnum.Containers_Modals_AddTrainerModal_NoClinicsFound
            )}
          </Typography>
        )}
      </>
    );
  };

  //TODO: useMemo
  const renderCardActions = () => {
    if (showSuccess) {
      return (
        <Button
          color="primary"
          onClick={handleContinueClick}
          className={classes.cardActionButton}
        >
          {t(TranslationsEnum.Global_Continue)}
        </Button>
      );
    }

    if (clinicListLoading) {
      return <CircularProgress size={48} color="primary" />;
    }

    if (!clinicListData?.clinics.length) {
      return (
        <Button
          color="primary"
          onClick={handleModalClose}
          className={classes.cardActionButton}
        >
          {t(TranslationsEnum.Global_Ok)}
        </Button>
      );
    }

    return (
      <>
        <Button
          color="primary"
          onClick={handleModalClose}
          className={classes.cardActionButton}
        >
          {t(TranslationsEnum.Global_Cancel)}
        </Button>
        <Button
          form="AddTrainerForm"
          color="primary"
          type="submit"
          className={classes.cardActionButton}
        >
          {loadingAskToJoinClinic ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            t(TranslationsEnum.Global_Add)
          )}
        </Button>
      </>
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={showSuccess ? handleContinueClick : handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <Card className={classes.cardContainer}>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            validateOnChange={false}
            onSubmit={handleAddClick}
          >
            {props => {
              return (
                <>
                  <CardContent
                    classes={{
                      root: classes.cardContentRoot
                    }}
                  >
                    {renderCardContent(props)}
                  </CardContent>
                  <CardActions
                    className={classes.cardActions}
                    classes={{
                      root: classes.cardAction,
                      spacing: classes.cardAction
                    }}
                  >
                    {renderCardActions()}
                  </CardActions>
                </>
              );
            }}
          </Formik>
        </Card>
      </Fade>
    </Modal>
  );
};

export default AddTrainerModal;
