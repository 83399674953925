import React from "react";
import clsx from "clsx";
import { Box, Paper, Typography, Divider } from "@material-ui/core";
import { Formik } from "formik";
import AssistanceLevelsForm from "./AssistanceLevelsForm/AssistanceLevelsForm";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import {
  updatePatientsProfileAssistanceLevelsMutation,
  updatePatientsProfileAssistanceLevelsByAzureOidMutation
} from "../../../mutations/patients";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import { IAssistanceLevelsValues } from "../../../types/formInputs/IAssistanceLevelsValues";
import { useDispatch } from "react-redux";
import { actions } from "../../../state/snackbar/actions";
import {
  SnackbarTypeEnum,
  TranslationsEnum,
  ClaimsEnum
} from "../../../types/enums";
import { useParams } from "react-router-dom";
import { IPatientIdRouteParams } from "../../../types/routeParams/IPatientIdRouteParams";
import * as Yup from "yup";
import { RequestedClinicStatusEnum } from "../../../types/enums/RequestedClinicStatusEnum";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import useStyles from "./AssistanceLevelsCard.styles";

interface IProps {
  initialValues: IAssistanceLevelsValues;
  patientRequestedClinicStatus?: RequestedClinicStatusEnum;
}

const AssistanceLevelsCard = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { patientId } = useParams<IPatientIdRouteParams>();
  const { initialValues, patientRequestedClinicStatus } = props;

  const { userProfile } = useSelector(selectUserProfile);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;

  const dispatchSuccessSnackbar = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(
          TranslationsEnum.Containers_MyoSuitSetupTab_AssistanceLevelsCard_SuccessMessage
        )
      )
    );
  };

  const [
    updatePatientsProfileAssistanceLevels,
    {
      loading: loadingUpdatePatientAssistanceOperator,
      error: errorUpdatePatientAssistanceOperator
    }
  ] = useMutation(
    updatePatientsProfileAssistanceLevelsMutation(Number(patientId)),
    {
      onError: () => {},
      variables: { values: initialValues },
      onCompleted: dispatchSuccessSnackbar
    }
  );

  useErrorHandling(errorUpdatePatientAssistanceOperator);

  const [
    updatePatientsProfileAssistanceLevelsByAzureOid,
    {
      loading: loadingUpdatePatientAssistancePatient,
      error: errorUpdatePatientAssistancePatient
    }
  ] = useMutation(updatePatientsProfileAssistanceLevelsByAzureOidMutation, {
    onError: () => {},
    variables: { values: initialValues },
    onCompleted: dispatchSuccessSnackbar
  });

  useErrorHandling(errorUpdatePatientAssistancePatient);

  const handleSubmit = (values: IAssistanceLevelsValues) => {
    if (isOperator) {
      updatePatientsProfileAssistanceLevels({
        variables: { values }
      });
    } else if (isPatient) {
      updatePatientsProfileAssistanceLevelsByAzureOid({
        variables: { values }
      });
    }
  };

  const schema = Yup.object().shape({
    ConcFR: Yup.number()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    ConcFL: Yup.number()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    EccFR: Yup.number()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    EccFL: Yup.number()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required))
  });

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <Box className={classes.header}>
          <Typography
            variant="body2"
            className={clsx(classes.darkGrey, classes.descriptiveText)}
          >
            {t(
              TranslationsEnum.Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevels
            )}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.formContainer}>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {props => (
              <AssistanceLevelsForm
                loading={
                  loadingUpdatePatientAssistanceOperator ||
                  loadingUpdatePatientAssistancePatient
                }
                patientRequestedClinicStatus={patientRequestedClinicStatus}
                {...props}
              />
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default AssistanceLevelsCard;
