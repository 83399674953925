import translation from "./translation.json";
import translationDPA from "./translationDPA.json";
import translationElectron from "./translationElectron.json";

const translationDE = {
  ...translation,
  ...translationDPA,
  ...translationElectron
};

export default translationDE;
