import { useTranslation } from "react-i18next";
import { defaultError } from "../../constants/defaultValues";
import { useState, useEffect } from "react";

export const useErrorDescription = (
  code: number | undefined,
  GUID?: string
) => {
  const [errorDescription, setErrorDescription] = useState<
    string | undefined
  >();
  const { t } = useTranslation();
  useEffect(() => {
    if (code) {
      const translationString = t(`ErrorCodes.${code}`, {
        GUID
      });

      if (translationString === `ErrorCodes.${code}`) {
        setErrorDescription(t(`ErrorCodes.${defaultError}`));
        return;
      }
      setErrorDescription(translationString);
      return;
    }
    setErrorDescription(undefined);
  }, [GUID, code, t]);

  return errorDescription;
};
