import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  cardContentRoot: {
    padding: 0
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    paddingTop: 18,
    paddingRight: 18,
    paddingBottom: 18,
    paddingLeft: theme.spacing(3)
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal"
  },
  cardActionButton: {
    textTransform: "uppercase"
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  cardAction: {
    margin: 0,
    padding: 0
  }
}));
