import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

const createPageButton = (theme: Theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 30,
  padding: `0 ${theme.spacing(0.5)}px`,
  height: 30,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.secondary,
  cursor: "pointer",
  backgroundColor: ColorEnum.LightBlack,
  transition: theme.transitions.create(["color", "background-color"]),
  "&:hover": {
    color: theme.palette.text.primary
  }
});

export default makeStyles((theme: Theme) => ({
  cell: {
    width: "auto"
  },
  spacer: {
    flexGrow: 1
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  text: {
    marginRight: theme.spacing(1)
  },
  pageButton: createPageButton(theme),
  spacerButton: {
    ...createPageButton(theme),
    width: "auto",
    cursor: "default",
    "&:hover": {
      color: theme.palette.text.secondary
    }
  },
  pageButtonActive: {
    ...createPageButton(theme),
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary
  },
  pageInputWrapper: {
    width: "auto"
  },
  pageInput: {
    width: 60,
    textAlign: "center"
  },
  mobileProgressWrapper: {
    position: "absolute",
    left: theme.spacing(2),
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
