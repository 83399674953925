// IMPORTANT! Synced with backend, note this when editing
export enum SessionSelectEnum {
  LastSession = 0,
  Last5Session = 1,
  Last10Session = 2,
  Last30days = 3,
  CustomPeriod = 4,
  AllSessions = 5,
  CustomSelect = 6
}
