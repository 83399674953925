import { useState, useMemo, useEffect, useCallback } from "react";
import {
  uploadSessionFiles,
  getInfoOfUploadedFiles
} from "../../api/sessionFiles";
import {
  sessionHeaderFilePrefix,
  sessionLogsFilePrefix
} from "../../shared-constants/files";
import { UploadStatusEnum } from "../../types/enums/UploadStatusEnum";
import { defaultError } from "../../constants/defaultValues";
import { IFile } from "../../types/files/IFile";
import { MyoFileUploadStatusEnum } from "../../types/enums/MyoFileUploadStatusEnum";
import { IFileInfo } from "../../types/files/IFileInfo";

const useHandleOnDrop = () => {
  const [filesByNames, setFilesByNames] = useState<Record<string, IFile>>({});
  const [filenames, setFilenames] = useState<string[]>([]);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFinishedUploading, setIsFinishedUploading] = useState(false);
  const [infosOfUploadedFiles, setInfosOfUploadedFiles] = useState<IFileInfo[]>(
    []
  );

  const handleOnDrop = (receivedFiles: File[], rejectedFiles: File[]) => {
    const receivedFilenames = receivedFiles.map(file => file.name);
    const newFilesByNames: Record<string, IFile> = receivedFiles.reduce(
      (acc, cv) => ({
        ...acc,
        [cv.name]: {
          file: cv,
          uploadStatus:
            cv.name.includes(sessionLogsFilePrefix) || cv.size !== 0
              ? UploadStatusEnum.Pending
              : UploadStatusEnum.Resolved
        }
      }),
      filesByNames
    );

    setFilesByNames(newFilesByNames);

    const unionOfFilenames = filenames.concat(
      receivedFilenames.filter(filename => filenames.indexOf(filename) < 0)
    );

    setFilenames(unionOfFilenames);

    setIsFinishedUploading(false);
    if (rejectedFiles.length) {
      setIsWrongFileType(true);
    }
  };

  const uploadFiles = useCallback(
    async (file: File) => {
      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setFilesByNames(cv => ({
            ...cv,
            [file.name]: {
              ...cv[file.name],
              uploadProgress,
              uploadStatus: UploadStatusEnum.Uploading
            }
          }));
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      const formData = new FormData();
      formData.append("file", file);
      try {
        setIsUploading(true);
        setFilesByNames(cv => ({
          ...cv,
          [file.name]: {
            ...cv[file.name],
            uploadStatus: UploadStatusEnum.Uploading
          }
        }));
        const { data } = await uploadSessionFiles({ formData, config });
        setFilesByNames(cv => ({
          ...cv,
          [file.name]: {
            ...cv[file.name],
            uploadStatus: UploadStatusEnum.Resolved,
            unitSerialNumber: data.unitSerialNumber,
            isHeader: file.name.includes(sessionHeaderFilePrefix),
            isDuplicate:
              data.myoFileUploadStatus === MyoFileUploadStatusEnum.Reuploaded
          }
        }));
      } catch (e) {
        const error = e.response?.data?.Code || defaultError;
        setFilesByNames(cv => ({
          ...cv,
          [file.name]: {
            ...cv[file.name],
            uploadStatus: UploadStatusEnum.Resolved,
            error
          }
        }));
      } finally {
        setIsUploading(false);
      }
    },
    [setFilesByNames]
  );

  const checkForWarnings = useCallback(
    async (names: string[]) => {
      try {
        const {
          data: { infoOfUploadedFiles }
        }: {
          data: { infoOfUploadedFiles: IFileInfo[] };
        } = await getInfoOfUploadedFiles({
          filenames: names
        });
        setInfosOfUploadedFiles(infoOfUploadedFiles);
        const updatedFilesByNames = infoOfUploadedFiles.reduce(
          (acc, cv) => ({
            ...acc,
            [cv.fileName]: {
              ...filesByNames[cv.fileName],
              warning: cv.fileUploadWarning
            }
          }),
          {}
        );
        setFilesByNames(updatedFilesByNames);
      } catch (e) {}
    },
    [filesByNames]
  );

  useEffect(() => {
    if (!isUploading) {
      const files = filenames.map(name => filesByNames[name]);
      const pendingHeadersFiles = files.filter(
        fileInfo =>
          fileInfo.uploadStatus === UploadStatusEnum.Pending &&
          fileInfo.file.name.includes(sessionHeaderFilePrefix)
      );
      if (pendingHeadersFiles.length > 0) {
        uploadFiles(pendingHeadersFiles[0].file);
        return;
      }
      const pendingSessionLogsFiles = files.filter(
        fileInfo =>
          fileInfo.uploadStatus === UploadStatusEnum.Pending &&
          fileInfo.file.name.includes(sessionLogsFilePrefix)
      );
      if (pendingSessionLogsFiles.length > 0) {
        uploadFiles(pendingSessionLogsFiles[0].file);
        return;
      }
      const otherMyoFiles = files.filter(
        fileInfo => fileInfo.uploadStatus === UploadStatusEnum.Pending
      );
      if (otherMyoFiles.length > 0) {
        const updatedIgnoredFilesByName = otherMyoFiles.reduce(
          (acc, cv) => ({
            ...acc,
            [cv.file.name]: {
              ...filesByNames[cv.file.name],
              ignored: true,
              uploadStatus: UploadStatusEnum.Resolved
            }
          }),
          filesByNames
        );
        setFilesByNames(updatedIgnoredFilesByName);
        return;
      }
      if (filenames.length > 0 && !isFinishedUploading) {
        setIsFinishedUploading(true);
        checkForWarnings(filenames);
      }
    }
  }, [
    checkForWarnings,
    filesByNames,
    filenames,
    isUploading,
    uploadFiles,
    isFinishedUploading
  ]);

  const files = useMemo(
    () => ({
      filesByNames,
      filenames
    }),
    [filesByNames, filenames]
  );

  return {
    handleOnDrop,
    files,
    isWrongFileType,
    setIsWrongFileType,
    isFinishedUploading,
    infosOfUploadedFiles
  };
};

export default useHandleOnDrop;
