import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  button: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center"
  }
}));
