import React, { useCallback, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import useStyles from "./Dropdown.styles";
import { IRelease } from "../../../types/IRelease";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DropdownItem from "./DropdownItem/DropdownItem";
import ErrorIcon from "@material-ui/icons/Error";
import { useGithubReleases } from "../../../utils/helpers/githubHelpers";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const Dropdown: React.FC = () => {
  const {
    releases,
    defaultRelease,
    loading,
    failedToLoad
  } = useGithubReleases();
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const classes = useStyles();

  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const arrowButtonCollapsedClass = collapsed
    ? classes.collapsed
    : classes.expanded;

  if (loading) {
    return (
      <Box className={classes.container} display="flex">
        <CircularProgress size={24} color="primary" />
      </Box>
    );
  }

  if (failedToLoad) {
    return (
      <Box className={classes.container} display="flex">
        <ErrorIcon className={classes.error} />
        <Typography variant="subtitle2" className={classes.error}>
          {t("Containers.DownloadDesktopApp.CouldNotLoadError")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1" className={classes.title}>
        {t("Containers.DownloadDesktopApp.DownloadApp")}
      </Typography>
      <Box display="flex" className={classes.dropdownContainer}>
        <DropdownItem
          release={defaultRelease}
          isDefault={releases.length > 0}
        />
        {releases.length > 0 && (
          <Box
            display="flex"
            className={clsx(
              classes.dropdownArrowButton,
              arrowButtonCollapsedClass
            )}
            onClick={toggleCollapse}
          >
            {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Box>
        )}
      </Box>
      <Box
        display={collapsed ? "none" : "flex"}
        className={classes.dropdownList}
      >
        {releases.map((release: IRelease, index: number) => (
          <DropdownItem key={index} release={release} isDefault={false} />
        ))}
      </Box>
    </Box>
  );
};

export default Dropdown;
