import { ActionsUnion } from "../../utils/typings/common";
import { IUserProfile } from "../../types/IUserProfile";

// Action types
export const SET_USER_PROFILE = "user/SET_USER_PROFILE";
export const SET_DPA = "user/SET_DPA";

// Action creators
export const actions = {
  setUserProfileAction: (userProfile: IUserProfile | undefined | null) => ({
    type: SET_USER_PROFILE as typeof SET_USER_PROFILE,
    payload: userProfile
  }),
  setDPA: (dpa: boolean) => ({
    type: SET_DPA as typeof SET_DPA,
    payload: dpa
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
