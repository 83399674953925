import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import useStyles from "./EditMessage.styles";
import { TranslationsEnum } from "../../types/enums";
import { useTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { MessageReceiverTypeEnum } from "../../types/enums/MessageReceiverTypeEnum";
import draftToHtml from "draftjs-to-html";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { editMessageMutation } from "../../mutations/messages";
import { useHistory, useParams } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { schema } from "./ValidationSchema";
import { Formik, Form } from "formik";
import { IMessageIdRouteParams } from "../../types/routeParams/IMessageIdRouteParams";
import { getSingleMessage } from "../../queries/messages";
import { IMessage } from "../../types/IMessage";
import htmlToDraft from "html-to-draftjs";
import { IEditMessage } from "../../types/formInputs/IEditMessage";
import { getMessageReceiverTypeName } from "../../utils/helpers/messageHelpers";

const EditMessage: React.FC = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { messageId } = useParams<IMessageIdRouteParams>();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    history.push(PathNamesEnum.Messages);
  }, [history]);

  const [editMessage, { error, loading }] = useMutation(editMessageMutation, {
    onError: () => {},
    onCompleted: handleSuccess
  });
  useErrorHandling(error);

  const {
    data: messageData,
    error: messageError,
    loading: messageLoading
  } = useQuery<{
    getSingleMessage: IMessage;
  }>(getSingleMessage(Number(messageId)), {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(messageError);

  useEffect(() => {
    if (messageData?.getSingleMessage) {
      const blocksFromHtml = htmlToDraft(messageData.getSingleMessage.text);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [messageData]);

  const onEditorStateChange = useCallback(
    editorState => {
      setEditorState(editorState);
    },
    [setEditorState]
  );

  const handleSave = useCallback(
    (values: IEditMessage) => {
      editMessage({
        variables: {
          message: {
            id: messageId,
            title: values.title,
            text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
          }
        }
      });
    },
    [editorState, editMessage, messageId]
  );

  const messageReceiverTypeName = useMemo(() => {
    if (messageData?.getSingleMessage?.messageReceiverType != null) {
      return getMessageReceiverTypeName(
        messageData.getSingleMessage.messageReceiverType
      );
    }
    return "";
  }, [messageData]);

  const messageReceiverType = useMemo(() => {
    return messageData?.getSingleMessage?.messageReceiverType;
  }, [messageData]);

  const receiverName = useMemo(() => {
    return messageData?.getSingleMessage?.receiverName;
  }, [messageData]);

  if (messageLoading) {
    return (
      <Box display="flex" justifyContent="center" pt={12.5}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const initialValues: IEditMessage = {
    title: messageData?.getSingleMessage.title || ""
  };

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography variant="h6" className={classes.darkGrey}>
          {t(TranslationsEnum.Containers_CreateEditMessage_EditMessage)}
        </Typography>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={handleSave}
        >
          {props => {
            const { values, errors, handleChange } = props;
            return (
              <Form>
                <Box className={classes.form}>
                  <Box className={classes.formItem}>
                    <TextField
                      name="title"
                      label={t(
                        TranslationsEnum.Containers_CreateEditMessage_MessageTitle
                      )}
                      margin="dense"
                      variant="outlined"
                      value={values.title}
                      error={Boolean(errors.title)}
                      helperText={errors.title}
                      onChange={handleChange}
                      className={classes.textField}
                      disabled={false}
                    />
                  </Box>
                  <Box className={classes.formItem}>
                    <TextField
                      name="messageReceiverType"
                      label={t(
                        TranslationsEnum.Containers_CreateEditMessage_ChooseMessageReceiverType
                      )}
                      margin="dense"
                      variant="outlined"
                      value={messageReceiverTypeName}
                      className={classes.textField}
                      disabled={true}
                    />
                  </Box>
                  {messageReceiverType === MessageReceiverTypeEnum.Private && (
                    <Box className={classes.formItem}>
                      <TextField
                        name="messageReceiverType"
                        label={t(
                          TranslationsEnum.Containers_CreateEditMessage_SelectUser
                        )}
                        margin="dense"
                        variant="outlined"
                        value={receiverName}
                        className={classes.textField}
                        disabled={true}
                      />
                    </Box>
                  )}
                </Box>
                <Box className={classes.formItem}>
                  <Editor
                    editorState={editorState}
                    editorClassName="editor-wrapper"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Box>
                <Box className={classes.buttonsContainer}>
                  <Button
                    disabled={loading}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t(TranslationsEnum.Global_Save)
                    )}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Box>
  );
};

export default EditMessage;
