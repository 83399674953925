import React from "react";
import { Grid, Typography, Tooltip, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ISessionDataGridItem } from "../../../../types/ISessionDataGridItem";
import { ISessionData } from "../../../../types/ISessionData";
import SitupsIcons from "../../../../assets/icons/SitupsIcon";
import StairsIcon from "../../../../assets/icons/StairsIcon";
import StepsIcon from "../../../../assets/icons/StepsIcon";
import TimerIcon from "../../../../assets/icons/TimerIcon";
import TrainingIcon from "../../../../assets/icons/TrainingIcons";
import WalkIcon from "../../../../assets/icons/WalkIcon";
import InfoIcon from "../../../../assets/icons/InfoIcon";
import { format } from "date-fns";
import TextHighlighter from "../../../../components/animations/TextHighlighter/TextHighlighter";
import useStyles from "./ChartFooter.styles";

interface IProps {
  session: ISessionData;
}

const sessionDataGridItems = (
  classes: Record<string, string>,
  t: TFunction,
  session: ISessionData
): ISessionDataGridItem[] => [
  {
    icon: <StepsIcon className={classes.iconMargin} />,
    value: session.sessionSteps.toString(),
    text: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.StepNumbers"
    ),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StepNumbersTooltip"
    )
  },
  {
    icon: <StairsIcon className={classes.iconMargin} />,
    value: session.sessionStairs.toString(),
    text: t("Global.Stairs"),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StairsTooltip"
    )
  },
  {
    icon: <SitupsIcons className={classes.iconMargin} />,
    value: session.sessionSTS.toString(),
    text: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.SeatTransfers"
    ),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.SeatTransfersTooltip"
    )
  },
  {
    icon: <TimerIcon className={classes.iconMargin} />,
    value: `${Math.round(session.sessionActiveRate * 10000) / 100}%`,
    text: t("Global.ActiveTime"),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.ActiveTimeTooltip"
    )
  },
  {
    icon: <TrainingIcon className={classes.iconMargin} />,
    value: `L:${session.sessionSupportL} - R:${session.sessionSupportR}`,
    text: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.SupportLevels"
    ),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.SupportLevelsTooltip"
    )
  },
  {
    icon: <WalkIcon className={classes.iconMargin} />,
    value: `${Math.round(session.sessionStepSymmetry * 10000) / 100}%`,
    text: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.StepSymmetry"
    ),
    tooltipText: t(
      "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StepSymmetryTooltip"
    )
  }
];

const ChartFooter = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { session } = props;

  return (
    <Grid container spacing={0}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.sessionTitle}
      >
        <Typography variant="subtitle1" className={classes.title}>
          {session.sessionDate
            ? `${t("Global.Session")} ${session.sessionDisplayId}, ${format(
                new Date(session.sessionDate),
                "dd LLL"
              )}`
            : t("Containers.SessionsTab.SessionsChart.ChartFooter.NoDate")}
        </Typography>
        <Tooltip
          title={t(
            "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.Session"
          )}
          arrow
          placement="bottom"
          classes={{
            tooltip: classes.tooltip
          }}
        >
          <Box>
            <InfoIcon />
          </Box>
        </Tooltip>
      </Grid>
      {sessionDataGridItems(classes, t, session).map((sessionPart, index) => (
        <Grid
          key={`sessionPart${index}`}
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className={classes.sessionPartContainer}
        >
          <Tooltip
            title={sessionPart.tooltipText}
            arrow
            placement="bottom"
            classes={{
              tooltip: classes.tooltip,
              tooltipArrow: classes.tootltipArrow
            }}
          >
            <Box className={classes.gridInfoIcon}>
              <InfoIcon />
            </Box>
          </Tooltip>
          <Box
            mt={2}
            mb={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {sessionPart.icon}
            <Box display="flex" flexDirection="column">
              <TextHighlighter>
                <Typography variant="body2" className={classes.valueText}>
                  {sessionPart.value}
                </Typography>
              </TextHighlighter>
              <Typography variant="body2" className={classes.descriptiveText}>
                {sessionPart.text}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChartFooter;
