import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

interface IProps {
  label: string;
}

export default makeStyles(() => ({
  formControl: {
    "&>div>div::before": {
      content: (props: IProps) => `'${props.label}' !important`,
      left: "9px !important",
      width: "max-content !important",
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  formControlError: {
    "&>div>div::before": {
      content: (props: IProps) => `'${props.label}' !important`,
      left: "9px !important",
      width: "max-content !important",
      color: `${ColorEnum.ErrorRed} !important`
    },
    "&>div>input:focus": {
      outline: `none`,
      boxShadow: `0 0 0 1px ${ColorEnum.ErrorRed} !important`
    }
  }
}));
