import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn
} from "mui-datatables";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { IOption } from "../../types/formInputs/IOption";
import { useQuery } from "@apollo/react-hooks";
import { getMyoSuitUnitsQuery } from "../../queries/units";
import { IUnit } from "../../types/IUnit";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { SelectField, DataTableFooter } from "../../components";
import { IPatientsTableDTO } from "../../types/tables/IPatientsTable";
import { getPatientsQuery } from "../../queries/patients";
import { IFilterConfig } from "../../types/filters/IFilterConfig";
import { getSessionFiles } from "../../api/sessionFiles";
import ErrorModal from "../Modals/ErrorModal/ErrorModal";
import { downloadBlob } from "../../utils/helpers/filesHelpers";
import { ISelectedRow } from "../../types/formInputs/ISelectRow";
import { useErrorDescription } from "../../utils/helpers/translationHelpers";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import clsx from "clsx";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import UploadToSDCardInstructions from "./UploadToSDCardInstructions/UploadToSDCardInstructions";
import { isElectron } from "../../utils/helpers/electronHelpers";
import { IWindow } from "../../types/IWindow";
import { IDisk } from "../../types/IDisk";
import ElectronErrorModal from "../Modals/ElectronErrorModal/ElectronErrorModal";
import { RENDERER_EVENTS } from "../../shared-constants/events";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { selectSelectedDrive } from "../../utils/helpers/stateSelectorHelpers";
import { useDispatch } from "react-redux";
import { actions } from "../../state/electron/actions";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import ClearSDCardModal from "../Modals/ClearSDCardModal/ClearSDCardModal";
import { useSelector } from "../../state/store";
import { selectPreselectedUnit } from "../../utils/helpers/stateSelectorHelpers";
import { TranslationsEnum, PatientsColumnsEnum } from "../../types/enums";
import { authProviderSignUpSignIn } from "../../utils/configs/authProvider";
import useStyles from "./PrepareSession.styles";
import { IPatient } from "../../types/IPatient";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import { selectUserProfile } from "../../utils/helpers/stateSelectorHelpers";

declare const window: IWindow;

const PrepareSession: React.FC = () => {
  const [patientsWithoutDpa, setPatientsWithoutDpa] = useState<IPatient[]>([
    {
      id: 0,
      isNewUser: true,
      genderType: 0,
      inSanitasTrial: false,
      isQuestionnaireDone: false
    }
  ]);
  const { userProfile } = useSelector(selectUserProfile);
  const [selectedPatientsDatas, setSelectedPatientsDatas] = useState<
    IPatient[]
  >();

  const { t } = useTranslation();
  const [openDpaModal, setOpenDpaModal] = useState(false);
  const handleOpenDpaModal = () => {
    setOpenDpaModal(true);
  };
  const handleCloseDpaModal = () => {
    setOpenDpaModal(false);
    setTempSignedPatientsIds([]);
  };
  const classes = useStyles();
  const { selectedDrive } = useSelector(selectSelectedDrive);
  const [selectedUnitSerialNumber, setSelectedUnitSerialNumber] = useState<
    string | undefined
  >();
  const [selectedPatientsIds, setSelectedPatientsIds] = useState<number[]>([]);
  const [showSDCardClearModal, setShowSDCardClearModal] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isDeviceDropdownOpen, setIsDeviceDropdownOpen] = useState(false);
  const [error, setError] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const errorDescription = useErrorDescription(error);
  const { preselectedUnitSerialNumber } = useSelector(selectPreselectedUnit);
  const dispatch = useDispatch();
  const history = useHistory();

  const unselectDrive = useCallback(() => {
    dispatch(actions.unselectDriveAction());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedDrive && !showInstructions && isElectron()) {
      history.push(PathNamesEnum.SyncMyoSuit);
      return;
    }

    if (isElectron()) {
      window.ipcRenderer.on(RENDERER_EVENTS.DOWNLOAD_FILES_REPLY, () => {
        setShowInstructions(true);
        unselectDrive();
        setLoading(false);
      });
    }

    return () => {
      if (isElectron()) {
        window.ipcRenderer.removeAllListeners(
          RENDERER_EVENTS.DOWNLOAD_FILES_REPLY
        );
      }
    };
  }, [
    selectedDrive,
    unselectDrive,
    showInstructions,
    setShowInstructions,
    history
  ]);

  const toggleDropdown = () => {
    setIsDeviceDropdownOpen(!isDeviceDropdownOpen);
  };

  const defaultSorting = {
    sortField: "id",
    sortDirection: SortDirectionEnum.Ascending
  };
  const [filterConfig, setFilterConfig] = useState<IFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: []
  });

  const handleOnComplete = ({
    getMyoSuitUnits
  }: {
    getMyoSuitUnits: IUnit[];
  }) => {
    if (getMyoSuitUnits.length === 1) {
      setSelectedUnitSerialNumber(getMyoSuitUnits[0].unitSerialNumber);
      return;
    }
    if (getMyoSuitUnits.length > 1 && selectedDrive) {
      const unit = getMyoSuitUnits.find(
        u => u.unitSerialNumber === selectedDrive.myoSuitDeviceName
      );
      if (unit) {
        setSelectedUnitSerialNumber(unit.unitSerialNumber);
        return;
      }
    }
    if (getMyoSuitUnits.length > 1 && preselectedUnitSerialNumber) {
      const unit = getMyoSuitUnits.find(
        u => u.unitSerialNumber === preselectedUnitSerialNumber
      );
      if (unit) {
        setSelectedUnitSerialNumber(unit.unitSerialNumber);
      }
    }
  };

  const { data: myoSuitUnits, error: getMyoSuitUnitsError } = useQuery<{
    getMyoSuitUnits: IUnit[];
  }>(getMyoSuitUnitsQuery, {
    fetchPolicy: "network-only",
    onCompleted: handleOnComplete
  });

  const { data: patientsData, error: getPatientsNamesError } = useQuery<{
    patientsTableDTO: IPatientsTableDTO;
  }>(getPatientsQuery, {
    variables: { filterConfig },
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(getMyoSuitUnitsError);
  useErrorHandling(getPatientsNamesError);

  const columns: MUIDataTableColumn[] = [
    {
      name: PatientsColumnsEnum.Id,
      label: t(TranslationsEnum.Global_ID),
      options: {
        customBodyRender: (value: number) => {
          const patient = patientsData?.patientsTableDTO.patients.find(
            x => x.id === value
          );
          return (
            <Box>
              {value}
              {(!patient?.dataProcessingAgreement ||
                !patient?.isPhysicalDataProcessingAgreement) && (
                <Tooltip title={t(TranslationsEnum.Global_NotVerified)}>
                  <WarningRoundedIcon
                    className={`${classes.warningBase} ${classes.orange}`}
                  />
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    },
    ...(userProfile?.clinicInSanitasTrial
      ? [
          {
            name: PatientsColumnsEnum.Id,
            label: "TABT",
            options: {
              customBodyRender: (value: number) => {
                const patient = patientsData?.patientsTableDTO.patients.find(
                  x => x.id === value
                );
                return (
                  <Box>
                    {patient?.isQuestionnaireDone && (
                      <Tooltip
                        title={t(TranslationsEnum.Global_QuestionnaireDone)}
                      >
                        <BorderColorRoundedIcon
                          className={`${classes.warningBase} ${classes.green}`}
                        />
                      </Tooltip>
                    )}
                    {patient?.inSanitasTrial &&
                      userProfile?.clinicInSanitasTrial && (
                        <Tooltip title={t(TranslationsEnum.Global_PartOfTrial)}>
                          <WarningRoundedIcon
                            className={`${classes.warningBase} ${classes.green}`}
                          />
                        </Tooltip>
                      )}
                  </Box>
                );
              }
            }
          }
        ]
      : []),
    {
      name: "name",
      label: t(TranslationsEnum.Global_DisplayName)
    }
  ];

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig(x => ({ ...x, page }));

  const units: IOption[] | undefined = useMemo(
    () =>
      myoSuitUnits?.getMyoSuitUnits.map(unit => ({
        label: unit.unitSerialNumber,
        value: unit.unitSerialNumber
      })),
    [myoSuitUnits]
  );

  const patients: [string, number][] | undefined = useMemo(
    () =>
      patientsData?.patientsTableDTO.patients.map(patient => [
        patient.name || "",
        patient.id
      ]),
    [patientsData]
  );

  const rowsIds = useMemo(() => patients?.map((patient, index) => index), [
    patients
  ]);

  const rowsSelected = useMemo(
    () =>
      rowsIds?.filter(
        row =>
          patients &&
          selectedPatientsIds &&
          selectedPatientsIds.includes(patients[row][1] as number)
      ),
    [patients, rowsIds, selectedPatientsIds]
  );

  const handleRowsSelect = useCallback(
    (selectedRows: ISelectedRow[]) => {
      if (patients) {
        // deselect all in page
        if (selectedRows.length === 0) {
          const patientsInPageIds = patients.map(patient => patient[1]);
          const patientsIdsWithoutPatientsInPage = selectedPatientsIds.filter(
            id => !patientsInPageIds.includes(id)
          );
          setSelectedPatientsIds(patientsIdsWithoutPatientsInPage);
          return;
        }

        const selectedRowsPatientsIds = selectedRows.map(
          row => patients[row.index][1]
        );
        // return difference of two arrays removing duplicates completely because it means row has been deselected
        const difference = selectedRowsPatientsIds.reduce<number[]>(
          (acc, cv) =>
            selectedPatientsIds.includes(cv)
              ? [...acc.filter(v => v !== cv)]
              : [...acc, cv],
          selectedPatientsIds
        );

        setSelectedPatientsIds(difference);

        const curentPatientDatas = patientsData?.patientsTableDTO.patients.filter(
          element => difference.includes(element.id)
        );

        const diferenceData = [
          ...(selectedPatientsDatas ?? []),
          ...(curentPatientDatas ?? [])
        ]
          .filter(
            (value, index, self) =>
              self.findIndex(m => m.id === value.id) === index
          )
          .filter(data => difference.includes(data.id));

        setSelectedPatientsDatas(diferenceData);
      }
    },
    [patients, selectedPatientsIds, patientsData, selectedPatientsDatas]
  );

  const hasSelectedDriveIfElectron = isElectron() ? !!selectedDrive : true;
  const downloadEnabled =
    selectedUnitSerialNumber &&
    selectedPatientsIds.length > 0 &&
    hasSelectedDriveIfElectron;

  const downloadToDrive = useCallback(
    async (drive: IDisk) => {
      setLoading(true);
      const {
        idToken: { rawIdToken }
      } = await authProviderSignUpSignIn.getIdToken();
      if (selectedPatientsIds && selectedUnitSerialNumber) {
        const { data } = await getSessionFiles({
          PatientIds: selectedPatientsIds,
          UnitSerialNumber: selectedUnitSerialNumber
        });
        window.downloadFiles({
          arrayBuffer: data,
          path: drive.path,
          token: `Bearer ${rawIdToken}`
        });
      }
    },
    [selectedPatientsIds, selectedUnitSerialNumber]
  );
  const [tempSignedPatientsIds, setTempSignedPatientsIds] = useState<number[]>(
    []
  );

  const downloadFiles = useCallback(async () => {
    try {
      if (!downloadEnabled) {
        if (!selectedUnitSerialNumber) {
          setIsDeviceDropdownOpen(true);
        }
        if (selectedPatientsIds.length === 0) {
          setHighlightText(true);
        }
        return;
      }

      const selectedWithoutDpa = selectedPatientsDatas?.filter(
        item =>
          userProfile?.clinicInSanitasTrial &&
          item.inSanitasTrial &&
          (!item.dataProcessingAgreement ||
            !item.isPhysicalDataProcessingAgreement) &&
          !tempSignedPatientsIds.includes(item.id)
      );

      setPatientsWithoutDpa(selectedWithoutDpa ?? []);

      if (
        tempSignedPatientsIds.length === 0 &&
        selectedWithoutDpa &&
        selectedWithoutDpa.length > 0
      ) {
        setTempSignedPatientsIds([-9999]);
        handleOpenDpaModal();
        return;
      }

      const selectedselectedWithoutDpaIds: number[] =
        selectedWithoutDpa?.map(i => i.id) ?? [];
      const downloadablePatientIds = selectedPatientsIds.filter(
        f => !selectedselectedWithoutDpaIds?.includes(f)
      );
      if (selectedUnitSerialNumber) {
        if (isElectron()) {
          if (selectedDrive) {
            // Filter out files that failed to upload, since we will not delete them anyways.
            const myoFilesToDelete = selectedDrive.myoFiles.filter(
              file => file.success || !file.isSessionFile
            );
            if (myoFilesToDelete.length > 0) {
              setShowSDCardClearModal(true);
              return;
            }
            if (selectedDrive) {
              await downloadToDrive(selectedDrive);
            }
          }
          return;
        }
        const { data } = await getSessionFiles({
          PatientIds: downloadablePatientIds,
          UnitSerialNumber: selectedUnitSerialNumber
        });
        downloadBlob(data);
        setShowInstructions(true);
      }
    } catch (error) {}
  }, [
    userProfile,
    downloadToDrive,
    downloadEnabled,
    selectedPatientsIds,
    selectedUnitSerialNumber,
    selectedPatientsDatas,
    setShowSDCardClearModal,
    selectedDrive,
    tempSignedPatientsIds
  ]);

  const buildCustomToolbarSelect = useCallback(
    () => (
      <Button
        color="primary"
        variant={downloadEnabled ? "contained" : "outlined"}
        onClick={downloadFiles}
      >
        {t("Containers.PrepareSession.Download")}
      </Button>
    ),
    [downloadEnabled, downloadFiles, t]
  );

  const handleSearchChange = (searchValue: string) => {
    const filters = [
      {
        filterField: "name",
        filterValue: searchValue || ""
      }
    ];
    setFilterConfig(x => ({ ...x, page: 0, filters }));
  };

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    count: patientsData?.patientsTableDTO.count,
    search: true,
    download: false,
    viewColumns: false,
    sort: false,
    filter: false,
    serverSide: true,
    print: false,
    selectableRowsOnClick: true,
    disableToolbarSelect: true,
    searchText: filterConfig.filters[0]?.filterValue,
    rowsPerPage: filterConfig.takePerPage,
    rowsSelected,
    textLabels: textLabelsConfig(),
    onSearchChange: handleSearchChange,
    onRowsSelect: handleRowsSelect,
    customFooter: buildCustomFooter,
    customToolbar: buildCustomToolbarSelect,
    onChangePage: handlePageChange
  };

  const clearError = () => setError(undefined);
  const getDataTitle = useCallback(() => {
    if (selectedPatientsIds.length === 1) {
      return `1 ${t("Containers.PrepareSession.SelectedPatient")}`;
    }
    if (selectedPatientsIds.length > 1) {
      return `${selectedPatientsIds.length} ${t(
        "Containers.PrepareSession.SelectedPatients"
      )}`;
    }
    return t("Containers.PrepareSession.SelectPatients");
  }, [selectedPatientsIds.length, t]);

  const renderIconComponent = useCallback(
    ({ className, ...props }) => (
      <ArrowDropDownIcon
        {...props}
        className={clsx(className, classes.selectUnitDropdownArrow)}
      />
    ),
    [classes.selectUnitDropdownArrow]
  );

  const [highlightText, setHighlightText] = useState(false);

  useEffect(() => {
    if (highlightText) {
      const timeout = setTimeout(() => {
        setHighlightText(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [highlightText]);

  const handleClose = useCallback(async () => {
    setShowSDCardClearModal(false);
  }, [setShowSDCardClearModal]);

  const handleDownload = useCallback(async () => {
    setShowSDCardClearModal(false);
    if (selectedDrive) {
      await downloadToDrive(selectedDrive);
    }
  }, [downloadToDrive, setShowSDCardClearModal, selectedDrive]);

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
        <ElectronErrorModal />
      </Box>
    );
  }
  const handleSubmit = () => {
    downloadFiles();
    handleCloseDpaModal();
  };

  return (
    <Box px={3} pt={4} className={classes.container}>
      <Dialog
        open={openDpaModal}
        onClose={handleCloseDpaModal}
        disableBackdropClick={true}
      >
        <Box m={2}>
          <Typography color="primary" variant="h5">
            {t(TranslationsEnum.Containers_PrepareSession_PatientsNotFit)}
          </Typography>
        </Box>
        <DialogContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t(TranslationsEnum.Global_ID)}</TableCell>
                  <TableCell>
                    {t(TranslationsEnum.Global_DisplayName)}
                  </TableCell>
                  <TableCell>{t(TranslationsEnum.Global_Tabt)}</TableCell>
                  <TableCell>{t(TranslationsEnum.Global_Approve)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientsWithoutDpa.map(patient => {
                  return (
                    <TableRow key={patient.id}>
                      <TableCell>{patient.id}</TableCell>
                      <TableCell>{patient.name}</TableCell>
                      <TableCell>
                        <Box>
                          {(!patient?.dataProcessingAgreement ||
                            !patient?.isPhysicalDataProcessingAgreement) && (
                            <Tooltip
                              title={t(TranslationsEnum.Global_NotVerified)}
                            >
                              <WarningRoundedIcon
                                className={`${classes.warningBase} ${classes.orange}`}
                              />
                            </Tooltip>
                          )}
                          {!patient?.isQuestionnaireDone && (
                            <Tooltip
                              title={t(
                                TranslationsEnum.Global_QuestionnaireNotDone
                              )}
                            >
                              <BorderColorRoundedIcon
                                className={`${classes.warningBase} ${classes.orange}`}
                              />
                            </Tooltip>
                          )}
                          {patient.isQuestionnaireDone &&
                            JSON.parse(patient.questionnaireResults ?? "{}")
                              .isSuccess === false && (
                              <Tooltip
                                title={t(
                                  TranslationsEnum.Containers_Modals_Questionnaire_Results_NoProfit
                                )}
                              >
                                <WarningRoundedIcon
                                  className={`${classes.warningBase} ${classes.orange}`}
                                />
                              </Tooltip>
                            )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          disabled={
                            !patient.isQuestionnaireDone ||
                            JSON.parse(patient.questionnaireResults ?? "{}")
                              .isSuccess === false
                          }
                          onChange={() => {
                            if (tempSignedPatientsIds.includes(patient.id)) {
                              setTempSignedPatientsIds(
                                tempSignedPatientsIds.filter(
                                  f => f !== patient.id
                                )
                              );
                            } else {
                              setTempSignedPatientsIds([
                                ...tempSignedPatientsIds,
                                ...[patient.id]
                              ]);
                            }
                          }}
                        ></Checkbox>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Box m={2}>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {tempSignedPatientsIds.length > 0
                ? t(TranslationsEnum.Global_Approve)
                : t(TranslationsEnum.Global_Close)}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {showInstructions ? (
        <UploadToSDCardInstructions />
      ) : (
        <>
          {units && (
            <SelectField
              onChange={event =>
                setSelectedUnitSerialNumber(event.target.value as string)
              }
              SelectProps={{
                open: isDeviceDropdownOpen,
                disableUnderline: true,
                className: classes.selectUnitButton,
                IconComponent: renderIconComponent,
                onClick: toggleDropdown
              }}
              selectField={{
                name: "name",
                options: units
              }}
              value={selectedUnitSerialNumber || null}
              placeholder={
                units.length === 0
                  ? t(TranslationsEnum.Global_NoAvailableMyosuit)
                  : t(TranslationsEnum.Containers_PrepareSession_SelectMyoSuit)
              }
            />
          )}
          <Box
            className={clsx({
              [classes.highlighted]: highlightText,
              [classes.notHighlighted]: !highlightText
            })}
          >
            <MUIDataTable
              title={getDataTitle()}
              data={patientsData?.patientsTableDTO.patients || []}
              columns={columns}
              options={options}
            />
          </Box>

          {isElectron() ? <ElectronErrorModal /> : null}
        </>
      )}
      <ErrorModal
        open={Boolean(error)}
        handleClose={clearError}
        message={errorDescription}
      />
      {isElectron() && (
        <ClearSDCardModal
          open={showSDCardClearModal}
          handleClose={handleClose}
          handleDownload={handleDownload}
        />
      )}
    </Box>
  );
};

export default PrepareSession;
