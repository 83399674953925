import React, { useEffect, useMemo } from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { Form, FormikProps } from "formik";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/SelectField/SelectField";
import { TFunction } from "i18next";
import { IStrapSizesValues } from "../../../../types/formInputs/IStrapSizesValues";
import { optionsSML, options5, options2 } from "../../../../constants/options";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum, TranslationsEnum } from "../../../../types/enums";
import { RequestedClinicStatusEnum } from "../../../../types/enums/RequestedClinicStatusEnum";
import useStyles from "./StrapSizesForm.styles";

const strapSizes = (t: TFunction): ISelectField[] => [
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size01Label"
    ),
    name: "Size01",
    options: optionsSML
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size02Label"
    ),
    name: "Size02",
    options: options5
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size03Label"
    ),
    name: "Size03",
    options: options5
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size04Label"
    ),
    name: "Size04",
    options: options2
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size05Label"
    ),
    name: "Size05",
    options: options5
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size06Label"
    ),
    name: "Size06",
    options: options5
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size07Label"
    ),
    name: "Size07",
    options: options2
  }
];

interface IDefaultProps {
  loading: boolean;
  focusedBox: number | undefined;
  hoveredBox: number | undefined;
  onSelectFieldClick: (number: number | undefined) => void;
  onSelectFieldHover: (number: number | undefined) => void;
  patientRequestedClinicStatus?: RequestedClinicStatusEnum;
}

type IProps = IDefaultProps & FormikProps<IStrapSizesValues>;

const StrapSizesForm = (props: IProps) => {
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    loading,
    errors,
    focusedBox,
    hoveredBox,
    onSelectFieldClick,
    onSelectFieldHover,
    patientRequestedClinicStatus
  } = props;
  const classes = useStyles();
  const { userProfile } = useSelector(selectUserProfile);

  const strapSizesArray = useMemo(() => strapSizes(t), [t]);

  useEffect(() => {
    if (focusedBox) {
      const focusedSelectId = `select${strapSizesArray[focusedBox - 1]?.name}`;

      const focusedSelectElement = document.getElementById(focusedSelectId);
      focusedSelectElement?.focus();
    }
  }, [focusedBox, strapSizesArray]);

  const handleBlur = () => onSelectFieldClick(undefined);

  const handleOpen = (number: number) => () => onSelectFieldClick(number);

  const handleHoverLeave = () => hoveredBox && onSelectFieldHover(undefined);

  const handleHoverEnter = (number: number) => () =>
    focusedBox !== number && onSelectFieldHover(number);

  const disable =
    loading ||
    (userProfile?.claimsType === ClaimsEnum.Operator &&
      patientRequestedClinicStatus === RequestedClinicStatusEnum.Pending);

  return (
    <Form key="StrapSizesForm">
      {strapSizesArray.map((size, index) => (
        <SelectField
          error={errors[size.name]}
          SelectProps={{
            onBlur: handleBlur,
            onOpen: handleOpen(index + 1),
            onMouseOut: handleHoverLeave,
            onMouseOver: handleHoverEnter(index + 1),
            disabled: disable
          }}
          FormControlProps={{
            variant: "outlined",
            className:
              hoveredBox === index + 1
                ? classes.formControlHovered
                : classes.formControl
          }}
          MenuItemProps={{
            className: classes.inputText
          }}
          InputLabelProps={{
            className: classes.descriptiveText
          }}
          key={`StrapSizesFormField${size.name}`}
          selectField={size}
          onChange={handleChange}
          value={values[size.name] || ""}
        />
      ))}
      <Box className={classes.saveButton}>
        <Button
          disabled={disable}
          variant="contained"
          color="primary"
          type="submit"
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t(TranslationsEnum.Global_Save)
          )}
        </Button>
      </Box>
    </Form>
  );
};

export default StrapSizesForm;
