import clsx from "clsx";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Button, CircularProgress, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from "mui-datatables";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTableFooter from "../../components/DataTableFooter/DataTableFooter";
import FilterChipBar from "../../components/FilterChipBar/FilterChipBar";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import { getClinicsListFilteredQuery } from "../../queries";
import {
  SnackbarTypeEnum,
  TranslationsEnum,
  ClinicsColumnsEnum
} from "../../types/enums";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import { IFilterConfig } from "../../types/filters/IFilterConfig";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import { IClinicsTableDTO } from "../../types/tables/IClinicsTable";
import { attachSortToColumns } from "../../utils/helpers/filtersHelpers";
import { pickOptionLabel } from "../../utils/helpers/optionHelper";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import {
  CreateClinicModal,
  DeleteClinicModal,
  EditClinicModal
} from "../Modals";
import DeleteIcon from "@material-ui/icons/Delete";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import { useDispatch } from "react-redux";
import { deleteClinicMutation } from "../../mutations";
import { clinicStatusOptions } from "../../constants/options";
import useStyles from "./Clinics.styles";

const Clinics: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultSorting = {
    sortField: ClinicsColumnsEnum.Id,
    sortDirection: SortDirectionEnum.Descending
  };

  const [filterConfig, setFilterConfig] = useState<IFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: []
  });

  const { data, loading, error, refetch } = useQuery<{
    clinicsTableDTO: IClinicsTableDTO;
  }>(getClinicsListFilteredQuery, {
    variables: { filterConfig },
    fetchPolicy: "network-only"
  });

  useErrorHandling(error);

  // Edit modal state
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [clinicToEditId, setClinicToEditId] = React.useState<null | number>(
    null
  );

  const handleEditModalClose = useCallback(() => {
    setClinicToEditId(null);
    setEditModalOpen(false);
  }, [setClinicToEditId, setEditModalOpen]);

  const handleEditModalOpen = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setEditModalOpen(true);
      setClinicToEditId(id);
    },
    [setEditModalOpen, setClinicToEditId]
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: ClinicsColumnsEnum.Id,
      label: t(TranslationsEnum.Global_ID)
    },
    {
      name: ClinicsColumnsEnum.Name,
      label: t(TranslationsEnum.Global_Name)
    },
    {
      name: ClinicsColumnsEnum.Description,
      label: t(TranslationsEnum.Global_Description)
    },
    {
      name: ClinicsColumnsEnum.Address,
      label: t(TranslationsEnum.Global_Address)
    },
    {
      name: ClinicsColumnsEnum.Telephone,
      label: t(TranslationsEnum.Global_PhoneNumber)
    },
    {
      name: ClinicsColumnsEnum.Status,
      label: t(TranslationsEnum.Global_ClinicStatus),
      options: {
        customBodyRender: value => {
          return <>{pickOptionLabel(value, clinicStatusOptions)}</>;
        }
      }
    },
    {
      name: ClinicsColumnsEnum.Id,
      label: " ",
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <Box display="flex">
              <Box display="flex">
                <Box onClick={handleEditModalOpen(value)}>
                  <IconButton aria-label="edit">
                    <EditIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
              <Box onClick={handleDeleteModalOpen(value)}>
                <IconButton aria-label="delete">
                  <DeleteIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
          );
        }
      }
    }
  ];

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection: filterConfig.sortDirection,
        name: filterConfig.sortField
      }),
    [filterConfig, columns]
  );

  // Create modal
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);

  const handleAddModalOpen = () => setAddModalOpen(true);

  const handleAddModalClose = () => setAddModalOpen(false);

  // Delete modal
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [clinicToDeleteId, setClinicToDeleteId] = React.useState<null | number>(
    null
  );

  const handleDeleteModalOpen = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setDeleteModalOpen(true);
      setClinicToDeleteId(id);
    },
    [setDeleteModalOpen, setClinicToDeleteId]
  );

  const handleDeleteModalClose = useCallback(() => {
    setClinicToDeleteId(null);
    setDeleteModalOpen(false);
  }, [setClinicToDeleteId, setDeleteModalOpen]);

  const handleDeleteSuccess = () => {
    refetch();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_ClinicDeletedSuccessfully)
      )
    );
  };

  const [
    deleteClinic,
    { error: deleteClinicError, loading: deleteLoading }
  ] = useMutation(deleteClinicMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess
  });

  useErrorHandling(deleteClinicError);

  const handleDeleteClinic = useCallback(() => {
    deleteClinic({
      variables: { values: { id: clinicToDeleteId } }
    });
    handleDeleteModalClose();
  }, [deleteClinic, handleDeleteModalClose, clinicToDeleteId]);

  const filterColumns: IFilterColumn[] = useMemo(
    () =>
      columns
        .filter(
          x =>
            x.name !== ClinicsColumnsEnum.Id &&
            x.name !== ClinicsColumnsEnum.Status
        )
        .map(x => ({
          name: x.name,
          label: x.label ?? ""
        })),
    [columns]
  );

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig(x => ({ ...x, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    setFilterConfig(x => ({ ...x, sortField, sortDirection }));
  };

  const hasSorting = useMemo(
    () =>
      !(
        defaultSorting.sortField === filterConfig.sortField &&
        defaultSorting.sortDirection === filterConfig.sortDirection
      ),
    [defaultSorting, filterConfig]
  );

  const actionButtons = (
    <Box>
      <Button
        className={clsx(classes.mb1, classes.mr2)}
        variant="contained"
        color="primary"
        onClick={handleAddModalOpen}
      >
        {t(TranslationsEnum.Global_NewClinic)}
      </Button>
    </Box>
  );

  const handleFilter = (filters: IWebParametersFilter[]) => {
    setFilterConfig(x => ({
      ...x,
      filters,
      page: 0
    }));
  };

  const resetSorting = () => {
    setFilterConfig(x => ({ ...x, ...defaultSorting }));
  };

  if (loading && !data) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const { clinics, count } = data?.clinicsTableDTO || {
    clinics: [],
    count: 0
  };

  const options: MUIDataTableOptions = {
    page: filterConfig.page,
    count,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: filterConfig.takePerPage,
    sort: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange
  };

  return (
    <Box px={3} pt={4}>
      <Box display="flex">
        <FilterChipBar
          filterColumns={filterColumns}
          onFilter={handleFilter}
          hasSorting={hasSorting}
          resetSorting={resetSorting}
          actions={actionButtons}
        />
      </Box>
      <MUIDataTable
        title={null}
        data={clinics}
        columns={fullColumns}
        options={options}
      />
      <CreateClinicModal
        open={isAddModalOpen}
        handleClose={handleAddModalClose}
        refetchClinics={refetch}
      />
      <EditClinicModal
        clinicId={clinicToEditId}
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
        refetchClinics={refetch}
      />
      <DeleteClinicModal
        open={isDeleteModalOpen}
        loading={deleteLoading}
        handleClose={handleDeleteModalClose}
        onDelete={handleDeleteClinic}
      />
    </Box>
  );
};

export default Clinics;
