import { createMuiTheme } from "@material-ui/core";
import { ColorEnum } from "../types/enums/ColorEnum";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: ColorEnum.Blue
    },
    secondary: {
      main: ColorEnum.SecondaryBlue
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none"
      }
    },
    MuiTableRow: {
      hover: {
        cursor: "pointer"
      }
    },
    MuiTableCell: {
      root: {
        padding: "12px 16px"
      }
    },
    MuiTableSortLabel: {
      root: {
        marginTop: 6
      }
    }
  }
});
