import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: ColorEnum.DropZoneBackgroundColor,
    borderRadius: theme.spacing(0.5),
    border: `1px dashed ${ColorEnum.BorderColor}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  blue: {
    color: ColorEnum.Blue
  }
}));
