import gql from "graphql-tag";

export const getUserProfileQuery = gql`
  query getUserProfile {
    userProfile @rest(type: "IUserProfile", path: "azureUsers/userProfile") {
      id
      clinicId
      name
      clinicName
      claimsType
      dataProcessingAgreementGuid
      dataProcessingAgreement
      patientHasUnit
      clinicInSanitasTrial
    }
  }
`;

export const getOperatorsQuery = gql`
  query getOperators($filterConfig: IFilterConfig!) {
    operatorsTableDTO(filterConfig: $filterConfig)
      @rest(
        type: "operatorsTableDTO"
        path: "azureUsers/operators/filtered"
        method: "POST"
        bodyKey: "filterConfig"
      ) {
      operators
      count
    }
  }
`;

export const getOperatorQuery = gql`
  query getOperator($values: Values!) {
    operator(values: $values)
      @rest(
        type: "GetPatientResponseDto"
        path: "azureUsers/operators/{args.values.azureUserId}"
      ) {
      azureUserId
      name
      email
      clinicId
      clinicName
      preferredLanguage
    }
  }
`;

export const getAzureUsersQuery = gql`
  query getAzureUsers($filterConfig: IFilterConfig!) {
    getAzureUsers(filterConfig: $filterConfig)
      @rest(
        type: "GetAzureUsersResponseDto"
        method: "POST"
        path: "azureUsers/filtered"
        bodyKey: "filterConfig"
      ) {
      azureUserId
      name
      claimsType
      email
    }
  }
`;
