import { ActionsUnion } from "../../utils/typings/common";
import { IDisk } from "../../types/IDisk";
import { IFile } from "../../types/files/IFile";

// Action types
export const SELECT_DRIVE = "electron/SELECT_DRIVE";
export const UNSELECT_DRIVE = "electron/UNSELECT_DRIVE";
export const CLEAR_FILES = "electron/CLEAR_FILES";
export const UPDATE_FILES_INFO = "electron/UPDATE_FILES_INFO";

// Action creators
export const actions = {
  selectDriveAction: (drive: IDisk) => ({
    type: SELECT_DRIVE as typeof SELECT_DRIVE,
    payload: drive
  }),
  clearMyoFiles: () => ({
    type: CLEAR_FILES as typeof CLEAR_FILES
  }),
  unselectDriveAction: () => ({
    type: UNSELECT_DRIVE as typeof UNSELECT_DRIVE
  }),
  updateFilesInfo: (files: Record<string, IFile>) => ({
    type: UPDATE_FILES_INFO as typeof UPDATE_FILES_INFO,
    payload: files
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
