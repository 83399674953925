import { useDispatch } from "react-redux";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import { actions as userActions } from "../../state/user/actions";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IError } from "../../types/IError";
import { ErrorCodeEnum } from "../../types/enums/ErrorCodesEnum";
import { useEffect } from "react";
import { ApolloError } from "apollo-client";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { authProviderSignUpSignIn } from "../configs/authProvider";
import { useSelector } from "../../state/store";
import { selectUserProfile } from "./stateSelectorHelpers";
import { ClaimsEnum } from "../../types/enums";

export const useErrorHandling = (error?: ApolloError) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { userProfile } = useSelector(state => ({
    ...selectUserProfile(state)
  }));

  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;

  useEffect(() => {
    if (error) {
      const errorResult = (error.networkError as IError)?.result;
      errorResult ? console.log(errorResult) : console.log(error);

      //TODO: fix this to only respond to no connection
      // If there is no connection to the server and got an error "ERR_CONNECTION_REFUSED"
      if (!errorResult) {
        const message = t(`Global.NoConnection`);
        dispatch(snackbarActions.showSnackbar(SnackbarTypeEnum.Error, message));
        history.push(PathNamesEnum.NoConnection);
        return;
      }

      // If user is not authorized
      if (errorResult.Code === ErrorCodeEnum.ErrorCode10004) {
        history.push(PathNamesEnum.Home);
        authProviderSignUpSignIn.login();
        return;
      }

      // If patient is not found or deactivated or doesn't belong to a clinic
      if (
        errorResult.Code === ErrorCodeEnum.ErrorCode30000 ||
        errorResult.Code === ErrorCodeEnum.ErrorCode30001 ||
        errorResult.Code === ErrorCodeEnum.ErrorCode30005
      ) {
        const message = t(`ErrorCodes.${errorResult.Code}`);
        dispatch(snackbarActions.showSnackbar(SnackbarTypeEnum.Error, message));
        isOperator
          ? history.push(PathNamesEnum.Patients)
          : history.push(PathNamesEnum.Home);
        return;
      }

      // If user has no permission
      if (errorResult.Code === ErrorCodeEnum.ErrorCode10013) {
        history.push(PathNamesEnum.Forbidden);
        return;
      }

      // If azure user is not assigned to neither patient nor operator
      if (errorResult.Code === ErrorCodeEnum.ErrorCode10012) {
        dispatch(userActions.setUserProfileAction(null));
        history.push(PathNamesEnum.RegistrationKey);
        return;
      }

      // If an Operator is not assigned to any clinic or the operator is not found, the webpage
      // will be disabled and the user will be redirected to a separate page. Otherwise show errors on snackbar
      if (
        errorResult.Code !== ErrorCodeEnum.ErrorCode20000 &&
        errorResult.Code !== ErrorCodeEnum.ErrorCode20001
      ) {
        const message = t(`ErrorCodes.${errorResult.Code}`);
        dispatch(snackbarActions.showSnackbar(SnackbarTypeEnum.Error, message));
        return;
      }
    }
  }, [dispatch, error, history, t, isOperator]);
};
