import React from "react";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../styles/styles";
import configureStore, { history } from "../../state/store";
import { ConnectedRouter } from "connected-react-router";
import PrivatePageStructure from "./PrivatePageStructure/PrivatePageStructure";
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
  MsalAuthProvider
} from "react-aad-msal";
import {
  authProviderSignUpSignIn,
  authProviderResetPassword,
  authProviderRegistration
} from "../../utils/configs/authProvider";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../../api/ApolloClient";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Switch, Route } from "react-router-dom";
import { PublicRoutes } from "../../constants/publicRoutes";
import PublicPageStructure from "./PublicPageStructure/PublicPageStructure";
import AutoUpdater from "../AutoUpdater/AutoUpdater";
import NetworkConnection from "../NetworkConnection/NetworkConnection";
import { useTranslation } from "react-i18next";
import { getLocaleByLanguage } from "../../utils/helpers/languageHelpers";
import { LanguageEnum } from "../../types/enums/LanguageEnum";
import { resetPasswordError, cancelError } from "../../constants/auth";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { LocalStorageKeyEnum } from "../../types/enums/LocalStorageKeyEnum";

export const store = configureStore();

const App: React.FC = () => {
  const { i18n } = useTranslation();

  let authProvider: MsalAuthProvider;

  if (window.location.pathname === PathNamesEnum.PatientRegistration) {
    authProvider = authProviderRegistration;
  } else if (
    localStorage.getItem(LocalStorageKeyEnum.AzureErrorCode) ===
    resetPasswordError
  ) {
    localStorage.removeItem(LocalStorageKeyEnum.AzureErrorCode);
    authProvider = authProviderResetPassword;
  } else {
    authProvider = authProviderSignUpSignIn;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <NetworkConnection>
        <AutoUpdater>
          <Provider store={store}>
            <ApolloProvider client={client}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocaleByLanguage(i18n.language as LanguageEnum)}
              >
                <ConnectedRouter history={history}>
                  <Switch>
                    {/* all public routes must be declared in this const array */}
                    <Route path={PublicRoutes}>
                      <PublicPageStructure />
                    </Route>
                    <AzureAD
                      provider={authProvider}
                      reduxStore={store}
                      forceLogin={true}
                    >
                      {(props: IAzureADFunctionProps) => {
                        const { authenticationState, error } = props;

                        if (error) {
                          if (
                            error.errorMessage.search(resetPasswordError) !== -1
                          ) {
                            localStorage.setItem(
                              LocalStorageKeyEnum.AzureErrorCode,
                              resetPasswordError
                            );
                          } else if (
                            error.errorMessage.search(cancelError) !== -1
                          ) {
                            localStorage.setItem(
                              LocalStorageKeyEnum.AzureErrorCode,
                              cancelError
                            );
                          }
                        }

                        switch (authenticationState) {
                          case AuthenticationState.Authenticated:
                            return <PrivatePageStructure />;
                          case AuthenticationState.InProgress:
                          default:
                            return <></>;
                        }
                      }}
                    </AzureAD>
                  </Switch>
                </ConnectedRouter>
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </Provider>
        </AutoUpdater>
      </NetworkConnection>
    </MuiThemeProvider>
  );
};

export default App;
