import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Typography
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./DefaultSDCardModal.styles";
import clsx from "clsx";
import { IDisk } from "../../../types/IDisk";
import { TranslationsEnum } from "../../../types/enums";

interface IProps {
  defaultDisk: IDisk | null;
  handleClose: () => void;
  handleAccept: () => void;
}

const DefaultSDCardModal: React.FC<IProps> = ({
  defaultDisk,
  handleClose,
  handleAccept
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const open = defaultDisk !== null;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <Box className={classes.container}>
          <Typography variant="h6" className={classes.fontWeightRegular}>
            {t(
              TranslationsEnum.Electron_Containers_DriveList_MyoSDCardFoundTitle
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            className={clsx(
              classes.messageContainer,
              classes.fontWeightRegular
            )}
          >
            {t(
              TranslationsEnum.Electron_Containers_DriveList_MyoSDCardFoundMessage,
              {
                label: defaultDisk?.label || "",
                path: defaultDisk?.path || ""
              }
            )}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClose}
              variant="text"
              color="primary"
              className={classes.disagreeButton}
            >
              {t(TranslationsEnum.Global_No)}
            </Button>
            <Button onClick={handleAccept} variant="contained" color="primary">
              {t(TranslationsEnum.Global_Yes)}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DefaultSDCardModal;
