import * as actions from "./actions";
import { ISessionsState } from "../../types/reducerStates/ISessionsState";
import { SessionSelectEnum } from "../../types/enums/SessionSelectEnum";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";

const initialState: ISessionsState = {
  patientId: undefined,
  sessionsIds: [],
  tableConfig: {
    sortField: "sessionDisplayId",
    sortDirection: SortDirectionEnum.Descending,
    takePerPage: 10,
    page: 0
  },
  chartConfig: {
    selectValue: SessionSelectEnum.Last10Session,
    takePerPage: 10,
    page: 0
  },
  chartSelectedSessionBarIndex: 0,
  chartActiveColumns: {
    showSteps: true,
    showStairs: true,
    showActiveTime: true
  },
  chartHighlightedSessionId: undefined
};

export const reducer = (
  state: ISessionsState = initialState,
  action: actions.ActionTypes
): ISessionsState => {
  switch (action.type) {
    case actions.SET_SELECTED_SESSIONS_IDS:
      return {
        ...state,
        sessionsIds: action.payload
      };
    case actions.SET_TABLE_CONFIG:
      return {
        ...state,
        tableConfig: action.payload
      };
    case actions.SET_CHART_CONFIG:
      return {
        ...state,
        chartConfig: action.payload
      };
    case actions.SET_CHART_SELECTED_SESSION_BAR_INDEX:
      return {
        ...state,
        chartSelectedSessionBarIndex: action.payload
      };
    case actions.SET_HIGHLIGHTED_SESSION_ID:
      return {
        ...state,
        chartHighlightedSessionId: action.payload
      };
    case actions.SET_CHART_ACTIVE_COLUMNS:
      return {
        ...state,
        chartActiveColumns: action.payload
      };
    case actions.SET_SELECTED_PATIENT_ID:
      return {
        ...state,
        patientId: action.payload
      };
    case actions.RESET_SESSIONS_DATA:
      return initialState;
    default:
      return state;
  }
};
