export enum PatientsColumnsEnum {
  Id = "id",
  ClinicId = "clinicId",
  GenerationDateTime = "generationDateTime",
  Name = "name",
  NumberOfSessionsDone = "numberOfSessionsDone",
  Weight = "weight",
  Height = "height",
  IncludeRequested = "includeRequested",
  Tabt = "tabt"
}
