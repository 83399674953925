import * as actions from "./actions";
import { ISyncSuitState } from "../../types/reducerStates/ISyncSuitState";

const initialState: ISyncSuitState = {
  preselectedUnitSerialNumber: null
};

export const reducer = (
  state: ISyncSuitState = initialState,
  action: actions.ActionTypes
): ISyncSuitState => {
  switch (action.type) {
    case actions.SET_PRESELECTED_UNIT:
      return {
        ...state,
        preselectedUnitSerialNumber: action.payload
      };
    default:
      return state;
  }
};
