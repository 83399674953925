import React, { useCallback } from "react";
import { Typography, Box, Button, Paper, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useStyles from "./ClearSDCardInstructions.styles";
import { PathNamesEnum } from "../../../../types/enums/PathNamesEnum";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum, TranslationsEnum } from "../../../../types/enums";
import { getSessionFiles } from "../../../../api/sessionFiles";
import { downloadBlob } from "../../../../utils/helpers/filesHelpers";
import {
  myoswissPricingEN,
  myoswissPricingDE,
  contactEmailAddress
} from "../../../../constants/contacts";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { getSelectedLanguage } from "../../../../utils/helpers/languageHelpers";

interface IProps {
  closeSDCardInstructions: () => void;
}

const ClearSDCardInstructions: React.FC<IProps> = ({
  closeSDCardInstructions
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { userProfile } = useSelector(selectUserProfile);

  const handleOnHomeClick = () => {
    history.push(PathNamesEnum.Home);
  };

  const handleOnPrepareSessionClick = () => {
    history.push(PathNamesEnum.PrepareSession);
  };

  const handleDownloadClick = async () => {
    const { data } = await getSessionFiles();
    downloadBlob(data);
  };

  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;

  const selectedLanguage = getSelectedLanguage();
  const pricingLink =
    selectedLanguage === LanguageEnum.En
      ? myoswissPricingEN
      : myoswissPricingDE;

  const renderNoSuitInstructions = useCallback(() => {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart1
          )}{" "}
          <Link href={pricingLink} target={"_blank"} underline="always">
            {pricingLink}
          </Link>
        </Typography>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart2
          )}{" "}
          <Link
            href={`mailto:${contactEmailAddress}`}
            target={"_blank"}
            underline="always"
          >
            {contactEmailAddress}
          </Link>
        </Typography>
      </Box>
    );
  }, [t, classes.darkGrey, classes.instructions, pricingLink]);

  const renderClearSDCardInstructions = useCallback(() => {
    return (
      <>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardSubtitle)}
        </Typography>
        {(t(
          TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardInstructionForOperator,
          {
            returnObjects: true
          }
        ) as Array<string>).map((value, index) => (
          <Typography
            variant="subtitle1"
            className={clsx(classes.darkGrey, classes.instructions)}
            key={index}
          >
            {`${index + 1}. `}
            {value}
          </Typography>
        ))}
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
          key={"last_instructions_line"}
        >
          {`${(t(
            TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardInstructionForOperator,
            {
              returnObjects: true
            }
          ) as Array<string>).length + 1}. `}
          {isPatient
            ? t(
                TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardInstructionPatient4thStep
              )
            : t(
                TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardInstructionOperator4thStep
              )}
        </Typography>
      </>
    );
  }, [t, isPatient, classes.darkGrey, classes.instructions]);

  return (
    <Paper className={classes.paperContainer}>
      <Typography
        variant="h6"
        className={clsx(classes.darkGrey, classes.header)}
      >
        {userProfile?.patientHasUnit
          ? t(TranslationsEnum.Containers_SyncMyoSuit_ClearSDCardTitle)
          : t(TranslationsEnum.Global_NoAvailableMyosuit)}
      </Typography>
      <Box className={classes.instructionsContainer}>
        {!userProfile?.patientHasUnit && isPatient
          ? renderNoSuitInstructions()
          : renderClearSDCardInstructions()}
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          type="submit"
          onClick={closeSDCardInstructions}
        >
          {t(TranslationsEnum.Global_Back)}
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          type="submit"
          onClick={handleOnHomeClick}
        >
          {t(TranslationsEnum.Containers_SyncMyoSuit_Home)}
        </Button>
        {isPatient ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleDownloadClick}
            disabled={!userProfile?.patientHasUnit}
          >
            {t(TranslationsEnum.Global_DownloadProfile)}
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleOnPrepareSessionClick}
          >
            {t(TranslationsEnum.Containers_SyncMyoSuit_PrepareNextSession)}
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default ClearSDCardInstructions;
