import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  root: {
    background: ColorEnum.PrimaryGrey
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  warningBase: {
    height: 14,
    position: "relative",
    top: 2,
    width: 18
  },
  orange: {
    color: ColorEnum.Orange
  },
  green: {
    color: ColorEnum.SuccessGreen
  },
  blue: {
    color: ColorEnum.SecondaryBlue
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  lightGrey: {
    color: ColorEnum.LightGrey
  },
  whiteBackground: {
    backgroundColor: ColorEnum.White
  },
  leftButton: {
    borderRadius: 100,
    marginRight: theme.spacing(1),
    color: ColorEnum.GreenDark,
    borderColor: ColorEnum.GreenDark,
    "&:hover": {
      borderColor: ColorEnum.SuccessDarkGreen
    }
  },
  middleButton: {
    borderRadius: 100,
    marginRight: theme.spacing(1)
  },
  rightButton: {
    borderRadius: 100
  },
  roundButton: {
    marginLeft: theme.spacing(2),
    borderRadius: 100
  },
  resendEmailButton: {
    marginLeft: theme.spacing(1),
    borderRadius: 100
  },
  tabs: {
    marginTop: 14
  },
  tab: {
    color: ColorEnum.Blue,
    opacity: "50%",
    fontStyle: "normal",
    fontWeight: 600
  },
  selectedTab: {
    color: ColorEnum.White,
    opacity: "100%",
    backgroundColor: ColorEnum.Blue
  },
  normalFontStyleWeight: {
    fontStyle: "normal",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center"
  },

  labelTextWidth: {
    width: 200
  },
  sendIcon: {
    marginLeft: theme.spacing(1)
  },
  warningAssignToClinicOrBuySuitContainer: {
    marginBottom: theme.spacing(1)
  },
  warningAssignToClinicOrBuySuit: {
    color: ColorEnum.Orange,
    position: "relative",
    top: 3,
    marginRight: theme.spacing(1)
  },
  tooltip: {
    height: 26
  },
  patientName: {
    width: "max-content"
  },
  idRow: {
    width: "max-content"
  },
  buttonTooltip: {
    fontSize: 13
  }
}));
