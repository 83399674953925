import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { RestLink } from "apollo-link-rest";
import { setContext } from "apollo-link-context";
import { authProviderSignUpSignIn } from "../utils/configs/authProvider";

const domain = process.env.REACT_APP_API_DOMAIN;

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from store if it exists
  let rawIdToken = "";

  try {
    const { idToken } = await authProviderSignUpSignIn.getIdToken();
    rawIdToken = idToken.rawIdToken;
  } catch (error) {}

  // return the headers to the context so restLink can read them
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${rawIdToken}`
    }
  };
});

const restLink = new RestLink({
  uri: domain
});

const client = new ApolloClient({
  link: authLink.concat(restLink),
  cache: new InMemoryCache()
});

export default client;
