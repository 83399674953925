// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
// import "core-js/es/map";
// import "core-js/es/set";
// we are probably going to support other browsers than chrome so these will be used later on
import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import "./i18n";
import "./styles/index.css";

ReactDOM.render(<App />, document.getElementById("root"));
