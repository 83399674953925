import gql from "graphql-tag";

export const deleteOperatorMutation = gql`
  mutation deleteOperator($values: Values!) {
    deleteOperator(values: $values)
      @rest(
        type: "DeleteOperator"
        path: "azureUsers/operators/{args.values.azureUserId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;

export const createOperatorMutation = gql`
  mutation createOperator($values: Values!) {
    createOperator(values: $values)
      @rest(
        type: "CreateOperatortDto"
        path: "azureUsers/operators"
        method: "POST"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updateOperatorMutation = gql`
  mutation updateOperator($values: Values!) {
    updateOperator(values: $values)
      @rest(
        type: "UpdateOperatortDto"
        path: "azureUsers/operators"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;
