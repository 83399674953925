import { ActionsUnion } from "../../utils/typings/common";

// Action types
export const SET_PRESELECTED_UNIT = "syncsuit/SET_PRESELECTED_UNIT";

// Action creators
export const actions = {
  setPreselectedUnit: (preselectedUnitSerialNumber: string | null) => ({
    type: SET_PRESELECTED_UNIT as typeof SET_PRESELECTED_UNIT,
    payload: preselectedUnitSerialNumber
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
