import React from "react";
import { Route, Switch } from "react-router-dom";
import PageTermsOfAgreements from "../containers/PageTermsOfAgreements/PageTermsOfAgreements";
import { PathNamesEnum } from "../types/enums/PathNamesEnum";

const PublicRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        key={"TermsOfAgreementsRoute"}
        exact={true}
        path={PathNamesEnum.TermsOfAgreementsWithGUID}
        component={PageTermsOfAgreements}
      />
    </Switch>
  );
};

export default PublicRoutes;
