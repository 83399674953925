import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";
import { maxContainerWidth } from "../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  selectUnitButton: {
    color: ColorEnum.White,
    borderRadius: 100,
    width: "fit-content",
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    backgroundColor: ColorEnum.Blue
  },
  selectUnitDropdownArrow: {
    color: ColorEnum.White,
    marginRight: theme.spacing(2)
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  container: {
    maxWidth: maxContainerWidth,
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3)
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  header: {
    marginLeft: theme.spacing(3)
  },
  warningBase: {
    height: 14,
    position: "relative",
    top: 2,
    width: 18
  },
  orange: {
    color: ColorEnum.Orange
  },
  green: {
    color: ColorEnum.SuccessGreen
  },
  highlighted: {
    "& tr > th.MuiTableCell-root:first-child > div > span": {
      background: ColorEnum.HighlighterBlue,
      transition: "background 1s",
      borderRadius: "50%"
    },
    "& tr > td.MuiTableCell-root:first-child > div > span": {
      background: ColorEnum.HighlighterBlue,
      transition: "background 1s",
      borderRadius: "50%"
    }
  },
  notHighlighted: {
    "& tr > td.MuiTableCell-root:first-child > div > span": {
      transition: "background 1s"
    },
    "& tr > th.MuiTableCell-root:first-child > div > span": {
      transition: "background 1s"
    }
  }
}));
