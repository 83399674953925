import { makeStyles } from "@material-ui/styles";
import { theme } from "../../styles/styles";
import { maxContainerWidth } from "../../styles/stylesConstants";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles(() => ({
  form: {
    width: 500
  },
  textField: {
    width: "100%"
  },
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    maxWidth: maxContainerWidth
  },
  paperContainer: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  formItem: {
    marginTop: theme.spacing(3)
  },
  editorWrapper: {
    border: `1px solid ${ColorEnum.BorderColor}`
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  buttonsContainer: {
    display: "flex",
    marginLeft: "auto"
  },
  button: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(2)
  }
}));
