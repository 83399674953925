import i18n from "../i18n";
import { MUIDataTableTextLabels } from "mui-datatables";
import { TranslationsEnum } from "../types/enums";

// this needs to be a function, so that it would change it's value on language change
export const textLabelsConfig = () =>
  ({
    body: {
      noMatch: i18n.t(TranslationsEnum.Components_Table_NoMatchingRecordsFound),
      toolTip: i18n.t(TranslationsEnum.Components_Table_Sort)
    }
  } as MUIDataTableTextLabels);
