import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createUnitMutation } from "../../../../mutations/units";
import {
  getFirmwareOptionsQuery,
  getUnitStatusOptionsQuery
} from "../../../../queries";
import { getClinicsOptionsQuery } from "../../../../queries/clinics";
import { actions } from "../../../../state/snackbar/actions";
import { TranslationsEnum } from "../../../../types/enums";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { IOption } from "../../../../types/formInputs/IOption";
import { IUnitForm } from "../../../../types/forms/IUnitForm";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import UnitForm from "../UnitForm/UnitForm";
import { unitFormSchema } from "../UnitForm/unitFormSchema";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetchUnits: (variables?: { filterConfig: IFilterConfig }) => void;
}

const CreateUnitModal: React.FC<IProps> = ({
  open,
  refetchUnits,
  handleClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreateSuccess = () => {
    handleModalClose();
    refetchUnits();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_UnitCreatedSuccessfully)
      )
    );
  };

  const { data: dataClinicsOptions, error: errorClinicsOptions } = useQuery<{
    clinicsOptions: IOption[];
  }>(getClinicsOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorClinicsOptions);

  const { data: dataFirmwareOptions, error: errorFirmwareOptions } = useQuery<{
    firmwareOptions: IOption[];
  }>(getFirmwareOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorFirmwareOptions);

  const {
    data: dataUnitStatusOptions,
    error: errorUnitStatusOptions
  } = useQuery<{
    unitStatusOptions: IOption[];
  }>(getUnitStatusOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorUnitStatusOptions);

  const [createUnit, { loading, error }] = useMutation(createUnitMutation, {
    onError: () => {},
    onCompleted: handleCreateSuccess
  });

  useErrorHandling(error);

  const handleFormSubmit = (values: IUnitForm) => {
    if (isValid) {
      const { patient, ...rest } = values;
      createUnit({
        variables: {
          values: {
            ...rest,
            patientId: patient?.id
          }
        }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const initialValues: IUnitForm = {
    serialNumber: "",
    hardwareModel: "",
    nexusProtocolVersion: "",
    currentFirmwareVersion: undefined,
    futureFirmwareVersion: undefined,
    unitStatus: undefined,
    unitStatusSource: undefined,
    clinicId: undefined,
    patient: null
  };

  const clinicsOptions = !dataClinicsOptions?.clinicsOptions
    ? []
    : [
        ...dataClinicsOptions.clinicsOptions,
        {
          label: t(TranslationsEnum.Global_None),
          value: ""
        }
      ];

  const firmwareOptions = dataFirmwareOptions?.firmwareOptions || [];
  const unitStatusOptions = dataUnitStatusOptions?.unitStatusOptions || [];

  const {
    values,
    isValid,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: unitFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    clinicsOptions,
    firmwareOptions,
    unitStatusOptions,
    formTitle: t(TranslationsEnum.Global_NewUnit),
    setFieldValue,
    handleSubmit,
    handleChange,
    handleClose: handleModalClose
  };

  return <UnitForm {...props} />;
};

export default CreateUnitModal;
