import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  listItem: {
    borderColor: ColorEnum.LineSeparatorColor,
    borderBottom: 1,
    margin: 0,
    paddingTop: theme.spacing(1)
  },
  header: {
    marginLeft: theme.spacing(3)
  }
}));
