import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums";

export default makeStyles((theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputItem: {
    marginTop: theme.spacing(1)
  },
  formItem: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    padding: theme.spacing(2),
    overflow: "unset"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  autocompleteField: {
    width: "100%"
  },
  autocomplete: {
    margin: theme.spacing(1)
  },
  selectField: {
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "auto"
  },
  selectFieldNoMarginBottom: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "auto"
  },
  datePicker: {
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  modalCheckbox: {
    paddingBottom: 4,
    paddingTop: 4
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  modalHeader: {
    marginLeft: theme.spacing(1)
  },
  phoneInputFormControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  phoneInputHelperText: {
    marginLeft: 14,
    marginRight: 14
  },
  helperText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  helperTextBottom: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  marginTop4: {
    marginTop: 4
  },
  flagIcons: {
    "&>div": {
      display: "flex",
      alignItems: "center"
    }
  },
  listItemIcon: {
    minWidth: 30
  },
  yesButton: {
    background: ColorEnum.Red,
    color: ColorEnum.White,
    "&:hover": {
      background: ColorEnum.DarkRed
    }
  }
}));
