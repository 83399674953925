import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Grid, Typography } from "@material-ui/core";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleBack: () => void;
  errors: string[];
}

const InclusionAlert: React.FC<IProps> = ({
  open,
  handleClose,
  handleBack,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography color="primary" variant="h5">
            {t(TranslationsEnum.Containers_Modals_Questionnaire_InclusionAlert)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box m={2}>
            {errors?.map(error => {
              return <Typography>{error}</Typography>;
            })}
          </Box>
        </DialogContent>
        <Box m={2}>
          <DialogActions>
            <Grid container direction="row" spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={handleBack}
                  color="primary"
                  variant="contained"
                >
                  {t(TranslationsEnum.Global_Back)}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  {t(TranslationsEnum.Global_Close)}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default InclusionAlert;
