import React, { useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./DropdownItem.styles";
import { IRelease } from "../../../../types/IRelease";
import { GetApp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

interface IProps {
  release: IRelease;
  isDefault: boolean;
}

const DropdownItem: React.FC<IProps> = ({ release, isDefault }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    window.open(release.downloadUrl, "_blank", "noopener");
  }, [release.downloadUrl]);

  const buttonStyle = isDefault
    ? {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    : undefined;

  return (
    <Box
      display="flex"
      className={classes.dropdownButton}
      style={buttonStyle}
      onClick={handleClick}
    >
      <Box display="flex">
        <img
          className={classes.platformLogo}
          src={release.platform.logo}
          alt={release.platform.name}
        />
        <Box>
          <Typography variant="body1" className={classes.dropdownTitle}>
            {t("Containers.DownloadDesktopApp.ForPlatform", {
              platform: release.platform.name
            })}
          </Typography>
          <Typography className={classes.dropdownSubtitle} variant="body1">
            {release.version}
          </Typography>
        </Box>
      </Box>
      <GetApp className={classes.downloadIcon} />
    </Box>
  );
};

export default DropdownItem;
