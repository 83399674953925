import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center"
  },
  icon: {
    color: ColorEnum.LightGreyIcons,
    fontSize: 96,
    marginTop: 100
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 300,
    marginTop: 30,
    color: ColorEnum.DarkGrey
  },
  subHeading: {
    color: ColorEnum.DarkGrey,
    fontStyle: "normal",
    fontWeight: 300
  },
  button: {
    marginTop: theme.spacing(6)
  }
}));
