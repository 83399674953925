import * as Yup from "yup";
import i18n from "../../../../i18n";
import { TimeSinceDiagnosisEnum } from "../../../../types/enums/TimeSinceDiagnosisEnum";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";

const timeSinceDiagnosisEnumArray = Object.values(TimeSinceDiagnosisEnum);
const languagesDPAEnumArray = Object.values(LanguageEnum);

// TODO: move general validations in one place
export const patientFormSchemaForPatient = (noneId: number | undefined) =>
  Yup.object().shape({
    preferredLanguage: Yup.mixed()
      .oneOf(languagesDPAEnumArray)
      .required(i18n.t("Validations.Required")),
    name: Yup.string().required(i18n.t("Validations.Required")),
    weight: Yup.number()
      .typeError(i18n.t("Validations.WeightMustBeANumber"))
      .min(1, i18n.t("Validations.WeightIsNotValid"))
      .max(200, i18n.t("Validations.WeightIsNotValid"))
      .required(i18n.t("Validations.Required")),
    height: Yup.number()
      .typeError(i18n.t("Validations.HeightMustBeANumber"))
      .min(1, i18n.t("Validations.HeightIsNotValid"))
      .max(250, i18n.t("Validations.HeightIsNotValid"))
      .required(i18n.t("Validations.Required")),
    pathologyId: Yup.number()
      .notOneOf([0], i18n.t("Validations.Required"))
      .required(i18n.t("Validations.Required")),
    timeSinceDiagnosis: Yup.mixed().when("pathologyId", {
      is: noneId,
      otherwise: Yup.mixed()
        .oneOf(timeSinceDiagnosisEnumArray)
        .required(i18n.t("Validations.Required"))
    }),
    suggestedPathology: Yup.string().when("pathologyId", {
      is: -1,
      then: Yup.string()
        .trim()
        .required(i18n.t("Validations.Required"))
    }),
    dateOfBirth: Yup.date()
      .typeError(i18n.t("Validations.Required"))
      .required(i18n.t("Validations.Required")),
    email: Yup.string().email(i18n.t("Validations.EmailIsNotValid")),

    // since phones in different countries have different length, we will use min 8 to say that it is required to enter phone
    phoneNumber: Yup.string().test(
      "phoneNumber",
      i18n.t("Validations.WrongNumberFormat"),
      (val: string) => {
        // if nothing is inserted, default value = ""
        if (!val) {
          return true;
        }

        // if only the "+" and country code is inserted (maximum country code length = 3 in europe)
        if (val.length <= 4) {
          return true;
        }

        // if length is at least 8 characters (shortest phone number in the world according to wikipedia)
        return (
          val
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "").length >= 8
        );
      }
    ),
    PhoneNumberOrEmail: Yup.bool().when(["phoneNumber", "email"], {
      is: (phoneNumber: string, email: string) => {
        if (email) {
          return false;
        }

        if (phoneNumber && phoneNumber.length > 4) {
          return false;
        }

        return true;
      },
      then: Yup.bool().required(i18n.t("Validations.PhoneNumberOrEmail"))
    })
  });

export const patientFormSchemaForOperator = (noneId: number | undefined) =>
  Yup.object().shape({
    preferredLanguage: Yup.mixed()
      .oneOf(languagesDPAEnumArray)
      .required(i18n.t("Validations.Required")),
    name: Yup.string().required(i18n.t("Validations.Required")),
    weight: Yup.number()
      .typeError(i18n.t("Validations.WeightMustBeANumber"))
      .min(0, i18n.t("Validations.WeightIsNotValid"))
      .max(200, i18n.t("Validations.WeightIsNotValid")),
    height: Yup.number()
      .typeError(i18n.t("Validations.HeightMustBeANumber"))
      .min(0, i18n.t("Validations.HeightIsNotValid"))
      .max(250, i18n.t("Validations.HeightIsNotValid")),
    pathologyId: Yup.number()
      .notOneOf([0], i18n.t("Validations.Required"))
      .required(i18n.t("Validations.Required")),
    timeSinceDiagnosis: Yup.mixed().when("pathologyId", {
      is: noneId,
      otherwise: Yup.mixed()
        .oneOf(timeSinceDiagnosisEnumArray)
        .required(i18n.t("Validations.Required"))
    }),
    suggestedPathology: Yup.string().when("pathologyId", {
      is: -1,
      then: Yup.string()
        .trim()
        .required(i18n.t("Validations.Required"))
    }),
    dateOfBirth: Yup.date()
      .typeError(i18n.t("Validations.Required"))
      .required(i18n.t("Validations.Required"))
  });
