import React, { useCallback } from "react";
import clsx from "clsx";
import { Box, Paper, Typography, Divider, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IOverallSessionsData } from "../../../../types/IOverallSessionsData";
import TextRow from "../../../../components/TextRow/TextRow";
import InfoIcon from "../../../../assets/icons/InfoIcon";
import useStyles from "./OverallSessionsCard.styles";

interface IProps {
  values: IOverallSessionsData;
}

const OverallSessionsCard = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values: {
      totalTrainingTime,
      totalTrainingCourses,
      totalDistanceCovered,
      activeTime,
      numberOfSteps
    }
  } = props;

  const isDataEmpty = useCallback(() => {
    if (
      (!totalTrainingTime || totalTrainingTime === "0") &&
      !totalTrainingCourses &&
      !totalDistanceCovered &&
      !activeTime &&
      !numberOfSteps
    ) {
      return true;
    }

    return false;
  }, [
    activeTime,
    numberOfSteps,
    totalDistanceCovered,
    totalTrainingCourses,
    totalTrainingTime
  ]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.root}>
        <Box className={clsx(classes.container, classes.header)}>
          <Typography variant="body2" className={classes.title}>
            {t(
              "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.OverallSessions"
            )}
          </Typography>
          <Tooltip
            arrow
            title={t(
              "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.Tooltip"
            )}
            classes={{
              tooltip: classes.tooltipText
            }}
          >
            <Box className={classes.tooltip}>
              <InfoIcon />
            </Box>
          </Tooltip>
        </Box>
        <Divider />
        <Box className={clsx(classes.container, classes.paddingBottom)}>
          {isDataEmpty() ? (
            <TextRow
              descriptiveText={t(
                "Containers.SessionsTab.OverallSessionsOverview.NoSessions"
              )}
              valueText=""
            />
          ) : (
            <>
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalTrainingTime"
                )}
                valueText={totalTrainingTime}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalTrainingCourses"
                )}
                valueText={totalTrainingCourses}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalDistanceCovered"
                )}
                valueText={`${totalDistanceCovered} m`}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.ActiveTime"
                )}
                valueText={`${activeTime}%`}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.NumberOfSteps"
                )}
                valueText={`${numberOfSteps} ${t(
                  "Global.Steps"
                ).toLowerCase()}`}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default OverallSessionsCard;
