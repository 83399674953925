import React from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { Form, FormikProps } from "formik";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/SelectField/SelectField";
import { TFunction } from "i18next";
import { IAssistanceLevelsValues } from "../../../../types/formInputs/IAssistanceLevelsValues";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum, TranslationsEnum } from "../../../../types/enums";
import { RequestedClinicStatusEnum } from "../../../../types/enums/RequestedClinicStatusEnum";
import useStyles from "./AssistanceLevelsForm.styles";

const options = [
  {
    label: "0",
    value: "0"
  },
  {
    label: "1",
    value: "1"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "5",
    value: "5"
  }
];
const AssistanceLevels = (t: TFunction): ISelectField[] => [
  {
    label: t(
      "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.ConcFR"
    ),
    name: "ConcFR",
    options
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.ConcFL"
    ),
    name: "ConcFL",
    options
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.EccFR"
    ),
    name: "EccFR",
    options
  },
  {
    label: t(
      "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.EccFL"
    ),
    name: "EccFL",
    options
  }
];

interface IProps extends FormikProps<IAssistanceLevelsValues> {
  loading: boolean;
  patientRequestedClinicStatus?: RequestedClinicStatusEnum;
}

const AssistanceLevelsForm = (props: IProps) => {
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    loading,
    errors,
    patientRequestedClinicStatus
  } = props;
  const classes = useStyles();
  const { userProfile } = useSelector(selectUserProfile);

  const disable =
    loading ||
    (userProfile?.claimsType === ClaimsEnum.Operator &&
      patientRequestedClinicStatus === RequestedClinicStatusEnum.Pending);

  return (
    <Form key="AssistanceLevelsForm">
      {AssistanceLevels(t).map((size: ISelectField) => {
        // If value is 0, which is a normal selectable value, the selector thinks its a falsy value, therefore we need to check
        const value = values[size.name] === 0 ? 0 : values[size.name] || "";

        return (
          <SelectField
            SelectProps={{
              disabled: disable
            }}
            FormControlProps={{
              variant: "outlined",
              className: classes.formControl
            }}
            MenuItemProps={{
              className: classes.inputText
            }}
            InputLabelProps={{
              className: classes.descriptiveText
            }}
            error={errors[size.name]}
            key={`AssistanceLevelsForm${size.name}`}
            selectField={size}
            onChange={handleChange}
            value={value}
          />
        );
      })}

      <Box className={classes.saveButton}>
        <Button
          disabled={disable}
          variant="contained"
          color="primary"
          type="submit"
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t(TranslationsEnum.Global_Save)
          )}
        </Button>
      </Box>
    </Form>
  );
};

export default AssistanceLevelsForm;
