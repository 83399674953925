import { FormikValues, Field } from "formik";
import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
interface IProps {
  values: FormikValues;
}

const GeneralSecond: React.FC<IProps> = ({ values }) => {
  const { t } = useTranslation();

  const { GeneralQuestionsSecond } = values;
  return (
    <>
      <Box>
        <Box m={2}>
          <Typography color="primary" variant="h5">
            {t(
              TranslationsEnum.Containers_Modals_Questionnaire_TickAllThatApply
            )}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Field
          name="GeneralQuestionsSecond.shufflingManner.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsSecond.shufflingManner.label
          }}
        />
      </Box>
      {(() => {
        if (GeneralQuestionsSecond.shufflingManner.value) {
          return (
            <Box pl={2}>
              <Typography color="primary" variant="caption" gutterBottom>
                {GeneralQuestionsSecond.shufflingManner.reminder}
              </Typography>
            </Box>
          );
        }
      })()}

      <Box>
        <Field
          name="GeneralQuestionsSecond.hipFlexionContracture.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{ label: GeneralQuestionsSecond.hipFlexionContracture.label }}
        />
      </Box>
      {(() => {
        if (GeneralQuestionsSecond.hipFlexionContracture.value) {
          return (
            <Box pl={2}>
              <Typography color="primary" variant="caption" gutterBottom>
                {GeneralQuestionsSecond.hipFlexionContracture.reminder}
              </Typography>
            </Box>
          );
        }
      })()}
      <Box>
        <Field
          name="GeneralQuestionsSecond.adductorContracture.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsSecond.adductorContracture.label
          }}
        />
      </Box>
      {(() => {
        if (GeneralQuestionsSecond.adductorContracture.value) {
          return (
            <Box pl={2}>
              <Typography color="primary" variant="caption" gutterBottom>
                {GeneralQuestionsSecond.adductorContracture.reminder}
              </Typography>
            </Box>
          );
        }
      })()}
    </>
  );
};

export default GeneralSecond;
