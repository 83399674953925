import * as actions from "./actions";
import { ISnackbarState } from "../../types/reducerStates/ISnackbarState";

const initialState: ISnackbarState = {
  type: undefined,
  message: "",
  open: false
};

export const reducer = (
  state: ISnackbarState = initialState,
  action: actions.ActionTypes
): ISnackbarState => {
  switch (action.type) {
    case actions.SHOW_SNACKBAR:
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        open: true
      };
    case actions.CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};
