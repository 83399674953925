import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  dropdownButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${ColorEnum.LightGreyBorder}`,
    borderRadius: 4,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    cursor: "pointer",

    "&:hover": {
      background: ColorEnum.DropZoneBackgroundColor
    }
  },
  dropdownTitle: {
    color: ColorEnum.Blue,
    fontWeight: 700,
    fontSize: 15
  },
  dropdownSubtitle: {
    color: ColorEnum.DarkGrey,
    fontSize: 13,
    lineHeight: "13px",
    opacity: 0.6
  },
  downloadIcon: {
    color: ColorEnum.Blue,
    marginRight: theme.spacing(1)
  },
  platformLogo: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    height: 38
  }
}));
