import { FormikValues, Field } from "formik";
import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../../types/enums";

export interface IProps {
  values: FormikValues;
}

const DeficiencyMain: React.FC<IProps> = ({ values }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box m={2}>
        <Typography color="primary" variant="h5">
          {t(TranslationsEnum.Containers_Modals_Questionnaire_TickAllThatApply)}
        </Typography>
      </Box>
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item>
          <Box>
            <Typography align="center" variant="h6">
              {values.DeficiencyMainQuestions.weakness.label}
            </Typography>
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.kneeExtensionDeficit.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .kneeExtensionDeficit.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.plantarflexionWeakness.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .plantarflexionWeakness.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.dorsiflexionWeakness.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .dorsiflexionWeakness.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.hipExtensionWeakness.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .hipExtensionWeakness.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.hipFlexionWeakness.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .hipFlexionWeakness.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.weakness.questions.abductorWeakness.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.weakness.questions
                    .abductorWeakness.main.label
              }}
            />
          </Box>
        </Grid>
        <Divider variant="middle" orientation="vertical" />
        <Grid item>
          <Box>
            <Typography align="center" variant="h6">
              {values.DeficiencyMainQuestions.spasticity.label}
            </Typography>
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.spasticity.questions.hamstringSpasticity.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.spasticity.questions
                    .hamstringSpasticity.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.spasticity.questions.hipFlexionsSpasticity.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.spasticity.questions
                    .hipFlexionsSpasticity.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.spasticity.questions.kneeExtensionSpasticity.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.spasticity.questions
                    .kneeExtensionSpasticity.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.spasticity.questions.plantarflexionSpasticity.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.spasticity.questions
                    .plantarflexionSpasticity.main.label
              }}
            />
          </Box>
        </Grid>
        <Divider variant="middle" orientation="vertical" />
        <Grid item>
          <Box>
            <Typography align="center" variant="h6">
              {values.DeficiencyMainQuestions.contracture.label}
            </Typography>
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.contracture.questions.ankleFixed.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.contracture.questions
                    .ankleFixed.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.contracture.questions.kneeFlexionContracture.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.contracture.questions
                    .kneeFlexionContracture.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.contracture.questions.hipFlexionContracture.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.contracture.questions
                    .hipFlexionContracture.main.label
              }}
            />
          </Box>
        </Grid>
        <Divider variant="middle" orientation="vertical" />
        <Grid item>
          <Box>
            <Typography align="center" variant="h6">
              {values.DeficiencyMainQuestions.posturalControl.label}
            </Typography>
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.posturalControl.questions.narrowStanceWidth.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.posturalControl.questions
                    .narrowStanceWidth.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.posturalControl.questions.asymmetricalWeightBearing.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.posturalControl.questions
                    .asymmetricalWeightBearing.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.posturalControl.questions.unevenLegLength.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.posturalControl.questions
                    .unevenLegLength.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.posturalControl.questions.wideStanceWidth.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.posturalControl.questions
                    .wideStanceWidth.main.label
              }}
            />
          </Box>
          <Box>
            <Field
              name="DeficiencyMainQuestions.posturalControl.questions.circumductionHipHiking.main.value"
              type="checkbox"
              color="primary"
              component={CheckboxWithLabel}
              Label={{
                label:
                  values.DeficiencyMainQuestions.posturalControl.questions
                    .circumductionHipHiking.main.label
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeficiencyMain;
