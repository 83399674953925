import axios from "axios";
import { authProviderSignUpSignIn } from "../utils/configs/authProvider";

const domain = process.env.REACT_APP_API_DOMAIN;

const instance = axios.create({
  baseURL: domain
});

instance.interceptors.request.use(async config => {
  const {
    idToken: { rawIdToken }
  } = await authProviderSignUpSignIn.getIdToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${rawIdToken}`
    }
  };
});

export default instance;
