export enum ErrorCodeEnum {
  ErrorCode10004 = 10004, // Unauthorized
  ErrorCode10012 = 10012, // Azure user is not assigned to neither patient nor operator
  ErrorCode10013 = 10013, // Forbidden
  ErrorCode20000 = 20000, // Operator is not found. Please contact us
  ErrorCode20001 = 20001, // Operator is not assigned to any clinic. Please contact us
  ErrorCode30000 = 30000, // Patient not found
  ErrorCode30001 = 30001, // Patient deactivated
  ErrorCode30005 = 30005 // Patient does not belong to this clinic and is not pending for it
}
