import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  saveButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(3)
  },
  inputText: {
    fontStyle: "normal",
    fontWeight: "normal",
    color: ColorEnum.LightGrey
  },
  descriptiveText: {
    fontStyle: "normal",
    fontWeight: 600,
    color: ColorEnum.LightGrey
  },
  formControl: {
    marginBottom: theme.spacing(3)
  }
}));
