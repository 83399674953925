import { useMutation } from "@apollo/react-hooks";
import { useFormik, FormikErrors } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateInviteUserToRegisterMutation } from "../../../../mutations/patients";
import { actions } from "../../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IPatient } from "../../../../types/IPatient";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import { inviteUserToRegisterFormSchema } from "./inviteUserToRegisterFormSchema";
import { RequestedClinicStatusEnum } from "../../../../types/enums/RequestedClinicStatusEnum";
import { ClaimsEnum, TranslationsEnum } from "../../../../types/enums";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";
import {
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  CardActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { IInviteUserToRegisterForm } from "../../../../types/forms/IInviteUserToRegisterForm";
import clsx from "clsx";
import useStyles from "../../styles/modalForm.styles";

interface IProps {
  handleClose: () => void;
  open: boolean;
  patient: IPatient;
  refetchPatient: () => void;
}

const InviteUserToRegisterModal: React.FC<IProps> = ({
  patient,
  open,
  refetchPatient,
  handleClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const hasEmailOrPhoneNumber = patient.email || patient.phoneNumber;
  const dispatchSuccessSnackbar = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(
          hasEmailOrPhoneNumber
            ? TranslationsEnum.Snackbar_ContactInfoUpdatedSuccessfully
            : TranslationsEnum.Snackbar_UserInvitedSuccessfully
        )
      )
    );
  };

  const { userProfile } = useSelector(selectUserProfile);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;

  const [updateInviteUserToRegister, { loading, error }] = useMutation(
    updateInviteUserToRegisterMutation,
    {
      onError: () => {},
      onCompleted: () => {
        dispatchSuccessSnackbar();
        handleClose();
        resetForm();
        refetchPatient();
      }
    }
  );

  useErrorHandling(error);

  const handleFormSubmit = (values: IInviteUserToRegisterForm) => {
    if (isValid) {
      updateInviteUserToRegister({
        variables: { values: { ...values, id: patient.id } }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  //TODO: remove `or` values since the fields are mandatory, just currently database is not clean
  const initialValues: IInviteUserToRegisterForm = {
    email: patient.email || "",
    phoneNumber: patient.phoneNumber || ""
  };

  const {
    values,
    errors,
    isValid,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema: inviteUserToRegisterFormSchema(),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  // const handlePhoneInputChange = (phoneNumber: string, country?: ICountry) => {
  //   if (country && phoneNumber.endsWith(`+${country.dialCode}`)) {
  //     setFieldValue("phoneNumber", "");
  //   } else if (country && !phoneNumber.startsWith(`+${country.dialCode}`)) {
  //     setFieldValue("phoneNumber", "");
  //   } else {
  //     setFieldValue("phoneNumber", phoneNumber);
  //   }
  // };

  const isDisabled =
    patient.requestedClinicStatus === RequestedClinicStatusEnum.Pending &&
    isOperator;

  const errorWithTypes = errors as FormikErrors<{
    pathology: string;
    phoneNumber: string;
    phoneNumberOrEmail: boolean;
  }>;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.formItem}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.modalHeader}
              >
                {t(
                  hasEmailOrPhoneNumber
                    ? TranslationsEnum.Global_EditContactInfo
                    : TranslationsEnum.Global_InvitePersonToPortal,
                  {
                    name: patient.name
                  }
                )}
              </Typography>
              <Box display="flex" flexDirection="column">
                {/* <Typography variant="body2" className={classes.helperText}>
                  {t(
                    TranslationsEnum.Containers_Modals_Patient_PatientForm_EnterEmailOrNumber
                  )}
                </Typography> */}
                <TextField
                  name="email"
                  label={t("Containers.Modals.Patient.PatientForm.Email")}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.email}
                  error={
                    Boolean(errors.email) ||
                    Boolean(errorWithTypes.phoneNumberOrEmail)
                  }
                  helperText={errors.email || errorWithTypes.phoneNumberOrEmail}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                {/* <PhoneInputField
                  FormControlProps={{
                    className: classes.phoneInputFormControl
                  }}
                  FormHelperTextProps={{
                    className: classes.phoneInputHelperText
                  }}
                  inputStyle={{
                    width: "100%",
                    height: 40
                  }}
                  name="phoneNumber"
                  label="Phone number"
                  value={values.phoneNumber}
                  error={
                    errors.phoneNumber || errorWithTypes.phoneNumberOrEmail
                  }
                  onChange={handlePhoneInputChange}
                  preferredCountries={preferredCountries}
                  country="ch"
                  regions={["europe"]}
                  excludeCountries={excludeCountries}
                  countryCodeEditable={false}
                  disabled={isDisabled}
                /> */}
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color="primary" onClick={handleModalClose}>
                {t(TranslationsEnum.Global_Cancel)}
              </Button>
              <Button color="primary" type="submit" disabled={isDisabled}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(
                    hasEmailOrPhoneNumber
                      ? TranslationsEnum.Global_Save
                      : TranslationsEnum.Global_Invite
                  )
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Fade>
    </Modal>
  );
};

export default InviteUserToRegisterModal;
