import * as Yup from "yup";
import i18n from "../../../../i18n";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { TranslationsEnum } from "../../../../types/enums";

const languagesEnumArray = Object.values(LanguageEnum);

export const operatorFormSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t(TranslationsEnum.Validations_Required)),
  email: Yup.string()
    .email(i18n.t(TranslationsEnum.Validations_EmailIsNotValid))
    .required(i18n.t(TranslationsEnum.Validations_Required)),
  clinicId: Yup.number()
    .notOneOf([0], i18n.t(TranslationsEnum.Validations_Required))
    .required(i18n.t(TranslationsEnum.Validations_Required)),
  preferredLanguage: Yup.mixed()
    .oneOf(languagesEnumArray)
    .required(i18n.t(TranslationsEnum.Validations_Required))
});
