import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fade,
  Modal,
  TextField,
  Typography,
  ListItemIcon,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import clsx from "clsx";
import { DatePicker } from "@material-ui/pickers";
import { FormikErrors } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPatientForm } from "../../../../types/forms/IPatientForm";
import { format, subYears } from "date-fns";
import SelectField from "../../../../components/SelectField/SelectField";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { TFunction } from "i18next";
import { optionsPeriods, genderOptions } from "../../../../constants/options";
import ReactCountryFlag from "react-country-flag";
import { IOption } from "../../../../types/formInputs/IOption";
import { TranslationsEnum } from "../../../../types/enums";
import PhoneInputField, {
  ICountry
} from "../../../../components/PhoneInputField/PhoneInputField";
import {
  excludeCountries,
  preferredCountries
} from "../../../../constants/phoneInput";
import { languagesDPA } from "../../../../constants/languages";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import useStyles from "../../styles/modalForm.styles";

const timeSinceDiagnosisSelect = (t: TFunction): ISelectField => ({
  label: t("Containers.Modals.Patient.PatientForm.TimeSince"),
  name: "timeSinceDiagnosis",
  options: optionsPeriods(t)
});

const languagesSelect = (t: TFunction): ISelectField => ({
  label: t(
    TranslationsEnum.Containers_Modals_Patient_PatientForm_LanguageSelect
  ),
  name: "preferredLanguage",
  options: languagesDPA
});

const genderSelect = (t: TFunction): ISelectField => ({
  label: t(TranslationsEnum.Global_Gender),
  name: "genderType",
  options: genderOptions(t)
});

const pathologiesSelect = (t: TFunction, options: IOption[]): ISelectField => ({
  label: t("Containers.Modals.Patient.PatientForm.Pathology"),
  name: "pathologyId",
  //Since we use enums for pathology and other is not in that enum, it will have dummy value of -1
  options: [...options, { label: t("Global.Other"), value: -1 }]
});

const languageIcons = (className: string): Array<React.ReactElement> =>
  languagesDPA.map(language => (
    <>
      <ListItemIcon className={className}>
        <ReactCountryFlag
          countryCode={
            language.value.toString().toLowerCase() === LanguageEnum.En
              ? LanguageEnum.Gb.toUpperCase()
              : language.value.toString()
          }
          svg
        />
      </ListItemIcon>
    </>
  ));

interface IProps {
  isPatient?: boolean;
  isOperator?: boolean;
  isNewUser?: boolean;
  open: boolean;
  values: IPatientForm;
  errors: FormikErrors<{
    name: string;
    pathology: string;
    height?: number;
    weight?: number;
    timeSinceDiagnosis: string;
    suggestedPathology: string;
    email: string;
    dateOfBirth: Date;
    phoneNumber: string;
    PhoneNumberOrEmail: boolean;
    preferredLanguage: string;
    inSanitasTrial: boolean;
    sanitasTrialStart?: Date;
    sanitasTrialEnd?: Date;
  }>;
  loading: boolean;
  formTitle: string;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleClose: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
  isEditModal?: boolean;
  pathologyOptions: IOption[];
  noneId: number | undefined;
  isDisabled?: boolean;
  isDpaAccepted?: boolean;
  isClinicInTrial?: boolean;
}

const PatientForm: React.FC<IProps> = ({
  isPatient,
  isClinicInTrial,
  isOperator,
  isNewUser,
  values,
  errors,
  loading,
  open,
  formTitle,
  handleChange,
  handleClose,
  handleSubmit,
  setFieldValue,
  isEditModal,
  pathologyOptions,
  noneId,
  isDisabled,
  isDpaAccepted
}) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const preferDate = format(subYears(new Date(), 60), "yyyy-MM-dd");
  const inSanitasTrial = values.inSanitasTrial;

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formatedDate = format(new Date(date), "yyyy-MM-dd");
      setFieldValue("dateOfBirth", formatedDate);
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const formatedDate = format(new Date(date), "yyyy-MM-dd");
      setFieldValue("sanitasTrialStart", formatedDate);
    }
  };

  const handleTrialEndChange = (date: Date | null) => {
    if (date) {
      const formatedDate = format(new Date(date), "yyyy-MM-dd");
      setFieldValue("sanitasTrialEnd", formatedDate);
    }
  };

  useEffect(() => {
    values.sanitasTrialStart == null &&
      setFieldValue("sanitasTrialStart", format(new Date(), "yyyy-MM-dd"));
    values.sanitasTrialEnd == null &&
      setFieldValue("sanitasTrialEnd", format(new Date(), "yyyy-MM-dd"));
  }, [
    inSanitasTrial,
    setFieldValue,
    values.sanitasTrialEnd,
    values.sanitasTrialStart
  ]);

  const handlePhoneInputChange = (phoneNumber: string, country?: ICountry) => {
    if (country && phoneNumber.endsWith(`+${country.dialCode}`)) {
      setFieldValue("phoneNumber", "");
    } else if (country && !phoneNumber.startsWith(`+${country.dialCode}`)) {
      setFieldValue("phoneNumber", "");
    } else {
      setFieldValue("phoneNumber", phoneNumber);
    }
  };

  const handlePreferredLanguageChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setFieldValue("preferredLanguage", event.target.value);

    if (event.target.value !== LanguageEnum.Fr) {
      i18n.changeLanguage(event.target.value as string);
    }
  };

  const pathologiesSelectField = pathologiesSelect(t, pathologyOptions);
  const timeSinceDiagnosisSelectField = timeSinceDiagnosisSelect(t);
  const languagesSelectField = languagesSelect(t);
  const genderSelectField = genderSelect(t);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.formItem}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.modalHeader}
              >
                {formTitle}
              </Typography>
              <Box display="flex" flexDirection="column">
                <TextField
                  name="name"
                  label={t(TranslationsEnum.Global_DisplayName)}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`OptionsGender${genderSelectField.name}`}
                  selectField={genderSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[genderSelectField.name]}
                  value={(values as any)[genderSelectField.name]}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className:
                      values.preferredLanguage === LanguageEnum.Fr
                        ? classes.selectFieldNoMarginBottom
                        : classes.selectField
                  }}
                  key={`OptionsLanguageSelect${languagesSelectField.name}`}
                  selectField={languagesSelectField}
                  onChange={
                    isPatient && isNewUser
                      ? handlePreferredLanguageChange
                      : handleChange
                  }
                  // Cast to any needed to avoid typescript errors
                  //TODO: add type
                  error={(errors as any)[languagesSelectField.name]}
                  value={(values as any)[languagesSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    className: classes.flagIcons,
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                  ListItemsIcons={languageIcons(classes.listItemIcon)}
                />
                {isPatient &&
                  isNewUser &&
                  values.preferredLanguage === LanguageEnum.Fr && (
                    <Typography
                      variant="body2"
                      className={classes.helperTextBottom}
                    >
                      {t(
                        TranslationsEnum.Containers_Modals_Patient_PatientForm_FrenchNotAvailable
                      )}
                    </Typography>
                  )}
                {isPatient && (
                  <>
                    <Typography variant="body2" className={classes.helperText}>
                      {t(
                        TranslationsEnum.Containers_Modals_Patient_PatientForm_EnterEmailOrNumber
                      )}
                    </Typography>
                    <TextField
                      name="email"
                      label={t("Containers.Modals.Patient.PatientForm.Email")}
                      margin="dense"
                      className={clsx(classes.textField, classes.marginTop4)}
                      variant="outlined"
                      value={values.email}
                      error={
                        Boolean(errors.email) ||
                        Boolean(errors.PhoneNumberOrEmail)
                      }
                      helperText={errors.email || errors.PhoneNumberOrEmail}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <PhoneInputField
                      FormControlProps={{
                        className: classes.phoneInputFormControl
                      }}
                      FormHelperTextProps={{
                        className: classes.phoneInputHelperText
                      }}
                      inputStyle={{
                        width: "100%",
                        height: 40
                      }}
                      name="phoneNumber"
                      label="Phone number"
                      value={values.phoneNumber}
                      error={errors.phoneNumber || errors.PhoneNumberOrEmail}
                      onChange={handlePhoneInputChange}
                      preferredCountries={preferredCountries}
                      country="ch"
                      regions={["europe"]}
                      excludeCountries={excludeCountries}
                      countryCodeEditable={false}
                      disabled={isDisabled}
                    />
                  </>
                )}
                <DatePicker
                  label={t(
                    isDpaAccepted
                      ? TranslationsEnum.Global_DateOfBirth
                      : TranslationsEnum.Global_Birthyear
                  )}
                  name="dateOfBirth"
                  inputVariant="outlined"
                  cancelLabel={t("Global.Cancel")}
                  okLabel={t("Global.Ok")}
                  disableFuture
                  helperText={errors.dateOfBirth}
                  error={Boolean(errors.dateOfBirth)}
                  className={classes.datePicker}
                  openTo="year"
                  format={isDpaAccepted ? "yyyy-MM-dd" : "yyyy"}
                  views={isDpaAccepted ? ["year", "month", "date"] : ["year"]}
                  value={values.dateOfBirth}
                  initialFocusedDate={preferDate}
                  onChange={handleDateChange}
                  margin="dense"
                  disabled={isDisabled}
                />
                <TextField
                  name="weight"
                  label={t("Containers.Modals.Patient.PatientForm.Weight")}
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.weight}
                  error={Boolean(errors.weight)}
                  helperText={errors.weight}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <TextField
                  name="height"
                  label={t("Containers.Modals.Patient.PatientForm.Height")}
                  type="number"
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.height}
                  error={Boolean(errors.height)}
                  helperText={errors.height}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`OptionsPathologiesSelect${pathologiesSelectField.name}`}
                  selectField={pathologiesSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[pathologiesSelectField.name]}
                  value={(values as any)[pathologiesSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                {/* Since we use enums for pathology and other is not in that enum, it will have dummy value of -1 */}
                {values.pathologyId === -1 && (
                  <TextField
                    name="suggestedPathology"
                    label={t(
                      "Containers.Modals.Patient.PatientForm.OtherPathology"
                    )}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    value={values.suggestedPathology}
                    error={Boolean(errors.suggestedPathology)}
                    helperText={errors.suggestedPathology}
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                )}
                {noneId !== (values as any)[pathologiesSelectField.name] &&
                  (values as any)[pathologiesSelectField.name] !== 0 && (
                    <SelectField
                      FormControlProps={{
                        variant: "outlined",
                        className: classes.selectField
                      }}
                      selectField={timeSinceDiagnosisSelectField}
                      onChange={handleChange}
                      // Cast to any needed to avoid typescript errors
                      error={
                        (errors as any)[timeSinceDiagnosisSelectField.name]
                      }
                      value={
                        (values as any)[timeSinceDiagnosisSelectField.name]
                      }
                      SelectProps={{
                        margin: "dense",
                        disabled: isDisabled
                      }}
                      InputLabelProps={{
                        margin: "dense"
                      }}
                    />
                  )}
                {isOperator && isClinicInTrial && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.textField}
                        checked={values.inSanitasTrial}
                        onChange={handleChange}
                        name="inSanitasTrial"
                        color="primary"
                      />
                    }
                    label={t(
                      TranslationsEnum.Containers_Modals_Patient_PatientForm_AddPatientToTrial
                    )}
                  />
                )}
                {isClinicInTrial && values.inSanitasTrial && (
                  <>
                    <DatePicker
                      label={t(
                        TranslationsEnum.Containers_Modals_Patient_PatientForm_StartDate
                      )}
                      name="sanitasTrialStart"
                      inputVariant="outlined"
                      cancelLabel={t("Global.Cancel")}
                      okLabel={t("Global.Ok")}
                      className={classes.datePicker}
                      openTo="year"
                      format={"yyyy-MM-dd"}
                      views={["year", "month", "date"]}
                      value={values.sanitasTrialStart}
                      initialFocusedDate={new Date()}
                      onChange={handleStartDateChange}
                      margin="dense"
                    />

                    <DatePicker
                      label={t(
                        TranslationsEnum.Containers_Modals_Patient_PatientForm_EndDate
                      )}
                      name="sanitasTrialEnd"
                      inputVariant="outlined"
                      cancelLabel={t("Global.Cancel")}
                      okLabel={t("Global.Ok")}
                      className={classes.datePicker}
                      disablePast
                      openTo="year"
                      format={"yyyy-MM-dd"}
                      views={["year", "month", "date"]}
                      value={values.sanitasTrialEnd}
                      initialFocusedDate={new Date()}
                      onChange={handleTrialEndChange}
                      margin="dense"
                      disabled={!values.inSanitasTrial}
                    />
                  </>
                )}
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              {!isNewUser && (
                <Button color="primary" onClick={handleClose}>
                  {t("Global.Cancel")}
                </Button>
              )}
              <Button color="primary" type="submit" disabled={isDisabled}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(
                    isOperator && !isEditModal
                      ? TranslationsEnum.Global_Next
                      : TranslationsEnum.Global_Save
                  )
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Fade>
    </Modal>
  );
};

export default PatientForm;
