import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  CircularProgress
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./ClearSDCardModal.styles";
import clsx from "clsx";
import { IWindow } from "../../../types/IWindow";
import { RENDERER_EVENTS } from "../../../shared-constants/events";
import { useSelector } from "../../../state/store";
import { selectSelectedDrive } from "../../../utils/helpers/stateSelectorHelpers";
import { actions } from "../../../state/electron/actions";
import { useDispatch } from "react-redux";
import { TranslationsEnum } from "../../../types/enums";
import FilesList from "./FilesList/FilesList";

declare const window: IWindow;

interface IProps {
  handleClose: () => void;
  handleDownload: () => void;
  open: boolean;
}

const ClearSDCardModal: React.FC<IProps> = ({
  open,
  handleClose,
  handleDownload
}) => {
  const { selectedDrive } = useSelector(selectSelectedDrive);
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clearMyoFiles = useCallback(() => {
    dispatch(actions.clearMyoFiles());
  }, [dispatch]);

  const clearSDCard = useCallback(() => {
    if (selectedDrive) {
      const filesToDelete = selectedDrive.myoFiles
        .filter(file => file.success || !file.isSessionFile)
        .map(file => file.fullPath);
      window.clearSDCard(filesToDelete);
    }
  }, [selectedDrive]);

  useEffect(() => {
    window.ipcRenderer.on(RENDERER_EVENTS.CLEAR_SD_CARD_REPLY, () => {
      clearMyoFiles();
      setAccepted(true);
    });

    window.ipcRenderer.on(RENDERER_EVENTS.ON_FINALIZE, () => {
      setLoading(false);
    });

    return () => {
      window.ipcRenderer.removeAllListeners(
        RENDERER_EVENTS.CLEAR_SD_CARD_REPLY
      );
      window.ipcRenderer.removeAllListeners(RENDERER_EVENTS.ON_FINALIZE);
    };
  }, [clearMyoFiles]);

  const handleAccept = useCallback(() => {
    clearSDCard();
    setLoading(true);
  }, [setLoading, clearSDCard]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <Box className={classes.container}>
          <Typography variant="h6" className={classes.fontWeightRegular}>
            {accepted
              ? t(
                  TranslationsEnum.Electron_Containers_ClearSDCardModal_TitleSuccess
                )
              : t(TranslationsEnum.Electron_Containers_ClearSDCardModal_Title)}
          </Typography>
          <Typography
            variant="subtitle2"
            className={clsx(
              classes.messageContainer,
              classes.fontWeightRegular
            )}
          >
            {accepted
              ? t(TranslationsEnum.Electron_Containers_ClearSDCardModal_Success)
              : t(
                  TranslationsEnum.Electron_Containers_ClearSDCardModal_Message
                )}
          </Typography>
          {!accepted && selectedDrive && (
            <FilesList files={selectedDrive.myoFiles} />
          )}
          {accepted ? (
            <Button
              onClick={handleDownload}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t(
                TranslationsEnum.Electron_Containers_ClearSDCardModal_Download
              )}
            </Button>
          ) : (
            <Box display="flex" justifyContent="flex-end">
              <Button
                disabled={loading}
                onClick={handleClose}
                variant="text"
                color="primary"
                className={classes.disagreeButton}
              >
                {t(TranslationsEnum.Global_No)}
              </Button>
              <Button
                disabled={loading}
                onClick={handleAccept}
                variant="contained"
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(TranslationsEnum.Global_Yes)
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ClearSDCardModal;
