import FilterChip from "../FilterChip/FilterChip";
import React, { useCallback, useMemo } from "react";
import { Grid, Tooltip, IconButton, Box, Chip } from "@material-ui/core";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import { useFormik } from "formik";
import { buildWebParametersFilters } from "../../utils/helpers/filtersHelpers";
import { Clear, Shuffle } from "@material-ui/icons";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import useStyles from "./FilterChipBar.styles";
import { useTranslation } from "react-i18next";
import { IActionColumn } from "../../types/filters/IActionColumn";

interface IProps {
  hasSorting: boolean;
  actionColumns?: IActionColumn[];
  filterColumns: IFilterColumn[];
  onFilter: (
    filterValues: IWebParametersFilter[],
    actionsValues: Record<string, boolean>
  ) => void;
  resetSorting: () => void;
  actions?: React.ReactNode;
}

const FilterChipBar = ({
  filterColumns,
  actionColumns,
  hasSorting,
  resetSorting,
  onFilter,
  actions
}: IProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const initialValues: Record<string, string | boolean> = useMemo(() => {
    const filtersValues = filterColumns.reduce((acc, x) => {
      return { ...acc, [x.name]: "" };
    }, {});

    const actionValues =
      actionColumns?.reduce((acc, x) => {
        return { ...acc, [x.name]: x.defaultValue };
      }, {}) || {};

    return {
      ...filtersValues,
      ...actionValues
    };
  }, [filterColumns, actionColumns]);

  const handleSubmit = useCallback(
    (values: Record<string, string | boolean>) => {
      const { actionsValues, webFilters } = buildWebParametersFilters(
        values,
        filterColumns,
        actionColumns
      );
      onFilter(webFilters, actionsValues);
    },
    [onFilter, filterColumns, actionColumns]
  );

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit: handleFormSubmit,
    resetForm
  } = useFormik({
    initialValues,
    onSubmit: handleSubmit
  });

  const { webFilters: webParametersFilters } = useMemo(
    () => buildWebParametersFilters(values, filterColumns, actionColumns),
    [values, filterColumns, actionColumns]
  );
  const hasValues = useMemo(() => webParametersFilters.length > 0, [
    webParametersFilters
  ]);

  const resetFilters = useCallback(() => {
    handleSubmit(initialValues);
    resetForm();
  }, [handleSubmit, resetForm, initialValues]);

  const handleSetField = useCallback(
    (name: string, value: boolean) => () => {
      setFieldValue(name, value);
      const { actionsValues, webFilters } = buildWebParametersFilters(
        { ...values, [name]: value },
        filterColumns,
        actionColumns
      );
      onFilter(webFilters, actionsValues);
    },
    [setFieldValue, values, filterColumns, actionColumns, onFilter]
  );

  return (
    <Grid
      container
      spacing={2}
      className={classes.gridWrapper}
      data-qa="FilterChipBar"
      alignItems="center"
    >
      <Grid item>
        <form key="tableFilter">
          <Box display="inline-block" marginRight={2}>
            {actions}
          </Box>
          {filterColumns.map((x, i) => (
            <FilterChip
              key={`filterChip${i}`}
              label={x.label}
              name={x.name}
              value={values[x.name] as string}
              onChange={handleChange}
              onSubmit={handleFormSubmit}
            />
          ))}
          {actionColumns?.map((x, i) => (
            <Chip
              key={`actionChip${i}`}
              variant={values[x.name] ? "default" : "outlined"}
              label={values[x.name] ? x.selectedLabel : x.label}
              onClick={handleSetField(x.name, !values[x.name])}
              color="primary"
              clickable={true}
            />
          ))}
        </form>
      </Grid>
      <Grid item className={classes.actionsItem}>
        <Tooltip title={t("Components.FilterChipBar.ResetSorting")}>
          <span>
            <IconButton
              color="primary"
              onClick={resetSorting}
              disabled={!hasSorting}
            >
              <Shuffle />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("Components.FilterChipBar.ClearFilters")}>
          <span>
            <IconButton
              color="primary"
              disabled={!hasValues}
              onClick={resetFilters}
            >
              <Clear />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default FilterChipBar;
