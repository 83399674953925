import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fade,
  Modal,
  TextField,
  Typography,
  ListItemIcon
} from "@material-ui/core";
import clsx from "clsx";
import { FormikErrors } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/SelectField/SelectField";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { TFunction } from "i18next";
import ReactCountryFlag from "react-country-flag";
import { IOption } from "../../../../types/formInputs/IOption";
import { TranslationsEnum } from "../../../../types/enums";
import { languages } from "../../../../constants/languages";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import useStyles from "../../styles/modalForm.styles";
import { IOperatorForm } from "../../../../types/forms/IOperatorForm";

const languagesSelect = (t: TFunction): ISelectField => ({
  label: t(
    TranslationsEnum.Containers_Modals_Patient_PatientForm_LanguageSelect
  ),
  name: "preferredLanguage",
  options: languages
});

const clinicsSelect = (t: TFunction, options: IOption[]): ISelectField => ({
  label: t(TranslationsEnum.Global_Trainer),
  name: "clinicId",
  options: [...options]
});

const languageIcons = (className: string): Array<React.ReactElement> =>
  languages.map(language => (
    <>
      <ListItemIcon className={className}>
        <ReactCountryFlag
          countryCode={
            language.value.toString().toLowerCase() === LanguageEnum.En
              ? LanguageEnum.Gb.toUpperCase()
              : language.value.toString()
          }
          svg
        />
      </ListItemIcon>
    </>
  ));

interface IProps {
  open: boolean;
  values: IOperatorForm;
  errors: FormikErrors<{
    name: string;
    clinicId: number;
    email: string;
  }>;
  loading: boolean;
  formTitle: string;
  clinicsOptions: IOption[];
  isDisabled?: boolean;
  isEditModal?: boolean;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleClose: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const OperatorForm: React.FC<IProps> = ({
  values,
  errors,
  loading,
  open,
  formTitle,
  isEditModal,
  clinicsOptions,
  isDisabled,
  handleChange,
  handleClose,
  handleSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const clinicsSelectField = clinicsSelect(t, clinicsOptions);
  const languagesSelectField = languagesSelect(t);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.formItem}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.modalHeader}
              >
                {formTitle}
              </Typography>
              <Box display="flex" flexDirection="column">
                <TextField
                  name="name"
                  label={t(TranslationsEnum.Global_Name)}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <TextField
                  name="email"
                  label={t(TranslationsEnum.Global_Email)}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`OptionsLanguageSelect${languagesSelectField.name}`}
                  selectField={languagesSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[languagesSelectField.name]}
                  value={(values as any)[languagesSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    className: classes.flagIcons,
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                  ListItemsIcons={languageIcons(classes.listItemIcon)}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`ClinicsSelect${clinicsSelectField.name}`}
                  selectField={clinicsSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[clinicsSelectField.name]}
                  value={(values as any)[clinicsSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color="primary" onClick={handleClose}>
                {t(TranslationsEnum.Global_Cancel)}
              </Button>
              <Button color="primary" type="submit" disabled={isDisabled}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(TranslationsEnum.Global_Save)
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Fade>
    </Modal>
  );
};

export default OperatorForm;
