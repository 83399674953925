import React, { useCallback } from "react";
import { isElectron } from "../../utils/helpers/electronHelpers";
import { isDev } from "../../utils/helpers/isDevHelper";
import UpdateProgress from "./UpdateProgress/UpdateProgress";
import { useAutoUpdater } from "../../utils/helpers/autoUpdaterHelper";
import useStyles from "./AutoUpdater.styles";
import { GetApp } from "@material-ui/icons";
import InfoModal from "../Modals/InfoModal/InfoModal";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../types/enums";

const AutoUpdater: React.FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    showUpdateProgress,
    showModal,
    handleModalClose,
    progress,
    state
  } = useAutoUpdater();
  const renderIcon = useCallback(() => <GetApp className={classes.icon} />, [
    classes.icon
  ]);

  if (showUpdateProgress) {
    return <UpdateProgress state={state} progress={progress} />;
  }

  return (
    <>
      <InfoModal
        title={t(TranslationsEnum.Electron_UpdateModal_FoundUpdateTitle)}
        message={t(TranslationsEnum.Electron_UpdateModal_FoundUpdateMessage)}
        open={showModal}
        renderIcon={renderIcon}
        handleClose={handleModalClose}
      />
      {children}
    </>
  );
};

const AutoUpdaterHOC: React.FC = props => {
  if (isDev || !isElectron()) {
    return <>{props.children}</>;
  }
  return <AutoUpdater {...props} />;
};

export default AutoUpdaterHOC;
