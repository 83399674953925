import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: ColorEnum.White,
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${ColorEnum.BorderColor}`,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    maxWidth: 600
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  errorIcon: {
    color: ColorEnum.Red,
    fontSize: 30
  },
  warningIcon: {
    color: ColorEnum.Orange,
    fontSize: 30
  },
  fontWeightRegular: {
    fontWeight: 400,
    whiteSpace: "pre-line"
  },
  messageContainer: {
    fontWeight: 400,
    marginTop: theme.spacing(1)
  },
  button: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(1)
  }
}));
