import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  listItem: {
    borderColor: ColorEnum.LineSeparatorColor,
    borderBottom: 1,
    margin: 0,
    paddingTop: theme.spacing(1)
  },
  rightItemsContainer: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3),
    alignItems: "center"
  },
  leftItemsContainer: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    alignItems: "center",
    marginRight: theme.spacing(5),
    minWidth: "fit-content"
  },
  infoContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  error: {
    color: ColorEnum.Red,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  },
  warning: {
    color: ColorEnum.Orange,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  },
  fileName: {
    color: ColorEnum.LightGrey,
    fontWeight: 400
  },
  progress: {
    color: ColorEnum.LightGrey,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  },
  checkMark: {
    color: ColorEnum.SuccessGreen,
    marginRight: theme.spacing(1)
  }
}));
