import i18n from "../../i18n";
import { LanguageEnum } from "../../types/enums/LanguageEnum";
import { localeMap } from "../../constants/languages";

export const getSelectedLanguage = (): LanguageEnum => {
  return i18n.language as LanguageEnum;
};

export const getLocaleByLanguage = (language: LanguageEnum): Locale => {
  return localeMap[language];
};
