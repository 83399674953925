import i18n from "../../i18n";
import { IOption } from "../../types/formInputs/IOption";
import { IPathology } from "../../types/IPathology";
import { NoneTranslationEnum } from "../../types/enums/NoneTranslationEnum";
import { TranslationsEnum } from "../../types/enums";
import { SessionDayMetricsProcessingStatusEnum } from "../../types/enums/SessionDayMetricsProcessingStatusEnum";

export const getTimeSinceDiagnosisEnumToString = (
  timeSinceDiagnosisEnum: number
): string => {
  switch (timeSinceDiagnosisEnum) {
    case 0:
      return i18n.t("Options.OptionsPeriods.Months0to3");
    case 1:
      return i18n.t("Options.OptionsPeriods.Months3to6");
    case 2:
      return i18n.t("Options.OptionsPeriods.Months6to1");
    case 3:
      return i18n.t("Options.OptionsPeriods.Years1to2");
    case 4:
      return i18n.t("Options.OptionsPeriods.Years2to5");
    case 5:
      return i18n.t("Options.OptionsPeriods.Years5to10");
    case 6:
      return i18n.t("Options.OptionsPeriods.Years10Plus");
  }

  return "";
};

export const createOptionsFromPathologiesList = (
  pathologies: IPathology[] | undefined
): { pathologyOptions: IOption[]; noneId?: number } => {
  const pathologyOptions: IOption[] = [];
  let noneOption: IOption | undefined = undefined;
  const enumArray = Object.values(NoneTranslationEnum) as string[];

  pathologies &&
    pathologies.forEach(pathology => {
      if (!enumArray.includes(pathology.name.toLowerCase())) {
        pathologyOptions.push({ label: pathology.name, value: pathology.id });
      } else {
        noneOption = { label: pathology.name, value: pathology.id };
      }
    });

  noneOption && pathologyOptions.push(noneOption);
  const noneId = noneOption ? Number((noneOption as IOption).value) : undefined;

  return {
    pathologyOptions,
    noneId
  };
};

export const getSessionDayMetricsProcessingStatusEnumToString = (
  sessionDayMetricsProcessingStatusEnum: SessionDayMetricsProcessingStatusEnum
): string => {
  switch (sessionDayMetricsProcessingStatusEnum) {
    case SessionDayMetricsProcessingStatusEnum.ProcessingFiles:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingOneOrMoreFiles
      );
    case SessionDayMetricsProcessingStatusEnum.ProcessingSuccessful:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingSuccessful
      );
    case SessionDayMetricsProcessingStatusEnum.OldMyosuitFirmware:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_OldMyosuitFirmware
      );
    case SessionDayMetricsProcessingStatusEnum.NoLogFile:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_NoLogFileLocated
      );
    case SessionDayMetricsProcessingStatusEnum.ProcessingError:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingError
      );
    case SessionDayMetricsProcessingStatusEnum.NoProcessingData:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_NoProcessingData
      );
    case SessionDayMetricsProcessingStatusEnum.ProcessingPending:
      return i18n.t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingPending
      );
  }

  return "";
};
