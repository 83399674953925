import { KeyboardEvent } from "react";
import { Done } from "@material-ui/icons";
import { Box, Popover, CardContent, TextField } from "@material-ui/core";
import useStyles from "./FilterChipPopover.styles";

import React, { PropsWithChildren } from "react";

interface IProps {
  anchor: HTMLElement | null;
  value: string;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  setAnchor: (anchor: HTMLElement | null) => void;
}

const FilterChipPopover = ({
  anchor,
  setAnchor,
  children,
  value,
  onChange,
  name,
  label
}: PropsWithChildren<IProps>) => {
  const classes = useStyles();

  const handleClose = (
    event?: {
      pageX?: number;
      pageY?: number;
    },
    reason?: string
  ) => {
    if (event && reason === "backdropClick") {
      const { pageX = 0, pageY = 0 } = event;
      setTimeout(() => {
        const element = document.elementFromPoint(pageX, pageY);
        if (element && typeof (element as HTMLElement).click === "function") {
          (element as HTMLElement).click();
        }
      }, 100);
    }
    setAnchor(null);
  };

  const handleKeyPress = ({ key }: KeyboardEvent) => {
    if (key === "Enter") {
      handleClose(undefined, "enterKeyDown");
    }
  };

  const closeDone = () => handleClose(undefined, "done");
  return (
    <>
      {children}
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        transitionDuration={{ enter: 200, exit: 0 }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
      >
        <CardContent
          className={classes.cardContent}
          onKeyPress={handleKeyPress}
          data-qa="PopoverControl"
        >
          <Box className={classes.controlBox}>
            <TextField
              name={name}
              label={label}
              value={value}
              onChange={onChange}
              id="outlined-basic"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box className={classes.done} onClick={closeDone}>
            <Done color="inherit" />
          </Box>
        </CardContent>
      </Popover>
    </>
  );
};

export default FilterChipPopover;
