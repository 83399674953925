import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  sessionTitle: {
    justifyContent: "center",
    display: "flex",
    background: ColorEnum.DarkGreyLighter
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    color: ColorEnum.DarkGrey,
    display: "flex",
    alignItems: "center"
  },
  lightGrey: {
    color: ColorEnum.LightGrey
  },
  sessionPartContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    borderColor: ColorEnum.LightGreyBorder,
    borderStyle: "solid solid none none",
    borderWidth: "thin",
    "&:last-child": {
      borderStyle: "solid none none none"
    }
  },
  valueText: {
    fontWeight: 600,
    fontStyle: "normal",
    color: ColorEnum.Blue
  },
  descriptiveText: {
    fontWeight: "normal",
    fontStyle: "normal",
    color: ColorEnum.LightGrey,
    opacity: 0.6,
    marginRight: theme.spacing(1)
  },
  iconMargin: {
    marginRight: 5,
    marginLeft: 5
  },
  gridInfoIcon: {
    position: "absolute",
    right: 0,
    height: 0
  },
  tooltip: {
    fontSize: 13
  },
  tootltipArrow: {
    position: "relative",
    top: 30
  }
}));
