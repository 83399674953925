import React from "react";
import { Typography, Box, Button, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useStyles from "./UploadToSDCardInstructions.styles";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import { isElectron } from "../../../utils/helpers/electronHelpers";
import { TranslationsEnum } from "../../../types/enums";

const UploadToSDCardInstructions: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    history.push(PathNamesEnum.SyncMyoSuit);
  };

  return (
    <Paper className={classes.paperContainer}>
      <Typography
        variant="h6"
        className={clsx(classes.darkGrey, classes.header)}
      >
        {t(
          isElectron()
            ? TranslationsEnum.Electron_Containers_PrepareSession_UploadToSDCardTitleElectron
            : TranslationsEnum.Containers_PrepareSession_UploadToSDCardTitle
        )}
      </Typography>
      {!isElectron() && (
        <Box className={classes.instructionsContainer}>
          <Typography
            variant="subtitle1"
            className={clsx(classes.darkGrey, classes.instructions)}
          >
            {t(
              TranslationsEnum.Containers_PrepareSession_UploadToSDCardSubtitle
            )}
          </Typography>
          {(t(
            TranslationsEnum.Containers_PrepareSession_UploadToSDCardInstruction,
            {
              returnObjects: true
            }
          ) as Array<string>).map((value, index) => (
            <Typography
              variant="subtitle1"
              className={clsx(classes.darkGrey, classes.instructions)}
              key={index}
            >
              {`${index + 1}. `}
              {value}
            </Typography>
          ))}
        </Box>
      )}
      <Box className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleOnClick}
        >
          {t("Global.Done")}
        </Button>
      </Box>
    </Paper>
  );
};

export default UploadToSDCardInstructions;
