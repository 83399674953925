import React, { useMemo } from "react";
import { ILocalFile } from "../../../../types/files/ILocalFile";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { TranslationsEnum } from "../../../../types/enums";
import useStyles from "./FileList.styles";
import { useTranslation } from "react-i18next";

interface IProps {
  files: ILocalFile[];
}

const FilesList: React.FC<IProps> = ({ files }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filesToDelete = useMemo(
    () => files.filter(file => file.success || !file.isSessionFile),
    [files]
  );
  const filesToIgnore = useMemo(
    () => files.filter(file => !(file.success || !file.isSessionFile)),
    [files]
  );

  return (
    <>
      <Box className={classes.messageContainer}>
        <Typography
          variant="subtitle2"
          className={clsx(classes.title, classes.fontWeightBold)}
        >
          {t(
            TranslationsEnum.Electron_Containers_ClearSDCardModal_FilesToDownload
          )}
        </Typography>
        {filesToDelete.length === 0 && (
          <Typography
            variant="subtitle2"
            className={clsx(classes.fileName, classes.fontWeightRegular)}
          >
            {t(TranslationsEnum.Electron_Containers_ClearSDCardModal_None)}
          </Typography>
        )}
        {filesToDelete.length > 0 && (
          <Box className={classes.fileNamesContainer}>
            {filesToDelete.map(file => (
              <Typography
                key={file.filename}
                variant="subtitle2"
                className={clsx(classes.fileName, classes.fontWeightRegular)}
              >
                {file.filename}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      <Box className={classes.messageContainer}>
        <Typography
          variant="subtitle2"
          className={clsx(classes.title, classes.fontWeightBold)}
        >
          {t(
            TranslationsEnum.Electron_Containers_ClearSDCardModal_FilesIgnored
          )}
        </Typography>
        {filesToIgnore.length === 0 && (
          <Typography
            variant="subtitle2"
            className={clsx(classes.fileName, classes.fontWeightRegular)}
          >
            {t(TranslationsEnum.Electron_Containers_ClearSDCardModal_None)}
          </Typography>
        )}
        {filesToIgnore.length > 0 && (
          <Box className={classes.fileNamesContainer}>
            {filesToIgnore.map(file => (
              <Typography
                key={file.filename}
                variant="subtitle2"
                className={clsx(classes.fileName, classes.fontWeightRegular)}
              >
                {file.filename}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default FilesList;
