import { useQuery } from "@apollo/react-hooks";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Fade,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { FormikErrors } from "formik";
import { TFunction } from "i18next";
import debounce from "lodash/debounce";
import React, { ChangeEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/SelectField/SelectField";
import { unitStatusSourceOptions } from "../../../../constants/options";
import { getPatientsQuery } from "../../../../queries";
import {
  PatientsColumnsEnum,
  SortDirectionEnum,
  TranslationsEnum
} from "../../../../types/enums";
import { IOption } from "../../../../types/formInputs/IOption";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { IUnitForm } from "../../../../types/forms/IUnitForm";
import { IPatient, IPatientWithLabel } from "../../../../types/IPatient";
import { IPatientsTableDTO } from "../../../../types/tables/IPatientsTable";
import useStyles from "../../styles/modalForm.styles";

const clinicsSelect = (t: TFunction, options: IOption[]): ISelectField => ({
  label: t(TranslationsEnum.Global_Trainer),
  name: "clinicId",
  options
});

const currentFirmwareSelect = (
  t: TFunction,
  options: IOption[]
): ISelectField => ({
  label: t(TranslationsEnum.Global_CurrentFirmwareVersion),
  name: "currentFirmwareVersion",
  options
});

const futureFirmwareSelect = (
  t: TFunction,
  options: IOption[]
): ISelectField => ({
  label: t(TranslationsEnum.Global_FutureFirmwareVersion),
  name: "futureFirmwareVersion",
  options
});

const unitStatusSelect = (t: TFunction, options: IOption[]): ISelectField => ({
  label: t(TranslationsEnum.Global_UnitStatus),
  name: "unitStatus",
  options
});

const unitStatusSourceSelect = (t: TFunction): ISelectField => ({
  label: t(TranslationsEnum.Global_UnitStatusSource),
  name: "unitStatusSource",
  options: unitStatusSourceOptions
});

interface IProps {
  open: boolean;
  values: IUnitForm;
  errors: FormikErrors<IUnitForm>;
  loading: boolean;
  formTitle: string;
  clinicsOptions: IOption[];
  firmwareOptions: IOption[];
  unitStatusOptions: IOption[];
  isDisabled?: boolean;
  isEditModal?: boolean;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleClose: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
}

const UnitForm: React.FC<IProps> = ({
  values,
  errors,
  loading,
  open,
  formTitle,
  isEditModal,
  isDisabled,
  clinicsOptions,
  firmwareOptions,
  unitStatusOptions,
  handleChange,
  handleClose,
  handleSubmit,
  setFieldValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const clinicsSelectField = clinicsSelect(t, clinicsOptions);
  const currentFirmwareSelectField = currentFirmwareSelect(t, firmwareOptions);
  const futureFirmwareSelectField = futureFirmwareSelect(t, firmwareOptions);
  const unitStatusSelectField = unitStatusSelect(t, unitStatusOptions);
  const unitStatusSourceSelectField = unitStatusSourceSelect(t);

  const defaultSorting = {
    sortField: PatientsColumnsEnum.Name,
    sortDirection: SortDirectionEnum.Descending
  };

  const { data, loading: usersLoading, refetch } = useQuery<{
    patientsTableDTO: IPatientsTableDTO;
  }>(getPatientsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      filterConfig: {
        ...defaultSorting,
        filters: [
          {
            filterField: PatientsColumnsEnum.Name,
            filterValue: ""
          }
        ]
      }
    }
  });

  const refetchUsers = useCallback(
    async (event: ChangeEvent<{}>, input: string) => {
      await refetch({
        filterConfig: {
          ...defaultSorting,
          filters: [
            {
              filterField: PatientsColumnsEnum.Name,
              filterValue: input
            }
          ]
        }
      });
    },
    [refetch, defaultSorting]
  );

  const debouncedRefetchUsers = debounce(refetchUsers, 300);

  const getUserLabel = useCallback((user: IPatient) => {
    if (!user) {
      return "";
    }

    if (!user.email) {
      return user.name + "";
    }
    return `${user.name} (${user.email})`;
  }, []);

  const usersOptions = useMemo(() => {
    if (usersLoading) {
      return [];
    }
    return data?.patientsTableDTO.patients.map(user => {
      return {
        ...user,
        label: getUserLabel(user)
      };
    });
  }, [usersLoading, data, getUserLabel]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.formItem}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.modalHeader}
              >
                {formTitle}
              </Typography>
              <Box display="flex" flexDirection="column">
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`ClinicsSelect${clinicsSelectField.name}`}
                  selectField={clinicsSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[clinicsSelectField.name]}
                  value={(values as any)[clinicsSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <Autocomplete
                  loading={usersLoading}
                  options={usersOptions}
                  className={classes.autocomplete}
                  getOptionLabel={option => option.label}
                  onInputChange={debouncedRefetchUsers}
                  onChange={(
                    event: ChangeEvent<{}>,
                    value: IPatientWithLabel | null
                  ) => {
                    setFieldValue("patient", value);
                  }}
                  value={values.patient}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        error={Boolean(errors.patient)}
                        helperText={errors.patient}
                        className={classes.autocompleteField}
                        label={t(
                          TranslationsEnum.Containers_CreateEditMessage_SelectUser
                        )}
                        variant="outlined"
                        name="patient"
                      />
                    );
                  }}
                />
                <TextField
                  name="serialNumber"
                  label={t(TranslationsEnum.Global_SerialNumber)}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.serialNumber}
                  error={Boolean(errors.serialNumber)}
                  helperText={errors.serialNumber}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <TextField
                  name="hardwareModel"
                  label={t(TranslationsEnum.Global_HardwareModel)}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.hardwareModel}
                  error={Boolean(errors.hardwareModel)}
                  helperText={errors.hardwareModel}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <TextField
                  name="nexusProtocolVersion"
                  label={t(TranslationsEnum.Global_NexusProtocolVersion)}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.nexusProtocolVersion}
                  error={Boolean(errors.nexusProtocolVersion)}
                  helperText={errors.nexusProtocolVersion}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`CurrentFirmwareSelect${currentFirmwareSelectField.name}`}
                  selectField={currentFirmwareSelectField}
                  onChange={handleChange}
                  error={(errors as any)[currentFirmwareSelectField.name]}
                  value={(values as any)[currentFirmwareSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`FutureFirmwareSelect${futureFirmwareSelectField.name}`}
                  selectField={futureFirmwareSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[futureFirmwareSelectField.name]}
                  value={(values as any)[futureFirmwareSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`unitStatusSelectField${unitStatusSelectField.name}`}
                  selectField={unitStatusSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[unitStatusSelectField.name]}
                  value={(values as any)[unitStatusSelectField.name] || ""}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`unitStatusSourceSelect${unitStatusSourceSelectField.name}`}
                  selectField={unitStatusSourceSelectField}
                  onChange={handleChange}
                  // Cast to any needed to avoid typescript errors
                  error={(errors as any)[unitStatusSourceSelectField.name]}
                  value={(values as any)[unitStatusSourceSelectField.name]}
                  SelectProps={{
                    margin: "dense",
                    disabled: isDisabled
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color="primary" onClick={handleClose}>
                {t(TranslationsEnum.Global_Cancel)}
              </Button>
              <Button color="primary" type="submit" disabled={isDisabled}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(TranslationsEnum.Global_Save)
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Fade>
    </Modal>
  );
};

export default UnitForm;
