import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    height: "100%"
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    display: "flex",
    minHeight: 28
  },
  checkIcon: {
    marginTop: 5,
    marginRight: 12
  },
  headerText: {
    flexDirection: "column"
  },
  formContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    display: "flex",
    justifyContent: "center"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  calibrationText: {
    fontStyle: "normal",
    fontWeight: 600
  },
  lastCalibratedText: {
    fontStyle: "normal",
    fontWeight: "normal"
  },
  boxesWrapper: {
    position: "absolute"
  },
  box: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3),
    minWidth: theme.spacing(3),
    background: ColorEnum.LightGrey,
    color: ColorEnum.White,
    "&:hover": {
      cursor: "pointer"
    }
  },
  selected: {
    background: theme.palette.primary.main
  },
  hovered: {
    opacity: 0.8,
    background: theme.palette.primary.main
  }
}));
