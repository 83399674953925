import React, { useCallback, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Box,
  useMediaQuery,
  Theme,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import TranslateIcon from "@material-ui/icons/Translate";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTranslation } from "react-i18next";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { IOption } from "../../types/formInputs/IOption";
import { defaultLanguage } from "../../constants/defaultValues";
import useStyles from "./LanguageMenu.styles";
import { LanguageEnum } from "../../types/enums/LanguageEnum";

interface IProps {
  languages: IOption[];
}

const LanguageMenu = (props: IProps) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectedLanguage = getSelectedLanguage();
  const { languages } = props;

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuItemClick = useCallback(
    (value: string | number) => () => {
      i18n.changeLanguage(value.toString());
      handleClose();
    },
    [i18n, handleClose]
  );

  const languageName = useMemo(
    () =>
      languages.find(language => language.value === selectedLanguage)?.label ||
      defaultLanguage.label,
    [languages, selectedLanguage]
  );

  return (
    <Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        className={classes.menuButton}
      >
        <TranslateIcon />
        {matches && <span className={classes.language}>{languageName}</span>}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language, index) => (
          <MenuItem
            key={language.value}
            selected={language.value === selectedLanguage}
            onClick={handleMenuItemClick(language.value)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReactCountryFlag
                countryCode={
                  (language.value as string).toLowerCase() === LanguageEnum.En
                    ? LanguageEnum.Gb.toUpperCase()
                    : (language.value as string)
                }
                svg
              />
            </ListItemIcon>
            <ListItemText>{language.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageMenu;
