import { withStyles, LinearProgress } from "@material-ui/core";
import { ColorEnum } from "../../../types/enums/ColorEnum";

const ProgressLine = withStyles({
  colorPrimary: {
    backgroundColor: ColorEnum.ThirdWhite
  },
  barColorPrimary: {
    backgroundColor: ColorEnum.White
  }
})(LinearProgress);

export default ProgressLine;
