import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    border: `1px solid ${ColorEnum.LightGreyBorder}`
  },
  divider: {
    marginBottom: theme.spacing(5)
  },
  sessionTitle: {
    justifyContent: "center",
    display: "flex",
    background: ColorEnum.DarkGreyLighter
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    color: ColorEnum.DarkGrey,
    display: "flex",
    alignItems: "center"
  }
}));
