import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
  Theme,
  Box,
  Typography,
  Tooltip
} from "@material-ui/core";
import { useSelector } from "../../state/store";
import { useDispatch } from "react-redux";
import { actions } from "../../state/header/actions";
import {
  selectHeaderOpen,
  selectUserProfile
} from "../../utils/helpers/stateSelectorHelpers";
import { useParams } from "react-router-dom";
import { IPatientIdRouteParams } from "../../types/routeParams/IPatientIdRouteParams";
import { useQuery } from "@apollo/react-hooks";
import {
  getPatientQueryForOperator,
  getPatientQueryForPatient
} from "../../queries/patients";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IPatient } from "../../types/IPatient";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { authProviderSignUpSignIn } from "../../utils/configs/authProvider";
import { useTranslation } from "react-i18next";
import NavigationTitle from "./NavigationTitle/NavigationTitle";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import LanguageMenu from "../../components/LanguageMenu/LanguageMenu";
import { languages } from "../../constants/languages";
import useStyles from "./Header.styles";
import { ClaimsEnum } from "../../types/enums";

const Header = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { patientId } = useParams<IPatientIdRouteParams>();

  const { isDrawerOpen, userProfile } = useSelector(state => ({
    ...selectHeaderOpen(state),
    ...selectUserProfile(state)
  }));

  const getPatientQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getPatientQueryForOperator(Number(patientId)!, getSelectedLanguage())
      : getPatientQueryForPatient(getSelectedLanguage());

  const {
    data: dataPatient,
    error: errorPatient,
    loading: loadingPatient,
    refetch: refetchPatient
  } = useQuery<{ getPatient: IPatient }>(getPatientQuery, {
    fetchPolicy: "cache-and-network",
    skip: !patientId || userProfile === undefined
  });

  useEffect(() => {
    if (patientId && userProfile !== undefined) {
      refetchPatient();
    }
  }, [patientId, refetchPatient, userProfile]);

  const patient: IPatient | undefined = dataPatient?.getPatient;

  const toggleDrawer = useCallback(() => {
    dispatch(actions.toggleDrawerAction(!isDrawerOpen));
  }, [dispatch, isDrawerOpen]);

  useErrorHandling(errorPatient);

  const { logout } = authProviderSignUpSignIn;
  const handleLogout = useCallback(() => logout(), [logout]);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen && matches
      })}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          className={clsx({
            [classes.hide]: isDrawerOpen && matches
          })}
        >
          <MenuIcon />
        </IconButton>
        {userProfile?.claimsType === ClaimsEnum.Operator ? (
          <>
            <Box className={classes.titleContainer}>
              <NavigationTitle
                loadingPatient={loadingPatient}
                patient={patient}
                userProfile={userProfile}
              />
            </Box>
            <Box display="flex">
              <Typography className={classes.idTypography}>
                ID: {userProfile?.id}
              </Typography>
              <Typography className={classes.usernameTypography}>
                {t("Components.Header.Operator")}: {userProfile?.name}
              </Typography>
            </Box>
          </>
        ) : (
          <Box className={classes.titleContainer} />
        )}
        <LanguageMenu languages={languages} />
        <Tooltip title="Logout">
          <IconButton onClick={handleLogout}>
            <ExitToAppIcon className={classes.white} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
