import React, { useState, useMemo, useCallback } from "react";
import clsx from "clsx";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
} from "mui-datatables";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DataTableFooter from "../../components/DataTableFooter/DataTableFooter";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import FilterChipBar from "../../components/FilterChipBar/FilterChipBar";
import { attachSortToColumns } from "../../utils/helpers/filtersHelpers";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import {
  getPatientsQuery,
  getPendingPatientsCountQuery,
  getPatientQueryForOperator,
} from "../../queries/patients";
import { IPatientsTableDTO } from "../../types/tables/IPatientsTable";
import { useTranslation } from "react-i18next";
import CreatePatientModal from "../Modals/Patient/CreatePatientModal/CreatePatientModal";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import { useSelector } from "../../state/store";
import { useDispatch } from "react-redux";
import { actions } from "../../state/sessions/actions";
import { selectPreviousPatientId } from "../../utils/helpers/stateSelectorHelpers";
import useStyles from "./Patients.styles";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import {
  deletePatientMutation,
  approveRejectToClinicMutation,
} from "../../mutations/patients";
import DeletePatientModal from "../Modals/Patient/DeletePatientModal/DeletePatientModal";
import {
  TranslationsEnum,
  PatientsColumnsEnum,
  LanguageEnum,
  GenderEnum,
} from "../../types/enums";
import { IPatientsFilterConfig } from "../../types/filters/IPatientsFilterConfig";
import { IActionColumn } from "../../types/filters/IActionColumn";
import queryString from "query-string";
import { RequestedClinicStatusEnum } from "../../types/enums/RequestedClinicStatusEnum";
import { IApproveRejectToClinic } from "../../types/requestDtos/IApproveRejectToClinic";
import { defaultIdIndex } from "../../constants/defaultValues";
import InviteUserToRegisterModal from "../Modals/Patient/InviteUserToRegisterModal/InviteUserToRegisterModal";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { IPatient } from "../../types/IPatient";
import Questionnaire from "../Questionnaire/Questionnaire";
import { IPatientForm } from "../../types/forms/IPatientForm";
import Results from "../Questionnaire/steps/Results/Results";
import QuestionnaireExplanation from "../Questionnaire/steps/Components/QuestionnaireExplanation";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import { selectUserProfile } from "../../utils/helpers/stateSelectorHelpers";
import { ProfitLevelResultEnum } from "../../types/enums/ProfitLevelResultEnum";

const Patients: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [patientForm, setPatientForm] = useState<IPatientForm>({
    name: "",
    weight: 0,
    height: 0,
    pathologyId: 0,
    timeSinceDiagnosis: "",
    suggestedPathology: "",
    email: "",
    dateOfBirth: "1900-01-01",
    phoneNumber: "",
    preferredLanguage: LanguageEnum.De,
    genderType: GenderEnum.Other,
    inSanitasTrial: false,
    questionnaire: null,
    isQuestionnaireDone: false,
    QuestionnaireResults: "",
  });
  const { userProfile } = useSelector(selectUserProfile);
  const handleSetPatientForm = (formValues: IPatientForm) =>
    setPatientForm(formValues);
  const [isQuestionnaireOpen, setQuestionnaireOpen] = useState(false);
  const handleQuestionnaireOpen = () => {
    handleQuestionnaireExplanationClose();
    if (patientForm?.inSanitasTrial) {
      setQuestionnaireOpen(true);
    }
  };

  const [
    isQuestionnaireExplanationOpen,
    setQuestionnaireExplanationOpen,
  ] = useState(false);
  const handleQuestionnaireExplanationClose = () => {
    setQuestionnaireExplanationOpen(false);
  };
  const handleQuestionnaireExplanationOpen = () => {
    setQuestionnaireExplanationOpen(true);
  };

  const handleQuestionnaireClose = () => {
    refetch();
    setQuestionnaireOpen(false);
  };
  const [openResults, setOpenResults] = useState(false);
  const [profitLevel, setProfitLevel] = useState<ProfitLevelResultEnum>();
  const handleSetProfitLevel = (profitLevel: ProfitLevelResultEnum) => {
    setProfitLevel(profitLevel);
  };

  const handleOpenResults = () => {
    setOpenResults(true);
  };
  const handleCloseResults = () => {
    setOpenResults(false);
  };

  const { previousPatientId } = useSelector(selectPreviousPatientId);
  const [isApprovePatient, setIsApprovePatient] = useState<
    boolean | undefined
  >();
  const [approveRejectPatientId, setApproveRejectPatientId] = useState<
    number | undefined
  >();

  const search = history.location.search;
  const {
    includeRequested,
  }: { [PatientsColumnsEnum.IncludeRequested]?: string } = queryString.parse(
    search
  );
  const includeRequestedVal = includeRequested !== "false";
  const defaultSorting = {
    sortField: PatientsColumnsEnum.GenerationDateTime,
    sortDirection: SortDirectionEnum.Descending,
  };
  const [filterConfig, setFilterConfig] = useState<IPatientsFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: [],
    includeRequested: includeRequestedVal,
  });

  const {
    error: errorPendingPatientsCount,
    refetch: refetchPendingPatientsCount,
  } = useQuery(getPendingPatientsCountQuery, {
    fetchPolicy: "network-only",
  });

  useErrorHandling(errorPendingPatientsCount);

  const { data, loading, error, refetch } = useQuery<{
    patientsTableDTO: IPatientsTableDTO;
  }>(getPatientsQuery, {
    variables: { filterConfig },
    fetchPolicy: "network-only",
  });

  useErrorHandling(error);

  const handleDeleteSuccess = () => {
    refetch();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t("Snackbar.ProfileDeletedSuccessfully")
      )
    );
  };

  const [
    deletePatient,
    { error: deletePatientError, loading: deleteLoading },
  ] = useMutation(deletePatientMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess,
  });

  useErrorHandling(deletePatientError);

  const handleApproveRejectSuccess = () => {
    refetch();
    refetchPendingPatientsCount();
    if (isApprovePatient) {
      dispatch(
        snackbarActions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_ApproveUserSuccess)
        )
      );
    } else {
      dispatch(
        snackbarActions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_RejectUserSuccess)
        )
      );
    }
  };

  const [
    approveRejectToClinic,
    {
      error: approveRejectToClinicError,
      loading: approveRejectToClinicLoading,
    },
  ] = useMutation(approveRejectToClinicMutation, {
    onError: () => {},
    onCompleted: handleApproveRejectSuccess,
  });

  useErrorHandling(approveRejectToClinicError);

  const columns: MUIDataTableColumn[] = [
    {
      name: PatientsColumnsEnum.Id,
      label: t(TranslationsEnum.Global_ID),
      options: {
        customBodyRender: (value: number) => {
          const patient = patients.find((x) => x.id === value);
          return (
            <Box>
              {value}
              {!patient?.dataProcessingAgreement && (
                <Tooltip title={t(TranslationsEnum.Global_NotVerified)}>
                  <WarningRoundedIcon className={classes.warning} />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    },
    ...(userProfile?.clinicInSanitasTrial
      ? [
          {
            name: PatientsColumnsEnum.Id,
            label: t(TranslationsEnum.Global_Tabt),
            options: {
              customBodyRender: (value: number) => {
                const patient = patients.find((x) => x.id === value);
                return (
                  <Box>
                    {patient?.isQuestionnaireDone && (
                      <Tooltip
                        title={t(TranslationsEnum.Global_QuestionnaireDone)}
                      >
                        <BorderColorRoundedIcon className={classes.warningOk} />
                      </Tooltip>
                    )}
                    {patient?.inSanitasTrial &&
                      userProfile?.clinicInSanitasTrial && (
                        <Tooltip title={t(TranslationsEnum.Global_PartOfTrial)}>
                          <WarningRoundedIcon className={classes.warningOk} />
                        </Tooltip>
                      )}
                  </Box>
                );
              },
            },
          },
        ]
      : []),

    {
      name: PatientsColumnsEnum.Name,
      label: t(TranslationsEnum.Global_DisplayName),
      options: {
        customBodyRender: (value: number, tableMeta: any) => {
          const patient = patients.find(
            (x) => x.id === tableMeta.rowData[defaultIdIndex]
          );
          return (
            <Box>
              {patient?.requestedClinicStatus ===
                RequestedClinicStatusEnum.Pending &&
                t(TranslationsEnum.Containers_Patients_UnapprovedUser)}{" "}
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: PatientsColumnsEnum.NumberOfSessionsDone,
      label: t(
        TranslationsEnum.Containers_Patients_HeaderColumns_NumberOfSessionsDone
      ),
      options: {
        customBodyRender: (value: number, tableMeta: any) => {
          const patient = patients.find(
            (x) => x.id === tableMeta.rowData[defaultIdIndex]
          );
          return (
            <Box>
              {patient?.requestedClinicStatus !==
              RequestedClinicStatusEnum.Pending
                ? value
                : " "}
            </Box>
          );
        },
      },
    },
    {
      name: PatientsColumnsEnum.Weight,
      label: t(TranslationsEnum.Containers_Patients_HeaderColumns_Weight),
      options: {
        customBodyRender: (value: number, tableMeta: any) => {
          const patient = patients.find(
            (x) => x.id === tableMeta.rowData[defaultIdIndex]
          );
          return (
            <Box>
              {patient?.requestedClinicStatus !==
              RequestedClinicStatusEnum.Pending
                ? value
                : " "}
            </Box>
          );
        },
      },
    },
    {
      name: PatientsColumnsEnum.Height,
      label: t(TranslationsEnum.Containers_Patients_HeaderColumns_Height),
      options: {
        customBodyRender: (value: number, tableMeta: any) => {
          const patient = patients.find(
            (x) => x.id === tableMeta.rowData[defaultIdIndex]
          );
          return (
            <Box>
              {patient?.requestedClinicStatus !==
              RequestedClinicStatusEnum.Pending
                ? value
                : " "}
            </Box>
          );
        },
      },
    },
    {
      name: PatientsColumnsEnum.Id,
      label: " ",
      options: {
        customBodyRender: (value: number) => {
          const patient = patients.find((x) => x.id === value);
          return (
            <>
              {patient?.requestedClinicStatus ===
              RequestedClinicStatusEnum.Pending ? (
                <Box display="flex">
                  {approveRejectToClinicLoading &&
                  approveRejectPatientId === patient.id ? (
                    <CircularProgress size={24} color="primary" />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        className={clsx(classes.button, classes.rejectButton)}
                        onClick={handleRejectClick(value)}
                      >
                        {t(TranslationsEnum.Global_Reject)}
                      </Button>
                      <Button
                        variant="contained"
                        className={clsx(classes.button, classes.approveButton)}
                        onClick={handleApproveClick(value)}
                      >
                        {t(TranslationsEnum.Global_Approve)}
                      </Button>
                    </>
                  )}
                </Box>
              ) : (
                <Box onClick={handleDeleteModalOpen(value)}>
                  <IconButton aria-label="delete">
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Box>
              )}
            </>
          );
        },
      },
    },
  ];

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection: filterConfig.sortDirection,
        name: filterConfig.sortField,
      }),
    [filterConfig, columns]
  );

  const actionColumns: IActionColumn[] = [
    {
      name: PatientsColumnsEnum.IncludeRequested,
      selectedLabel: t(
        TranslationsEnum.Containers_Patients_HeaderColumns_HideNewRequests
      ),
      label: t(
        TranslationsEnum.Containers_Patients_HeaderColumns_ShowNewRequests
      ),
      defaultValue: includeRequestedVal,
    },
  ];

  const [isAddPatientModalOpen, setAddPatientModalOpen] = React.useState(false);
  const handleAddPatientModalOpen = () => setAddPatientModalOpen(true);
  const handleAddPatientModalClose = () => {
    setAddPatientModalOpen(false);
  };

  const filterColumns: IFilterColumn[] = useMemo(
    () =>
      columns
        .filter((x) => x.name !== PatientsColumnsEnum.Id)
        .map((x) => ({
          name: x.name,
          label: x.label ?? "",
        })),
    [columns]
  );

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      _page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage,
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig((x) => ({ ...x, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    setFilterConfig((x) => ({ ...x, sortField, sortDirection }));
  };

  const handleRowClick = useCallback(
    (_rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      const patientId = data?.patientsTableDTO.patients[rowMeta.rowIndex].id;

      // if another patient is selected from the users table, reset sessions tab memory
      if (patientId !== previousPatientId) {
        dispatch(actions.resetSessionsDataAction());
        dispatch(actions.setSelectedPatientIdAction(patientId));
      }

      history.push(`${PathNamesEnum.Patients}/${patientId}?tab=0`);
    },
    [data, previousPatientId, history, dispatch]
  );

  const hasSorting = useMemo(
    () =>
      !(
        defaultSorting.sortField === filterConfig.sortField &&
        defaultSorting.sortDirection === filterConfig.sortDirection
      ),
    [defaultSorting, filterConfig]
  );

  const actionButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={handleAddPatientModalOpen}
    >
      {t("Containers.Patients.AddNewUser")}
    </Button>
  );

  const handleFilter = (
    filters: IWebParametersFilter[],
    actionsValues: Record<string, boolean>
  ) => {
    const includeVal = actionsValues[PatientsColumnsEnum.IncludeRequested];
    setFilterConfig((x) => ({
      ...x,
      filters,
      page: 0,
      includeRequested: actionsValues[PatientsColumnsEnum.IncludeRequested],
    }));
    history.push(
      `${PathNamesEnum.Patients}?${PatientsColumnsEnum.IncludeRequested}=${includeVal}`
    );
    refetchPendingPatientsCount();
  };

  const resetSorting = () => {
    setFilterConfig((x) => ({ ...x, ...defaultSorting }));
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [patientToDeleteId, setPatientToDeleteId] = React.useState<
    null | number
  >(null);

  const handleDeleteModalClose = useCallback(() => {
    setPatientToDeleteId(null);
    setDeleteModalOpen(false);
  }, [setPatientToDeleteId, setDeleteModalOpen]);

  const handleDeleteModalOpen = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setDeleteModalOpen(true);
      setPatientToDeleteId(id);
    },
    [setDeleteModalOpen, setPatientToDeleteId]
  );

  const handleDeletePatient = useCallback(() => {
    deletePatient({
      variables: { values: { id: patientToDeleteId } },
    });
    handleDeleteModalClose();
  }, [deletePatient, handleDeleteModalClose, patientToDeleteId]);

  const handleRejectClick = (id: number) => (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setApproveRejectPatientId(id);
    setIsApprovePatient(false);
    const values: IApproveRejectToClinic = {
      patientId: id,
      approve: false,
    };
    approveRejectToClinic({
      variables: {
        values,
      },
    });
  };

  const handleApproveClick = (id: number) => (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setApproveRejectPatientId(id);
    setIsApprovePatient(true);
    const values: IApproveRejectToClinic = {
      patientId: id,
      approve: true,
    };
    approveRejectToClinic({
      variables: {
        values,
      },
    });
  };

  const [createdUserId, setCreatedUserId] = React.useState<number | null>(null);
  const getPatientQuery = getPatientQueryForOperator(
    Number(createdUserId)!,
    getSelectedLanguage()
  );

  const {
    data: dataGetPatient,
    error: errorGetPatient,
    refetch: refetchPatient,
  } = useQuery<{ getPatient: IPatient }>(getPatientQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !createdUserId,
  });

  useErrorHandling(errorGetPatient);
  const patient = dataGetPatient?.getPatient;

  const [
    isInviteUserToRegisterModalOpen,
    setInviteUserToRegisterModalOpen,
  ] = React.useState(false);

  const handleInviteUserToRegisterModalOpen = () => {
    setInviteUserToRegisterModalOpen(true);
  };

  const handleInviteUserToRegisterModalClose = () => {
    setInviteUserToRegisterModalOpen(false);
  };

  if (loading && !data) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const { patients, count } = data?.patientsTableDTO || {
    patients: [],
    count: 0,
  };

  const options: MUIDataTableOptions = {
    page: filterConfig.page,
    count,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: filterConfig.takePerPage,
    sort: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange,
    onRowClick: handleRowClick,
  };

  return (
    <Box px={3} pt={4}>
      <Box display="flex">
        <FilterChipBar
          actionColumns={actionColumns}
          filterColumns={filterColumns}
          onFilter={handleFilter}
          hasSorting={hasSorting}
          resetSorting={resetSorting}
          actions={actionButton}
        />
      </Box>
      <MUIDataTable
        title={null}
        data={patients}
        columns={fullColumns}
        options={options}
      />
      <DeletePatientModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        loading={deleteLoading}
        onDelete={handleDeletePatient}
      />
      <CreatePatientModal
        setCreatedUserId={setCreatedUserId}
        open={isAddPatientModalOpen}
        handleClose={handleAddPatientModalClose}
        refetchPatients={refetch}
        openInviteModal={handleInviteUserToRegisterModalOpen}
        openQuestionnaireExplanation={handleQuestionnaireExplanationOpen}
        handleSetPatientForm={handleSetPatientForm}
      />
      <QuestionnaireExplanation
        open={
          isQuestionnaireExplanationOpen && !isInviteUserToRegisterModalOpen
        }
        handleQuestionnaireOpen={handleQuestionnaireOpen}
        handleClose={handleQuestionnaireExplanationClose}
      />
      <Questionnaire
        handleSetProfitLevel={handleSetProfitLevel}
        handleOpenResults={handleOpenResults}
        open={isQuestionnaireOpen}
        handleClose={handleQuestionnaireClose}
        patientForm={patientForm}
        patientId={createdUserId ?? 0}
      />
      <Results
        open={openResults}
        handleClose={handleCloseResults}
        profitLevel={profitLevel}
        allowRedoQuestionnaire={false}
      />
      {patient && (
        <InviteUserToRegisterModal
          handleClose={handleInviteUserToRegisterModalClose}
          open={isInviteUserToRegisterModalOpen}
          patient={patient}
          refetchPatient={refetchPatient}
        />
      )}
    </Box>
  );
};

export default Patients;
