import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, Grid, Typography } from "@material-ui/core";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import { useTranslation } from "react-i18next";
import { ProfitLevelResultEnum } from "../../../../types/enums/ProfitLevelResultEnum";

interface IProps {
  profitLevel: ProfitLevelResultEnum | undefined;
  open: boolean;
  allowRedoQuestionnaire: boolean;
  openQuestionnaire?: () => void;
  handleClose: () => void;
}

const Results: React.FC<IProps> = ({
  open,
  openQuestionnaire,
  profitLevel,
  handleClose,
  allowRedoQuestionnaire
}) => {
  const selectProfitlevelValue = (
    profitLevel: ProfitLevelResultEnum | undefined
  ) => {
    switch (profitLevel) {
      case ProfitLevelResultEnum.NoProfit:
        return t(
          TranslationsEnum.Containers_Modals_Questionnaire_Results_NoProfit
        );
      case ProfitLevelResultEnum.NormalProfit:
        return t(
          TranslationsEnum.Containers_Modals_Questionnaire_Results_NormalProfit
        );
      case ProfitLevelResultEnum.OptimalProfit:
        return t(
          TranslationsEnum.Containers_Modals_Questionnaire_Results_OptimalProfit
        );
    }
  };

  const { t } = useTranslation();
  const dialogTitle =
    profitLevel !== ProfitLevelResultEnum.NoProfit
      ? t(TranslationsEnum.Containers_Modals_Questionnaire_Results_Added)
      : t(TranslationsEnum.Containers_Modals_Questionnaire_Results_NotAdded);
  return (
    <Box>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogContent>
          <Box m={2}>
            <Typography color="primary" variant="h5">
              {dialogTitle}
            </Typography>
          </Box>
          <Box m={2}>
            <Typography>{selectProfitlevelValue(profitLevel)}</Typography>
          </Box>
        </DialogContent>
        <Box m={2}>
          <DialogActions>
            <Grid container direction="row" spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  {t(TranslationsEnum.Global_Close)}
                </Button>
              </Grid>
              {allowRedoQuestionnaire && (
                <Grid item>
                  <Button
                    onClick={openQuestionnaire}
                    color="primary"
                    variant="contained"
                  >
                    {t(
                      TranslationsEnum.Containers_Modals_QuestionnaireExplanation_DoTestNow
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Results;
