import * as actions from "./actions";
import { IUserState } from "../../types/reducerStates/IUserState";

const initialState: IUserState = {
  // userProfile undefined means it's still loading, if no user was found then the profile is set to null
  userProfile: undefined
};

export const reducer = (
  state: IUserState = initialState,
  action: actions.ActionTypes
): IUserState => {
  switch (action.type) {
    case actions.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload
      };
    }
    case actions.SET_DPA: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          dataProcessingAgreement: action.payload
        }
      };
    }
    default:
      return state;
  }
};
