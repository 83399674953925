import { store } from "../../containers/App/App";
import { format } from "date-fns";

export const downloadBlob = (data: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute(
    "download",
    `MyosuitPreparation_Operator${
      store.getState().user.userProfile?.id
    }_${format(new Date(), "yyyyMMdd")}.zip`
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
};
