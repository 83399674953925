import * as React from "react";
import { ISvgProps } from "../../types/ISvgProps";

const TimerIcon = (props: ISvgProps) => {
  return (
    <svg width={36} height={36} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42A8.962 8.962 0 0012 4a9 9 0 00-9 9c0 4.97 4.02 9 9 9a8.994 8.994 0 007.03-14.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
        fill="#195F93"
      />
    </svg>
  );
};

export default TimerIcon;
