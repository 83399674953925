import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: 600,
    padding: theme.spacing(2)
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  modalText: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  okButton: {
    marginLeft: theme.spacing(2),
    color: ColorEnum.White,
    background: ColorEnum.SuccessGreen,

    "&:hover": {
      background: ColorEnum.SuccessDarkGreen
    }
  },
  declineButton: {
    marginLeft: theme.spacing(1),
    color: ColorEnum.White,
    background: ColorEnum.Red,

    "&:hover": {
      background: ColorEnum.DarkRed
    }
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: "100%"
  }
}));
