import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    height: "100%"
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    height: 28
  },
  formContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  descriptiveText: {
    fontStyle: "normal",
    fontWeight: 600
  }
}));
