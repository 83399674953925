import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectProps,
  FormHelperText,
  InputLabelProps,
  FormHelperTextProps,
  FormControlProps,
  MenuItemProps,
  ListItemText
} from "@material-ui/core";
import { ISelectField } from "../../types/formInputs/ISelectField";

type MenuItemPropsExtended = MenuItemProps & {
  button?: true;
  onMenuItemClick?: FormChangeHandler;
};

type Value = string | number | null;

export type FormChangeHandler =
  | ((
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      child: React.ReactNode
    ) => void)
  | undefined;

interface IProps {
  placeholder?: string;
  onChange: FormChangeHandler;
  value: Value;
  selectField: ISelectField;
  error?: string;
  SelectProps?: SelectProps;
  FormControlProps?: FormControlProps;
  InputLabelProps?: InputLabelProps;
  MenuItemProps?: MenuItemPropsExtended;
  FormHelperTextProps?: FormHelperTextProps;
  ListItemsIcons?: Array<React.ReactElement>;
}

const SelectField = (props: IProps) => {
  const {
    selectField: { name, options, label },
    placeholder,
    onChange,
    value,
    error,
    SelectProps,
    FormControlProps,
    InputLabelProps,
    MenuItemProps,
    FormHelperTextProps,
    ListItemsIcons
  } = props;

  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement | null>(null);

  useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, []);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      child: React.ReactNode
    ) => {
      if (MenuItemProps) {
        const { onMenuItemClick } = MenuItemProps;
        onMenuItemClick && onMenuItemClick(event, child);
      }
      onChange && onChange(event, child);
    },
    [MenuItemProps, onChange]
  );

  let menuItemProps = MenuItemProps;
  if (MenuItemProps) {
    const { onMenuItemClick, ...rest } = MenuItemProps;
    menuItemProps = rest;
  }

  return (
    <FormControl
      {...FormControlProps}
      error={Boolean(error)}
      key={`formControl${name}`}
      fullWidth
    >
      {label && (
        <InputLabel
          {...InputLabelProps}
          ref={inputLabel}
          htmlFor={`select${name}`}
          key={`inputLabelKey${name}`}
          id={`inputLabelId${name}`}
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...SelectProps}
        error={Boolean(error)}
        labelWidth={labelWidth}
        name={name}
        id={`select${name}`}
        key={`select${name}`}
        value={
          placeholder ? (value === 0 ? 0 : value || "_placeholder") : value
        }
        onChange={handleChange}
      >
        {placeholder && (
          <MenuItem value="_placeholder" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option, index) => {
          const { value, label } = option;
          return (
            <MenuItem
              {...menuItemProps}
              key={`${name} option ${label} ${index}`}
              value={value}
            >
              {ListItemsIcons ? (
                <>
                  {ListItemsIcons[index]}
                  <ListItemText>{label}</ListItemText>
                </>
              ) : (
                <ListItemText>{label}</ListItemText>
              )}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText {...FormHelperTextProps} error={Boolean(error)}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};
export default SelectField;
