import React from "react";
import { Grid, CircularProgress, Box } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import {
  getPatientSessionsOverviewQueryForOperator,
  getPatientSessionsOverviewQueryForPatient
} from "../../../queries/sessions";
import OverallSessionsCard from "./OverallSessionsCard/OverallSessionsCard";
import PersonalRecordsCard from "./PersonalRecordsCard/PersonalRecordsCard";
import { IOverallSessionsData } from "../../../types/IOverallSessionsData";
import { IPersonalRecordsData } from "../../../types/IPersonalRecordsData";
import {
  convertSecondsToDateFormat,
  removeUnusedFractionsForOverallSessionData
} from "../../../utils/helpers/parsingHelper";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum } from "../../../types/enums";

interface IProps {
  patientId: number;
}

const OverallSessionsOverview = (props: IProps) => {
  const { patientId } = props;

  const { userProfile } = useSelector(selectUserProfile);

  const getPatientSessionsOverviewQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getPatientSessionsOverviewQueryForOperator(Number(patientId)!)
      : getPatientSessionsOverviewQueryForPatient;

  const { loading, data, error } = useQuery(getPatientSessionsOverviewQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(error);

  const OverallSessionsValues: IOverallSessionsData = {
    totalTrainingTime: convertSecondsToDateFormat(
      data?.getPatientSessionsOverview.totalTime
    ),
    totalTrainingCourses: data?.getPatientSessionsOverview.totalSessions,
    totalDistanceCovered: data?.getPatientSessionsOverview.totalDistance,
    activeTime: data?.getPatientSessionsOverview.meanActiveRate * 100,
    numberOfSteps: data?.getPatientSessionsOverview.meanSteps
  };

  const PersonalRecordsValues: IPersonalRecordsData = {
    longestActiveTimeInARow: convertSecondsToDateFormat(
      data?.getPatientSessionsOverview.longestActivityTime
    ),
    longestDistanceWalked: data?.getPatientSessionsOverview.longestDistance,
    longestStepSequence: data?.getPatientSessionsOverview.longestSteps
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress size={110} color="primary" />
        </Box>
      ) : (
        <>
          <Grid item lg={6} md={6} xs={12}>
            <OverallSessionsCard
              values={removeUnusedFractionsForOverallSessionData(
                OverallSessionsValues
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <PersonalRecordsCard values={PersonalRecordsValues} />
          </Grid>
        </>
      )}
    </>
  );
};

export default OverallSessionsOverview;
