import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  lightGrey: {
    color: ColorEnum.LightGrey
  },
  fontWeightRegular: {
    fontWeight: 400
  },
  fontWeightBold: {
    fontWeight: 800
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));
