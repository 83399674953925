import React, { useCallback } from "react";
import clsx from "clsx";
import { Box, Paper, Typography, Divider, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IPersonalRecordsData } from "../../../../types/IPersonalRecordsData";
import TextRow from "../../../../components/TextRow/TextRow";
import InfoIcon from "../../../../assets/icons/InfoIcon";
import useStyles from "./PersonalRecordsCard.styles";

interface IProps {
  values: IPersonalRecordsData;
}

const PersonalRecordsCard = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values: {
      longestActiveTimeInARow,
      longestStepSequence,
      longestDistanceWalked
    }
  } = props;

  const isDataEmpty = useCallback(() => {
    if (
      (!longestActiveTimeInARow || longestActiveTimeInARow === "0") &&
      !longestDistanceWalked &&
      !longestStepSequence
    ) {
      return true;
    }

    return false;
  }, [longestActiveTimeInARow, longestDistanceWalked, longestStepSequence]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.root}>
        <Box className={clsx(classes.container, classes.header)}>
          <Typography variant="body2" className={classes.title}>
            {t(
              "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.PersonalRecords"
            )}
          </Typography>
          <Tooltip
            arrow
            title={t(
              "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.Tooltip"
            )}
            classes={{
              tooltip: classes.tooltipText
            }}
          >
            <Box className={classes.tooltip}>
              <InfoIcon />
            </Box>
          </Tooltip>
        </Box>
        <Divider />
        <Box className={clsx(classes.container, classes.paddingBottom)}>
          {isDataEmpty() ? (
            <TextRow
              descriptiveText={t(
                "Containers.SessionsTab.OverallSessionsOverview.NoSessions"
              )}
              valueText=""
            />
          ) : (
            <>
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestActiveTimeInARow"
                )}
                valueText={longestActiveTimeInARow}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestDistanceWalked"
                )}
                valueText={`${longestDistanceWalked} m`}
              />
              <TextRow
                descriptiveText={t(
                  "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestStepSequence"
                )}
                valueText={`${longestStepSequence} ${t(
                  "Global.Steps"
                ).toLowerCase()}`}
              />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default PersonalRecordsCard;
