import React, { useState, useMemo, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
} from "mui-datatables";
import { Box, Button, CircularProgress } from "@material-ui/core";
import DataTableFooter from "../../components/DataTableFooter/DataTableFooter";
import { IFilterColumn } from "../../types/filters/IFilterColumn";
import FilterChipBar from "../../components/FilterChipBar/FilterChipBar";
import { attachSortToColumns } from "../../utils/helpers/filtersHelpers";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import { getPatientsQuery } from "../../queries/patients";
import { IPatientsTableDTO } from "../../types/tables/IPatientsTable";
import { useTranslation } from "react-i18next";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import useStyles from "./PatientsAdmin.styles";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import { TranslationsEnum, PatientsColumnsEnum } from "../../types/enums";
import { IPatientsFilterConfig } from "../../types/filters/IPatientsFilterConfig";
import { SetDPAAdminMutation } from "../../mutations";
import { useDispatch } from "react-redux";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { actions } from "../../state/snackbar/actions";

const PatientsAdmin: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultSorting = {
    sortField: PatientsColumnsEnum.Id,
    sortDirection: SortDirectionEnum.Descending,
  };

  const handleSetDpa = (id: any) => () => {
    setDpa({
      variables: { values: { id, isAgreed: true } },
    });
  };

  const [filterConfig, setFilterConfig] = useState<IPatientsFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: [
      {
        filterField: "IsPhysicalDataProcessingAgreement",
        filterValue: "true",
      },
      {
        filterField: "DataProcessingAgreement",
        filterValue: "null",
      },
    ],
  });

  const { data, loading, error, refetch } = useQuery<{
    patientsTableDTO: IPatientsTableDTO;
  }>(getPatientsQuery, {
    variables: { filterConfig },
    fetchPolicy: "network-only",
  });

  const dispatch = useDispatch();

  const handleSetDpaSuccess = () => {
    refetch();
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_SetDpaSuccess)
      )
    );
  };

  const [setDpa, { error: setDpaErrors }] = useMutation(SetDPAAdminMutation, {
    onError: () => {},
    onCompleted: handleSetDpaSuccess,
  });

  useErrorHandling(setDpaErrors);
  useErrorHandling(error);

  const columns: MUIDataTableColumn[] = [
    {
      name: PatientsColumnsEnum.Id,
      label: t(TranslationsEnum.Global_ID),
      options: {
        customBodyRender: (value) => {
          return <Box>{value}</Box>;
        },
      },
    },
    {
      name: PatientsColumnsEnum.Name,
      label: t(TranslationsEnum.Global_Name),
      options: {
        customBodyRender: (value) => {
          return <Box>{value}</Box>;
        },
      },
    },
    {
      name: PatientsColumnsEnum.ClinicId,
      label: t(TranslationsEnum.Global_Clinics),
      options: {
        customBodyRender: (value) => {
          return <Box>{value}</Box>;
        },
      },
    },

    {
      name: PatientsColumnsEnum.Id,
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Box>
                <Button
                  className={classes.button}
                  onClick={handleSetDpa(value)}
                  variant="contained"
                  color="primary"
                >
                  {t(TranslationsEnum.Global_Approve)}
                </Button>
              </Box>
            </>
          );
        },
      },
    },
  ];

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection: filterConfig.sortDirection,
        name: filterConfig.sortField,
      }),
    [filterConfig, columns]
  );

  const filterColumns: IFilterColumn[] = useMemo(
    () =>
      columns
        .filter((x) => x.name !== PatientsColumnsEnum.Id)
        .map((x) => ({
          name: x.name,
          label: x.label ?? ""
        })),
    [columns]
  );

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage,
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig((x) => ({ ...x, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    setFilterConfig((x) => ({ ...x, sortField, sortDirection }));
  };

  const hasSorting = useMemo(
    () =>
      !(
        defaultSorting.sortField === filterConfig.sortField &&
        defaultSorting.sortDirection === filterConfig.sortDirection
      ),
    [defaultSorting, filterConfig]
  );

  const handleFilter = (filters: IWebParametersFilter[]) => {
    setFilterConfig((x) => ({
      ...x,
      filters,
      page: 0,
    }));
  };

  const resetSorting = () => {
    setFilterConfig((x) => ({ ...x, ...defaultSorting }));
  };

  if (loading && !data) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const { patients, count } = data?.patientsTableDTO || {
    patients: [],
    count: 0,
  };

  const options: MUIDataTableOptions = {
    page: filterConfig.page,
    count,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: filterConfig.takePerPage,
    sort: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange,
  };

  return (
    <Box px={3} pt={4}>
      <Box display="flex">
        <FilterChipBar
          filterColumns={filterColumns}
          onFilter={handleFilter}
          hasSorting={hasSorting}
          resetSorting={resetSorting}
        />
      </Box>
      <MUIDataTable
        title={null}
        data={patients}
        columns={fullColumns}
        options={options}
      />
    </Box>
  );
};

export default PatientsAdmin;
