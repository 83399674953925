import gql from "graphql-tag";

export const getMessagesCount = gql`
  query getMessagesCount {
    messagesCount
      @rest(type: "GetMessagesCountDto", path: "messages/unreadCount") {
      unreadMessagesCount
    }
  }
`;

export const getSingleMessage = (messageId: number) => gql`
  query getSingleMessage {
    getSingleMessage @rest(type: "GetSingleMessageDto", path: "messages/${messageId}") {
        id 
        title
        text
        lastEditedAt
        messageReceiverType
        receiverName
        text
        title 
    }
  }
`;

export const getMessagesQuery = gql`
  query getMessages($filterConfig: IFilterConfig!) {
    getMessages(filterConfig: $filterConfig)
      @rest(
        type: "GetMessagesResponseDto"
        method: "POST"
        path: "messages/filtered"
        bodyKey: "filterConfig"
      ) {
      count
      messages @type(name: "Message") {
        id
        lastEditedAt
        title
      }
    }
  }
`;

export const getMessagesForUserQuery = gql`
  query getMessages($filterConfig: IFilterConfig!) {
    getMessages(filterConfig: $filterConfig)
      @rest(
        type: "GetMessagesResponseDto"
        method: "POST"
        path: "messages/filtered/user"
        bodyKey: "filterConfig"
      ) {
      count
      messages @type(name: "Message") {
        id: messageId
        userMessageId
        isRead
        title
        lastEditedAt
      }
    }
  }
`;
