import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";
import { maxContainerWidth } from "../../styles/stylesConstants";
import { theme } from "../../styles/styles";

export default makeStyles(() => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    maxWidth: maxContainerWidth
  },
  paperContainer: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  checkbox: {
    padding: 0,
    "&:hover ": {
      backgroundColor: "unset !important",
      cursor: "auto"
    }
  },
  warning: {
    color: ColorEnum.Orange,
    height: 14,
    position: "relative",
    top: 2,
    width: 18
  },
  button: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center"
  },
  rejectButton: {
    marginRight: 10,
    color: ColorEnum.Red
  },
  approveButton: {
    color: ColorEnum.Blue
  }
}));
