import { AxiosRequestConfig } from "axios";
import instance from "./axiosInstance";

interface IUploadSessionFilesPayload {
  formData: FormData;
  config: AxiosRequestConfig;
}

export const uploadSessionFiles = ({
  formData,
  config
}: IUploadSessionFilesPayload) => instance.post("upload", formData, config);

interface IGetSessionFilesPayload {
  PatientIds: number[];
  UnitSerialNumber: string;
}

export const getSessionFiles = (payload?: IGetSessionFilesPayload) =>
  instance.post("patientProfile", payload ?? {}, {
    responseType: "arraybuffer"
  });

interface IGetInfoOfUploadedFilesPayload {
  filenames: string[];
}
export const getInfoOfUploadedFiles = (
  payload: IGetInfoOfUploadedFilesPayload
) => instance.post("getInfoOfUploadedFiles", payload);
