const MAIN_EVENTS = {
  READ_DISKS: "READ_DISKS",
  GO_BACK: "GO_BACK",
  GET_LOCAL_FILES: "GET_LOCAL_FILES",
  CLEAR_SD_CARD: "CLEAR_SD_CARD",
  DOWNLOAD_FILES: "DOWNLOAD_FILES",
  INSTALL_UPDATE: "INSTALL_UPDATE",
  DOWNLOAD_UPDATE: "DOWNLOAD_UPDATE",
  CHECK_FOR_UPDATES: "CHECK_FOR_UPDATES",
  HAS_CHECKED_UPDATES_ON_LAUNCH: "HAS_CHECKED_UPDATES_ON_LAUNCH"
};

const RENDERER_EVENTS = {
  READ_DISKS_REPLY: "READ_DISKS_REPLY",
  GET_LOCAL_FILES_REPLY: "GET_LOCAL_FILES_REPLY",
  CLEAR_SD_CARD_REPLY: "CLEAR_SD_CARD_REPLY",
  DOWNLOAD_FILES_REPLY: "DOWNLOAD_FILES_REPLY",
  ON_ERROR: "ON_ERROR",
  ON_UPDATE_STATE_CHANGE: "ON_UPDATE_STATE_CHANGE",
  ON_UPDATE_PROGRESS_CHANGE: "ON_UPDATE_PROGRESS_CHANGE",
  ON_FINALIZE: "ON_FINALIZE"
};

const AUTO_UPDATE_EVENTS = {
  CHECKING_FOR_UPDATES: "CHECKING_FOR_UPDATES",
  UPDATE_AVAILABLE: "UPDATE_AVAILABLE",
  UPDATE_DOWNLOADED: "UPDATE_DOWNLOADED",
  UP_TO_DATE: "UP_TO_DATE"
};

exports.MAIN_EVENTS = MAIN_EVENTS;
exports.RENDERER_EVENTS = RENDERER_EVENTS;
exports.AUTO_UPDATE_EVENTS = AUTO_UPDATE_EVENTS;
