import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { maxContainerWidth } from "../../styles/stylesConstants";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    maxWidth: maxContainerWidth
  },
  paperContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  header: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  buttonsContainer: {
    display: "flex",
    marginLeft: "auto"
  },
  button: {
    alignSelf: "flex-end",
    margin: theme.spacing(2),
    marginRight: theme.spacing(3)
  }
}));
