import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Typography,
  Button,
  Collapse,
  Tabs,
  Tab,
  Tooltip,
  Link,
  Grid
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import SendIcon from "@material-ui/icons/Send";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { IPatient } from "../../../types/IPatient";
import EditPatientModal from "../../Modals/Patient/EditPatientModal/EditPatientModal";
import DeletePatientModal from "../../Modals/Patient/DeletePatientModal/DeletePatientModal";
import { differenceInYears } from "date-fns";
import { useMutation } from "@apollo/react-hooks";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import {
  resendDPAEmailMutation,
  deletePatientMutation,
  deletePatientFromClaimsMutation,
  setPaperDPASignedMutation
} from "../../../mutations/patients";
import { actions } from "../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../types/enums/SnackbarTypeEnum";
import { useDispatch } from "react-redux";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import {
  ClaimsEnum,
  GenderEnum,
  LanguageEnum,
  TranslationsEnum
} from "../../../types/enums";
import { authProviderSignUpSignIn } from "../../../utils/configs/authProvider";
import { AddTrainerModal } from "../../Modals/index";
import { RequestedClinicStatusEnum } from "../../../types/enums/RequestedClinicStatusEnum";
import { defaultClinicId } from "../../../constants/defaultValues";
import useStyles from "./PatientsInnerPageHeader.styles";
import { mainPage } from "../../../constants/contacts";
import InviteUserToRegisterModal from "../../Modals/Patient/InviteUserToRegisterModal/InviteUserToRegisterModal";
import Questionnaire from "../../Questionnaire/Questionnaire";
import { IPatientForm } from "../../../types/forms/IPatientForm";
import Results from "../../Questionnaire/steps/Results/Results";
import { ProfitLevelResultEnum } from "../../../types/enums/ProfitLevelResultEnum";
import QuestionnaireExplanation from "../../Questionnaire/steps/Components/QuestionnaireExplanation";

interface IProps {
  patient: IPatient;
  refetchPatient: (variables?: Record<string, any> | undefined) => void;
}

const PatientsInnerPageHeader = (props: IProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isPathologyOpen, setIsPathologyOpen] = useState(false);
  const { tab }: { tab?: string } = queryString.parse(location.search);
  const { patient, refetchPatient } = props;
  const {
    id,
    dateOfBirth,
    clinicId,
    requestedClinicStatus,
    name,
    dataProcessingAgreement,
    email,
    weight,
    height,
    pathology,
    phoneNumber,
    inSanitasTrial,
    isQuestionnaireDone,
    dataProcessingAgreementSendAt,
    mwT10Result,
    mwT2Result,
    bergBalanceResult,
    bergBalanceTimestamp,
    tugResult,
    tugTimestamp
  } = patient;
  const { userProfile } = useSelector(selectUserProfile);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;
  const partOfMyosuiteReha =
    inSanitasTrial && userProfile?.clinicInSanitasTrial;

  const dispatchEmailSentSuccessSnackbar = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_EmailWasSuccessfullySent)
      )
    );
  };

  const [patientForm, setPatientForm] = useState<IPatientForm>({
    name: "",
    weight: 0,
    height: 0,
    pathologyId: 0,
    timeSinceDiagnosis: "",
    suggestedPathology: "",
    email: "",
    dateOfBirth: "1900-01-01",
    phoneNumber: "",
    preferredLanguage: LanguageEnum.De,
    genderType: GenderEnum.Other,
    inSanitasTrial: false,
    questionnaire: null,
    isQuestionnaireDone: false,
    QuestionnaireResults: "{}",
    sanitasTrialStart: patient.sanitasTrialStart,
    sanitasTrialEnd: patient.sanitasTrialEnd
  });

  const handleSetPatientForm = (formValues: IPatientForm) =>
    setPatientForm(formValues);
  const [openResults, setOpenResults] = useState(false);
  const results = JSON.parse(patient?.questionnaireResults ?? "{}");
  const [profitLevel, setProfitLevel] = useState(results.fitLevel);

  const handleGoToCostApproval = () => {
    history.push(`${PathNamesEnum.CostApprovalBase}/${patient.id}`);
  };

  const [
    isQuestionnaireExplanationOpen,
    setQuestionnaireExplanationOpen
  ] = useState(false);
  const handleQuestionnaireExplanationClose = () => {
    setQuestionnaireExplanationOpen(false);
  };
  const handleQuestionnaireExplanationOpen = () => {
    setQuestionnaireExplanationOpen(true);
  };

  const handleOnComplete = () => {
    dispatchEmailSentSuccessSnackbar();
    refetchPatient();
  };

  const handleSetProfitLevel = (profitLevel: ProfitLevelResultEnum) => {
    setProfitLevel(profitLevel);
  };

  const handleOpenResults = () => {
    setOpenResults(true);
  };

  const handleCloseResults = () => {
    setOpenResults(false);
    refetchPatient();
  };

  const handleDeleteSuccess = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_ProfileDeletedSuccessfully)
      )
    );
    if (userProfile?.claimsType === ClaimsEnum.Patient) {
      authProviderSignUpSignIn.logout();
    } else if (userProfile?.claimsType === ClaimsEnum.Operator) {
      history.push(PathNamesEnum.Patients);
    }
  };

  const [resendDPAEmail, { error: resendEmailError }] = useMutation(
    resendDPAEmailMutation,
    {
      onCompleted: dispatchEmailSentSuccessSnackbar
    }
  );

  const [setPaperDPASigned, { error: setPaperDPASignedError }] = useMutation(
    setPaperDPASignedMutation,
    {
      onCompleted: handleOnComplete
    }
  );

  const [
    deletePatient,
    { error: deletePatientError, loading: deleteLoading }
  ] = useMutation(deletePatientMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess
  });

  const [
    deletePatientFromClaims,
    {
      error: deletePatientFromClaimsError,
      loading: deletePatientFromClaimsLoading
    }
  ] = useMutation(deletePatientFromClaimsMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess
  });

  const handleResendDPAEmail = () =>
    resendDPAEmail({
      variables: {
        values: {
          patientId: id
        }
      }
    });

  const handleSetPaperDPASigned = () =>
    setPaperDPASigned({
      variables: {
        values: {
          patientId: id
        }
      }
    });

  const handleDeletePatient = () => {
    if (isPatient) {
      deletePatientFromClaims();
    } else if (isOperator) {
      deletePatient({
        variables: { values: { id: id } }
      });
    }
  };

  useErrorHandling(resendEmailError);
  useErrorHandling(setPaperDPASignedError);
  useErrorHandling(deletePatientError);
  useErrorHandling(deletePatientFromClaimsError);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    history.push(`?tab=${newValue}`);
  };

  const handleShowPathologyClick = () => setIsPathologyOpen(!isPathologyOpen);

  const [isEditModalOpen, setEditModalOpen] = React.useState(false);

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const [isQuestionnaireOpen, setQuestionnaireOpen] = useState(false);
  const handleQuestionnaireOpen = () => {
    setQuestionnaireOpen(true);
  };

  const handleQuestionnaireClose = () => {
    setQuestionnaireOpen(false);
  };

  const [
    isInviteUserToRegisterModalOpen,
    setInviteUserToRegisterModalOpen
  ] = React.useState(false);

  const handleInviteUserToRegisterModalOpen = () => {
    setInviteUserToRegisterModalOpen(true);
  };

  const handleInviteUserToRegisterModalClose = () => {
    setInviteUserToRegisterModalOpen(false);
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const toggleModal = () => setOpen(!open);

  const age = dateOfBirth
    ? differenceInYears(new Date(), new Date(dateOfBirth))
    : 0;

  const needsAssignToClinic =
    isPatient &&
    (!clinicId || clinicId === defaultClinicId) &&
    requestedClinicStatus !== RequestedClinicStatusEnum.Pending;

  const showDeleteButton =
    isPatient ||
    (isOperator && requestedClinicStatus !== RequestedClinicStatusEnum.Pending);

  const hasEmailOrPhoneNumber = patient.email || patient.phoneNumber;

  return (
    <Box className={classes.root}>
      <InviteUserToRegisterModal
        handleClose={handleInviteUserToRegisterModalClose}
        open={isInviteUserToRegisterModalOpen}
        patient={patient}
        refetchPatient={refetchPatient}
      />
      <EditPatientModal
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
        patient={patient}
        refetchPatient={refetchPatient}
        handleSetPatientForm={handleSetPatientForm}
        openQuestionnaireExplanation={handleQuestionnaireExplanationOpen}
      />
      <QuestionnaireExplanation
        open={isQuestionnaireExplanationOpen}
        handleQuestionnaireOpen={handleQuestionnaireOpen}
        handleClose={handleQuestionnaireExplanationClose}
      />
      <Questionnaire
        handleSetProfitLevel={handleSetProfitLevel}
        handleOpenResults={handleOpenResults}
        open={isQuestionnaireOpen}
        handleClose={handleQuestionnaireClose}
        patientForm={patientForm}
        patientId={id ?? 0}
        patient={patient}
      />
      <Results
        open={openResults}
        handleClose={handleCloseResults}
        profitLevel={profitLevel}
        openQuestionnaire={handleQuestionnaireOpen}
        allowRedoQuestionnaire={true}
      />

      <DeletePatientModal
        open={isDeleteModalOpen}
        claimsType={userProfile?.claimsType}
        handleClose={handleDeleteModalClose}
        loading={isPatient ? deletePatientFromClaimsLoading : deleteLoading}
        onDelete={handleDeletePatient}
      />
      {isPatient && (
        <AddTrainerModal
          open={open}
          handleClose={toggleModal}
          refetchPatient={refetchPatient}
        />
      )}
      <Box className={classes.container}>
        {needsAssignToClinic && !userProfile?.patientHasUnit && (
          <Typography
            variant="subtitle1"
            className={classes.warningAssignToClinicOrBuySuitContainer}
          >
            <WarningRoundedIcon
              fontSize="small"
              className={classes.warningAssignToClinicOrBuySuit}
            />
            {t(TranslationsEnum.Global_WarningToAssignToClinicOrBuySuit)}{" "}
            <Link href={mainPage} target={"_blank"} underline="always">
              {mainPage}
            </Link>
          </Typography>
        )}
        <Box mb={2} className={classes.row}>
          <Box>
            <Typography
              variant="h6"
              className={clsx(
                classes.normalFontStyleWeight,
                classes.darkGrey,
                classes.patientName
              )}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              className={clsx(
                classes.normalFontStyleWeight,
                classes.darkGrey,
                classes.idRow
              )}
            >
              ID: {id}
              {!dataProcessingAgreement && (
                <Tooltip
                  className={classes.buttonTooltip}
                  title={t(TranslationsEnum.Global_NotVerified)}
                >
                  <WarningRoundedIcon
                    fontSize="small"
                    className={`${classes.warningBase} ${classes.orange}`}
                  />
                </Tooltip>
              )}
              {isQuestionnaireDone && (
                <Tooltip
                  className={classes.buttonTooltip}
                  title={t(TranslationsEnum.Global_QuestionnaireDone)}
                >
                  <BorderColorRoundedIcon
                    fontSize="small"
                    className={`${classes.warningBase} ${classes.green}`}
                  />
                </Tooltip>
              )}
              {partOfMyosuiteReha && (
                <Tooltip
                  className={classes.buttonTooltip}
                  title={t(TranslationsEnum.Global_PartOfTrial)}
                >
                  <WarningRoundedIcon
                    fontSize="small"
                    className={`${classes.warningBase} ${classes.green}`}
                  />
                </Tooltip>
              )}
            </Typography>
          </Box>
          <Box display="flex" height="fit-content" width="100%">
            {needsAssignToClinic && (
              <Tooltip
                classes={{ tooltip: classes.buttonTooltip }}
                title={t(
                  TranslationsEnum.Containers_PatientsInnerPage_AddTrainerTooltip
                )}
              >
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(
                      classes.normalFontStyleWeight,
                      classes.roundButton
                    )}
                    onClick={toggleModal}
                  >
                    {t(
                      TranslationsEnum.Containers_PatientsInnerPage_Header_AssignToTrainer
                    )}
                  </Button>
                </div>
              </Tooltip>
            )}
            <Button
              variant="outlined"
              color="primary"
              className={clsx(
                classes.normalFontStyleWeight,
                classes.roundButton
              )}
              onClick={handleShowPathologyClick}
            >
              {isPathologyOpen
                ? t(
                    TranslationsEnum.Containers_PatientsInnerPage_Header_HideDetails
                  )
                : t(
                    TranslationsEnum.Containers_PatientsInnerPage_Header_ShowMoreDetails
                  )}
            </Button>
            {dataProcessingAgreement === null && email && (
              <Button
                variant="outlined"
                color="primary"
                className={clsx(
                  classes.normalFontStyleWeight,
                  classes.resendEmailButton
                )}
                onClick={handleResendDPAEmail}
              >
                {t(
                  TranslationsEnum.Containers_PatientsInnerPage_Header_ResendEmail
                )}
                <SendIcon fontSize="small" className={classes.sendIcon} />
              </Button>
            )}
            {isOperator &&
              userProfile?.clinicInSanitasTrial === true &&
              dataProcessingAgreement === null &&
              dataProcessingAgreementSendAt === null && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.resendEmailButton
                  )}
                  onClick={handleSetPaperDPASigned}
                >
                  {t(TranslationsEnum.Global_PatientSignedWrittenConsent)}
                  <SendIcon fontSize="small" className={classes.sendIcon} />
                </Button>
              )}
            {isOperator &&
              patient.inSanitasTrial &&
              patient.isQuestionnaireDone &&
              userProfile?.clinicInSanitasTrial && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.resendEmailButton
                  )}
                  onClick={handleGoToCostApproval}
                >
                  {t(TranslationsEnum.Components_Header_SideMenu_CostApproval)}
                  <BorderColorRoundedIcon
                    fontSize="small"
                    className={classes.sendIcon}
                  />
                </Button>
              )}
            {isOperator && (
              <Button
                variant="outlined"
                color="primary"
                className={clsx(
                  classes.normalFontStyleWeight,
                  classes.resendEmailButton
                )}
                onClick={
                  patient?.isQuestionnaireDone
                    ? handleOpenResults
                    : handleQuestionnaireOpen
                }
              >
                {t(TranslationsEnum.Containers_Modals_Questionnaire_Title)}
                <BorderColorRoundedIcon
                  fontSize="small"
                  className={classes.sendIcon}
                />
              </Button>
            )}
            {isOperator && (
              <>
                <Tooltip
                  classes={{ tooltip: classes.buttonTooltip }}
                  title={t(
                    hasEmailOrPhoneNumber
                      ? TranslationsEnum.Global_EditContactInfo
                      : TranslationsEnum.Global_InvitePersonToPortal,
                    {
                      name: t(TranslationsEnum.Global_Person)
                    }
                  )}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    className={clsx(
                      { [classes.marginLeftAuto]: isOperator },
                      classes.leftButton
                    )}
                    onClick={handleInviteUserToRegisterModalOpen}
                  >
                    <ContactMailIcon />
                  </Button>
                </Tooltip>
              </>
            )}

            <Tooltip
              classes={{ tooltip: classes.buttonTooltip }}
              title={t(TranslationsEnum.Global_EditProfile)}
            >
              <Button
                variant="outlined"
                color="primary"
                className={clsx(
                  { [classes.marginLeftAuto]: isPatient },
                  classes.middleButton
                )}
                onClick={handleEditModalOpen}
              >
                <EditIcon />
              </Button>
            </Tooltip>
            {showDeleteButton && (
              <Tooltip
                classes={{ tooltip: classes.buttonTooltip }}
                title={t(TranslationsEnum.Global_DeleteProfile)}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.rightButton}
                  onClick={handleDeleteModalOpen}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Grid container>
          <Grid item>
            <Box mb={2}>
              {email && (
                <Box className={classes.row}>
                  <Typography
                    variant="body2"
                    className={clsx(
                      classes.normalFontStyleWeight,
                      classes.lightGrey,
                      classes.labelTextWidth
                    )}
                  >
                    {t(
                      TranslationsEnum.Containers_PatientsInnerPage_Header_Email
                    )}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={clsx(
                      classes.normalFontStyleWeight,
                      classes.darkGrey
                    )}
                  >
                    {email}
                  </Typography>
                </Box>
              )}
              {phoneNumber && (
                <Box className={classes.row}>
                  <Typography
                    variant="body2"
                    className={clsx(
                      classes.normalFontStyleWeight,
                      classes.lightGrey,
                      classes.labelTextWidth
                    )}
                  >
                    {t(TranslationsEnum.Global_PhoneNumber)}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={clsx(
                      classes.normalFontStyleWeight,
                      classes.darkGrey
                    )}
                  >
                    {phoneNumber}
                  </Typography>
                </Box>
              )}
              <Box className={classes.row}>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.lightGrey,
                    classes.labelTextWidth
                  )}
                >
                  {t(TranslationsEnum.Containers_PatientsInnerPage_Header_Age)}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.darkGrey
                  )}
                >
                  {age}
                </Typography>
              </Box>
              <Box className={classes.row}>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.lightGrey,
                    classes.labelTextWidth
                  )}
                >
                  {t(
                    TranslationsEnum.Containers_PatientsInnerPage_Header_Weight
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.darkGrey
                  )}
                >
                  {weight}
                </Typography>
              </Box>
              <Box className={classes.row}>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.lightGrey,
                    classes.labelTextWidth
                  )}
                >
                  {t(
                    TranslationsEnum.Containers_PatientsInnerPage_Header_Height
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.normalFontStyleWeight,
                    classes.darkGrey
                  )}
                >
                  {height}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {(mwT10Result || mwT2Result || bergBalanceResult || tugResult) && (
            <Grid item>
              <Box ml={1} borderLeft={1} className={clsx(classes.lightGrey)}>
                <Box ml={1}>
                  {mwT10Result && (
                    <Box className={classes.row}>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.lightGrey,
                          classes.labelTextWidth
                        )}
                      >
                        {t(TranslationsEnum.Containers_PatientsInnerPage_Met10)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.darkGrey
                        )}
                      >
                        {mwT10Result}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box ml={1}>
                  {mwT2Result && (
                    <Box className={classes.row}>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.lightGrey,
                          classes.labelTextWidth
                        )}
                      >
                        {t(TranslationsEnum.Containers_PatientsInnerPage_Min2)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.darkGrey
                        )}
                      >
                        {mwT2Result}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box ml={1}>
                  {bergBalanceResult && bergBalanceTimestamp && (
                    <Box className={classes.row}>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.lightGrey,
                          classes.labelTextWidth
                        )}
                      >
                        {t(TranslationsEnum.Containers_PatientsInnerPage_Berg)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.darkGrey
                        )}
                      >
                        {bergBalanceResult}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box ml={1} mb={2}>
                  {tugTimestamp && (
                    <Box className={classes.row}>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.lightGrey,
                          classes.labelTextWidth
                        )}
                      >
                        {t(TranslationsEnum.Containers_PatientsInnerPage_Tug)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={clsx(
                          classes.normalFontStyleWeight,
                          classes.darkGrey
                        )}
                      >
                        {tugResult}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Collapse in={isPathologyOpen}>
          <Box className={classes.row}>
            <Typography
              variant="body2"
              className={clsx(
                classes.normalFontStyleWeight,
                classes.lightGrey,
                classes.labelTextWidth
              )}
            >
              {t(
                TranslationsEnum.Containers_PatientsInnerPage_Header_Pathology
              )}
            </Typography>
            <Typography
              variant="body2"
              className={clsx(classes.normalFontStyleWeight, classes.darkGrey)}
            >
              {pathology}
            </Typography>
          </Box>
        </Collapse>
      </Box>
      <Tabs
        className={classes.tabs}
        value={Number(tab)}
        classes={{
          indicator: classes.whiteBackground
        }}
        onChange={handleTabChange}
        aria-label="tabs"
      >
        <Tab
          label={t(TranslationsEnum.Containers_PatientsInnerPage_Tabs_Sessions)}
          className={classes.tab}
          classes={{
            selected: classes.selectedTab
          }}
        />
        <Tab
          label={t(
            TranslationsEnum.Containers_PatientsInnerPage_Tabs_MyoSuitSetup
          )}
          className={classes.tab}
          classes={{
            selected: classes.selectedTab
          }}
        />
      </Tabs>
    </Box>
  );
};

export default PatientsInnerPageHeader;
