import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import useStyles from "./UpdateProgress.styles";
import { useTranslation } from "react-i18next";
import MyoSwissLogoWhite from "../../../assets/myoswiss-logo-white.png";
import prettyBytes from "pretty-bytes";
import ProgressLine from "../ProgressLine/ProgressLine";
import { IProgress } from "../../../types/IProgress";

interface IProps {
  state: string | null;
  progress: IProgress | null;
}

const UpdateProgress: React.FC<IProps> = ({ state, progress }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const total = progress ? prettyBytes(progress.total) : null;
  const transferred = progress ? prettyBytes(progress.transferred) : null;
  const bytesPerSecond = progress ? prettyBytes(progress.bytesPerSecond) : null;
  const percentage = progress ? progress.percent : null;
  let text = "";
  if (progress) {
    text = `${bytesPerSecond}/s - ${transferred} of ${total}`;
  } else if (state) {
    text = t(`Electron.UpdateEvents.${state}`);
  }

  return (
    <Box display="flex" className={classes.box}>
      <img
        src={MyoSwissLogoWhite}
        className={classes.logo}
        alt="myo swiss logo"
      />
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
      {percentage ? (
        <ProgressLine
          className={classes.progress}
          variant="determinate"
          value={percentage}
        />
      ) : (
        <CircularProgress className={classes.circularProgress} />
      )}
    </Box>
  );
};

export default UpdateProgress;
