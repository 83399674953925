import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { FormikErrors } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/SelectField/SelectField";
import { TranslationsEnum } from "../../../../types/enums";
import { IOption } from "../../../../types/formInputs/IOption";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { IClinicForm } from "../../../../types/forms/IClinicForm";
import useStyles from "../../styles/modalForm.styles";
import PhoneInputField, {
  ICountry
} from "../../../../components/PhoneInputField/PhoneInputField";
import {
  preferredCountries,
  excludeCountries
} from "../../../../constants/phoneInput";
import { clinicStatusOptions } from "../../../../constants/options";

const clinicStatusSelect = (
  t: TFunction,
  options: IOption[]
): ISelectField => ({
  label: t(TranslationsEnum.Global_ClinicStatus),
  name: "status",
  options
});

interface IProps {
  open: boolean;
  values: IClinicForm;
  errors: FormikErrors<IClinicForm>;
  loading: boolean;
  formTitle: string;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  handleClose: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => any;
}

const ClinicForm: React.FC<IProps> = ({
  values,
  errors,
  loading,
  open,
  formTitle,
  handleChange,
  handleClose,
  handleSubmit,
  setFieldValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const clinicStatusSelectField = clinicStatusSelect(t, clinicStatusOptions);

  const handlePhoneInputChange = (phoneNumber: string, country?: ICountry) => {
    if (country && phoneNumber.endsWith(`+${country.dialCode}`)) {
      setFieldValue("telephone", "");
    } else if (country && !phoneNumber.startsWith(`+${country.dialCode}`)) {
      setFieldValue("telephone", "");
    } else {
      setFieldValue("telephone", phoneNumber);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <form onSubmit={handleSubmit}>
          <Card className={classes.formItem}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.modalHeader}
              >
                {formTitle}
              </Typography>
              <Box display="flex" flexDirection="column">
                <TextField
                  name="name"
                  label={t(TranslationsEnum.Global_Name)}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={values.name}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  onChange={handleChange}
                />
                <TextField
                  name="description"
                  label={t(TranslationsEnum.Global_Description)}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.description}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                  onChange={handleChange}
                />
                <TextField
                  name="address"
                  label={t(TranslationsEnum.Global_Address)}
                  margin="dense"
                  className={clsx(classes.textField, classes.marginTop4)}
                  variant="outlined"
                  value={values.address}
                  error={Boolean(errors.address)}
                  helperText={errors.address}
                  onChange={handleChange}
                />
                <PhoneInputField
                  FormControlProps={{
                    className: classes.phoneInputFormControl
                  }}
                  FormHelperTextProps={{
                    className: classes.phoneInputHelperText
                  }}
                  inputStyle={{
                    width: "100%",
                    height: 40
                  }}
                  name="telephone"
                  label={t(TranslationsEnum.Global_PhoneNumber)}
                  value={values.telephone}
                  error={errors.telephone}
                  onChange={handlePhoneInputChange}
                  preferredCountries={preferredCountries}
                  country="ch"
                  regions={["europe"]}
                  excludeCountries={excludeCountries}
                  countryCodeEditable={false}
                />
                <SelectField
                  FormControlProps={{
                    variant: "outlined",
                    className: classes.selectField
                  }}
                  key={`ClinicsSelect${clinicStatusSelectField.name}`}
                  selectField={clinicStatusSelectField}
                  onChange={event => {
                    setFieldValue("status", event.target.value);
                  }}
                  // Cast to any needed to avoid typescript errors
                  error={errors.status}
                  value={values.status}
                  SelectProps={{
                    margin: "dense"
                  }}
                  InputLabelProps={{
                    margin: "dense"
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.textField}
                      checked={values.inSanitasTrial}
                      onChange={handleChange}
                      name="inSanitasTrial"
                      color="primary"
                    />
                  }
                  label="Add clinic to Sanitas trial"
                />
              </Box>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button color="primary" onClick={handleClose}>
                {t(TranslationsEnum.Global_Cancel)}
              </Button>
              <Button color="primary" type="submit">
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(TranslationsEnum.Global_Save)
                )}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Fade>
    </Modal>
  );
};

export default ClinicForm;
