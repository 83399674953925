import React, { useMemo, useCallback, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
  MUIDataTableMeta
} from "mui-datatables";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip
} from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import DataTableFooter from "../../../components/DataTableFooter/DataTableFooter";
import { attachSortToColumns } from "../../../utils/helpers/filtersHelpers";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import { useTranslation } from "react-i18next";
import { ISessionsTableDTO } from "../../../types/tables/ISessionsTable";
import {
  getPatientSessionsForTableQueryForOperator,
  getPatientSessionsForTableQueryForPatient
} from "../../../queries/sessions";
import { prepareSessionDataForTable } from "../../../utils/helpers/parsingHelper";
import { ISelectRow } from "../../../types/tables/ISelectRow";
import { SortDirectionEnum } from "../../../types/enums/SortDirectionEnum";
import {
  selectSessionIds,
  selectSessionsTableConfig,
  selectSessionsChartConfig,
  selectUserProfile,
  selectSessionsChartHighlightedSessionId
} from "../../../utils/helpers/stateSelectorHelpers";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../state/store";
import { actions } from "../../../state/sessions/actions";
import { SessionSelectEnum } from "../../../types/enums/SessionSelectEnum";
import { textLabelsConfig } from "../../../constants/tableTranslationsConfig";
import { LanguageEnum } from "../../../types/enums/LanguageEnum";
import { ClaimsEnum, TranslationsEnum } from "../../../types/enums";
import useStyles from "./SessionsTable.styles";
import { defaultSessionIdIndex } from "../../../constants/defaultValues";
import EditSessionCommentsModal from "../../Modals/EditSesionComments/EditSessionCommentsModal";
import { ISessionGoal } from "../../../types/ISessionGoal";
import { IEditSessionGoalsDto } from "../../../types/requestDtos/IEditSessionGoalsDto";
import { setSessionGoalsMutation } from "../../../mutations/sessions";
import { SnackbarTypeEnum } from "../../../types/enums/SnackbarTypeEnum";
import { actions as snackbarActions } from "../../../state/snackbar/actions";

interface IProps {
  patientId: number;
}

const SessionsTable = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { patientId } = props;

  const {
    selectedSessionsIds,
    sessionsTableConfig,
    sessionsChartConfig,
    userProfile,
    chartHighlightedSessionId
  } = useSelector(state => ({
    ...selectSessionIds(state),
    ...selectSessionsTableConfig(state),
    ...selectSessionsChartConfig(state),
    ...selectUserProfile(state),
    ...selectSessionsChartHighlightedSessionId(state)
  }));

  const getPatientSessionsForTableQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getPatientSessionsForTableQueryForOperator(Number(patientId)!)
      : getPatientSessionsForTableQueryForPatient;

  const { data, loading, error, refetch } = useQuery<{
    sessionsTableDTO: ISessionsTableDTO;
  }>(getPatientSessionsForTableQuery, {
    variables: { sessionsTableConfig },
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(error);

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_HeaderColumns_DisplayId
      ),
      options: {
        customBodyRender: value => {
          const session = sessions.find(x => x.id === value);

          const valueToDisplay = `Session ${session?.sessionDisplayId}`;
          return (
            <Box>
              {session?.id === chartHighlightedSessionId ? (
                <Box className={classes.selectedText}>{valueToDisplay}</Box>
              ) : (
                <Box>{valueToDisplay}</Box>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: "sessionDate",
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_HeaderColumns_Date
      ),
      options: {
        customBodyRender: (value, tableMeta) => {
          const session = sessions.find(
            x => x.id === tableMeta.rowData[defaultSessionIdIndex]
          );

          return (
            <Box>
              {session?.id === chartHighlightedSessionId ? (
                <Box className={classes.selectedText}>{value}</Box>
              ) : (
                <Box>{value}</Box>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: "sessionTime",
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_HeaderColumns_Time
      ),
      options: {
        customBodyRender: (value, tableMeta) => {
          const session = sessions.find(
            x => x.id === tableMeta.rowData[defaultSessionIdIndex]
          );

          return (
            <Box>
              {session?.id === chartHighlightedSessionId ? (
                <Box className={classes.selectedText}>{value}</Box>
              ) : (
                <Box>{value}</Box>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: "sessionProcessingStatus",
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsTable_HeaderColumns_ProcessingStatus
      ),
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const session = sessions.find(
            x => x.id === tableMeta.rowData[defaultSessionIdIndex]
          );

          return (
            <Box>
              {session?.id === chartHighlightedSessionId ? (
                <Box className={classes.selectedText}>{value}</Box>
              ) : (
                <Box>{value}</Box>
              )}
            </Box>
          );
        }
      }
    },

    {
      name: "goal",
      label: "Goals",
      options: {
        sort: false,
        customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
          const session = sessions.find(
            x => x.id === tableMeta.rowData[defaultSessionIdIndex]
          );

          return (
            <Box>
              {session?.id === chartHighlightedSessionId ? (
                <Box className={classes.selectedText}>{value}</Box>
              ) : (
                <Box>{value}</Box>
              )}
            </Box>
          );
        }
      }
    },
    ...(userProfile?.clinicInSanitasTrial &&
    userProfile?.claimsType === ClaimsEnum.Operator
      ? [
          {
            name: "sessions",
            label: " ",
            options: {
              sort: false,
              customBodyRender: (
                value: number,
                tableMeta: MUIDataTableMeta
              ) => {
                const session = sessions.find(
                  x => x.id === tableMeta.rowData[defaultSessionIdIndex]
                );

                const shouldShowAlert =
                  session?.sessionsGoals.filter(
                    sessionGoal => sessionGoal.goal === null
                  ).length !== 0;

                return (
                  <Grid spacing={1} direction="row" container>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleShowEditSessionCommentsModal(
                          session?.sessionsGoals
                        )}
                      >
                        {t(
                          TranslationsEnum.Containers_Modals_SessionCommentsModal_ViewOrEditGoals
                        )}
                      </Button>
                    </Grid>

                    <Grid item>
                      {shouldShowAlert && (
                        <Tooltip
                          title={t(
                            TranslationsEnum.Containers_Modals_SessionCommentsModal_NoGoals
                          )}
                        >
                          <WarningRoundedIcon color="error" />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                );
              }
            }
          }
        ]
      : [])
  ];

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection:
          sessionsTableConfig.sortDirection || SortDirectionEnum.Ascending,
        name: sessionsTableConfig.sortField || "Id"
      }),
    [columns, sessionsTableConfig.sortDirection, sessionsTableConfig.sortField]
  );
  const [sessionGoals, setSessionGoals] = useState<
    ISessionGoal[] | undefined
  >();
  const [
    showEditSessionCommentsModal,
    setShowEditSessionCommentsModal
  ] = useState(false);
  const handleCloseEditSessionCommentsModal = () => {
    setShowEditSessionCommentsModal(false);
  };

  const handleShowEditSessionCommentsModal = (
    sessionGoals: ISessionGoal[] | undefined
  ) => () => {
    setSessionGoals(sessionGoals);
    setShowEditSessionCommentsModal(true);
  };

  const handleSessionCommentUpdateSuccess = () => {
    refetch();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_UpdateSessionCommentsSuccess)
      )
    );
  };

  const [
    editSessionGoals,
    { error: setSessionGoalsError, loading: setSessionGoalsLoading }
  ] = useMutation(setSessionGoalsMutation, {
    onError: () => {},
    onCompleted: handleSessionCommentUpdateSuccess
  });

  useErrorHandling(setSessionGoalsError);

  const handleSetSessionGoals = (sessionGoals: IEditSessionGoalsDto) => {
    editSessionGoals({
      variables: { values: sessionGoals }
    });
  };

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: sessionsTableConfig.page,
          changeRowsPerPage
        }}
      />
    ),
    [sessionsTableConfig.page]
  );

  const handlePageChange = (page: number) =>
    dispatch(actions.setTableConfigAction({ ...sessionsTableConfig, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    dispatch(
      actions.setTableConfigAction({
        ...sessionsTableConfig,
        sortField: sortField,
        sortDirection
      })
    );
  };

  const sessions = useMemo(() => data?.sessionsTableDTO.sessions, [data]) || [];
  const sessionsCount: number =
    useMemo(() => data?.sessionsTableDTO.count, [data]) || 0;

  const handleRowsClicked = useCallback(
    (sessionIds: Array<number>) => {
      dispatch(actions.setChartSelectedSessionBarIndexAction(0));
      dispatch(actions.setChartHighlightedSessionIdAction(undefined));
      dispatch(actions.setSelectedSessionIdsAction(sessionIds));
      dispatch(
        actions.setChartConfigAction({
          ...sessionsChartConfig,
          page: 0,
          begin: undefined,
          end: undefined,
          selectValue: SessionSelectEnum.CustomSelect,
          sessionIds: sessionIds
        })
      );
    },
    [dispatch, sessionsChartConfig]
  );

  const handleRowsSelect = useCallback(
    (currentRowsSelected: Array<ISelectRow>) => {
      // Checks if only one was clicked
      if (currentRowsSelected.length === 1) {
        const clickedSessionId = sessions[currentRowsSelected[0].index].id;

        if (selectedSessionsIds.includes(clickedSessionId)) {
          handleRowsClicked(
            selectedSessionsIds.filter(id => id !== clickedSessionId)
          );
        } else {
          handleRowsClicked([...selectedSessionsIds, clickedSessionId]);
        }
      }
      // Else means select / deselect all was clicked
      else {
        if (currentRowsSelected.length) {
          const newSessionIds = selectedSessionsIds;

          sessions.forEach(session => {
            if (!newSessionIds.includes(session.id)) {
              newSessionIds.push(session.id);
            }
          });

          handleRowsClicked(newSessionIds);
        } else {
          const newSessionIds = selectedSessionsIds;

          sessions.forEach(session => {
            const index = newSessionIds.indexOf(session.id);
            if (index !== -1) {
              newSessionIds.splice(index, 1);
            }
          });

          handleRowsClicked(newSessionIds);
        }
      }
    },
    [handleRowsClicked, selectedSessionsIds, sessions]
  );

  const rowsSelected: Array<number> = [];

  sessions.forEach((session, index) => {
    selectedSessionsIds.includes(session.id) && rowsSelected.push(index);
  });

  if ((!data && loading) || setSessionGoalsLoading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  const options: MUIDataTableOptions = {
    page: sessionsTableConfig.page,
    count: sessionsCount,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "multiple",
    serverSide: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    rowsPerPage: sessionsTableConfig.takePerPage,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange,
    sort: true,
    selectableRowsOnClick: true,
    disableToolbarSelect: true,
    onRowsSelect: handleRowsSelect,
    rowsSelected
  };

  return (
    <>
      <Box className={classes.table}>
        <MUIDataTable
          title={null}
          data={prepareSessionDataForTable(
            sessions,
            i18n.language as LanguageEnum
          )}
          columns={fullColumns}
          options={options}
        />
      </Box>
      <EditSessionCommentsModal
        open={showEditSessionCommentsModal}
        handleClose={handleCloseEditSessionCommentsModal}
        sessionGoals={sessionGoals}
        handleSetSessionGoals={handleSetSessionGoals}
      />
    </>
  );
};

export default SessionsTable;
