import { Box, Button, Paper, Typography } from "@material-ui/core";
import { TranslationsEnum } from "../../types/enums";
import React, { useCallback } from "react";
import useStyles from "./DownloadSuccessPage.styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";

const DownloadSuccessPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    history.push(PathNamesEnum.SyncMyoSuit);
  }, [history]);

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography
          variant="h6"
          className={clsx(classes.darkGrey, classes.header)}
        >
          {t(
            TranslationsEnum.Electron_Containers_PrepareSession_UploadToSDCardTitleElectron
          )}
        </Typography>
        <Box className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleOnClick}
          >
            {t(TranslationsEnum.Global_Done)}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DownloadSuccessPage;
