import React from "react";
import { Chip } from "@material-ui/core";
import useSyles from "./ChipNumber.styles";

interface IProps {
  label: number;
}

const ChipNumber: React.FC<IProps> = ({ label }) => {
  const classes = useSyles();

  switch (label) {
    case 1:
      return <Chip label={label} />;
    case 2:
      return <Chip className={classes.lightBlue} label={label} />;
    case 3:
      return <Chip className={classes.lightOrange} label={label} />;
    case 4:
      return <Chip className={classes.lightPurple} label={label} />;
    case 5:
      return <Chip className={classes.lightGreen} label={label} />;
    case 6:
      return <Chip className={classes.lightBlue} label={label} />;
    case 7:
      return <Chip className={classes.lightOrange} label={label} />;
    case 8:
      return <Chip className={classes.lightPurple} label={label} />;
    default:
      return <Chip label={label} />;
  }
};

export default ChipNumber;
