import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  sessionHeaderFilePrefix,
  sessionLogsFilePrefix,
  sessionFilesExtension
} from "../../../shared-constants/files";
import useStyles from "./DropzoneText.styles";

interface IProps {
  isDragActive: boolean;
}

export const DropzoneText: React.FC<IProps> = ({ isDragActive }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h5"
        className={clsx(classes.darkGrey, classes.header)}
      >
        {isDragActive
          ? t("Containers.SyncMyoSuit.DropHere")
          : t("Containers.SyncMyoSuit.ClickToUpload")}
      </Typography>
      <Typography
        variant="subtitle2"
        className={clsx(classes.lightGrey, classes.fontWeightRegular)}
      >
        {t("Containers.SyncMyoSuit.UploadTip")}
      </Typography>
      <Typography
        variant="subtitle2"
        className={clsx(classes.lightGrey, classes.fontWeightBold)}
      >
        {sessionHeaderFilePrefix}
        <span className={classes.fontWeightRegular}>00000-0</span>
        {sessionFilesExtension}
      </Typography>
      <Typography
        variant="subtitle2"
        className={clsx(classes.lightGrey, classes.fontWeightBold)}
      >
        {sessionLogsFilePrefix}
        <span className={classes.fontWeightRegular}>00000-0</span>
        {sessionFilesExtension}
      </Typography>
    </>
  );
};
