import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actions } from "../../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import OperatorForm from "../OperatorForm/OperatorForm";
import { operatorFormSchema } from "../OperatorForm/operatorFormSchema";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { TranslationsEnum } from "../../../../types/enums";
import { getClinicsOptionsQuery } from "../../../../queries/clinics";
import { IOption } from "../../../../types/formInputs/IOption";
import { IOperatorForm } from "../../../../types/forms/IOperatorForm";
import { createOperatorMutation } from "../../../../mutations/azureUsers";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetchOperators: (variables?: { filterConfig: IFilterConfig }) => void;
}

const CreateOperatorModal: React.FC<IProps> = ({
  open,
  refetchOperators,
  handleClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreateOperatorSuccess = () => {
    handleModalClose();
    refetchOperators();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_OperatorCreatedSuccessfully)
      )
    );
  };

  const { data: dataClinicsOptions, error: errorClinicsOptions } = useQuery<{
    clinicsOptions: IOption[];
  }>(getClinicsOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorClinicsOptions);

  const [createOperator, { loading, error }] = useMutation(
    createOperatorMutation,
    {
      onError: () => {},
      onCompleted: handleCreateOperatorSuccess
    }
  );

  useErrorHandling(error);

  const handleFormSubmit = (values: IOperatorForm) => {
    if (isValid) {
      createOperator({
        variables: { values }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const initialValues: IOperatorForm = {
    name: "",
    email: "",
    clinicId: 0,
    preferredLanguage: LanguageEnum.De
  };

  const clinicsOptions = dataClinicsOptions?.clinicsOptions || [];

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: operatorFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    setFieldValue,
    formTitle: t(TranslationsEnum.Global_NewOperator),
    handleSubmit,
    handleChange,
    handleClose: handleModalClose,
    clinicsOptions
  };

  return <OperatorForm {...props} />;
};

export default CreateOperatorModal;
