import React from "react";
import clsx from "clsx";
import { Box, Paper, Typography, Divider } from "@material-ui/core";
import { Formik } from "formik";
import StrapSizesForm from "./StrapSizesForm/StrapSizesForm";
import { useTranslation } from "react-i18next";
import { IStrapSizesValues } from "../../../types/formInputs/IStrapSizesValues";
import { useMutation } from "@apollo/react-hooks";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import {
  updatePatientsProfileStrapSizesMutation,
  updatePatientsProfileStrapSizesByAzureOidMutation
} from "../../../mutations/patients";
import { actions } from "../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../types/enums/SnackbarTypeEnum";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IPatientIdRouteParams } from "../../../types/routeParams/IPatientIdRouteParams";
import * as Yup from "yup";
import {
  RequestedClinicStatusEnum,
  TranslationsEnum,
  ClaimsEnum
} from "../../../types/enums";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import useStyles from "./StrapSizesCard.styles";

interface IProps {
  initialValues: IStrapSizesValues;
  focusedBox: number | undefined;
  hoveredBox: number | undefined;
  onSelectFieldClick: (number: number | undefined) => void;
  onSelectFieldHover: (number: number | undefined) => void;
  patientRequestedClinicStatus?: RequestedClinicStatusEnum;
}

const StrapSizesCard = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { patientId } = useParams<IPatientIdRouteParams>();
  const {
    initialValues,
    focusedBox,
    hoveredBox,
    onSelectFieldClick,
    onSelectFieldHover,
    patientRequestedClinicStatus
  } = props;

  const { userProfile } = useSelector(selectUserProfile);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;

  const dispatchSuccessSnackbar = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(
          TranslationsEnum.Containers_MyoSuitSetupTab_StrapSizesCard_SuccessMessage
        )
      )
    );
  };

  const [
    updatePatientsProfileStrapSizes,
    { loading: loadingUpdateStrapsOperator, error: errorUpdateStrapsOperator }
  ] = useMutation(updatePatientsProfileStrapSizesMutation(Number(patientId)), {
    onError: () => {},
    variables: { values: initialValues },
    onCompleted: dispatchSuccessSnackbar
  });

  useErrorHandling(errorUpdateStrapsOperator);

  const [
    updatePatientsProfileStrapSizesByAzureOid,
    { loading: loadingUpdateStrapsPatient, error: errorUpdateStrapsPatient }
  ] = useMutation(updatePatientsProfileStrapSizesByAzureOidMutation, {
    onError: () => {},
    variables: { values: initialValues },
    onCompleted: dispatchSuccessSnackbar
  });

  useErrorHandling(errorUpdateStrapsPatient);

  const handleSubmit = (values: IStrapSizesValues) => {
    if (isOperator) {
      updatePatientsProfileStrapSizes({
        variables: { values: values }
      });
    } else if (isPatient) {
      updatePatientsProfileStrapSizesByAzureOid({
        variables: { values: values }
      });
    }
  };

  const schema = Yup.object().shape({
    Size01: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size02: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size03: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size04: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size05: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size06: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required)),
    Size07: Yup.string()
      .typeError(t(TranslationsEnum.Validations_SelectFieldTypeError))
      .required(t(TranslationsEnum.Global_Required))
  });

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <Box className={classes.header}>
          <Typography
            variant="body2"
            className={clsx(classes.darkGrey, classes.descriptiveText)}
          >
            {t(
              TranslationsEnum.Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizes
            )}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.formContainer}>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {props => (
              <StrapSizesForm
                loading={
                  loadingUpdateStrapsOperator || loadingUpdateStrapsPatient
                }
                focusedBox={focusedBox}
                hoveredBox={hoveredBox}
                onSelectFieldClick={onSelectFieldClick}
                onSelectFieldHover={onSelectFieldHover}
                patientRequestedClinicStatus={patientRequestedClinicStatus}
                {...props}
              />
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default StrapSizesCard;
