import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import { contactEmailAddress } from "../../constants/contacts";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { useHistory } from "react-router-dom";
import useStyles from "./ErrorPage.styles";
import { TranslationsEnum } from "../../types/enums";

interface IProps {
  titleTextPath: TranslationsEnum;
  subtitleTextPath: TranslationsEnum;
  buttonTextPath?: TranslationsEnum;
  hasBackHomeButton?: boolean;
  hasContactButton?: boolean;
}

const ErrorPage: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const {
    titleTextPath,
    buttonTextPath,
    subtitleTextPath,
    hasBackHomeButton,
    hasContactButton
  } = props;

  const handleBackClick = () => history.push(PathNamesEnum.Home);

  return (
    <Box className={classes.root}>
      <Box>
        <Box>
          <ErrorOutlinedIcon className={classes.icon} />
          <Typography variant="h3" className={classes.heading}>
            {t(titleTextPath)}
          </Typography>
          <Typography variant="h6" className={classes.subHeading}>
            {t(subtitleTextPath)}
          </Typography>

          {hasBackHomeButton && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleBackClick}
            >
              {t(buttonTextPath || "")}
            </Button>
          )}

          {hasContactButton && (
            <Button
              component="a"
              href={`mailto:${contactEmailAddress}`}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t(buttonTextPath || "")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorPage;
