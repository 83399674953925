import gql from "graphql-tag";

export const getClinicsListQuery = gql`
  query getClinicsList {
    clinics @rest(type: "IClinic", path: "clinics") {
      id
      name
    }
  }
`;

export const getClinicsListFilteredQuery = gql`
  query getClinicsListFilteredQuery($filterConfig: IFilterConfig!) {
    clinicsTableDTO(filterConfig: $filterConfig)
      @rest(
        type: "clinicsTableDTO"
        path: "clinics/filtered"
        method: "POST"
        bodyKey: "filterConfig"
      ) {
      clinics
      count
    }
  }
`;

export const getClinicsOptionsQuery = gql`
  query getClinicsOptions {
    clinicsOptions @rest(type: "IOption", path: "clinics/options") {
      label
      value
    }
  }
`;

export const getClinicQuery = gql`
  query getClinic($values: Values!) {
    clinic(values: $values)
      @rest(type: "IClinic", path: "clinics/{args.values.id}") {
      id
      name
      address
      description
      status
      telephone
      inSanitasTrial
    }
  }
`;
