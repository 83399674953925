import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2)
  },
  title: {
    fontSize: 15,
    fontWeight: 500
  },
  dropdownArrowButton: {
    width: 42,
    alignItems: "center",
    border: `1px solid ${ColorEnum.LightGreyBorder}`,
    borderRadius: 4,
    justifyContent: "center",
    borderLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    cursor: "pointer"
  },
  collapsed: {
    color: ColorEnum.LightGreyIcons,

    "&:hover": {
      background: ColorEnum.Blue,
      color: ColorEnum.White
    }
  },
  expanded: {
    background: ColorEnum.Blue,
    color: ColorEnum.White
  },
  dropdownContainer: {
    width: 340,
    marginTop: theme.spacing(0.5)
  },
  dropdownList: {
    background: ColorEnum.White,
    width: 340,
    position: "absolute",
    flexDirection: "column"
  },
  error: {
    color: ColorEnum.Red,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  }
}));
