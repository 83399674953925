import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IWindow } from "../../../types/IWindow";
import ErrorModal from "../ErrorModal/ErrorModal";
import { RENDERER_EVENTS } from "../../../shared-constants/events";

declare const window: IWindow;

const ElectronErrorModal: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    setError(undefined);
  }, []);

  useEffect(() => {
    window.ipcRenderer.on(
      RENDERER_EVENTS.ON_ERROR,
      (event: Event, errorName: string) => {
        setError(t(`Electron.ErrorCodes.${errorName}`));
        setOpen(true);
      }
    );

    return () => {
      window.ipcRenderer.removeAllListeners(RENDERER_EVENTS.ON_ERROR);
    };
  }, [t]);

  return <ErrorModal open={open} handleClose={handleClose} message={error} />;
};

export default ElectronErrorModal;
