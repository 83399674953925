import { makeStyles, fade } from "@material-ui/core";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles(theme => {
  const focusedRangeColor = fade(theme.palette.primary.main, 0.3);
  const focusedRangeGradient = `linear-gradient(to right, ${focusedRangeColor}, ${focusedRangeColor})`;
  const transparentRangeGradient = `linear-gradient(to right, ${ColorEnum.LightBlack}, ${ColorEnum.LightBlack})`;
  return {
    dateRangePickerDialog: {
      "& .MuiPickersCalendar-transitionContainer": {
        minHeight: 218,
        marginTop: 10
      }
    },
    day: {
      width: 40,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: 0,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
      transition: "none",
      "&::after": {
        borderRadius: "100%",
        bottom: 0,
        boxSizing: "border-box",
        content: '""',
        height: 36,
        width: 36,
        left: 0,
        margin: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        transform: "scale(0)",
        zIndex: 2
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        "&::after": {
          backgroundColor: theme.palette.background.paper,
          border: `2px solid ${theme.palette.primary.main}`,
          bottom: -2,
          left: -2,
          height: 36,
          width: 36,
          right: -2,
          top: -2,
          boxSizing: "content-box",
          transform: "scale(1)"
        }
      },
      "& > .MuiIconButton-label": {
        zIndex: 3
      }
    },
    hidden: {
      opacity: 0,
      pointerEvents: "none"
    },
    current: {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    dayDisabled: {
      pointerEvents: "none",
      color: theme.palette.text.hint
    },
    focused: {
      color: theme.palette.primary.contrastText
    },
    focusedRange: {
      background: `${focusedRangeGradient} no-repeat 0/20px 40px, ${focusedRangeGradient} no-repeat 20px 0/20px 40px`,
      fontWeight: theme.typography.fontWeightMedium,
      width: 40,
      marginRight: 0,
      marginLeft: 0,
      borderRadius: 0
    },
    focusedFirst: {
      background: `${transparentRangeGradient} no-repeat 0/20px 40px,${focusedRangeGradient} no-repeat 20px 0/20px 40px`
    },
    focusedLast: {
      background: `${focusedRangeGradient} no-repeat 0/20px 40px,${transparentRangeGradient} no-repeat 20px 0/20px 40px`
    },
    beginCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    },
    endCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    }
  };
});
