import * as React from "react";
import { ISvgProps } from "../../types/ISvgProps";

const SitupsIcon = (props: ISvgProps) => {
  return (
    <svg width={36} height={36} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.822 3.92a2.92 2.92 0 11-5.841 0 2.92 2.92 0 015.841 0zm-3.34 5.545a1.783 1.783 0 00-3.268-1.244l-3.416 5.445A1.782 1.782 0 007.95 16.36c.108.055.23.086.359.086H12.5l-2.205 5.733a.99.99 0 00.924 1.346h2.434a.792.792 0 100-1.584h-1.57l2.206-5.734a.99.99 0 00-.924-1.345h-3.11l2.391-3.812h6.7a.792.792 0 000-1.584h-5.866zM4.666 6a.667.667 0 00-1.333 0v16.667a.667.667 0 101.333 0v-4h4.667v.666a.667.667 0 101.333 0v-.666c0-.737-.597-1.334-1.333-1.334H4.667V6z"
        fill="#195F93"
      />
    </svg>
  );
};

export default SitupsIcon;
