import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import { Box, Typography, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getPatientsCountQuery,
  getPendingPatientsCountQuery
} from "../../queries/patients";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { useQuery } from "@apollo/react-hooks";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import useStyles from "./Home.styles";
import { TranslationsEnum, PatientsColumnsEnum } from "../../types/enums";
import clsx from "clsx";

const Home: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: dataPatientsCount,
    error: errorPatientsCount,
    loading: loadingPatientsCount
  } = useQuery(getPatientsCountQuery, {
    fetchPolicy: "cache-and-network"
  });
  useErrorHandling(errorPatientsCount);
  const patientsCount = dataPatientsCount?.getPatientsCount.count;

  const {
    data: dataPendingPatientsCount,
    error: errorPendingPatientsCount,
    loading: loadingPendingPatientsCount
  } = useQuery(getPendingPatientsCountQuery, {
    fetchPolicy: "cache-and-network"
  });
  useErrorHandling(errorPendingPatientsCount);

  const pendingPatientsCount =
    dataPendingPatientsCount?.pendingPatientsCount?.pendingPatientsCount;

  const handleViewPatientsWithFilterButtonClick = () =>
    history.push(
      `${PathNamesEnum.Patients}?${PatientsColumnsEnum.IncludeRequested}=true`
    );

  const handleViewPatientsButtonClick = () =>
    history.push(
      `${PathNamesEnum.Patients}?${PatientsColumnsEnum.IncludeRequested}=false`
    );

  return (
    <Box className={classes.container}>
      {loadingPatientsCount || loadingPendingPatientsCount ? (
        <CircularProgress size={110} color="primary" />
      ) : (
        <>
          <PeopleIcon color="primary" className={classes.peopleIcon} />
          <Typography variant="h3" className={classes.welcome}>
            {t(TranslationsEnum.Containers_Home_Welcome)},
          </Typography>

          <Typography variant="body1" component="p" className={classes.text}>
            {Boolean(pendingPatientsCount) ? (
              <>
                {t(TranslationsEnum.Containers_Home_RequestsCountText, {
                  pendingPatientsCount
                })}{" "}
                {t(TranslationsEnum.Containers_Home_UsersCountText, {
                  patientsCount
                })}
              </>
            ) : (
              <>
                {t(TranslationsEnum.Containers_Home_RegisteredUsersText, {
                  patientsCount
                })}
              </>
            )}
          </Typography>
          <Box>
            {Boolean(pendingPatientsCount) && (
              <Button
                variant="contained"
                className={clsx(classes.newRequestButton, classes.button)}
                onClick={handleViewPatientsWithFilterButtonClick}
              >
                {t(TranslationsEnum.Containers_Home_ViewNewRequests)}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleViewPatientsButtonClick}
            >
              {t(TranslationsEnum.Containers_Home_ViewUsersButton)}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Home;
