import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";
import { drawerWidth } from "../../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    opacity: 0.85,
    color: ColorEnum.White,
    borderRight: "none"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    background: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      minHeight: theme.spacing(7)
    }
  },
  logo: {
    width: "60%",
    marginRight: "auto",
    paddingLeft: theme.spacing(1),
    cursor: "pointer"
  },
  list: {
    padding: 0
  },
  listItem: {
    borderBottom: `1px solid ${ColorEnum.ThirdWhite}`
  },
  selected: {
    backgroundColor: `${ColorEnum.SecondaryWhite} !important`
  },
  white: {
    color: ColorEnum.White
  },
  listItemText: {
    marginLeft: theme.spacing(1)
  },
  link: {
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: ColorEnum.White,
    paddingBottom: theme.spacing(0.5)
  },
  linksContainer: {
    marginTop: "auto",
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  badge: {
    background: ColorEnum.Orange
  },
  version: {
    marginTop: 10,
    color: theme.palette.grey["400"],
    alignSelf: "center",
    fontSize: 11
  },
  warningIcon: {
    color: ColorEnum.Orange,
    marginRight: -1
  },
  tooltip: {
    position: "relative"
  },
  tooltipText: {
    fontSize: 13
  }
}));
