import { FormikValues, Field } from "formik";
import React from "react";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";

interface IProps {
  values: FormikValues;
}

const GeneralFirst: React.FC<IProps> = ({ values }) => {
  const { GeneralQuestionsFirst } = values;
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Field
          fullWidth
          name="GeneralQuestionsFirst.height.value"
          type="number"
          component={TextField}
          margin="dense"
          variant={"outlined"}
          label={GeneralQuestionsFirst.height.label}
        />
      </Box>
      <Box>
        <Field
          fullWidth
          name="GeneralQuestionsFirst.weight.value"
          type="number"
          component={TextField}
          margin="dense"
          variant={"outlined"}
          label={GeneralQuestionsFirst.weight.label}
        />
      </Box>
      <Box>
        <Field
          fullWidth
          name="GeneralQuestionsFirst.reachScore.value"
          type="number"
          component={TextField}
          margin="dense"
          variant={"outlined"}
          label={GeneralQuestionsFirst.reachScore.label}
        />
      </Box>
      <Box>
        <Box m={2}>
          <Typography color="primary" variant="h5">
            {t(
              TranslationsEnum.Containers_Modals_Questionnaire_TickAllThatApply
            )}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Field
          name="GeneralQuestionsFirst.ankleContracture.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.ankleContracture.label
          }}
        />
      </Box>
      <Box>
        <Field
          name="GeneralQuestionsFirst.versusValgusDeformity.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.versusValgusDeformity.label
          }}
        />
      </Box>{" "}
      <Box>
        <Field
          name="GeneralQuestionsFirst.pregnant.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.pregnant.label
          }}
        />
      </Box>{" "}
      <Box>
        <Field
          name="GeneralQuestionsFirst.hearing.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.hearing.label
          }}
        />
      </Box>{" "}
      <Box>
        <Field
          name="GeneralQuestionsFirst.sagittalPlane.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.sagittalPlane.label
          }}
        />
      </Box>
      <Box>
        <Field
          name="GeneralQuestionsFirst.canStand.value"
          type="checkbox"
          color="primary"
          component={CheckboxWithLabel}
          Label={{
            label: GeneralQuestionsFirst.canStand.label
          }}
        />
      </Box>
    </>
  );
};

export default GeneralFirst;
