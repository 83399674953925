import React from "react";
import { Grid } from "@material-ui/core";
import OverallSessionsOverview from "./OverallSessionsOverview/OverallSessionsOverview";
import SessionsChart from "./SessionsChart/SessionsChart";
import SessionsTable from "./SessionsTable/SessionsTable";

interface IProps {
  patientId: number;
}

const SessionsTab = (props: IProps) => {
  const { patientId } = props;

  return (
    <Grid container spacing={5}>
      <OverallSessionsOverview patientId={patientId} />
      <Grid item lg={12} md={12} xs={12}>
        <SessionsChart patientId={patientId} />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <SessionsTable patientId={patientId} />
      </Grid>
    </Grid>
  );
};

export default SessionsTab;
