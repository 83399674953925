import React, { useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import StrapSizesCard from "./StrapSizesCard/StrapSizesCard";
import AssistanceLevelsCard from "./AssistanceLevelsCard/AssistanceLevelsCard";
import { IStrapSizesValues } from "../../types/formInputs/IStrapSizesValues";
import {
  getPatientsProfileQueryForOperator,
  getPatientsProfileQueryForPatient
} from "../../queries/patients";
import { useQuery } from "@apollo/react-hooks";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IAssistanceLevelsValues } from "../../types/formInputs/IAssistanceLevelsValues";
import MyoSuitCalibrationCard from "./MyoSuitCalibrationCard/MyoSuitCalibrationCard";
import { useSelector } from "../../state/store";
import { selectUserProfile } from "../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum } from "../../types/enums";
import { RequestedClinicStatusEnum } from "../../types/enums/RequestedClinicStatusEnum";

interface IProps {
  patientId: number;
  patientRequestedClinicStatus?: RequestedClinicStatusEnum;
}

const MyoSuitSetupTab = (props: IProps) => {
  const { patientId, patientRequestedClinicStatus } = props;

  const [focusedBox, setFocusedBox] = useState<number | undefined>();
  const [hoveredBox, setHoveredBox] = useState<number | undefined>();

  const { userProfile } = useSelector(selectUserProfile);

  const getPatientsProfileQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getPatientsProfileQueryForOperator(Number(patientId)!)
      : getPatientsProfileQueryForPatient;

  const { loading, data, error } = useQuery(getPatientsProfileQuery, {
    fetchPolicy: "cache-and-network"
  });
  useErrorHandling(error);

  const initialValuesStrapSizes: IStrapSizesValues = {
    Size01: data?.getPatientsProfile.size01,
    Size02: data?.getPatientsProfile.size02,
    Size03: data?.getPatientsProfile.size03,
    Size04: data?.getPatientsProfile.size04,
    Size05: data?.getPatientsProfile.size05,
    Size06: data?.getPatientsProfile.size06,
    Size07: data?.getPatientsProfile.size07
  };

  const initialValuesAssistanceLevels: IAssistanceLevelsValues = {
    ConcFR: data?.getPatientsProfile.concFR,
    ConcFL: data?.getPatientsProfile.concFL,
    EccFR: data?.getPatientsProfile.eccFR,
    EccFL: data?.getPatientsProfile.eccFL
  };

  const handleFocusedBoxSelect = (number: number | undefined) =>
    setFocusedBox(number);

  const handleHoveredBoxSelect = (number: number | undefined) =>
    setHoveredBox(number);

  return (
    <>
      {loading ? (
        <CircularProgress size={110} color="primary" />
      ) : (
        <Grid container spacing={5}>
          <Grid item lg={4} md={6} xs={12}>
            <MyoSuitCalibrationCard
              patientId={patientId}
              focusedBox={focusedBox}
              hoveredBox={hoveredBox}
              onBoxClick={handleFocusedBoxSelect}
              onBoxHover={handleHoveredBoxSelect}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <StrapSizesCard
              initialValues={initialValuesStrapSizes}
              focusedBox={focusedBox}
              hoveredBox={hoveredBox}
              onSelectFieldClick={handleFocusedBoxSelect}
              onSelectFieldHover={handleHoveredBoxSelect}
              patientRequestedClinicStatus={patientRequestedClinicStatus}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <AssistanceLevelsCard
              initialValues={initialValuesAssistanceLevels}
              patientRequestedClinicStatus={patientRequestedClinicStatus}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MyoSuitSetupTab;
