import { ActionsUnion } from "../../utils/typings/common";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";

// Action types
export const SHOW_SNACKBAR = "snackbar/SHOW_SNACKBAR";
export const CLOSE_SNACKBAR = "snackbar/CLOSE_SNACKBAR";

// Action creators
export const actions = {
  showSnackbar: (type: SnackbarTypeEnum, message: string) => ({
    type: SHOW_SNACKBAR as typeof SHOW_SNACKBAR,
    payload: {
      type,
      message
    }
  }),
  closeSnackbar: () => ({
    type: CLOSE_SNACKBAR as typeof CLOSE_SNACKBAR
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
