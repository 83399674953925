import React, { useState, useMemo, useEffect } from "react";
import { Paper, Divider, Grid, Typography } from "@material-ui/core";
import ChartHeader from "./ChartHeader/ChartHeader";
import ChartBody from "./ChartBody/ChartBody";
import { useTranslation } from "react-i18next";
import ChartFooter from "./ChartFooter/ChartFooter";
import { ISessionData } from "../../../types/ISessionData";
import { SessionSelectEnum } from "../../../types/enums/SessionSelectEnum";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import { useQuery } from "@apollo/react-hooks";
import {
  getPatientSessionsForChartQueryForOperator,
  getPatientSessionsForChartQueryForPatient
} from "../../../queries/sessions";
import { DateRangePicker } from "../../../components";
import { DateRange } from "../../../components/DateRangePicker/DateRangePicker";
import { removeUnusedFractionsForSessionData } from "../../../utils/helpers/parsingHelper";
import { ISessionsForChart } from "../../../types/ISessionsForChart";
import { useSelector } from "../../../state/store";
import {
  selectSessionsChartSelectedSessionBarIndex,
  selectSessionsChartConfig,
  selectUserProfile
} from "../../../utils/helpers/stateSelectorHelpers";
import { useDispatch } from "react-redux";
import { actions } from "../../../state/sessions/actions";
import { ClaimsEnum } from "../../../types/enums";
import useStyles from "./SessionsChart.styles";

interface IProps {
  patientId: number;
}

const SessionsChart = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { patientId } = props;

  const {
    selectedSessionBarIndex,
    sessionsChartConfig,
    userProfile
  } = useSelector(state => ({
    ...selectSessionsChartSelectedSessionBarIndex(state),
    ...selectSessionsChartConfig(state),
    ...selectUserProfile(state)
  }));

  // Sessions
  const handleQueryCompletedCallback = (data: {
    getPatientSessions: ISessionsForChart;
  }) => {
    if (sessionsChartConfig.selectValue !== SessionSelectEnum.CustomSelect) {
      dispatch(
        actions.setSelectedSessionIdsAction(
          data?.getPatientSessions.allSessionsIds
        )
      );
    }
    dispatch(
      actions.setChartHighlightedSessionIdAction(
        data.getPatientSessions.sessions[0]?.id
      )
    );
  };

  const getPatientSessionsForChartQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getPatientSessionsForChartQueryForOperator(Number(patientId)!)
      : getPatientSessionsForChartQueryForPatient;

  const { data, error, loading, refetch } = useQuery<{
    getPatientSessions: ISessionsForChart;
  }>(getPatientSessionsForChartQuery, {
    variables: { sessionsChartConfig },
    fetchPolicy: "cache-and-network",
    onCompleted: handleQueryCompletedCallback
  });

  let sessions: ISessionData[] =
    useMemo(() => data?.getPatientSessions.sessions, [data]) || [];

  if (sessions) {
    sessions = removeUnusedFractionsForSessionData(sessions);
  }

  const sessionsCount: number =
    useMemo(() => data?.getPatientSessions.count, [data]) || 0;

  useErrorHandling(error);

  useEffect(() => {
    refetch();
  }, [refetch, sessionsChartConfig]);
  // Sessions

  // Chart Bars
  let lastPage = Math.ceil(sessionsCount / sessionsChartConfig.takePerPage);
  if (lastPage > 0) {
    lastPage -= 1;
  }
  // Chart Bars

  // Calendar
  const [openCalendarControl, setOpenCalendarControl] = useState(false);
  const handleCloseCalendar = () => setOpenCalendarControl(false);

  const handleDateRangeChange = (range: DateRange) => {
    dispatch(
      actions.setChartConfigAction({
        ...sessionsChartConfig,
        page: 0,
        selectValue: SessionSelectEnum.CustomPeriod,
        begin: range.begin?.toDateString(),
        end: range.end?.toDateString()
      })
    );
  };
  // Calendar

  return (
    <>
      <Paper className={classes.root}>
        {!loading && (
          <ChartHeader
            setOpenCalendarControl={setOpenCalendarControl}
            defaultSelectedSessionId={sessions[0]?.id}
          />
        )}
        <Divider className={classes.divider} />
        <ChartBody loading={loading} lastPage={lastPage} sessions={sessions} />
        {sessions && sessions.length > 0 ? (
          <ChartFooter session={sessions[selectedSessionBarIndex]} />
        ) : (
          <Grid container spacing={0}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.sessionTitle}
            >
              <Typography variant="caption" className={classes.title}>
                {t(
                  "Containers.SessionsTab.SessionsChart.ChartFooter.NoSessions"
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
      <DateRangePicker
        open={openCalendarControl}
        placeholder="Select a date range"
        onClose={handleCloseCalendar}
        onChange={handleDateRangeChange}
        TextFieldComponent={() => null}
      />
    </>
  );
};

export default SessionsChart;
