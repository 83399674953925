import React, { useState, useEffect, useCallback } from "react";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn
} from "mui-datatables";
import { IWindow } from "../../types/IWindow";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
  Button
} from "@material-ui/core";
import { IDisk } from "../../types/IDisk";
import useStyles from "./DriveSelect.styles";
import { useTranslation } from "react-i18next";
import { Replay } from "@material-ui/icons";
import ElectronErrorModal from "../Modals/ElectronErrorModal/ElectronErrorModal";
import DefaultSDCardModal from "../Modals/DefaultSDCardModal/DefaultSDCardModal";
import { RENDERER_EVENTS } from "../../shared-constants/events";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { TranslationsEnum } from "../../types/enums";
const {
  sessionHeaderFilePrefix,
  sessionLogsFilePrefix
} = require("../../shared-constants/files");

declare const window: IWindow;

interface IProps {
  onDriveSelect: (drive: IDisk) => void;
}

const DriveSelect: React.FC<IProps> = ({ onDriveSelect }) => {
  const [disks, setDisks] = useState<IDisk[]>([]);
  const [loading, setLoading] = useState(false);
  const [defaultDisk, setDefaultDisk] = useState<IDisk | null>(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const loadDisks = () => {
    setLoading(true);
    window.readDisks();
  };

  useEffect(() => {
    window.ipcRenderer.on(
      RENDERER_EVENTS.READ_DISKS_REPLY,
      (event: Event, reply: IDisk[]) => {
        setDisks(reply);
        const defaultExists = reply.find(disk => {
          return !!disk.myoFiles.find(
            file =>
              file.filename.includes(sessionHeaderFilePrefix) ||
              file.filename.includes(sessionLogsFilePrefix)
          );
        });
        if (defaultExists) {
          setDefaultDisk(defaultExists);
        }
        setLoading(false);
      }
    );

    loadDisks();

    return () => {
      window.ipcRenderer.removeAllListeners(RENDERER_EVENTS.READ_DISKS_REPLY);
    };
  }, []);

  const selectDrive = useCallback(
    (drive: IDisk) => {
      if (onDriveSelect) {
        onDriveSelect(drive);
      }
    },
    [onDriveSelect]
  );

  const handleRowClick = useCallback(
    (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      const disk = disks[rowMeta.dataIndex];
      if (disk) {
        selectDrive(disk);
      }
    },
    [disks, selectDrive]
  );

  const handleSyncBtnClick = useCallback(
    (value: string) => () => {
      const disk = disks.find(disk => disk.path === value);
      if (disk) {
        selectDrive(disk);
      }
    },
    [disks, selectDrive]
  );

  const handleAccept = () => {
    if (defaultDisk) {
      selectDrive(defaultDisk);
    }
  };

  const handleClose = () => {
    setDefaultDisk(null);
  };

  const options: MUIDataTableOptions = {
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: false,
    sort: false,
    pagination: false,
    onRowClick: handleRowClick
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "label",
      label: t(
        TranslationsEnum.Electron_Containers_SyncMyoSuit_HeaderColumns_DeviceName
      )
    },
    {
      name: "path",
      label: t(
        TranslationsEnum.Electron_Containers_SyncMyoSuit_HeaderColumns_Drive
      )
    },
    {
      name: "path",
      label: " ",
      options: {
        customBodyRender: value => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                className={classes.syncBtn}
                onClick={handleSyncBtnClick(value)}
              >
                <SyncAltIcon className={classes.syncIcon} /> Sync
              </Button>
            </Box>
          );
        }
      }
    }
  ];

  const title = (
    <Box display="flex" className={classes.title}>
      <Typography variant="h5">
        {t(TranslationsEnum.Electron_Containers_SyncMyoSuit_StorageDevices)}
      </Typography>
      <Tooltip
        title={t(
          TranslationsEnum.Electron_Containers_SyncMyoSuit_ReloadDevices
        )}
      >
        <span>
          <IconButton
            className={classes.reloadIcon}
            color="primary"
            onClick={loadDisks}
          >
            <Replay />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  return (
    <Box px={3} pt={4} className={classes.container}>
      <MUIDataTable
        title={title}
        data={disks}
        columns={columns}
        options={options}
      />
      <ElectronErrorModal />
      <DefaultSDCardModal
        defaultDisk={defaultDisk}
        handleAccept={handleAccept}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default DriveSelect;
