import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FilesListItem from "./FilesListItem/FilesListItem";
import { IFiles } from "../../../../types/files/IFiles";
import useStyles from "./FilesList.styles";
import { TranslationsEnum } from "../../../../types/enums";
import { isElectron } from "../../../../utils/helpers/electronHelpers";

interface IProps {
  files: IFiles;
  isFinishedUploading: boolean;
}

const FilesList: React.FC<IProps> = ({ files, isFinishedUploading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (isElectron()) {
      if (isFinishedUploading) {
        return t(TranslationsEnum.Electron_Containers_SyncMyoSuit_DataUploaded);
      }
      return t(TranslationsEnum.Electron_Containers_SyncMyoSuit_DataUploading);
    }
    return t(TranslationsEnum.Containers_SyncMyoSuit_UploadedFiles);
  }, [isFinishedUploading, t]);
  return (
    <>
      <Box borderBottom={1} display="flex" className={classes.listItem}>
        <Typography
          variant="h6"
          className={clsx(classes.darkGrey, classes.header)}
        >
          {title}
        </Typography>
      </Box>
      {files.filenames.map(name => (
        <FilesListItem fileInfo={files.filesByNames[name]} key={name} />
      ))}
    </>
  );
};

export default FilesList;
