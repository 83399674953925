export enum PathNamesEnum {
  Home = "/",
  Patients = "/users",
  PatientsAdmin = "/usersAdmin",
  Operators = "/operators",
  Units = "/units",
  Contact = "/contact",
  Patient = "/users/:patientId",
  SyncMyoSuit = "/sync-myo-suit",
  DownloadDesktopApp = "/download-desktop-app",
  PrepareSession = "/prepare-session",
  NoConnection = "/contact-no-connection",
  TermsOfAgreements = "/dpa",
  TermsOfAgreementsWithGUID = "/dpa/:DPAGUID",
  PatientRegistration = "/patientRegistration",
  RegistrationKey = "/registration-key",
  Forbidden = "/forbidden",
  DownloadSuccessPage = "/download-success",
  NoSuitInstructions = "/no-suit-instructions",
  Messages = "/messages",
  Message = "/messages/:messageId",
  CreateMessage = "/messages/create",
  EditMessage = "/messages/edit/:messageId",
  Clinics = "/clinics",
  CostApproval = "/costApproval/:patientId",
  CostApprovalBase = "/costApproval"
}
