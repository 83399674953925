import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MyoSwissLogoWhite from "../../assets/myoswiss-logo-white.png";
import {
  Typography,
  Paper,
  Box,
  Button,
  CircularProgress,
  Link,
  Modal,
  Backdrop,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useParams, useHistory } from "react-router-dom";
import { IDPAGUIDRouteParams } from "../../types/routeParams/IDPAGUIDRouteParams";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { patientAcceptDPAMutation } from "../../mutations/patients";
import { ISetDPADto } from "../../types/requestDtos/ISetDPADto";
import LanguageMenu from "../../components/LanguageMenu/LanguageMenu";
import { IPatientDPA } from "../../types/IPatientDPA";
import { getPatientDPAQuery } from "../../queries/patients";
import { contactEmailAddress, mainPage } from "../../constants/contacts";
import { getSelectedLanguage } from "../../utils/helpers/languageHelpers";
import { LanguageEnum } from "../../types/enums/LanguageEnum";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import { languagesDPA, languageNumberPairs } from "../../constants/languages";
import useStyles from "./PageTermsOfAgreements.styles";
import { FinishRegistrationModal } from "../Modals";
import { actions as userActions } from "../../state/user/actions";
import { useDispatch } from "react-redux";
import { getUserProfileQuery } from "../../queries";
import { IUserProfile } from "../../types/IUserProfile";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { authProviderSignUpSignIn } from "../../utils/configs/authProvider";
import { TranslationsEnum } from "../../types/enums";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const PageTermsOfAgreements = () => {
  const selectedLanguage = getSelectedLanguage();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showFader, setShowFader] = useState(true);
  const [open, setOpen] = useState(false);
  const { DPAGUID } = useParams<IDPAGUIDRouteParams>();
  const [isRefetched, setIsRefetched] = useState(false);
  const [isDPAAccepted, setIsDPAAccepted] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const { data: userProfileData } = useQuery<{
    userProfile: IUserProfile;
  }>(getUserProfileQuery, {
    fetchPolicy: "network-only",
    onCompleted: () =>
      dispatch(userActions.setUserProfileAction(userProfileData?.userProfile))
  });

  const handleGetPatientDPACompleted = ({
    patientDPA
  }: {
    patientDPA: IPatientDPA;
  }) => {
    if (!isRefetched) {
      const language =
        languageNumberPairs.find(x => x.index === patientDPA.preferredLanguage)
          ?.language || LanguageEnum.De;

      i18n.changeLanguage(language);
    }
  };

  const { data, loading, error: getDpaError, refetch } = useQuery<{
    patientDPA: IPatientDPA;
  }>(getPatientDPAQuery(DPAGUID), {
    fetchPolicy: "cache-and-network",
    onCompleted: handleGetPatientDPACompleted
  });

  const [
    patientAcceptDPA,
    { error: patientAcceptDPAMutationError }
  ] = useMutation(patientAcceptDPAMutation, {
    onError: () => {},
    onCompleted: () => {
      if (!patientAcceptDPAMutationError) {
        setIsRefetched(true);
        refetch();

        //checks if the user is logged in, meaning he created his own account
        if (userProfileData?.userProfile) {
          if (!isDPAAccepted) {
            dispatch(userActions.setUserProfileAction(null));
            history.push(PathNamesEnum.Home);
            authProviderSignUpSignIn.logout();
          } else {
            history.push(PathNamesEnum.Home);
          }
        }
        // else means the user is accepting dpa from email when operator created his account
        else if (isDPAAccepted) {
          setFinishRegistrationModalOpen(true);
        }
      }
    }
  });

  //TODO: add error handling, when we have more public pages and we can see the structure of them

  //TODO: fix being disabled bug, when screen size is at some particular size and buttons are always disabled
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (
        -1 * currPos.y + window.innerHeight >=
        document.body.scrollHeight - 70
      ) {
        setIsDisabled(false);
        setShowFader(false);
      } else {
        setShowFader(true);
      }
    },
    [],
    undefined,
    false,
    200
  );

  useEffect(() => {
    if (window.innerHeight > document.body.scrollHeight && !loading) {
      setIsDisabled(false);
      setShowFader(false);
    }
  }, [loading]);

  const handleSetDPAClick = (isAgreed: boolean) => () => {
    const requestDto: ISetDPADto = {
      DPAGUID,
      isAgreed
    };

    patientAcceptDPA({
      variables: { requestDto }
    });

    if (open) {
      handleClose();
    }
    setIsDPAAccepted(isAgreed);
    if (userProfileData?.userProfile) {
      dispatch(userActions.setDPA(isAgreed));
    }
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [
    isFinishRegistrationModalOpen,
    setFinishRegistrationModalOpen
  ] = React.useState(false);

  const handleFinishRegistrationModalClose = () =>
    setFinishRegistrationModalOpen(false);

  const { logout } = authProviderSignUpSignIn;
  const handleLogout = useCallback(() => logout(), [logout]);

  const renderBottomToolbar = () => {
    if (data?.patientDPA.dataProcessingAgreement === null) {
      return (
        <>
          <Button
            onClick={handleOpen}
            disabled={isDisabled}
            className={classes.disagreeButton}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_IDisagree)}
          </Button>
          <Button
            onClick={handleSetDPAClick(true)}
            disabled={isDisabled}
            variant="contained"
            className={classes.agreeButton}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_IAgree)}
          </Button>
        </>
      );
    } else if (data?.patientDPA.dataProcessingAgreement) {
      return (
        <>
          <Typography
            variant="body2"
            className={clsx(classes.bottomText, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_DPAAgreed)}
            <Link
              href={
                LanguageEnum.En === selectedLanguage
                  ? `${mainPage}en`
                  : mainPage
              }
              target={"_blank"}
              underline="always"
              className={classes.link}
            >
              {mainPage}
            </Link>
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography
            variant="body2"
            className={clsx(classes.bottomText, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_DPADisagreed)}
            <Link
              href={`mailto:${contactEmailAddress}`}
              className={classes.link}
            >
              {contactEmailAddress}
            </Link>
          </Typography>
        </>
      );
    }
  };

  const renderMain = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" pt={12.5}>
          <CircularProgress size={110} color="primary" />
        </Box>
      );
    }

    if (getDpaError) {
      return (
        <Box textAlign="center">
          <Box>
            <ErrorOutlinedIcon className={classes.icon} />
            <Typography variant="h3" className={classes.heading}>
              {t(TranslationsEnum.PageTermsOfAgreements_LinkNoLongerAvailable)}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Paper className={classes.container}>
          <Typography variant="h6" align="center" className={classes.title}>
            {t(TranslationsEnum.PageTermsOfAgreements_Title)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph1)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle1)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph2)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph3)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph4)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph5)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle2)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph6)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle3)}
            </strong>
          </Typography>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph1Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph1Part2)}
            </Typography>
          </Box>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph2Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph2Part2)}
            </Typography>
          </Box>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph7)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle4)}
            </strong>
          </Typography>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph3Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph3Part2)}
            </Typography>
          </Box>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph4Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph4Part2)}
            </Typography>
          </Box>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph8)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle5)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph9)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle6)}
            </strong>
          </Typography>

          <Typography variant="body2" className={classes.text}>
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph10)}
          </Typography>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph5Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph5Part2)}
            </Typography>
          </Box>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph6Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph6Part2)}
            </Typography>
          </Box>

          <Box className={clsx(classes.marginBottom1, classes.row)}>
            <FiberManualRecordIcon className={classes.bulletPoint} />
            <Typography variant="body2" className={classes.text}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph7Part1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_Subparagraph7Part2)}
            </Typography>
          </Box>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph11)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph12)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle7)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph13)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph14)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle8)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph15)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle9)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph16)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle10)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph17)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph18)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph19)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph20)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle11)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph21)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph22)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph23)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph24)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph25)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph26)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph27)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph28)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle12)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph29)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph30)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle13)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph31)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle14)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom3, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Paragraph32)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            <strong>
              {t(TranslationsEnum.PageTermsOfAgreements_Subtitle15)}
            </strong>
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts1)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts2)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts3)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts4)}
          </Typography>

          <Box className={classes.marginBottom1}>
            <Link href={`mailto:${contactEmailAddress}`}>
              {contactEmailAddress}
            </Link>
          </Box>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts5)}
          </Typography>

          <Typography
            variant="body2"
            className={clsx(classes.marginBottom1, classes.text)}
          >
            {t(TranslationsEnum.PageTermsOfAgreements_Contacts6)}
          </Typography>
        </Paper>
        <AppBar position="fixed" className={classes.bottomAppBar}>
          <Toolbar className={classes.toolbar}>{renderBottomToolbar()}</Toolbar>
        </AppBar>
        {showFader && <Box className={classes.fader} />}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 400
          }}
        >
          <Paper className={classes.modalPaper}>
            <Typography className={classes.marginBottom3}>
              <strong>
                {t(TranslationsEnum.PageTermsOfAgreements_DisagreeModalPart1)}
              </strong>{" "}
              {t(TranslationsEnum.PageTermsOfAgreements_DisagreeModalPart2)}
            </Typography>
            <Box display="flex">
              <Button
                onClick={handleSetDPAClick(false)}
                className={clsx(classes.disagreeButton, classes.marginLeftAuto)}
              >
                {t(TranslationsEnum.PageTermsOfAgreements_IDisagree)}
              </Button>
              <Button
                onClick={handleSetDPAClick(true)}
                variant="contained"
                className={classes.agreeButton}
              >
                {t(TranslationsEnum.PageTermsOfAgreements_IAgree)}
              </Button>
            </Box>
          </Paper>
        </Modal>
      </>
    );
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar} variant="dense">
          <img
            src={MyoSwissLogoWhite}
            alt="myo swiss logo"
            className={classes.logo}
          />
          <Box className={classes.languageMenu}>
            <LanguageMenu languages={languagesDPA} />
          </Box>
          <Tooltip title="Logout" className={classes.logoutButton}>
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon className={classes.white} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <FinishRegistrationModal
        guid={DPAGUID}
        open={isFinishRegistrationModalOpen}
        handleClose={handleFinishRegistrationModalClose}
      />
      {renderMain()}
    </>
  );
};

export default PageTermsOfAgreements;
