import { IRootState } from "../../state/store";

export const selectHeaderOpen = (state: IRootState) => ({
  isDrawerOpen: state.header.isOpen
});

export const selectToken = (state: IRootState) =>
  state.auth.aadResponse?.jwtIdToken;

export const selectSessionIds = (state: IRootState) => ({
  selectedSessionsIds: state.sessions.sessionsIds
});

export const selectSessionsTableConfig = (state: IRootState) => ({
  sessionsTableConfig: state.sessions.tableConfig
});

export const selectActiveColumns = (state: IRootState) => ({
  activeColumns: state.sessions.chartActiveColumns
});

export const selectSessionsChartConfig = (state: IRootState) => ({
  sessionsChartConfig: state.sessions.chartConfig
});

export const selectSessionsChartSelectedSessionBarIndex = (
  state: IRootState
) => ({
  selectedSessionBarIndex: state.sessions.chartSelectedSessionBarIndex
});

export const selectSessionsChartBarIndex = (state: IRootState) => ({
  selectedSessionBarIndex: state.sessions.chartSelectedSessionBarIndex
});

export const selectPreviousPatientId = (state: IRootState) => ({
  previousPatientId: state.sessions.patientId
});

export const selectUserProfile = (state: IRootState) => ({
  userProfile: state.user.userProfile
});

export const selectSelectedDrive = (state: IRootState) => ({
  selectedDrive: state.electron.selectedDrive
});

export const selectPreselectedUnit = (state: IRootState) => ({
  preselectedUnitSerialNumber: state.syncSuit.preselectedUnitSerialNumber
});

export const selectSessionsChartHighlightedSessionId = (state: IRootState) => ({
  chartHighlightedSessionId: state.sessions.chartHighlightedSessionId
});
