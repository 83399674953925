import { IAzureUser } from "../../types/IAzureUser";

export const SortAzureUsersByProperty = (
  array: IAzureUser[] | null | undefined,
  property: keyof IAzureUser
): IAzureUser[] => {
  if (!array) {
    return [];
  }

  const compare = (a: IAzureUser, b: IAzureUser) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  };

  return array.sort(compare);
};
