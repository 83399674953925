import { Route, Redirect, RouteProps, StaticContext } from "react-router";
import React, { useMemo } from "react";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import { IUserProfile } from "../../types/IUserProfile";
import { ClaimsEnum } from "../../types/enums";
import { RouteComponentProps } from "react-router-dom";
import { defaultClinicId } from "../../constants/defaultValues";

interface IProps extends RouteProps {
  userProfile: IUserProfile | undefined | null;
  claims?: ClaimsEnum | ClaimsEnum[];
}

const PrivateRoute = (props: IProps) => {
  const { component, userProfile, claims, ...rest } = props;

  const isUserAssignedToDefaultClinic =
    userProfile?.clinicId === defaultClinicId;
  const isUserAssignedToClinic = Boolean(userProfile?.clinicId);
  const isUserFound = Boolean(userProfile?.id);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;

  const Component: any = component;

  const hasPermission = useMemo(() => {
    if (!claims) {
      return true;
    }

    if (
      Array.isArray(claims) &&
      userProfile?.claimsType !== null &&
      userProfile?.claimsType !== undefined &&
      claims.includes(userProfile?.claimsType)
    ) {
      return true;
    }

    if (userProfile?.claimsType && claims === userProfile.claimsType) {
      return true;
    }

    return false;
  }, [claims, userProfile]);

  const renderRoute = (
    renderProps: RouteComponentProps<any, StaticContext, {} | null | undefined>
  ) => {
    if (!isUserFound) {
      return (
        <Redirect
          to={{
            pathname: PathNamesEnum.RegistrationKey,
            state: { from: props.location }
          }}
        />
      );
    } else if (
      (!isUserAssignedToClinic || isUserAssignedToDefaultClinic) &&
      isOperator
    ) {
      return (
        <Redirect
          to={{
            pathname: PathNamesEnum.Contact,
            state: { from: props.location }
          }}
        />
      );
    } else if (!hasPermission) {
      return (
        <Redirect
          to={{
            pathname: PathNamesEnum.Forbidden,
            state: { from: props.location }
          }}
        />
      );
    }

    return <Component {...renderProps} />;
  };

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;
