import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actions } from "../../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import OperatorForm from "../OperatorForm/OperatorForm";
import { operatorFormSchema } from "../OperatorForm/operatorFormSchema";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { TranslationsEnum } from "../../../../types/enums";
import { getClinicsOptionsQuery } from "../../../../queries/clinics";
import { IOption } from "../../../../types/formInputs/IOption";
import { IOperatorForm } from "../../../../types/forms/IOperatorForm";
import { updateOperatorMutation } from "../../../../mutations";
import { getOperatorQuery } from "../../../../queries";
import { IOperatorDto } from "../../../../types/IOperatorDto";
import { languageNumberPairs } from "../../../../constants/languages";

interface IProps {
  open: boolean;
  operatorAzureId: number | null;
  handleClose: () => void;
  refetchOperators: (variables?: { filterConfig: IFilterConfig }) => void;
}

const EditOperatorModal: React.FC<IProps> = ({
  open,
  operatorAzureId,
  refetchOperators,
  handleClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: operatorData, error: operatorError } = useQuery<{
    operator: IOperatorDto;
  }>(getOperatorQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !operatorAzureId,
    variables: {
      values: {
        azureUserId: operatorAzureId
      }
    }
  });

  useErrorHandling(operatorError);
  const operator = operatorData?.operator;

  const handleUpdateOperatorSuccess = () => {
    handleModalClose();
    refetchOperators();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_OperatorUpdatedSuccessfully)
      )
    );
  };

  const { data: dataClinicsOptions, error: errorClinicsOptions } = useQuery<{
    clinicsOptions: IOption[];
  }>(getClinicsOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorClinicsOptions);

  const [updateOperator, { loading, error }] = useMutation(
    updateOperatorMutation,
    {
      onError: () => {},
      onCompleted: handleUpdateOperatorSuccess
    }
  );

  useErrorHandling(error);

  const handleFormSubmit = (values: IOperatorForm) => {
    if (isValid) {
      updateOperator({
        variables: { values: { ...values, azureUserId: operatorAzureId } }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const getDefaultPreferredLanguage = (): string => {
    return (
      languageNumberPairs.find(x => x.index === operator?.preferredLanguage)
        ?.language || LanguageEnum.De
    );
  };

  const initialValues: IOperatorForm = {
    name: operator?.name ?? "",
    email: operator?.email ?? "",
    clinicId: operator?.clinicId ?? 0,
    preferredLanguage: getDefaultPreferredLanguage()
  };

  const clinicsOptions = dataClinicsOptions?.clinicsOptions || [];

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: operatorFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    clinicsOptions,
    formTitle: t(TranslationsEnum.Global_EditOperator),
    isEditModal: true,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleClose: handleModalClose
  };

  return <OperatorForm {...props} />;
};

export default EditOperatorModal;
