import React from "react";
import clsx from "clsx";
import {
  Box,
  Paper,
  Typography,
  Divider,
  CircularProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MyoSuitFront from "../../../assets/myosuit-front.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useQuery } from "@apollo/react-hooks";
import {
  getSuitCalibrationQueryForOperator,
  getSuitCalibrationQueryForPatient
} from "../../../queries/patients";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";
import { format } from "date-fns";
import { useSelector } from "../../../state/store";
import { selectUserProfile } from "../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum } from "../../../types/enums";
import useStyles from "./MyoSuitCalibrationCard.styles";

interface IProps {
  patientId: number;
  focusedBox: number | undefined;
  hoveredBox: number | undefined;
  onBoxClick: (number: number) => void;
  onBoxHover: (number: number | undefined) => void;
}

const MyoSuitCalibrationCard = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { patientId, focusedBox, hoveredBox, onBoxClick, onBoxHover } = props;
  const { userProfile } = useSelector(selectUserProfile);

  const getSuitCalibrationQuery =
    userProfile?.claimsType === ClaimsEnum.Operator
      ? getSuitCalibrationQueryForOperator(Number(patientId)!)
      : getSuitCalibrationQueryForPatient;

  const { data, error, loading } = useQuery(getSuitCalibrationQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(error);

  const isCalibrated = data?.getSuitCalibration.isCalibrated;
  const lastCalibrated = data?.getSuitCalibration.lastCalibrated;

  const handleBoxClick = (number: number) => () => onBoxClick(number);

  const handleBoxHoverEnter = (number: number) => () => onBoxHover(number);

  const handleBoxHoverLeave = () => onBoxHover(undefined);

  return (
    <Box className={classes.container}>
      <Paper className={classes.container}>
        <Box className={classes.header}>
          {isCalibrated && (
            <CheckCircleIcon color="primary" className={classes.checkIcon} />
          )}
          <Box className={classes.headerText}>
            {loading ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <Typography
                  variant="body2"
                  className={clsx(classes.darkGrey, classes.calibrationText)}
                >
                  {isCalibrated
                    ? t(
                        "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.Calibrated"
                      )
                    : t(
                        "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.NotCalibrated"
                      )}
                </Typography>
                {isCalibrated && (
                  <Typography
                    variant="caption"
                    className={clsx(
                      classes.darkGrey,
                      classes.lastCalibratedText
                    )}
                  >
                    {t(
                      "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.LastCalibration",
                      {
                        calibrationDate: format(
                          new Date(lastCalibrated),
                          "dd LLL"
                        )
                      }
                    )}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.formContainer}>
          <img src={MyoSuitFront} alt="myosuit front" />
          <Box className={classes.boxesWrapper}>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 1,
                [classes.hovered]: hoveredBox === 1 && focusedBox !== 1
              })}
              onClick={handleBoxClick(1)}
              onMouseOver={handleBoxHoverEnter(1)}
              onMouseOut={handleBoxHoverLeave}
              top={164}
              left={2}
            >
              1
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 2,
                [classes.hovered]: hoveredBox === 2 && focusedBox !== 2
              })}
              onClick={handleBoxClick(2)}
              onMouseOver={handleBoxHoverEnter(2)}
              onMouseOut={handleBoxHoverLeave}
              top={118}
              right={36}
            >
              2
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 3,
                [classes.hovered]: hoveredBox === 3 && focusedBox !== 3
              })}
              onClick={handleBoxClick(3)}
              onMouseOver={handleBoxHoverEnter(3)}
              onMouseOut={handleBoxHoverLeave}
              top={136}
              right={36}
            >
              3
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 4,
                [classes.hovered]: hoveredBox === 4 && focusedBox !== 4
              })}
              onClick={handleBoxClick(4)}
              onMouseOver={handleBoxHoverEnter(4)}
              onMouseOut={handleBoxHoverLeave}
              top={240}
              right={37}
            >
              4
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 5,
                [classes.hovered]: hoveredBox === 5 && focusedBox !== 5
              })}
              onClick={handleBoxClick(5)}
              onMouseOver={handleBoxHoverEnter(5)}
              onMouseOut={handleBoxHoverLeave}
              top={46}
              left={38}
            >
              5
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 6,
                [classes.hovered]: hoveredBox === 6 && focusedBox !== 6
              })}
              onClick={handleBoxClick(6)}
              onMouseOver={handleBoxHoverEnter(6)}
              onMouseOut={handleBoxHoverLeave}
              top={64}
              left={38}
            >
              6
            </Box>
            <Box
              className={clsx(classes.box, {
                [classes.selected]: focusedBox === 7,
                [classes.hovered]: hoveredBox === 7 && focusedBox !== 7
              })}
              onClick={handleBoxClick(7)}
              onMouseOver={handleBoxHoverEnter(7)}
              onMouseOut={handleBoxHoverLeave}
              top={168}
              left={39}
            >
              7
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default MyoSuitCalibrationCard;
