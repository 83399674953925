import {
  MsalAuthProvider,
  LoginType,
  IMsalAuthProviderConfig
} from "react-aad-msal";
import { Configuration, AuthenticationParameters } from "msal";
import { isElectron } from "../helpers/electronHelpers";

const redirectUri = isElectron()
  ? process.env.REACT_APP_ELECTRON_AZURE_ADD_REDIRECT
  : process.env.REACT_APP_AZURE_AD_REDIRECT;

// Msal Configurations
const configRegistration: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY_REGISTRATION,
    validateAuthority: false,
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || "",
    redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

const configResetPassword: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY_RESET_PASSWORD,
    validateAuthority: false,
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || "",
    redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

const configSignUpSignIn: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY_SIGN_UP_SIGN_IN,
    validateAuthority: false,
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID || "",
    redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {};

// Options
const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,

  // We need a separate blank page for token response to come in an iframe. Hence, we must create an empty auth.html file in "public" folder
  // https://www.npmjs.com/package/react-aad-msal#options
  tokenRefreshUri: window.location.origin + "/auth.html"
  // Also, add redirect url in Azure -> Active Directory -> App registrations -> Authentication
};

export const authProviderRegistration = new MsalAuthProvider(
  configRegistration,
  authenticationParameters,
  options
);

export const authProviderResetPassword = new MsalAuthProvider(
  configResetPassword,
  authenticationParameters,
  options
);

export const authProviderSignUpSignIn = new MsalAuthProvider(
  configSignUpSignIn,
  authenticationParameters,
  options
);
