import gql from "graphql-tag";

export const getPatientSessionsOverviewQueryForOperator = (
  patientId: number
) => gql`
query getPatientSessionsOverview {
    getPatientSessionsOverview @rest(type: "OverallSessionsOverviewDto", path: "sessions/overview/${patientId}") {
    totalTime,
    totalSessions,
    totalDistance,
    meanActiveRate,
    meanSteps,
    longestActivityTime,
    longestDistance,
    longestSteps
  }
}
`;

export const getPatientSessionsOverviewQueryForPatient = gql`
  query getPatientSessionsOverview {
    getPatientSessionsOverview
      @rest(type: "OverallSessionsOverviewDto", path: "sessions/overview") {
      totalTime
      totalSessions
      totalDistance
      meanActiveRate
      meanSteps
      longestActivityTime
      longestDistance
      longestSteps
    }
  }
`;

export const getPatientSessionsForChartQueryForOperator = (
  patientId: number
) => gql`
query getPatientSessions($sessionsChartConfig: ISessionsChartConfig!) {
  getPatientSessions(sessionsChartConfig: $sessionsChartConfig) 
  @rest(
    type: "getPatientSessionsDto", 
    path: "sessions/chart/${patientId}",
    method: "POST",
    bodyKey: "sessionsChartConfig"
    ) {
      sessions,
      count,
      allSessionsIds
  }
}
`;

export const getPatientSessionsForChartQueryForPatient = gql`
  query getPatientSessions($sessionsChartConfig: ISessionsChartConfig!) {
    getPatientSessions(sessionsChartConfig: $sessionsChartConfig)
      @rest(
        type: "getPatientSessionsDto"
        path: "sessions/chart"
        method: "POST"
        bodyKey: "sessionsChartConfig"
      ) {
      sessions
      count
      allSessionsIds
    }
  }
`;

export const getPatientSessionsForTableQueryForOperator = (
  patientId: number
) => gql`
query getPatientSessions($sessionsTableConfig: ISessionsTableConfig!) {
  sessionsTableDTO(sessionsTableConfig: $sessionsTableConfig) 
  @rest(
    type: "sessionsTableDTO", 
    path: "sessions/table/${patientId}",
    method: "POST",
    bodyKey: "sessionsTableConfig"
    ) {
      sessions,
      count
  }
}
`;

export const getPatientSessionsForTableQueryForPatient = gql`
  query getPatientSessions($sessionsTableConfig: ISessionsTableConfig!) {
    sessionsTableDTO(sessionsTableConfig: $sessionsTableConfig)
      @rest(
        type: "sessionsTableDTO"
        path: "sessions/table"
        method: "POST"
        bodyKey: "sessionsTableConfig"
      ) {
      sessions
      count
    }
  }
`;
