export enum UnitsColumnsEnum {
  Id = "id",
  SerialNumber = "serialNumber",
  HardwareModel = "hardwareModel",
  NexusProtocolVersion = "nexusProtocolVersion",
  CurrentFirmwareVersion = "currentFirmwareVersion",
  FutureFirmwareVersion = "futureFirmwareVersion",
  UnitStatusCode = "unitStatusCode",
  UnitStatusName = "unitStatusName",
  UnitStatusType = "unitStatusType",
  UnitStatusSource = "unitStatusSource",
  ClinicName = "clinicName",
  PatientName = "patientName"
}
