import * as actions from "./actions";
import { IAuthState } from "../../types/reducerStates/IAuthState";

const initialState: IAuthState = {
  aadResponse: undefined
};

export const reducer = (
  state: IAuthState = initialState,
  action: actions.ActionTypes
): IAuthState => {
  switch (action.type) {
    case actions.AAD_LOGIN_SUCCESS:
      return { ...state, aadResponse: action.payload };
    case actions.AAD_LOGOUT_SUCCESS:
      return { ...state, aadResponse: undefined };
    default:
      return state;
  }
};
