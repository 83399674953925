import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Typography
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./ErrorModal.styles";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

interface IProps {
  icon?: "error" | "warning";
  open: boolean;
  message?: string;
  title?: string;
  handleClose: () => void;
}

const ErrorModal: React.FC<IProps> = ({
  icon,
  open,
  message,
  title,
  handleClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const pickIcon = () => {
    switch (icon) {
      case "warning":
        return <WarningRoundedIcon className={classes.warningIcon} />;
      case "error":
      default:
        return <ErrorIcon className={classes.errorIcon} />;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400
      }}
    >
      <Fade in={open}>
        <Box className={classes.container}>
          {pickIcon()}
          <Box className={classes.messageContainer}>
            <Typography variant="h6" className={classes.fontWeightRegular}>
              {title || t("Containers.Modals.Error.HeaderTitle")}
            </Typography>
            {message && (
              <Typography
                variant="subtitle2"
                className={classes.fontWeightRegular}
              >
                {message}
              </Typography>
            )}
          </Box>
          <Button
            color={"primary"}
            className={classes.button}
            onClick={handleClose}
          >
            {t("Global.Ok")}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ErrorModal;
