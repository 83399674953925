import * as Yup from "yup";
import i18n from "../../i18n";
import { TranslationsEnum } from "../../types/enums";
import { MessageReceiverTypeEnum } from "../../types/enums/MessageReceiverTypeEnum";
import { IMessageAutocompleteEnum } from "../../types/enums/IMessageAutocompleteEnum";

export const schema = Yup.object().shape({
  title: Yup.string()
    .min(3, i18n.t(TranslationsEnum.Validations_MinLength3))
    .required(i18n.t(TranslationsEnum.Validations_Required)),
  messageReceiverType: Yup.number().required(),
  azureUser: Yup.object()
    .nullable()
    .when(IMessageAutocompleteEnum.MessageReceiverType, {
      is: MessageReceiverTypeEnum.Private,
      then: Yup.object()
        .nullable()
        .required(i18n.t(TranslationsEnum.Validations_Required))
    })
});
