import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  updatePatientMutation,
  updatePatientFromClaimsMutation
} from "../../../../mutations/patients";
import { actions } from "../../../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IPatientForm } from "../../../../types/forms/IPatientForm";
import { IPatient } from "../../../../types/IPatient";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import PatientForm from "../PatientForm/PatientForm";
import {
  patientFormSchemaForOperator,
  patientFormSchemaForPatient
} from "../PatientForm/patientSchema";
import { getSelectedLanguage } from "../../../../utils/helpers/languageHelpers";
import { IGetPathologiesDto } from "../../../../types/requestDtos/IGetPathologiesDto";
import { getPathologiesListQuery } from "../../../../queries/pathologies";
import { IPathologyDTO } from "../../../../types/IPathology";
import { createOptionsFromPathologiesList } from "../../../../utils/helpers/enumMapperHelper";
import { languageNumberPairs } from "../../../../constants/languages";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { RequestedClinicStatusEnum } from "../../../../types/enums/RequestedClinicStatusEnum";
import {
  ClaimsEnum,
  TranslationsEnum,
  GenderEnum
} from "../../../../types/enums";
import { useSelector } from "../../../../state/store";
import { selectUserProfile } from "../../../../utils/helpers/stateSelectorHelpers";
import { useState } from "react";

interface IProps {
  handleClose: () => void;
  open: boolean;
  patient: IPatient;
  refetchPatient: () => void;
  handleSetPatientForm: (form: IPatientForm) => void;
  openQuestionnaireExplanation: () => void;
}

const EditPatientModal: React.FC<IProps> = ({
  patient,
  open,
  refetchPatient,
  handleClose,
  handleSetPatientForm,
  openQuestionnaireExplanation
}) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const dispatchSuccessSnackbar = () => {
    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(
          TranslationsEnum.Containers_Modals_Patient_UpdatePatientModal_SuccessMessage
        )
      )
    );
  };

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const { userProfile } = useSelector(selectUserProfile);
  const isOperator = userProfile?.claimsType === ClaimsEnum.Operator;
  const isPatient = userProfile?.claimsType === ClaimsEnum.Patient;
  const isClinicInTrial = userProfile?.clinicInSanitasTrial;
  const getPathologiesDto: IGetPathologiesDto = useMemo(
    () => ({
      language: getSelectedLanguage()
    }),
    []
  );

  const { data: dataPathologies, error: errorPathologies } = useQuery<{
    pathologiesDto: IPathologyDTO;
  }>(getPathologiesListQuery, {
    variables: { getPathologiesDto },
    fetchPolicy: "network-only"
  });

  useErrorHandling(errorPathologies);

  const [updatePatient, { loading, error }] = useMutation(
    updatePatientMutation(patient.id),
    {
      onError: () => {},
      onCompleted: () => {
        dispatchSuccessSnackbar();
        handleClose();
        if (showQuestionnaire) {
          openQuestionnaireExplanation();
        } else {
          resetForm();
          refetchPatient();
        }
      }
    }
  );

  useErrorHandling(error);

  const [
    updatePatientFromClaims,
    {
      loading: loadingUpdatePatientFromClaims,
      error: errorUpdatePatientFromClaims
    }
  ] = useMutation(updatePatientFromClaimsMutation, {
    onError: () => {},
    onCompleted: () => {
      dispatchSuccessSnackbar();
      handleClose();
      resetForm();
      refetchPatient();
    }
  });

  useErrorHandling(errorUpdatePatientFromClaims);

  const handleFormSubmit = (values: IPatientForm) => {
    if (isValid) {
      if (isPatient) {
        updatePatientFromClaims({
          variables: { values }
        });
      } else if (isOperator) {
        setShowQuestionnaire(
          values.inSanitasTrial && values.questionnaire === null
        );
        updatePatient({
          variables: { values }
        });
      }
      handleSetPatientForm(values);
    }
  };

  const handleModalClose = () => {
    if (!patient.isNewUser) {
      handleClose();
      resetForm();
    }
  };

  const getDefaultPreferredLanguage = (): string => {
    if (patient.isNewUser) {
      return i18n.language;
    }

    return (
      languageNumberPairs.find(x => x.index === patient.preferredLanguage)
        ?.language || LanguageEnum.De
    );
  };

  const initialValues: IPatientForm = {
    name: patient.name || "",
    pathologyId: patient.pathologyId || 0,
    height: patient.height || 0,
    weight: patient.weight || 0,
    timeSinceDiagnosis: patient.timeSinceDiagnosis || 0,
    email: patient.email || "",
    dateOfBirth: patient.dateOfBirth || "1900-01-01",
    phoneNumber: patient.phoneNumber || "",
    preferredLanguage: getDefaultPreferredLanguage(),
    genderType:
      patient.genderType === null ? GenderEnum.Other : patient.genderType,
    inSanitasTrial: patient.inSanitasTrial,
    questionnaire: null,
    isQuestionnaireDone: false,
    QuestionnaireResults: "",
    sanitasTrialStart: patient.sanitasTrialStart,
    sanitasTrialEnd: patient.sanitasTrialEnd
  };

  const pathologies = dataPathologies?.pathologiesDto.pathologies;
  const { pathologyOptions, noneId } = createOptionsFromPathologiesList(
    pathologies
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: isPatient
      ? patientFormSchemaForPatient(noneId)
      : patientFormSchemaForOperator(noneId),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  const isOpen = open || (patient.isNewUser && isPatient);

  const props = {
    errors,
    values,
    open: isOpen,
    loading: loading || loadingUpdatePatientFromClaims,
    formTitle: t("Containers.Modals.Patient.PatientForm.EditUser"),
    handleSubmit,
    handleChange,
    handleClose: handleModalClose,
    setFieldValue,
    isEditModal: true,
    pathologyOptions,
    noneId,
    isDisabled:
      patient.requestedClinicStatus === RequestedClinicStatusEnum.Pending &&
      isOperator,
    isNewUser: patient.isNewUser,
    isPatient: isPatient,
    isOperator: isOperator,
    isClinicInTrial: isClinicInTrial,
    isDpaAccepted: patient.dataProcessingAgreement
  };

  return <PatientForm {...props} />;
};

export default EditPatientModal;
