import React, { useCallback, useEffect, useState } from "react";
import useStyles from "./NetworkConnection.styles";
import { WifiOff } from "@material-ui/icons";
import InfoModal from "../Modals/InfoModal/InfoModal";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../types/enums";

const NetworkConnection: React.FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleOnlineStatusChange = useCallback(() => {
    setIsOnline(navigator.onLine);
  }, [setIsOnline]);

  useEffect(() => {
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [handleOnlineStatusChange]);

  const renderIcon = useCallback(() => <WifiOff className={classes.icon} />, [
    classes.icon
  ]);

  return (
    <>
      <InfoModal
        title={t(
          TranslationsEnum.Electron_ConnectionLostModal_ConnectionLostTitle
        )}
        message={t(
          TranslationsEnum.Electron_ConnectionLostModal_ConnectionLostMessage
        )}
        open={!isOnline}
        renderIcon={renderIcon}
        handleClose={() => {}}
        hideButton={true}
      />
      {children}
    </>
  );
};

export default NetworkConnection;
