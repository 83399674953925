import React from "react";
import useStyles from "./DownloadDesktopApp.styles";
import { Box, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import Dropdown from "./Dropdown/Dropdown";
import { useTranslation } from "react-i18next";

const DownloadDesktopApp: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography
          variant="h6"
          className={clsx(classes.darkGrey, classes.header)}
        >
          {t("Containers.DownloadDesktopApp.Title")}
        </Typography>
        <Box className={classes.descriptionContainer}>
          <Typography
            variant="subtitle1"
            className={clsx(classes.darkGrey, classes.description)}
          >
            {t("Containers.DownloadDesktopApp.Description")}
          </Typography>
          <Dropdown />
        </Box>
      </Paper>
    </Box>
  );
};

export default DownloadDesktopApp;
