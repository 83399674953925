import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  peopleIcon: {
    fontSize: 97
  },
  welcome: {
    marginTop: 22,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 48,
    color: ColorEnum.DarkGrey
  },
  button: {
    marginTop: 40
  },
  newRequestButton: {
    backgroundColor: ColorEnum.Orange,
    color: ColorEnum.White,
    marginRight: theme.spacing(3),
    "&:hover": {
      backgroundColor: ColorEnum.OrangeHover
    }
  },
  text: {
    marginTop: 18,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 20,
    color: ColorEnum.LightGrey
  }
}));
