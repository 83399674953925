import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  lightGrey: {
    color: ColorEnum.LightGrey
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal"
  }
}));
