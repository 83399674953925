import React from "react";
import SyncMyoSuitElectron from "./electron/SyncMyoSuitElectron";
import SyncMyoSuitReact from "./react/SyncMyoSuitReact";
import { isElectron } from "../../utils/helpers/electronHelpers";

const SyncMyoSuitSwitch: React.FC = () => {
  return isElectron() ? <SyncMyoSuitElectron /> : <SyncMyoSuitReact />;
};

export default SyncMyoSuitSwitch;
