import gql from "graphql-tag";

export const updateInviteUserToRegisterMutation = gql`
  mutation createInviteUserToRegister($values: Values!) {
    createInviteUserToRegister(values: $values)
      @rest(
        type: "CreateInviteUserToRegisterDto"
        path: "patients/inviteUser"
        method: "Put"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const deletePatientMutation = gql`
  mutation deletePatient($values: Values!) {
    deletePatient(values: $values)
      @rest(
        type: "DeletePatient"
        path: "patients/{args.values.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;

export const deletePatientFromClaimsMutation = gql`
  mutation deletePatientFromClaimsMutation($values: Values!) {
    deletePatientFromClaimsMutation(values: $values)
      @rest(type: "DeletePatient", path: "patients", method: "DELETE") {
      NoResponse
    }
  }
`;

export const resendDPAEmailMutation = gql`
  mutation resendDPAEmail($values: Values!) {
    resendDPAEmail(values: $values)
      @rest(
        type: "ResendDPAEmailDto"
        path: "patients/resendDPAEmail"
        method: "POST"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const setPaperDPASignedMutation = gql`
  mutation setPaperDPASigned($values: Values!) {
    setPaperDPASigned(values: $values)
      @rest(
        type: "PatientIdDto"
        path: "patients/setPaperDPASigned"
        method: "POST"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const SetDPAAdminMutation = gql`
  mutation setPatientDpaAdmin($values: Values!) {
    setPatientDpaAdmin(values: $values)
      @rest(
        type: "SetDPAAdminDto"
        path: "patients/DPA/admin"
        method: "PUT"
        bodyKey: "values"
      ) {
      id
    }
  }
`;

export const createPatientMutation = gql`
  mutation createPatient($values: Values!) {
    createPatient(values: $values)
      @rest(
        type: "CreatePatientDto"
        path: "patients"
        method: "POST"
        bodyKey: "values"
      ) {
      id
      inSanitasTrial
    }
  }
`;

export const updatePatientMutation = (patientId: number) => gql`
  mutation updatePatient($values: Values!) {
    updatePatient(values: $values)
      @rest(
        type: "UpdatePatientDto"
        path: "patients/${patientId}"
        method: "Put"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updatePatientFromClaimsMutation = gql`
  mutation updatePatientFromClaims($values: Values!) {
    updatePatientFromClaims(values: $values)
      @rest(
        type: "UpdatePatientDto"
        path: "patients/updateFromOid"
        method: "Put"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updatePatientsProfileStrapSizesMutation = (
  patientId: number
) => gql`
  mutation updatePatientsProfileStrapSizes($values: Values!) {
    updatePatientStrapSizes(values: $values)
      @rest(
        type: "updatePatientStrapSizesDto"
        path: "patientProfile/strapSizes/${patientId}"
        method: "PUT"
        bodyKey: "values"
      ) {
        NoResponse
      }
  }
`;

export const updatePatientsProfileStrapSizesByAzureOidMutation = gql`
  mutation updatePatientsProfileStrapSizesByAzureOid($values: Values!) {
    updatePatientStrapSizesByAzureOid(values: $values)
      @rest(
        type: "updatePatientStrapSizesByAzureOidDto"
        path: "patientProfile/strapSizes/byAzureOid"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updatePatientsProfileAssistanceLevelsMutation = (
  patientId: number
) => gql`
mutation updatePatientsProfileAssistanceLevels($values: Values!) {
  updatePatientsProfileAssistanceLevels(values: $values)
    @rest(
      type: "updatePatientsProfileAssistanceLevelsDto"
      path: "patientProfile/assistanceLevels/${patientId}"
      method: "PUT"
      bodyKey: "values"
    ) {
      NoResponse
    }
}
`;

export const updatePatientsProfileAssistanceLevelsByAzureOidMutation = gql`
  mutation updatePatientsProfileAssistanceLevelsByAzureOid($values: Values!) {
    updatePatientsProfileAssistanceLevelsByAzureOid(values: $values)
      @rest(
        type: "updatePatientsProfileAssistanceLevelsByAzureOidDto"
        path: "patientProfile/assistanceLevels/byAzureOid"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const patientAcceptDPAMutation = gql`
  mutation patientAcceptDPA($requestDto: ISetDPADto!) {
    patientAcceptDPA(requestDto: $requestDto)
      @rest(
        type: "SetDPADto"
        path: "patients/DPA"
        method: "PUT"
        bodyKey: "requestDto"
      ) {
      NoResponse
    }
  }
`;

export const assignPatientToAzureUserMutation = gql`
  mutation assignPatientToAzureUser($values: IAssignPatientToAzureUser!) {
    assignPatientToAzureUser(values: $values)
      @rest(
        type: "AssignPatientToAzureUser"
        path: "patients/registrationKey"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const askToJoinClinicMutation = gql`
  mutation askToJoinClinic($values: IAskToJoinClinic!) {
    askToJoinClinic(values: $values)
      @rest(
        type: "askToJoinClinic"
        path: "patients/askToJoinClinic"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const cancelClinicJoinMutation = gql`
  mutation cancelClinicJoin($values: none!) {
    cancelClinicJoin(values: $values)
      @rest(
        type: "cancelClinicJoin"
        path: "patients/cancelClinicJoin"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const unassignFromClinicMutation = gql`
  mutation unassignFromClinic($values: none!) {
    unassignFromClinic(values: $values)
      @rest(
        type: "unassignFromClinic"
        path: "patients/unassignFromClinic"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const approveRejectToClinicMutation = gql`
  mutation approveRejectToClinic($values: IApproveRejectToClinic!) {
    approveRejectToClinic(values: $values)
      @rest(
        type: "approveRejectToClinic"
        path: "patients/approveRejectToClinic"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const setPatientEmailByGuidMutation = gql`
  mutation setPatientEmailByGuid($values: IProvideEmail!) {
    setPatientEmailByGuidMutation(values: $values)
      @rest(
        type: "provideEmail"
        path: "patients/setEmail"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;
