export const contactEmailAddress = "support@myoswiss.com";
export const mainPage = "https://myo.swiss/";
export const DPA_En = "https://myo.swiss/en/data-privacy-statement/";
export const DPA_De = "https://myo.swiss/data-privacy-statement/";
export const impressum_En = "https://myo.swiss/en/impressum/";
export const impressum_De = "https://myo.swiss/impressum-2/";
export const myoswissPricingEN = "https://www.myo.swiss/en/pricing/";
export const myoswissPricingDE = "https://www.myo.swiss/preise/";

export const resourcesOperator_De = "https://myo.swiss/ressourcen-fachpersonal";
export const resourcesOperator_En =
  "https://myo.swiss/en/resources-healthcare-professionals";
export const resourcesPatient_De =
  "https://myo.swiss/ressourcen-privatgebrauch";
export const resourcesPatient_En =
  "https://myo.swiss/en/resources-private-user";
