import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, CircularProgress, IconButton } from "@material-ui/core";
import useStyles from "./Messages.styles";
import { useSelector } from "../../state/store";
import { selectUserProfile } from "../../utils/helpers/stateSelectorHelpers";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  getMessagesForUserQuery,
  getMessagesQuery
} from "../../queries/messages";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import { MessagesColumnsEnum } from "../../types/enums/MessagesColumnsEnum";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from "mui-datatables";
import { format, parseISO, toDate } from "date-fns";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import {
  ClaimsEnum,
  SnackbarTypeEnum,
  TranslationsEnum
} from "../../types/enums";
import { useTranslation } from "react-i18next";
import DataTableFooter from "../../components/DataTableFooter/DataTableFooter";
import { IMessageTableDTO } from "../../types/tables/IMessageTable";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IFilterConfig } from "../../types/filters/IFilterConfig";
import { textLabelsConfig } from "../../constants/tableTranslationsConfig";
import DeletePatientModal from "../Modals/Patient/DeletePatientModal/DeletePatientModal";
import {
  deleteMessageMutation,
  readMessageMutation
} from "../../mutations/messages";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { attachSortToColumns } from "../../utils/helpers/filtersHelpers";
import { useMessagesCount } from "../../utils/helpers/messageHelpers";

const Messages: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userProfile } = useSelector(selectUserProfile);
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const defaultSorting = {
    sortField: MessagesColumnsEnum.LastEditedAt,
    sortDirection: SortDirectionEnum.Descending
  };
  const [filterConfig, setFilterConfig] = useState<IFilterConfig>({
    ...defaultSorting,
    takePerPage: 25,
    page: 0,
    filters: []
  });
  const [messageToDeleteId, setMessageToDeleteId] = React.useState<
    null | number
  >(null);

  const query =
    userProfile?.claimsType === ClaimsEnum.Admin
      ? getMessagesQuery
      : getMessagesForUserQuery;
  const { data, loading, error, refetch } = useQuery<{
    getMessages: IMessageTableDTO;
  }>(query, {
    variables: { filterConfig },
    fetchPolicy: "network-only"
  });

  useErrorHandling(error);

  const handleDeleteSuccess = () => {
    refetch();
    dispatch(
      snackbarActions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_MessageDeletedSuccessfully)
      )
    );
  };

  const [
    deleteMessage,
    { error: deleteMessageError, loading: deleteLoading }
  ] = useMutation(deleteMessageMutation, {
    onError: () => {},
    onCompleted: handleDeleteSuccess
  });

  useErrorHandling(deleteMessageError);

  const [readMessage] = useMutation(readMessageMutation);
  const { refetch: refetchMessagesCount } = useMessagesCount();

  const handleRowClick = useCallback(
    (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      const message = data?.getMessages?.messages[rowMeta.dataIndex];
      if (
        message &&
        !message.isRead &&
        userProfile?.claimsType !== ClaimsEnum.Admin
      ) {
        readMessage({
          variables: {
            values: {
              id: message.userMessageId
            }
          }
        });
        refetchMessagesCount();
      }
      history.push(
        generatePath(PathNamesEnum.Message, { messageId: message?.id })
      );
    },
    [history, data, readMessage, userProfile, refetchMessagesCount]
  );

  const handleDeleteModalOpen = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setDeleteModalOpen(true);
      setMessageToDeleteId(id);
    },
    [setDeleteModalOpen, setMessageToDeleteId]
  );

  const handleDeleteModalClose = useCallback(() => {
    setMessageToDeleteId(null);
    setDeleteModalOpen(false);
  }, [setMessageToDeleteId, setDeleteModalOpen]);

  const handleDeleteMessage = useCallback(() => {
    deleteMessage({
      variables: { values: { id: messageToDeleteId } }
    });
    handleDeleteModalClose();
  }, [deleteMessage, handleDeleteModalClose, messageToDeleteId]);

  const handleEdit = useCallback(
    (messageId: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(generatePath(PathNamesEnum.EditMessage, { messageId }));
    },
    [history]
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: MessagesColumnsEnum.Title,
      label: t(TranslationsEnum.Containers_Messages_HeaderColumns_Title)
    },
    {
      name: MessagesColumnsEnum.LastEditedAt,
      label: t(TranslationsEnum.Containers_Messages_HeaderColumns_SentAt),
      options: {
        customBodyRender: value => {
          return format(toDate(parseISO(value)), "dd/MM/yyyy HH:mm");
        }
      }
    }
  ];

  if (userProfile?.claimsType === ClaimsEnum.Admin) {
    columns.unshift({
      name: MessagesColumnsEnum.Id,
      label: t(TranslationsEnum.Global_ID)
    });
  }
  if (userProfile?.claimsType === ClaimsEnum.Admin) {
    columns.push({
      name: MessagesColumnsEnum.Id,
      label: " ",
      options: {
        customBodyRender: value => {
          return (
            <Box display="flex">
              <Box onClick={handleEdit(value)}>
                <IconButton aria-label="delete">
                  <EditIcon color="primary" />
                </IconButton>
              </Box>
              <Box onClick={handleDeleteModalOpen(value)}>
                <IconButton aria-label="delete">
                  <DeleteIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
          );
        }
      }
    });
  }

  const handleCreateNewMessage = useCallback(() => {
    history.push(PathNamesEnum.CreateMessage);
  }, [history]);

  const { messages, count } = data?.getMessages || {
    messages: [],
    count: 0
  };

  const buildCustomFooter = useCallback(
    (
      rowCount: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: number) => void,
      changePage: any // TODO: typescript bug using "@types/mui-datatables@2.13.4
    ) => (
      <DataTableFooter
        {...{
          count: rowCount,
          changePage: changePage as (page: number) => void,
          rowsPerPage,
          pagination: true,
          page: filterConfig.page,
          changeRowsPerPage
        }}
      />
    ),
    [filterConfig.page]
  );

  const handlePageChange = (page: number) =>
    setFilterConfig(x => ({ ...x, page }));

  const handleSortingChange = (sortField: string, sortDirection: string) => {
    setFilterConfig(x => ({ ...x, sortField, sortDirection }));
  };

  const options: MUIDataTableOptions = {
    page: filterConfig.page,
    count,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    serverSide: true,
    rowsPerPage: filterConfig.takePerPage,
    sort: true,
    textLabels: textLabelsConfig(),
    customFooter: buildCustomFooter,
    onChangePage: handlePageChange,
    onColumnSortChange: handleSortingChange,
    onRowClick: handleRowClick,
    setRowProps: (_, rowIndex) => {
      const message = messages[rowIndex];
      if (!message.isRead && userProfile?.claimsType !== ClaimsEnum.Admin) {
        return {
          className: classes.highlightRow
        };
      }
      return {};
    }
  };

  const fullColumns = useMemo(
    () =>
      attachSortToColumns(columns, {
        sortDirection: filterConfig.sortDirection,
        name: filterConfig.sortField
      }),
    [filterConfig, columns]
  );

  if (loading && !data) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  return (
    <Box px={3} pt={4}>
      {userProfile?.claimsType === ClaimsEnum.Admin && (
        <Box display="flex" pb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewMessage}
          >
            {t(TranslationsEnum.Containers_Messages_NewMessage)}
          </Button>
        </Box>
      )}
      <DeletePatientModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        loading={deleteLoading}
        onDelete={handleDeleteMessage}
      />
      <MUIDataTable
        title={null}
        data={messages}
        columns={fullColumns}
        options={options}
      />
    </Box>
  );
};

export default Messages;
