import React, { useMemo } from "react";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import useStyles from "./Message.styles";
import { useQuery } from "@apollo/react-hooks";
import { getSingleMessage } from "../../queries/messages";
import { useParams } from "react-router-dom";
import { IMessageIdRouteParams } from "../../types/routeParams/IMessageIdRouteParams";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";

const Message: React.FC = () => {
  const classes = useStyles();
  const { messageId } = useParams<IMessageIdRouteParams>();

  const { data, error, loading } = useQuery(
    getSingleMessage(Number(messageId)),
    {
      fetchPolicy: "cache-and-network"
    }
  );

  useErrorHandling(error);

  const editorState = useMemo(() => {
    if (data?.getSingleMessage?.text) {
      const blocksFromHtml = htmlToDraft(data?.getSingleMessage?.text);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  }, [data]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" pt={12.5}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <Typography variant="h6" className={classes.darkGrey}>
          {data?.getSingleMessage?.title}
        </Typography>
        <Editor
          toolbarHidden={true}
          readOnly={true}
          editorState={editorState}
        />
      </Paper>
    </Box>
  );
};

export default Message;
