import { CustomFilesEnum } from "../types/enums/CustomFilesEnum";
import instance from "./axiosInstance";

export const downloadPDF = (
  url: string,
  callback: (url: string | undefined, customFileEnum: CustomFilesEnum) => void,
  customFileEnum: CustomFilesEnum
) => {
  const response = instance({
    url,
    method: "POST",
    responseType: "blob",
    headers: {
      accept: "application/pdf"
    }
  })
    .then(response => {
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);
      callback(blobUrl, customFileEnum);
    })
    .catch(e => {
      callback(undefined, customFileEnum);
    });

  return response;
};
