import { ISessionData } from "../../types/ISessionData";
import { IOverallSessionsData } from "../../types/IOverallSessionsData";
import { ISessionDataForTableDisplay } from "../../types/tables/ISessionDataForTableDisplay";
import { format } from "date-fns";
import { getLocaleByLanguage } from "./languageHelpers";
import { LanguageEnum } from "../../types/enums/LanguageEnum";
import { getSessionDayMetricsProcessingStatusEnumToString } from "./enumMapperHelper";

const secondsInAWeek = 604800;
const secondsInADay = 86400;
const secondsInAnHour = 3600;
const secondsInAnMinute = 60;

export const convertSecondsToDateFormat = (seconds: number): string => {
  let result = "";
  let secondsForCounting = seconds;

  if (secondsForCounting >= secondsInAWeek) {
    const weeks = Math.floor(secondsForCounting / secondsInAWeek);
    result += `${weeks} w `;
    secondsForCounting -= weeks * secondsInAWeek;
  }

  if (secondsForCounting >= secondsInADay) {
    const days = Math.floor(secondsForCounting / secondsInADay);
    result += `${days} d `;
    secondsForCounting -= days * secondsInADay;
  }

  if (secondsForCounting >= secondsInAnHour) {
    const hours = Math.floor(secondsForCounting / secondsInAnHour);
    result += `${hours} h `;
    secondsForCounting -= hours * secondsInAnHour;
  }

  if (secondsForCounting >= secondsInAnMinute) {
    const minutes = Math.floor(secondsForCounting / secondsInAnMinute);
    result += `${minutes} min `;
    secondsForCounting -= minutes * secondsInAnMinute;
  }

  if (secondsForCounting > 0) {
    result += `${secondsForCounting} sec`;
  }

  return result || "0";
};

export const normalizeSessionData = (
  sessions: ISessionData[]
): ISessionData[] => {
  const maxSteps = Math.max.apply(
    Math,
    sessions.map(session => session.sessionSteps)
  );
  const maxStairs = Math.max.apply(
    Math,
    sessions.map(session => session.sessionStairs)
  );

  const normalizedSessions = sessions.map(session => ({
    ...session,
    sessionSteps: Math.round((session.sessionSteps / maxSteps) * 100) / 100,
    sessionStairs: Math.round((session.sessionStairs / maxStairs) * 100) / 100
  }));

  return normalizedSessions;
};

export const removeUnusedFractionsForSessionData = (
  sessions: ISessionData[]
): ISessionData[] => {
  return (sessions = sessions?.map(session => ({
    ...session,
    sessionActiveRate: Math.round(session.sessionActiveRate * 100) / 100,
    sessionStepSymmetry: Math.round(session.sessionStepSymmetry * 100) / 100
  })));
};

export const removeUnusedFractionsForOverallSessionData = (
  session: IOverallSessionsData
): IOverallSessionsData => ({
  ...session,
  activeTime: Math.round(session.activeTime * 100) / 100,
  totalDistanceCovered: Math.round(session.totalDistanceCovered * 100) / 100
});

export const prepareSessionDataForTable = (
  sessions: ISessionData[],
  selectedLanguage: LanguageEnum
): ISessionDataForTableDisplay[] => {
  const sessionsForDisplay: ISessionDataForTableDisplay[] = [];

  sessions.forEach(session => {
    const sessionForDisplay: ISessionDataForTableDisplay = {
      ...session,
      sessionTime: convertSecondsToDateFormat(session.sessionTime),
      sessionActiveRate: `${Math.round(session.sessionActiveRate * 100)}%`,
      sessionStepSymmetry: `${Math.round(session.sessionStepSymmetry * 100)}%`,
      sessionDate: format(new Date(session.sessionDate), "dd LLL", {
        locale: getLocaleByLanguage(selectedLanguage)
      }),
      sessionProcessingStatus: getSessionDayMetricsProcessingStatusEnumToString(
        session.sessionProcessingStatus
      )
    };

    sessionsForDisplay.push(sessionForDisplay);
  });

  return sessionsForDisplay;
};
