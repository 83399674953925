import { ActionsUnion } from "../../utils/typings/common";
import { IAccountInfo } from "react-aad-msal";

// Action types
export const AAD_LOGIN_SUCCESS = "AAD_LOGIN_SUCCESS";
export const AAD_LOGOUT_SUCCESS = "AAD_LOGOUT_SUCCESS";

// Action creators
export const actions = {
  loginSuccessAction: (aadResponse: IAccountInfo) => ({
    type: AAD_LOGIN_SUCCESS as typeof AAD_LOGIN_SUCCESS,
    payload: aadResponse
  }),
  logoutSuccessAction: () => ({
    type: AAD_LOGOUT_SUCCESS as typeof AAD_LOGOUT_SUCCESS
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
