import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme: Theme) => ({
  controlBox: {
    maxWidth: 160
  },
  cardContent: {
    "&, &:last-child": {
      paddingBottom: theme.spacing(2),
      maxWidth: 240,
      display: "flex",
      alignItems: "center",
      flexDirection: "row"
    }
  },
  done: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.primary.main,
    transition: theme.transitions.create("color"),
    "&:hover, &:focus, &:active": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : theme.palette.primary.light
    }
  }
}));
