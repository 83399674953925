import * as React from "react";
import { ISvgProps } from "../../types/ISvgProps";

const TrainingIcon = (props: ISvgProps) => {
  return (
    <svg width={36} height={36} viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.648 5.631l1.519 1.52-1.558 1.576-5.317-5.318 1.558-1.576 1.519 1.52 1.494-1.52 2.304 2.304-1.52 1.494zM11.013 2.65l-1.52 1.519 5.318 5.317 3.038 3.038 1.52-1.519-8.356-8.355zM4.177 9.486l-1.52 1.52 8.356 8.355 1.519-1.52-8.355-8.355zm7.595-1.52l2.28 2.28-3.799 3.797-2.278-2.278 3.797-3.798zM1.833 14.85l1.52 1.519-1.52 1.519 2.28 2.279 1.518-1.52 1.52 1.52L8.734 18.6l-5.317-5.317-1.583 1.566z"
        fill="#195F93"
      />
    </svg>
  );
};

export default TrainingIcon;
