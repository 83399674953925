import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  table: {
    "&> div": {
      height: "100%",
      boxShadow: "none",
      border: `1px solid ${ColorEnum.LightGreyBorder}`
    }
  },
  selectedText: {
    fontWeight: "bold"
  }
}));
