import React, { useCallback } from "react";
import {
  CircularProgress,
  IconButton,
  Typography,
  Box
} from "@material-ui/core";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IPatient } from "../../../types/IPatient";
import { IUserProfile } from "../../../types/IUserProfile";
import { useHistory } from "react-router-dom";
import useStyles from "./NavigationTitle.styles";

interface IProps {
  loadingPatient: boolean;
  patient: IPatient | undefined;
  userProfile: IUserProfile | undefined;
}

const NavigationTitle = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    location: { pathname },
    push
  } = useHistory();
  const { loadingPatient, patient, userProfile } = props;

  const goBackToPatientsPage = useCallback(() => push(PathNamesEnum.Patients), [
    push
  ]);

  const goBackToSyncMyoSuit = useCallback(
    () => push(PathNamesEnum.SyncMyoSuit),
    [push]
  );

  const renderNavigationTitle = () => {
    switch (true) {
      case patient?.id && loadingPatient:
        return <CircularProgress className={classes.white} />;
      case patient?.id && Boolean(patient):
        return (
          <>
            <IconButton onClick={goBackToPatientsPage}>
              <KeyboardBackspaceIcon className={classes.white} />
            </IconButton>
            <Typography variant="h6" component="h6">
              {t("Components.Header.Users")}
            </Typography>
            <ArrowForwardIosIcon className={classes.subtitleArrow} />
            <Typography className={classes.subtitle}>
              <Box mr={1} component="span">
                ID: {patient?.id}{" "}
              </Box>
              {patient?.name}
            </Typography>
          </>
        );
      case pathname === PathNamesEnum.Patients:
        return (
          <Typography variant="h6" component="h6">
            {t("Components.Header.Users")}
          </Typography>
        );
      case pathname === PathNamesEnum.SyncMyoSuit:
        return (
          <Typography variant="h6" component="h6">
            {t("Components.Header.SyncMyoSuit")}
          </Typography>
        );
      case pathname === PathNamesEnum.PrepareSession:
        return (
          <>
            <IconButton onClick={goBackToSyncMyoSuit}>
              <KeyboardBackspaceIcon className={classes.white} />
            </IconButton>
            <Typography variant="h6" component="h6">
              {t("Components.Header.SyncMyoSuit")}
            </Typography>
            <ArrowForwardIosIcon className={classes.subtitleArrow} />
            <Typography className={classes.subtitle}>
              {t("Components.Header.PrepareSession")}
            </Typography>
          </>
        );
      case pathname === PathNamesEnum.Home:
        return (
          <Typography variant="h6" component="h6">
            {userProfile?.clinicName}
          </Typography>
        );
      default:
        return null;
    }
  };

  return <Box className={classes.container}>{renderNavigationTitle()}</Box>;
};

export default NavigationTitle;
