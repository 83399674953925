import gql from "graphql-tag";

export const getFirmwareOptionsQuery = gql`
  query getFirmwareOptions {
    firmwareOptions @rest(type: "IOption", path: "firmwares/options") {
      label
      value
    }
  }
`;
