import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    boxShadow: "none",
    border: `1px solid ${ColorEnum.LightGreyBorder}`
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  title: {
    color: ColorEnum.DarkGrey,
    fontStyle: "normal",
    fontWeight: 600
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  tooltip: {
    position: "relative",
    bottom: 5
  },
  tooltipText: {
    fontSize: 13
  }
}));
