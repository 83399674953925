import { useMutation } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actions } from "../../../../state/snackbar/actions";
import { TranslationsEnum } from "../../../../types/enums";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import { clinicFormSchema } from "../ClinicForm/ClinicFormSchema";
import { createClinicMutation } from "../../../../mutations/clinics";
import { IClinicForm } from "../../../../types/forms/IClinicForm";
import ClinicForm from "../ClinicForm/ClinicForm";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { ClinicStatusEnum } from "../../../../types/enums/ClinicStatusEnum";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetchClinics: (variables?: { filterConfig: IFilterConfig }) => void;
}

const CreateClinicModal: React.FC<IProps> = ({
  open,
  handleClose,
  refetchClinics
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCreateSuccess = () => {
    refetchClinics();
    handleModalClose();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_ClinicCreatedSuccessfully)
      )
    );
  };

  const [createClinic, { loading, error }] = useMutation(createClinicMutation, {
    onError: () => {},
    onCompleted: handleCreateSuccess
  });

  useErrorHandling(error);

  const handleFormSubmit = (values: IClinicForm) => {
    if (isValid) {
      createClinic({
        variables: {
          values
        }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const initialValues: IClinicForm = {
    address: "",
    description: "",
    name: "",
    status: ClinicStatusEnum.Public,
    telephone: "",
    inSanitasTrial: false
  };

  const {
    values,
    isValid,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: clinicFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    formTitle: t(TranslationsEnum.Global_NewClinic),
    setFieldValue,
    handleSubmit,
    handleChange,
    handleClose: handleModalClose
  };

  return <ClinicForm {...props} />;
};

export default CreateClinicModal;
