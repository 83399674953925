import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  menuButton: {
    color: ColorEnum.White,
    marginLeft: 10
  },
  language: {
    margin: "0px 4px 0px 8px"
  },
  listItemIcon: {
    minWidth: 30
  }
}));
