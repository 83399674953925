import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History, createBrowserHistory } from "history";
import { reducer as snackbarReducer } from "./snackbar/reducer";
import { reducer as headerReducer } from "./header/reducer";
import { reducer as authReducer } from "./auth/reducer";
import { reducer as userReducer } from "./user/reducer";
import { reducer as sessionsReducer } from "./sessions/reducer";
import { reducer as electronReducer } from "./electron/reducer";
import { reducer as syncSuitReducer } from "./syncSuit/reducer";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector
} from "react-redux";

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    snackbar: snackbarReducer,
    header: headerReducer,
    auth: authReducer,
    user: userReducer,
    sessions: sessionsReducer,
    electron: electronReducer,
    syncSuit: syncSuitReducer
  });

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
  console.groupCollapsed(action.type);
  console.log("state", store.getState());
  console.info("dispatching", action);
  console.groupEnd();
  const result = next(action);
  return result;
};

export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

const rootReducer = createRootReducer(history);
export type IRootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;

const configureStore = () => {
  const middlewares = [loggerMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  const store = createStore(rootReducer, undefined, composedEnhancers);
  return store;
};

export default configureStore;
