import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Link,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { contactEmailAddress } from "../../constants/contacts";
import { TranslationsEnum } from "../../types/enums";
import { Formik, Form } from "formik";
import { schema } from "./ValidationSchema";
import { useMutation } from "@apollo/react-hooks";
import { assignPatientToAzureUserMutation } from "../../mutations/patients";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { IAssignPatientToAzureUser } from "../../types/formInputs/IAssignPatientToAzureUser";
import { useHistory } from "react-router-dom";
import { PathNamesEnum } from "../../types/enums/PathNamesEnum";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import SendIcon from "@material-ui/icons/Send";
import { IUserProfile } from "../../types/IUserProfile";
import { getUserProfileQuery } from "../../queries";
import { actions as snackbarActions } from "../../state/snackbar/actions";
import { actions as userActions } from "../../state/user/actions";
import { useDispatch } from "react-redux";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";
import { useLazyQuery } from "@apollo/react-hooks";
import useStyles from "./EnterRegistrationKeyPage.styles";

const EnterRegistrationKeyPage: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [
    getUserProfile,
    { error: errorGetUserProfile, loading: loadingGetUserProfile, data }
  ] = useLazyQuery<{
    userProfile: IUserProfile;
  }>(getUserProfileQuery, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      dispatch(userActions.setUserProfileAction(data?.userProfile));
      dispatch(
        snackbarActions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_PatientAssignedSuccessfully)
        )
      );
      history.push(PathNamesEnum.Home);
    }
  });

  const [
    assignPatientToAzureUser,
    { loading: loadingAssignPatient, error: errorAssignPatient }
  ] = useMutation(assignPatientToAzureUserMutation, {
    onError: () => {},
    onCompleted: () => getUserProfile()
  });

  useErrorHandling(errorAssignPatient);
  useErrorHandling(errorGetUserProfile);

  const handleSubmit = (values: IAssignPatientToAzureUser) => {
    assignPatientToAzureUser({
      variables: { values: { ...values } }
    });
  };

  const initialValues: IAssignPatientToAzureUser = {
    registrationKey: ""
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box>
          <VpnKeyIcon className={classes.icon} />
          <Typography variant="h3" className={classes.heading}>
            {t(TranslationsEnum.Containers_PageEnterRegistrationKey_Title)}
          </Typography>
          <Box className={classes.container}>
            <Typography variant="h6" className={classes.subHeading}>
              {t(TranslationsEnum.Containers_PageEnterRegistrationKey_Subtitle)}
              <Link
                href={`mailto:${contactEmailAddress}`}
                className={classes.link}
              >
                {contactEmailAddress}
              </Link>
            </Typography>
            <Box>
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                validateOnChange={false}
                onSubmit={handleSubmit}
              >
                {props => {
                  const { values, errors, handleChange } = props;
                  return (
                    <Form key="RegistrationKey" className={classes.form}>
                      <TextField
                        inputProps={{
                          maxLength: 6
                        }}
                        className={classes.textField}
                        name="registrationKey"
                        label={t(
                          TranslationsEnum.Containers_PageEnterRegistrationKey_Label
                        )}
                        margin="dense"
                        variant="outlined"
                        value={values.registrationKey}
                        error={Boolean(errors.registrationKey)}
                        helperText={errors.registrationKey}
                        onChange={handleChange}
                      />
                      <Button
                        className={classes.sendButton}
                        disabled={loadingGetUserProfile || loadingAssignPatient}
                        variant="contained"
                        color="primary"
                        type="submit"
                        endIcon={
                          !loadingGetUserProfile &&
                          !loadingAssignPatient && <SendIcon />
                        }
                      >
                        {loadingGetUserProfile || loadingAssignPatient ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          t(TranslationsEnum.Global_Send)
                        )}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EnterRegistrationKeyPage;
