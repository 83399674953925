import { MUIDataTableColumn } from "mui-datatables";
import { IWebParametersFilter } from "../../types/filters/IWebParametersFilter";
import { SortDirectionEnum } from "../../types/enums/SortDirectionEnum";
import { IActionColumn } from "../../types/filters/IActionColumn";
import { IFilterColumn } from "../../types/filters/IFilterColumn";

export const buildWebParametersFilters = (
  values: Record<string, string | boolean>,
  filterColumns: IFilterColumn[],
  actionColumns?: IActionColumn[]
) => {
  const keys = Object.keys(values);

  const filterKeys = filterColumns.map(x => x.name);
  const actionKeys = actionColumns?.map(x => x.name) || [];

  const webFilters: IWebParametersFilter[] = keys.reduce<
    IWebParametersFilter[]
  >((acc, x) => {
    if (values[x] && values[x] !== "" && filterKeys.indexOf(x) > -1) {
      return [
        ...acc,
        {
          filterField: x,
          filterValue: values[x] as string
        }
      ];
    }
    return acc;
  }, []);

  const actionsValues = keys.reduce((acc, x) => {
    if (values[x] !== "" && actionKeys.indexOf(x) > -1) {
      return {
        ...acc,
        [x]: values[x]
      };
    }
    return acc;
  }, {});

  return {
    webFilters,
    actionsValues
  };
};

export const attachSortToColumns = (
  columns: MUIDataTableColumn[],
  sortOptions?: {
    name: string;
    sortDirection: string;
  }
): MUIDataTableColumn[] => {
  return columns.map(column => {
    const { name, options } = column;
    let columnSort = {};
    if (sortOptions && name === sortOptions.name) {
      columnSort = {
        sort: true,
        sortDirection:
          sortOptions.sortDirection === SortDirectionEnum.Ascending
            ? "asc"
            : "desc"
      };
    }
    return {
      ...column,
      options: {
        ...(options || {}),
        ...columnSort
      }
    };
  });
};
