import gql from "graphql-tag";

export const createClinicMutation = gql`
  mutation createClinic($values: Values!) {
    createClinic(values: $values)
      @rest(
        type: "CreateClinicDto"
        path: "clinics/create"
        method: "POST"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updateClinicMutation = gql`
  mutation updateClinic($values: Values!) {
    updateClinic(values: $values)
      @rest(
        type: "UpdateClinicDto"
        path: "clinics/update"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const deleteClinicMutation = gql`
  mutation deleteClinicMutation($values: Values!) {
    deleteClinicMutation(values: $values)
      @rest(
        type: "DeleteClinic"
        path: "clinics/delete/{args.values.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;
