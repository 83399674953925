import * as React from "react";
import { ISvgProps } from "../../types/ISvgProps";

const StepsIcon = (props: ISvgProps) => {
  return (
    <svg width={36} height={36} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13.155 17.005c1.12.369 2.11.604 3.532 1.025-.546 6.644-7.072 4.005-3.532-1.026zm5.827-6.104c-.073-1.995-.438-4.612-3.346-4.202-1.363.343-2.37 1.784-2.843 4.27-.26 1.367-.11 3.285.198 4.436.28.81.184.76.486.914 1.165.253 2.319.533 3.494.818 1.194-.811 2.174-5.12 2.011-6.236zm-8.972-.153c.306-1.15.457-3.069.197-4.436-.473-2.486-1.48-3.927-2.843-4.27-2.908-.41-3.273 2.208-3.346 4.203-.163 1.116.818 5.424 2.011 6.235 1.175-.285 2.329-.565 3.495-.818.3-.153.205-.103.486-.914zm-3.697 2.626c.546 6.644 7.072 4.005 3.532-1.026-1.121.37-2.11.605-3.532 1.026z"
        fill="#195F93"
      />
    </svg>
  );
};

export default StepsIcon;
