import DeviceDetector from "device-detector-js";
import { PlatformEnum } from "../../types/enums/PlatformEnum";

export const isDesktop = (): boolean => {
  const userAgent = navigator.userAgent;
  const deviceDetector = new DeviceDetector();
  const { device } = deviceDetector.parse(userAgent);
  return device?.type === "desktop";
};

export const currentPlatform = () => {
  const userAgent = navigator.userAgent;
  switch (true) {
    case userAgent.includes(PlatformEnum.Linux):
      return PlatformEnum.Linux;
    case userAgent.includes(PlatformEnum.Mac):
      return PlatformEnum.Mac;
    default:
    case userAgent.includes(PlatformEnum.Windows):
      return PlatformEnum.Windows;
  }
};
