import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateClinicMutation } from "../../../../mutations";
import { getClinicQuery } from "../../../../queries";
import { actions } from "../../../../state/snackbar/actions";
import { TranslationsEnum } from "../../../../types/enums";
import { ClinicStatusEnum } from "../../../../types/enums/ClinicStatusEnum";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { IClinicForm } from "../../../../types/forms/IClinicForm";
import { IClinic } from "../../../../types/IClinic";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import ClinicForm from "../ClinicForm/ClinicForm";
import { clinicFormSchema } from "../ClinicForm/ClinicFormSchema";

interface IProps {
  open: boolean;
  clinicId: number | null;
  handleClose: () => void;
  refetchClinics: (variables?: { filterConfig: IFilterConfig }) => void;
}

const EditClinicModal: React.FC<IProps> = ({
  open,
  clinicId,
  refetchClinics,
  handleClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: clinicData, error: clinicError } = useQuery<{
    clinic: IClinic;
  }>(getClinicQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !clinicId,
    variables: {
      values: {
        id: clinicId
      }
    }
  });

  useErrorHandling(clinicError);
  const clinic = clinicData?.clinic;

  const handleUpdateSuccess = () => {
    handleModalClose();
    refetchClinics();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_ClinicUpdatedSuccessfully)
      )
    );
  };

  const [updateClinic, { loading, error }] = useMutation(updateClinicMutation, {
    onError: () => {},
    onCompleted: handleUpdateSuccess
  });

  useErrorHandling(error);

  const handleFormSubmit = (values: IClinicForm) => {
    if (isValid) {
      updateClinic({
        variables: {
          values: {
            ...values,
            id: clinicId
          }
        }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const initialValues: IClinicForm = {
    address: clinic?.address || "",
    description: clinic?.description || "",
    name: clinic?.name || "",
    status: clinic?.status || ClinicStatusEnum.Public,
    telephone: clinic?.telephone || "",
    inSanitasTrial: clinic?.inSanitasTrial || false
  };

  const {
    values,
    isValid,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: clinicFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    formTitle: t(TranslationsEnum.Global_EditClinic),
    setFieldValue,
    handleSubmit,
    handleChange,
    handleClose: handleModalClose
  };

  return <ClinicForm {...props} />;
};

export default EditClinicModal;
