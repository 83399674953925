import {
  Button,
  Card,
  CardActions,
  CardContent,
  Fade,
  Modal,
  Typography,
  Box,
  CircularProgress,
  TextField
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../types/enums/TranslationsEnum";
import { IProvideEmail } from "../../../types/requestDtos/IProvideEmail";
import { Formik, Form } from "formik";
import { schema } from "./ValidationSchema";
import SendIcon from "@material-ui/icons/Send";
import { SuccessDpaEmailForm } from "../../../constants/ids";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IPatientEmailByGuid } from "../../../types/IPatientEmailByGuid";
import { getPatientEmailByGuidQuery } from "../../../queries";
import { actions as snackbarActions } from "../../../state/snackbar/actions";
import { useDispatch } from "react-redux";
import { SnackbarTypeEnum } from "../../../types/enums/SnackbarTypeEnum";
import { setPatientEmailByGuidMutation } from "../../../mutations/patients";
import useStyles from "./FinishRegistrationModal.styles";

interface IProps {
  open: boolean;
  handleClose: () => void;
  guid: string;
}

const FinishRegistrationModal: React.FC<IProps> = ({
  open,
  handleClose,
  guid
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: dataGetEmail, loading: loadingGetEmail } = useQuery<{
    patientEmail: IPatientEmailByGuid;
  }>(getPatientEmailByGuidQuery(guid), {
    fetchPolicy: "cache-and-network"
  });

  const [
    setPatientEmailByGuid,
    { loading: loadingSetPatientEmailByGuid }
  ] = useMutation(setPatientEmailByGuidMutation, {
    onError: () => {
      dispatch(
        snackbarActions.showSnackbar(
          SnackbarTypeEnum.Error,
          t(TranslationsEnum.ErrorCodes_30008)
        )
      );
    },
    onCompleted: () => {
      dispatch(
        snackbarActions.showSnackbar(
          SnackbarTypeEnum.Success,
          t(TranslationsEnum.Snackbar_AddEmailSuccess)
        )
      );
      handleClose();
    }
  });

  //TODO: add error handling

  const initialValues: IProvideEmail = {
    email: "",
    guid: guid
  };

  //TODO: useMemo
  const renderCardContent = () => {
    if (dataGetEmail?.patientEmail.email) {
      return (
        <Box>
          <Typography variant="h5" className={classes.modalText}>
            {t(
              TranslationsEnum.Containers_Modals_FinishRegistrationModal_FinishRegistrationTitle
            )}
          </Typography>
          <Typography variant="body1" className={classes.modalText}>
            {t(
              TranslationsEnum.Containers_Modals_FinishRegistrationModal_FinishRegistrationSubtitle
            )}
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <Typography variant="h5" className={classes.modalText}>
          {t(
            TranslationsEnum.Containers_Modals_FinishRegistrationModal_FinishRegistrationWithEmailTitle
          )}
        </Typography>
        <Typography variant="body1" className={classes.modalText}>
          {t(
            TranslationsEnum.Containers_Modals_FinishRegistrationModal_FinishRegistrationWithEmailSubtitle
          )}
        </Typography>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={handleSubmitClick}
        >
          {props => {
            const { values, errors, handleChange } = props;

            return (
              <Form id={SuccessDpaEmailForm} key={SuccessDpaEmailForm}>
                <TextField
                  className={classes.textField}
                  name="email"
                  label={t(TranslationsEnum.Global_Email)}
                  variant="outlined"
                  value={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={handleChange}
                />
              </Form>
            );
          }}
        </Formik>
        <Typography gutterBottom variant="body1" className={classes.modalText}>
          {t(
            TranslationsEnum.Containers_Modals_FinishRegistrationModal_FinishRegistrationWarning
          )}
        </Typography>
      </Box>
    );
  };

  //TODO: useMemo

  const renderCardActions = () => {
    if (dataGetEmail?.patientEmail.email) {
      return (
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.okButton}
        >
          {t(TranslationsEnum.Global_Ok)}
        </Button>
      );
    }

    return (
      <>
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.declineButton}
        >
          {t(
            TranslationsEnum.Containers_Modals_FinishRegistrationModal_NoThankYou
          )}
        </Button>
        <Button
          variant="contained"
          form={SuccessDpaEmailForm}
          type="submit"
          className={classes.okButton}
          endIcon={<SendIcon />}
          disabled={loadingSetPatientEmailByGuid}
        >
          {loadingSetPatientEmailByGuid ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t(TranslationsEnum.Global_Send)
          )}
        </Button>
      </>
    );
  };

  const handleSubmitClick = (values: IProvideEmail) => {
    setPatientEmailByGuid({
      variables: {
        values
      }
    });
  };

  if (loadingGetEmail) {
    return <CircularProgress size={48} color="primary" />;
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card className={classes.cardContainer}>
          <CardContent>{renderCardContent()}</CardContent>
          <CardActions className={classes.cardActions}>
            {renderCardActions()}
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

export default FinishRegistrationModal;
