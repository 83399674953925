import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";
import { drawerWidth } from "../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    [theme.breakpoints.up("sm")]: {
      minHeight: theme.spacing(7)
    }
  },
  hide: {
    display: "none"
  },
  titleContainer: {
    display: "flex",
    marginRight: "auto"
  },
  title: {
    paddingTop: 7
  },
  subtitle: {
    display: "flex",
    marginTop: 13,
    marginLeft: theme.spacing(2)
  },
  white: {
    color: ColorEnum.White
  },
  subtitleArrow: {
    fontSize: 15,
    marginTop: 17,
    marginLeft: theme.spacing(2)
  },
  idTypography: {
    marginRight: theme.spacing(2)
  },
  usernameTypography: {
    marginRight: theme.spacing(3)
  }
}));
