import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  orange: {
    color: ColorEnum.OrangeDark
  },
  green: {
    color: ColorEnum.GreenDark
  },
  purple: {
    color: ColorEnum.PurpleDark
  }
}));
