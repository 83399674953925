import React, { useEffect, useState, cloneElement, ReactElement } from "react";
import clsx from "clsx";
import useStyles from "./TextHighlighter.styles";

interface IProps {
  children: ReactElement;
}

const TextHighlighter: React.FC<IProps> = ({ children }) => {
  const classes = useStyles();
  const [highlightText, setHighlightText] = useState(false);

  useEffect(() => {
    setHighlightText(true);
    const timeout = setTimeout(() => {
      setHighlightText(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [children]);

  return (
    <>
      {cloneElement(children, {
        className: clsx(
          {
            [classes.highlighted]: highlightText,
            [classes.notHighlighted]: !highlightText
          },
          children.props.className
        )
      })}
    </>
  );
};

export default TextHighlighter;
