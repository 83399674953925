import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./TextRow.styles";

interface IProps {
  descriptiveText: string;
  valueText: string | number;
}

const TextRow = (props: IProps) => {
  const classes = useStyles();
  const { descriptiveText, valueText } = props;

  return (
    <Box className={classes.row}>
      <Typography
        variant="body2"
        className={clsx(classes.text, classes.lightGrey)}
      >
        {descriptiveText}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.text, classes.darkGrey)}
      >
        {valueText}
      </Typography>
    </Box>
  );
};

export default TextRow;
