import gql from "graphql-tag";

export const getUnitStatusOptionsQuery = gql`
  query getUnitStatusOptions {
    unitStatusOptions @rest(type: "IOption", path: "unitStatus/options") {
      label
      value
    }
  }
`;
