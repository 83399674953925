import gql from "graphql-tag";

export const getIsFirmwareNeededToUpdateQuery = gql`
  query getIsFirmwareNeededToUpdateQuery {
    isFirmwareUpdateNeededResponseDto
      @rest(
        type: "IsFirmwareUpdateNeededResponseDto"
        path: "units/isFirmwareUpdateNeeded"
      ) {
      isFirmwareUpdateNeeded
    }
  }
`;

export const getMyoSuitUnitsQuery = gql`
  query getMyoSuitUnits {
    getMyoSuitUnits @rest(type: "IMyoSuitUnit", path: "units") {
      id
      unitSerialNumber
      hardwareModel
      nexusProtocolVersion
      currentFirmwareVersion
      futureFirmwareVersion
      unitStatus
      unitStatusSource
      clinicId
    }
  }
`;

export const getUnitsQuery = gql`
  query getUnits($filterConfig: IFilterConfig!) {
    unitsTableDTO(filterConfig: $filterConfig)
      @rest(
        type: "unitsTableDTO"
        path: "units/filtered"
        method: "POST"
        bodyKey: "filterConfig"
      ) {
      units
      count
    }
  }
`;

export const getUnitQuery = gql`
  query getUnit($values: Values!) {
    unit(values: $values)
      @rest(type: "IUnitDto", path: "units/{args.values.id}") {
      id
      serialNumber
      hardwareModel
      nexusProtocolVersion
      currentFirmwareVersion
      futureFirmwareVersion
      unitStatus
      unitStatusSource
      clinicId
      patient
    }
  }
`;
