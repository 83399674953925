import { ActionsUnion } from "../../utils/typings/common";
import { ISessionsTableConfig } from "../../types/filters/ISessionsTableConfig";
import { ISessionsChartConfig } from "../../types/filters/ISessionsChartConfig";
import { IChartActiveColumns } from "../../types/filters/IChartActiveColumns";

// Action types
export const SET_SELECTED_SESSIONS_IDS = "sessions/SET_SELECTED_SESSIONS_IDS";
export const SET_TABLE_CONFIG = "sessions/SET_TABLE_CONFIG";
export const SET_CHART_CONFIG = "sessions/SET_CHART_CONFIG";
export const SET_CHART_SELECTED_SESSION_BAR_INDEX =
  "sessions/SET_CHART_SELECTED_SESSION_BAR_INDEX";
export const SET_HIGHLIGHTED_SESSION_ID = "sessions/SET_HIGHLIGHTED_SESSION_ID";
export const SET_CHART_ACTIVE_COLUMNS = "sessions/SET_CHART_ACTIVE_COLUMNS";
export const SET_SELECTED_PATIENT_ID = "sessions/SET_SELECTED_PATIENT_ID";
export const RESET_SESSIONS_DATA = "sessions/RESET_SESSIONS_DATA";

// Action creators
export const actions = {
  setSelectedSessionIdsAction: (sessionsIds: Array<number>) => ({
    type: SET_SELECTED_SESSIONS_IDS as typeof SET_SELECTED_SESSIONS_IDS,
    payload: sessionsIds
  }),
  setTableConfigAction: (config: ISessionsTableConfig) => ({
    type: SET_TABLE_CONFIG as typeof SET_TABLE_CONFIG,
    payload: config
  }),
  setChartConfigAction: (config: ISessionsChartConfig) => ({
    type: SET_CHART_CONFIG as typeof SET_CHART_CONFIG,
    payload: config
  }),
  setChartSelectedSessionBarIndexAction: (index: number) => ({
    type: SET_CHART_SELECTED_SESSION_BAR_INDEX as typeof SET_CHART_SELECTED_SESSION_BAR_INDEX,
    payload: index
  }),
  setChartHighlightedSessionIdAction: (id: number | undefined) => ({
    type: SET_HIGHLIGHTED_SESSION_ID as typeof SET_HIGHLIGHTED_SESSION_ID,
    payload: id
  }),
  setChartActiveColumnsAction: (activeColumns: IChartActiveColumns) => ({
    type: SET_CHART_ACTIVE_COLUMNS as typeof SET_CHART_ACTIVE_COLUMNS,
    payload: activeColumns
  }),
  setSelectedPatientIdAction: (id: number | undefined) => ({
    type: SET_SELECTED_PATIENT_ID as typeof SET_SELECTED_PATIENT_ID,
    payload: id
  }),
  resetSessionsDataAction: () => ({
    type: RESET_SESSIONS_DATA as typeof RESET_SESSIONS_DATA
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
