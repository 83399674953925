import React, { useCallback } from "react";
import { Typography, Box, Button, Paper, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useStyles from "./PatientUploadInstructions.styles";
import { PathNamesEnum } from "../../../../types/enums/PathNamesEnum";
import { TranslationsEnum } from "../../../../types/enums";
import { getSessionFiles } from "../../../../api/sessionFiles";
import { downloadBlob } from "../../../../utils/helpers/filesHelpers";
import { IUserProfile } from "../../../../types/IUserProfile";
import {
  myoswissPricingEN,
  myoswissPricingDE,
  contactEmailAddress
} from "../../../../constants/contacts";
import { LanguageEnum } from "../../../../types/enums/LanguageEnum";
import { getSelectedLanguage } from "../../../../utils/helpers/languageHelpers";

interface IProps {
  userProfile: IUserProfile | undefined | null;
  closePatientInstructions: () => void;
}

const PatientUploadInstructions: React.FC<IProps> = ({
  userProfile,
  closePatientInstructions
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const selectedLanguage = getSelectedLanguage();
  const pricingLink =
    selectedLanguage === LanguageEnum.En
      ? myoswissPricingEN
      : myoswissPricingDE;

  const handleOnHomeClick = () => {
    history.push(PathNamesEnum.Home);
  };

  const handleDownloadClick = async () => {
    const { data } = await getSessionFiles();
    downloadBlob(data);
  };

  const renderInstructions = useCallback(() => {
    return (t(TranslationsEnum.Containers_SyncMyoSuit_InstructionsForPatient, {
      returnObjects: true
    }) as Array<string>).map((value, index) => (
      <Typography
        variant="subtitle1"
        className={clsx(classes.darkGrey, classes.instructions)}
        key={index}
      >
        {`${index + 1}. `}
        {value}
      </Typography>
    ));
  }, [t, classes.darkGrey, classes.instructions]);

  const renderNoSuitInstructions = useCallback(() => {
    return (
      <Box>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart1
          )}{" "}
          <Link href={pricingLink} target={"_blank"} underline="always">
            {pricingLink}
          </Link>
        </Typography>
        <Typography
          variant="subtitle1"
          className={clsx(classes.darkGrey, classes.instructions)}
        >
          {t(
            TranslationsEnum.Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart2
          )}{" "}
          <Link
            href={`mailto:${contactEmailAddress}`}
            target={"_blank"}
            underline="always"
          >
            {contactEmailAddress}
          </Link>
        </Typography>
      </Box>
    );
  }, [t, classes.darkGrey, classes.instructions, pricingLink]);

  return (
    <Paper className={classes.paperContainer}>
      <Typography
        variant="h6"
        className={clsx(classes.darkGrey, classes.header)}
      >
        {userProfile?.patientHasUnit
          ? t(TranslationsEnum.Global_PrepareYourProfile)
          : t(TranslationsEnum.Global_NoAvailableMyosuit)}
      </Typography>
      <Box className={classes.instructionsContainer}>
        {userProfile?.patientHasUnit
          ? renderInstructions()
          : renderNoSuitInstructions()}
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          type="submit"
          onClick={closePatientInstructions}
        >
          {t(TranslationsEnum.Global_Back)}
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          type="submit"
          onClick={handleOnHomeClick}
        >
          {t(TranslationsEnum.Containers_SyncMyoSuit_Home)}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleDownloadClick}
          disabled={!userProfile?.patientHasUnit}
        >
          {t(TranslationsEnum.Global_DownloadProfile)}
        </Button>
      </Box>
    </Paper>
  );
};

export default PatientUploadInstructions;
