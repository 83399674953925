import { useState, useCallback, useEffect } from "react";
import { IWindow } from "../../types/IWindow";
import {
  RENDERER_EVENTS,
  AUTO_UPDATE_EVENTS
} from "../../shared-constants/events";

declare const window: IWindow;

export const useAutoUpdater = () => {
  const hasCheckedOnLaunch = window.hasCheckedUpdatesOnLaunch();
  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(
    !hasCheckedOnLaunch
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [progress, setProgress] = useState(null);
  const [state, setState] = useState(null);

  const handleModalClose = useCallback(() => {
    window.downloadUpdate();
    setShowModal(false);
    setShowUpdateProgress(true);
  }, []);

  const handleStateChange = useCallback(
    (event, nextState) => {
      setState(nextState);
      if (nextState === AUTO_UPDATE_EVENTS.UPDATE_AVAILABLE) {
        if (hasCheckedOnLaunch) {
          setShowModal(true);
        } else {
          window.downloadUpdate();
        }
      }
      if (nextState === AUTO_UPDATE_EVENTS.UPDATE_DOWNLOADED) {
        setProgress(null);
        window.installUpdate();
      }
      if (nextState === AUTO_UPDATE_EVENTS.UP_TO_DATE) {
        setShowUpdateProgress(false);
      }
    },
    [hasCheckedOnLaunch]
  );

  const handleUpdateProgress = useCallback((event, nextProgress) => {
    setProgress(nextProgress);
  }, []);

  useEffect(() => {
    window.ipcRenderer.on(
      RENDERER_EVENTS.ON_UPDATE_STATE_CHANGE,
      handleStateChange
    );
    window.ipcRenderer.on(
      RENDERER_EVENTS.ON_UPDATE_PROGRESS_CHANGE,
      handleUpdateProgress
    );

    if (!hasCheckedOnLaunch) {
      window.checkForUpdates();
    }

    return () => {
      window.ipcRenderer.removeAllListeners(
        RENDERER_EVENTS.ON_UPDATE_STATE_CHANGE
      );
      window.ipcRenderer.removeAllListeners(
        RENDERER_EVENTS.ON_UPDATE_PROGRESS_CHANGE
      );
    };
  }, [handleStateChange, handleUpdateProgress, hasCheckedOnLaunch]);

  return {
    showUpdateProgress,
    showModal,
    handleModalClose,
    progress,
    state
  };
};
