import React, { useMemo, useCallback } from "react";
import { Box, FormControlLabel, Checkbox } from "@material-ui/core";
import { SelectField } from "../../../../components";
import { useTranslation } from "react-i18next";
import { ChartColumnEnum } from "../../../../types/enums/ChartColumnEnum";
import { ISelectField } from "../../../../types/formInputs/ISelectField";
import { SessionSelectEnum } from "../../../../types/enums/SessionSelectEnum";
import { useSelector } from "../../../../state/store";
import {
  selectActiveColumns,
  selectSessionsChartConfig
} from "../../../../utils/helpers/stateSelectorHelpers";
import { actions } from "../../../../state/sessions/actions";
import { useDispatch } from "react-redux";
import { TFunction } from "i18next";
import useStyles from "./ChartHeader.styles";
import { TranslationsEnum } from "../../../../types/enums";

const sessionsSelect = (
  t: TFunction,
  selectValue: SessionSelectEnum
): ISelectField => {
  const options = [
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_LastSession
      ),
      value: SessionSelectEnum.LastSession
    },
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last5Session
      ),
      value: SessionSelectEnum.Last5Session
    },
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last10Session
      ),
      value: SessionSelectEnum.Last10Session
    },
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last30days
      ),
      value: SessionSelectEnum.Last30days
    },
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_CustomPeriod
      ),
      value: SessionSelectEnum.CustomPeriod
    },
    {
      label: t(
        TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_AllSessions
      ),
      value: SessionSelectEnum.AllSessions
    }
  ];

  return {
    name: "sessionsSelect",
    options:
      selectValue === SessionSelectEnum.CustomSelect
        ? [
            ...options,
            {
              label: t(
                TranslationsEnum.Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_CustomSelect
              ),
              value: SessionSelectEnum.CustomSelect
            }
          ]
        : options
  };
};

interface IProps {
  setOpenCalendarControl: React.Dispatch<React.SetStateAction<boolean>>;
  defaultSelectedSessionId?: number;
}

const ChartHeader = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setOpenCalendarControl, defaultSelectedSessionId } = props;

  const { activeColumns, sessionsChartConfig } = useSelector(state => ({
    ...selectActiveColumns(state),
    ...selectSessionsChartConfig(state)
  }));

  const selectField = useMemo(
    () => sessionsSelect(t, sessionsChartConfig.selectValue),
    [sessionsChartConfig.selectValue, t]
  );

  const handleMenuItemClick = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      if (event) {
        const {
          target: { value }
        } = event;
        if (value === SessionSelectEnum.CustomPeriod) {
          setOpenCalendarControl(true);
        }
      }
    },
    [setOpenCalendarControl]
  );

  const handleCheckClick = useCallback(
    (columnName: ChartColumnEnum) => () =>
      dispatch(
        actions.setChartActiveColumnsAction({
          ...activeColumns,
          [columnName]: !activeColumns[columnName]
        })
      ),
    [activeColumns, dispatch]
  );

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const sessionSelectFieldValue = e.target.value as SessionSelectEnum;
      dispatch(actions.setChartSelectedSessionBarIndexAction(0));
      dispatch(
        actions.setChartHighlightedSessionIdAction(defaultSelectedSessionId)
      );

      if (sessionSelectFieldValue !== SessionSelectEnum.CustomPeriod) {
        dispatch(
          actions.setChartConfigAction({
            ...sessionsChartConfig,
            selectValue: sessionSelectFieldValue,
            begin: undefined,
            end: undefined,
            page: 0
          })
        );
      }
    },
    [dispatch, sessionsChartConfig, defaultSelectedSessionId]
  );

  return (
    <Box className={classes.container}>
      <Box>
        <SelectField
          SelectProps={{
            disableUnderline: true
          }}
          MenuItemProps={{
            onMenuItemClick: handleMenuItemClick
          }}
          selectField={selectField}
          value={sessionsChartConfig.selectValue}
          onChange={handleSelectChange}
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeColumns.showSteps}
              value={activeColumns.showSteps}
              onChange={handleCheckClick(ChartColumnEnum.ShowSteps)}
              className={classes.orange}
              color="default"
              classes={{
                checked: classes.orange
              }}
            />
          }
          label={t(TranslationsEnum.Global_Steps)}
          className={classes.orange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeColumns.showStairs}
              value={activeColumns.showStairs}
              onChange={handleCheckClick(ChartColumnEnum.ShowStairs)}
              className={classes.green}
              color="default"
              classes={{
                checked: classes.green
              }}
            />
          }
          label={t(TranslationsEnum.Global_Stairs)}
          className={classes.green}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeColumns.showActiveTime}
              value={activeColumns.showActiveTime}
              onChange={handleCheckClick(ChartColumnEnum.ShowActiveTime)}
              className={classes.purple}
              color="default"
              classes={{
                checked: classes.purple
              }}
            />
          }
          label={t(TranslationsEnum.Global_ActiveTime)}
          className={classes.purple}
        />
      </Box>
    </Box>
  );
};

export default ChartHeader;
