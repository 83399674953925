import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { drawerWidth } from "../../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  content: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    paddingTop: theme.spacing(7),
    minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
    height: "100%"
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  container: {
    minHeight: `calc(100% - ${theme.spacing(7)}px)`
  }
}));
