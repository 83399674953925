export enum TranslationsEnum {
  Components_DataTableFooter_0Records = "Components.DataTableFooter.0Records",
  Components_DataTableFooter_First = "Components.DataTableFooter.First",
  Components_DataTableFooter_Last = "Components.DataTableFooter.Last",
  Components_DataTableFooter_Next = "Components.DataTableFooter.Next",
  Components_DataTableFooter_Of = "Components.DataTableFooter.Of",
  Components_DataTableFooter_Page = "Components.DataTableFooter.Page",
  Components_DataTableFooter_Previous = "Components.DataTableFooter.Previous",
  Components_FilterChipBar_ClearFilters = "Components.FilterChipBar.ClearFilters",
  Components_FilterChipBar_ResetSorting = "Components.FilterChipBar.ResetSorting",
  Components_Header_Operator = "Components.Header.Operator",
  Components_Header_PrepareSession = "Components.Header.PrepareSession",
  Components_Header_SideMenu_DPALink = "Components.Header.SideMenu.DPALink",
  Components_Header_SideMenu_DesktopApp = "Components.Header.SideMenu.DesktopApp",
  Components_Header_SideMenu_Home = "Components.Header.SideMenu.Home",
  Components_Header_SideMenu_Impressum = "Components.Header.SideMenu.Impressum",
  Components_Header_SideMenu_Messages = "Components.Header.SideMenu.Messages",
  Components_Header_SideMenu_Resources = "Components.Header.SideMenu.Resources",
  Components_Header_SideMenu_QuestionnaireDownload = "Components.Header.SideMenu.QuestionnaireDownload",
  Components_Header_SideMenu_DPADownload = "Components.Header.SideMenu.DPADownload",
  Components_Header_SideMenu_Results = "Components.Header.SideMenu.Results",
  Components_Header_SideMenu_SyncMyoSuit = "Components.Header.SideMenu.SyncMyoSuit",
  Components_Header_SideMenu_Users = "Components.Header.SideMenu.Users",
  Components_Header_SideMenu_CostApproval = "Components.Header.SideMenu.CostApproval",
  Components_Header_SideMenu_FirmwareWarning = "Components.Header.SideMenu.FirmwareWarning",
  Components_Header_SyncMyoSuit = "Components.Header.SyncMyoSuit",
  Components_Header_Users = "Components.Header.Users",
  Components_Table_NoMatchingRecordsFound = "Components.Table.NoMatchingRecordsFound",
  Components_Table_Sort = "Components.Table.Sort",
  Containers_CostApproval_DownloadPdf = "Containers.CostApproval.DownloadPdf",
  Containers_CostApproval_NameOfPractice = "Containers.CostApproval.NameOfPractice",
  Containers_CostApproval_Street = "Containers.CostApproval.Street",
  Containers_CostApproval_Place = "Containers.CostApproval.Place",
  Containers_CostApproval_PlaceDate = "Containers.CostApproval.PlaceDate",
  Containers_CostApproval_PlaceClinic = "Containers.CostApproval.PlaceClinic",
  Containers_CostApproval_DateGenerated = "Containers.CostApproval.DateGenerated",
  Containers_CostApproval_ObjectOfApproval = "Containers.CostApproval.ObjectOfApproval",
  Containers_CostApproval_SirOrMadam = "Containers.CostApproval.SirOrMadam",
  Containers_CostApproval_Request = "Containers.CostApproval.Request",
  Containers_CostApproval_PatientDetails = "Containers.CostApproval.PatientDetails",
  Containers_CostApproval_FirstName = "Containers.CostApproval.FirstName",
  Containers_CostApproval_Surname = "Containers.CostApproval.Surname",
  Containers_CostApproval_AddressLiving = "Containers.CostApproval.AddressLiving",
  Containers_CostApproval_Birthdate = "Containers.CostApproval.Birthdate",
  Containers_CostApproval_Gender = "Containers.CostApproval.Gender",
  Containers_CostApproval_InsuranceNumber = "Containers.CostApproval.InsuranceNumber",
  Containers_CostApproval_Pathology = "Containers.CostApproval.Pathology",
  Containers_CostApproval_UsabilityTestResults = "Containers.CostApproval.UsabilityTestResults",
  Containers_CostApproval_ResultAssessment = "Containers.CostApproval.ResultAssessment",
  Containers_CostApproval_Met10 = "Containers.CostApproval.Met10",
  Containers_CostApproval_Min2 = "Containers.CostApproval.Min2",
  Containers_CostApproval_Berg = "Containers.CostApproval.Berg",
  Containers_CostApproval_Tinetti = "Containers.CostApproval.Tinetti",
  Containers_CostApproval_Tug = "Containers.CostApproval.Tug",
  Containers_CostApproval_Other = "Containers.CostApproval.Other",
  Containers_CostApproval_Prescription = "Containers.CostApproval.Prescription",
  Containers_CostApproval_PresentAndAttached = "Containers.CostApproval.PresentAndAttached",
  Containers_CostApproval_NotPresent = "Containers.CostApproval.NotPresent",
  Containers_CostApproval_ConsentForm = "Containers.CostApproval.ConsentForm",
  Containers_CostApproval_PatientCheck = "Containers.CostApproval.PatientCheck",
  Containers_CostApproval_SpeakGerman = "Containers.CostApproval.SpeakGerman",
  Containers_CostApproval_ConsentSigned = "Containers.CostApproval.ConsentSigned",
  Containers_CostApproval_PrecludingConditions = "Containers.CostApproval.PrecludingConditions",
  Containers_CostApproval_ZsrNumberOfPT = "Containers.CostApproval.ZsrNumberOfPT",
  Containers_CostApproval_DetailsOfPT = "Containers.CostApproval.DetailsOfPT",
  Containers_CostApproval_ZrsGln = "Containers.CostApproval.ZrsGln",
  Containers_CostApproval_StartDate = "Containers.CostApproval.StartDate",
  Containers_CostApproval_Thanks = "Containers.CostApproval.Thanks",
  Containers_CreateEditMessage_AllGroups = "Containers.CreateEditMessage.AllGroups",
  Containers_CreateEditMessage_ChooseMessageReceiverType = "Containers.CreateEditMessage.ChooseMessageReceiverType",
  Containers_CreateEditMessage_CreateMessage = "Containers.CreateEditMessage.CreateMessage",
  Containers_CreateEditMessage_EditMessage = "Containers.CreateEditMessage.EditMessage",
  Containers_CreateEditMessage_MessageTitle = "Containers.CreateEditMessage.MessageTitle",
  Containers_CreateEditMessage_OperatorGroup = "Containers.CreateEditMessage.OperatorGroup",
  Containers_CreateEditMessage_PatientGroup = "Containers.CreateEditMessage.PatientGroup",
  Containers_CreateEditMessage_Private = "Containers.CreateEditMessage.Private",
  Containers_CreateEditMessage_SelectUser = "Containers.CreateEditMessage.SelectUser",
  Containers_DownloadDesktopApp_CouldNotLoadError = "Containers.DownloadDesktopApp.CouldNotLoadError",
  Containers_DownloadDesktopApp_Description = "Containers.DownloadDesktopApp.Description",
  Containers_DownloadDesktopApp_DownloadApp = "Containers.DownloadDesktopApp.DownloadApp",
  Containers_DownloadDesktopApp_ForPlatform = "Containers.DownloadDesktopApp.ForPlatform",
  Containers_DownloadDesktopApp_Title = "Containers.DownloadDesktopApp.Title",
  Containers_Home_RegisteredUsersText = "Containers.Home.RegisteredUsersText",
  Containers_Home_RequestsCountText = "Containers.Home.RequestsCountText",
  Containers_Home_UsersCountText = "Containers.Home.UsersCountText",
  Containers_Home_ViewNewRequests = "Containers.Home.ViewNewRequests",
  Containers_Home_ViewUsersButton = "Containers.Home.ViewUsersButton",
  Containers_Home_Welcome = "Containers.Home.Welcome",
  Containers_Messages_HeaderColumns_SentAt = "Containers.Messages.HeaderColumns.SentAt",
  Containers_Messages_HeaderColumns_Title = "Containers.Messages.HeaderColumns.Title",
  Containers_Messages_NewMessage = "Containers.Messages.NewMessage",
  Containers_Modals_SessionCommentsModal_Met10 = "Containers.Modals.SessionCommentsModal.Met10",
  Containers_Modals_SessionCommentsModal_Min2 = "Containers.Modals.SessionCommentsModal.Min2",
  Containers_Modals_SessionCommentsModal_Berg = "Containers.Modals.SessionCommentsModal.Berg",
  Containers_Modals_SessionCommentsModal_Tinetti = "Containers.Modals.SessionCommentsModal.Tinetti",
  Containers_Modals_SessionCommentsModal_Tug = "Containers.Modals.SessionCommentsModal.Tug",
  Containers_Modals_SessionCommentsModal_Other = "Containers.Modals.SessionCommentsModal.Other",
  Containers_Modals_SessionCommentsModal_AddComments = "Containers.Modals.SessionCommentsModal.AddComments",
  Containers_Modals_SessionCommentsModal_AddGoal = "Containers.Modals.SessionCommentsModal.AddGoal",
  Containers_Modals_SessionCommentsModal_AddResults = "Containers.Modals.SessionCommentsModal.AddResults",
  Containers_Modals_SessionCommentsModal_EditCommentsTitle = "Containers.Modals.SessionCommentsModal.EditCommentsTitle",
  Containers_Modals_SessionCommentsModal_EditResults = "Containers.Modals.SessionCommentsModal.EditResults",
  Containers_Modals_SessionCommentsModal_EditComments = "Containers.Modals.SessionCommentsModal.EditComments",
  Containers_Modals_SessionCommentsModal_SessionDate = "Containers.Modals.SessionCommentsModal.SessionDate",
  Containers_Modals_SessionCommentsModal_Goal = "Containers.Modals.SessionCommentsModal.Goal",
  Containers_Modals_SessionCommentsModal_Comment = "Containers.Modals.SessionCommentsModal.Comment",
  Containers_Modals_SessionCommentsModal_NoGoals = "Containers.Modals.SessionCommentsModal.NoGoals",
  Containers_Modals_SessionCommentsModal_ViewOrEditGoals = "Containers.Modals.SessionCommentsModal.ViewOrEditGoals",
  Containers_Modals_QuestionnaireExplanation_Explanation = "Containers.Modals.QuestionnaireExplanation.Explanation",
  Containers_Modals_QuestionnaireExplanation_DoTestNow = "Containers.Modals.QuestionnaireExplanation.DoTestNow",
  Containers_Modals_QuestionnaireExplanation_StartNow = "Containers.Modals.QuestionnaireExplanation.StartNow",
  Containers_Modals_QuestionnaireExplanation_Later = "Containers.Modals.QuestionnaireExplanation.Later",
  Containers_Modals_Questionnaire_TickAllThatApply = "Containers.Modals.Questionnaire.TickAllThatApply",
  Containers_Modals_Questionnaire_Title = "Containers.Modals.Questionnaire.Title",
  Containers_Modals_Questionnaire_GeneralOne_Title = "Containers.Modals.Questionnaire.GeneralOne.Title",
  Containers_Modals_Questionnaire_GeneralOne_Height = "Containers.Modals.Questionnaire.GeneralOne.Height",
  Containers_Modals_Questionnaire_GeneralOne_Weight = "Containers.Modals.Questionnaire.GeneralOne.Weight",
  Containers_Modals_Questionnaire_GeneralOne_ReachScore = "Containers.Modals.Questionnaire.GeneralOne.ReachScore",
  Containers_Modals_Questionnaire_GeneralOne_AnkleContracture = "Containers.Modals.Questionnaire.GeneralOne.AnkleContracture",
  Containers_Modals_Questionnaire_GeneralOne_VersusValgusDeformity = "Containers.Modals.Questionnaire.GeneralOne.VersusValgusDeformity",
  Containers_Modals_Questionnaire_GeneralOne_IsPregnant = "Containers.Modals.Questionnaire.GeneralOne.IsPregnant",
  Containers_Modals_Questionnaire_GeneralOne_Hearing = "Containers.Modals.Questionnaire.GeneralOne.Hearing",
  Containers_Modals_Questionnaire_GeneralOne_SagittalPlane = "Containers.Modals.Questionnaire.GeneralOne.SagittalPlane",
  Containers_Modals_Questionnaire_GeneralOne_CanStand = "Containers.Modals.Questionnaire.GeneralOne.CanStand",
  Containers_Modals_Questionnaire_GeneralTwo_Title = "Containers.Modals.Questionnaire.GeneralTwo.Title",
  Containers_Modals_Questionnaire_GeneralTwo_ShufflingManner = "Containers.Modals.Questionnaire.GeneralTwo.ShufflingManner",
  Containers_Modals_Questionnaire_GeneralTwo_ShufflingMannerReminder = "Containers.Modals.Questionnaire.GeneralTwo.ShufflingMannerReminder",
  Containers_Modals_Questionnaire_GeneralTwo_HipFlexionContracture = "Containers.Modals.Questionnaire.GeneralTwo.HipFlexionContracture",
  Containers_Modals_Questionnaire_GeneralTwo_HipFlexionContractureReminder = "Containers.Modals.Questionnaire.GeneralTwo.HipFlexionContractureReminder",
  Containers_Modals_Questionnaire_GeneralTwo_AdductorContracture = "Containers.Modals.Questionnaire.GeneralTwo.AdductorContracture",
  Containers_Modals_Questionnaire_GeneralTwo_AdductorContractureReminder = "Containers.Modals.Questionnaire.GeneralTwo.AdductorContractureReminder",
  Containers_Modals_Questionnaire_DeficiencyMain_Title = "Containers.Modals.Questionnaire.DeficiencyMain.Title",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_Title = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.Title",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_KneeExtensionDeficit = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.KneeExtensionDeficit",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_PlantarflexionWeakness = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.PlantarflexionWeakness",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_DorsiflexionWeakness = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.DorsiflexionWeakness",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_HipExtensionWeakness = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.HipExtensionWeakness",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_HipFlexionWeakness = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.HipFlexionWeakness",
  Containers_Modals_Questionnaire_DeficiencyMain_Weakness_AbductorWeakness = "Containers.Modals.Questionnaire.DeficiencyMain.Weakness.AbductorWeakness",
  Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_Title = "Containers.Modals.Questionnaire.DeficiencyMain.Spasticity.Title",
  Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_HamstringSpasticity = "Containers.Modals.Questionnaire.DeficiencyMain.Spasticity.HamstringSpasticity",
  Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_HipFlexionsSpasticity = "Containers.Modals.Questionnaire.DeficiencyMain.Spasticity.HipFlexionsSpasticity",
  Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_KneeExtensionSpasticity = "Containers.Modals.Questionnaire.DeficiencyMain.Spasticity.KneeExtensionSpasticity",
  Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_PlantarflexionSpasticity = "Containers.Modals.Questionnaire.DeficiencyMain.Spasticity.PlantarflexionSpasticity",
  Containers_Modals_Questionnaire_DeficiencyMain_Contracture_Title = "Containers.Modals.Questionnaire.DeficiencyMain.Contracture.Title",
  Containers_Modals_Questionnaire_DeficiencyMain_Contracture_AnkleFixed = "Containers.Modals.Questionnaire.DeficiencyMain.Contracture.AnkleFixed",
  Containers_Modals_Questionnaire_DeficiencyMain_Contracture_KneeFlexionContracture = "Containers.Modals.Questionnaire.DeficiencyMain.Contracture.KneeFlexionContracture",
  Containers_Modals_Questionnaire_DeficiencyMain_Contracture_HipFlexionContracture = "Containers.Modals.Questionnaire.DeficiencyMain.Contracture.HipFlexionContracture",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_Title = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.Title",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_NarrowStanceWidth = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.NarrowStanceWidth",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_AsymmetricalWeightBearing = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.AsymmetricalWeightBearing",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_UnevenLegLength = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.UnevenLegLength",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_WideStanceWidth = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.WideStanceWidth",
  Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_CircumductionHipHiking = "Containers.Modals.Questionnaire.DeficiencyMain.PosturalControl.CircumductionHipHiking",
  Containers_Modals_Questionnaire_DeficiencyDetails_Ankle = "Containers.Modals.Questionnaire.DeficiencyDetails.Ankle",
  Containers_Modals_Questionnaire_DeficiencyDetails_Knee = "Containers.Modals.Questionnaire.DeficiencyDetails.Knee",
  Containers_Modals_Questionnaire_DeficiencyDetails_Hip = "Containers.Modals.Questionnaire.DeficiencyDetails.Hip",
  Containers_Modals_Questionnaire_DeficiencyDetails_UpperBody = "Containers.Modals.Questionnaire.DeficiencyDetails.UpperBody",
  Containers_Modals_Questionnaire_DeficiencyDetails_Coronal = "Containers.Modals.Questionnaire.DeficiencyDetails.Coronal",
  Containers_Modals_Questionnaire_DeficiencyStance_Name = "Containers.Modals.Questionnaire.DeficiencyStance.Name",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuVeilPF25 = "Containers.Modals.Questionnaire.DeficiencyStance.ZuVeilPF25",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuWenigPF25 = "Containers.Modals.Questionnaire.DeficiencyStance.ZuWenigPF25",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuWenigBeugung = "Containers.Modals.Questionnaire.DeficiencyStance.ZuWenigBeugung",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuVielStreckung345 = "Containers.Modals.Questionnaire.DeficiencyStance.ZuVielStreckung345",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuWenigStreckung34 = "Containers.Modals.Questionnaire.DeficiencyStance.ZuWenigStreckung34",
  Containers_Modals_Questionnaire_DeficiencyStance_ZuVielBeugung25 = "Containers.Modals.Questionnaire.DeficiencyStance.ZuVielBeugung25",
  Containers_Modals_Questionnaire_DeficiencyStance_InfoHeader = "Containers.Modals.Questionnaire.DeficiencyStance.InfoHeader",
  Containers_Modals_Questionnaire_DeficiencyStance_InfoBody = "Containers.Modals.Questionnaire.DeficiencyStance.InfoBody",
  Containers_Modals_Questionnaire_DeficiencyStance_InfoFooter = "Containers.Modals.Questionnaire.DeficiencyStance.InfoFooter",
  Containers_Modals_Questionnaire_DeficiencySwing_Name = "Containers.Modals.Questionnaire.DeficiencySwing.Name",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuVielPF8 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuVielPF8",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuWenigPF6 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuWenigPF6",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuWenigBeugung67 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuWenigBeugung67",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuVielStreckung8 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuVielStreckung8",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuWenigStreckung8 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuWenigStreckung8",
  Containers_Modals_Questionnaire_DeficiencySwing_ZuVielBeugung67 = "Containers.Modals.Questionnaire.DeficiencySwing.ZuVielBeugung67",
  Containers_Modals_Questionnaire_DeficiencySwing_InfoHeader = "Containers.Modals.Questionnaire.DeficiencySwing.InfoHeader",
  Containers_Modals_Questionnaire_DeficiencySwing_InfoBody = "Containers.Modals.Questionnaire.DeficiencySwing.InfoBody",
  Containers_Modals_Questionnaire_DeficiencySwing_InfoFooter = "Containers.Modals.Questionnaire.DeficiencySwing.InfoFooter",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_Name = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.Name",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_AnteriorFlexion = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.AnteriorFlexion",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_PosteriorFlexion = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.PosteriorFlexion",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_FlexionLatera = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.FlexionLatera",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoHeader = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.InfoHeader",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoBody = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.InfoBody",
  Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoFooter = "Containers.Modals.Questionnaire.DeficiencyDetailsUpperBody.InfoFooter",
  Containers_Modals_Questionnaire_Results_NotAdded = "Containers.Modals.Questionnaire.Results.NotAdded",
  Containers_Modals_Questionnaire_Results_Height = "Containers.Modals.Questionnaire.Results.Height",
  Containers_Modals_Questionnaire_Results_Weight = "Containers.Modals.Questionnaire.Results.Weight",
  Containers_Modals_Questionnaire_Results_ReachScore = "Containers.Modals.Questionnaire.Results.ReachScore",
  Containers_Modals_Questionnaire_Results_AnkleContracture = "Containers.Modals.Questionnaire.Results.AnkleContracture",
  Containers_Modals_Questionnaire_Results_VersusValgusDeformity = "Containers.Modals.Questionnaire.Results.VersusValgusDeformity",
  Containers_Modals_Questionnaire_Results_IsPregnant = "Containers.Modals.Questionnaire.Results.IsPregnant",
  Containers_Modals_Questionnaire_Results_Hearing = "Containers.Modals.Questionnaire.Results.Hearing",
  Containers_Modals_Questionnaire_Results_SagittalPlane = "Containers.Modals.Questionnaire.Results.SagittalPlane",
  Containers_Modals_Questionnaire_Results_CanStand = "Containers.Modals.Questionnaire.Results.CanStand",
  Containers_Modals_Questionnaire_Results_Added = "Containers.Modals.Questionnaire.Results.Added",
  Containers_Modals_Questionnaire_Results_NoProfit = "Containers.Modals.Questionnaire.Results.NoProfit",
  Containers_Modals_Questionnaire_Results_NormalProfit = "Containers.Modals.Questionnaire.Results.NormalProfit",
  Containers_Modals_Questionnaire_Results_OptimalProfit = "Containers.Modals.Questionnaire.Results.OptimalProfit",
  Containers_Modals_Questionnaire_InclusionAlert = "Containers.Modals.Questionnaire.InclusionAlert",
  Containers_Modals_Questionnaire_GaitPhaseExplanation_Header = "Containers.Modals.Questionnaire.GaitPhaseExplanation.Header",
  Containers_Modals_Questionnaire_GaitPhaseExplanation_Body = "Containers.Modals.Questionnaire.GaitPhaseExplanation.Body",
  Containers_Modals_Questionnaire_GaitPhaseExplanation_Footer = "Containers.Modals.Questionnaire.GaitPhaseExplanation.Footer",
  Containers_Modals_AddTrainerModal_ChooseClinic = "Containers.Modals.AddTrainerModal.ChooseClinic",
  Containers_Modals_AddTrainerModal_Info = "Containers.Modals.AddTrainerModal.Info",
  Containers_Modals_AddTrainerModal_NoClinicsFound = "Containers.Modals.AddTrainerModal.NoClinicsFound",
  Containers_Modals_AddTrainerModal_RequestSuccessPart1 = "Containers.Modals.AddTrainerModal.RequestSuccessPart1",
  Containers_Modals_AddTrainerModal_RequestSuccessPart2 = "Containers.Modals.AddTrainerModal.RequestSuccessPart2",
  Containers_Modals_Error_HeaderTitle = "Containers.Modals.Error.HeaderTitle",
  Containers_Modals_FinishRegistrationModal_FinishRegistrationSubtitle = "Containers.Modals.FinishRegistrationModal.FinishRegistrationSubtitle",
  Containers_Modals_FinishRegistrationModal_FinishRegistrationTitle = "Containers.Modals.FinishRegistrationModal.FinishRegistrationTitle",
  Containers_Modals_FinishRegistrationModal_FinishRegistrationWarning = "Containers.Modals.FinishRegistrationModal.FinishRegistrationWarning",
  Containers_Modals_FinishRegistrationModal_FinishRegistrationWithEmailSubtitle = "Containers.Modals.FinishRegistrationModal.FinishRegistrationWithEmailSubtitle",
  Containers_Modals_FinishRegistrationModal_FinishRegistrationWithEmailTitle = "Containers.Modals.FinishRegistrationModal.FinishRegistrationWithEmailTitle",
  Containers_Modals_FinishRegistrationModal_NoThankYou = "Containers.Modals.FinishRegistrationModal.NoThankYou",
  Containers_Modals_Message_DeleteMessageModal_Title = "Containers.Modals.Message.DeleteMessageModal.Title",
  Containers_Modals_Clinic_DeleteClinicModal_Title = "Containers.Modals.Clinic.DeleteClinicModal.Title",
  Containers_Modals_Unit_DeleteUnitModal_Title = "Containers.Modals.Unit.DeleteUnitModal.Title",
  Containers_Modals_Patient_CreatePatientModal_SuccessMessage = "Containers.Modals.Patient.CreatePatientModal.SuccessMessage",
  Containers_Modals_Patient_DeletePatientModal_OperatorTitle = "Containers.Modals.Patient.DeletePatientModal.OperatorTitle",
  Containers_Modals_Patient_DeletePatientModal_PatientTitle = "Containers.Modals.Patient.DeletePatientModal.PatientTitle",
  Containers_Modals_Patient_PatientForm_DateOfBirth = "Containers.Modals.Patient.PatientForm.DateOfBirth",
  Containers_Modals_Patient_PatientForm_EditUser = "Containers.Modals.Patient.PatientForm.EditUser",
  Containers_Modals_Patient_PatientForm_Email = "Containers.Modals.Patient.PatientForm.Email",
  Containers_Modals_Patient_PatientForm_EnterEmailOrNumber = "Containers.Modals.Patient.PatientForm.EnterEmailOrNumber",
  Containers_Modals_Patient_PatientForm_FrenchNotAvailable = "Containers.Modals.Patient.PatientForm.FrenchNotAvailable",
  Containers_Modals_Patient_PatientForm_FullName = "Containers.Modals.Patient.PatientForm.FullName",
  Containers_Modals_Patient_PatientForm_Height = "Containers.Modals.Patient.PatientForm.Height",
  Containers_Modals_Patient_PatientForm_LanguageSelect = "Containers.Modals.Patient.PatientForm.LanguageSelect",
  Containers_Modals_Patient_PatientForm_NewUser = "Containers.Modals.Patient.PatientForm.NewUser",
  Containers_Modals_Patient_PatientForm_OtherPathology = "Containers.Modals.Patient.PatientForm.OtherPathology",
  Containers_Modals_Patient_PatientForm_Pathology = "Containers.Modals.Patient.PatientForm.Pathology",
  Containers_Modals_Patient_PatientForm_TimeSince = "Containers.Modals.Patient.PatientForm.TimeSince",
  Containers_Modals_Patient_PatientForm_Weight = "Containers.Modals.Patient.PatientForm.Weight",
  Containers_Modals_Patient_PatientForm_AddPatientToTrial = "Containers.Modals.Patient.PatientForm.AddPatientToTrial",
  Containers_Modals_Patient_PatientForm_StartDate = "Containers.Modals.Patient.PatientForm.StartDate",
  Containers_Modals_Patient_PatientForm_EndDate = "Containers.Modals.Patient.PatientForm.EndDate",
  Containers_Modals_Patient_UpdatePatientModal_SuccessMessage = "Containers.Modals.Patient.UpdatePatientModal.SuccessMessage",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevels = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevels",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevelsForm_ConcFL = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.ConcFL",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevelsForm_ConcFR = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.ConcFR",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevelsForm_EccFL = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.EccFL",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_AssistanceLevelsForm_EccFR = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.AssistanceLevelsForm.EccFR",
  Containers_MyoSuitSetupTab_AssistanceLevelsCard_SuccessMessage = "Containers.MyoSuitSetupTab.AssistanceLevelsCard.SuccessMessage",
  Containers_MyoSuitSetupTab_MyoSuitCalibrationCard_Calibrated = "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.Calibrated",
  Containers_MyoSuitSetupTab_MyoSuitCalibrationCard_LastCalibration = "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.LastCalibration",
  Containers_MyoSuitSetupTab_MyoSuitCalibrationCard_NotCalibrated = "Containers.MyoSuitSetupTab.MyoSuitCalibrationCard.NotCalibrated",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizes = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizes",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size01Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size01Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size02Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size02Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size03Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size03Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size04Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size04Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size05Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size05Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size06Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size06Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_StrapSizesForm_Size07Label = "Containers.MyoSuitSetupTab.StrapSizesCard.StrapSizesForm.Size07Label",
  Containers_MyoSuitSetupTab_StrapSizesCard_SuccessMessage = "Containers.MyoSuitSetupTab.StrapSizesCard.SuccessMessage",
  Containers_PageContact_PageContactSubtitle = "Containers.PageContact.PageContactSubtitle",
  Containers_PageContact_PageContactTitle = "Containers.PageContact.PageContactTitle",
  Containers_PageContact_SupportButton = "Containers.PageContact.SupportButton",
  Containers_PageEnterRegistrationKey_Label = "Containers.PageEnterRegistrationKey.Label",
  Containers_PageEnterRegistrationKey_Subtitle = "Containers.PageEnterRegistrationKey.Subtitle",
  Containers_PageEnterRegistrationKey_Title = "Containers.PageEnterRegistrationKey.Title",
  Containers_PageNoConnection_PageNoConnectionSubtitle = "Containers.PageNoConnection.PageNoConnectionSubtitle",
  Containers_PageNoConnection_PageNoConnectionTitle = "Containers.PageNoConnection.PageNoConnectionTitle",
  Containers_PageNotFound_HomeButton = "Containers.PageNotFound.HomeButton",
  Containers_PageNotFound_PageSubTitle404 = "Containers.PageNotFound.PageSubTitle404",
  Containers_PageNotFound_PageTitle404 = "Containers.PageNotFound.PageTitle404",
  Containers_PageUnauthorized_PageUnauthorizedSubtitle = "Containers.PageUnauthorized.PageUnauthorizedSubtitle",
  Containers_PageUnauthorized_PageUnauthorizedTitle = "Containers.PageUnauthorized.PageUnauthorizedTitle",
  Containers_Patients_AddNewUser = "Containers.Patients.AddNewUser",
  Containers_Patients_HeaderColumns_Height = "Containers.Patients.HeaderColumns.Height",
  Containers_Patients_HeaderColumns_HideNewRequests = "Containers.Patients.HeaderColumns.HideNewRequests",
  Containers_Patients_HeaderColumns_Id = "Containers.Patients.HeaderColumns.Id",
  Containers_Patients_HeaderColumns_Name = "Containers.Patients.HeaderColumns.Name",
  Containers_Patients_HeaderColumns_NumberOfSessionsDone = "Containers.Patients.HeaderColumns.NumberOfSessionsDone",
  Containers_Patients_HeaderColumns_Pathology = "Containers.Patients.HeaderColumns.Pathology",
  Containers_Patients_HeaderColumns_ShowNewRequests = "Containers.Patients.HeaderColumns.ShowNewRequests",
  Containers_Patients_HeaderColumns_Weight = "Containers.Patients.HeaderColumns.Weight",
  Containers_Patients_UnapprovedUser = "Containers.Patients.UnapprovedUser",
  Containers_PatientsInnerPage_Met10 = "Containers.PatientsInnerPage.Met10",
  Containers_PatientsInnerPage_Min2 = "Containers.PatientsInnerPage.Min2",
  Containers_PatientsInnerPage_Berg = "Containers.PatientsInnerPage.Berg",
  Containers_PatientsInnerPage_Tinetti = "Containers.PatientsInnerPage.Tinetti",
  Containers_PatientsInnerPage_Tug = "Containers.PatientsInnerPage.Tug",
  Containers_PatientsInnerPage_Other = "Containers.PatientsInnerPage.Other",
  Containers_PatientsInnerPage_AddTrainer = "Containers.PatientsInnerPage.AddTrainer",
  Containers_PatientsInnerPage_AddTrainerTooltip = "Containers.PatientsInnerPage.AddTrainerTooltip",
  Containers_PatientsInnerPage_Assigned = "Containers.PatientsInnerPage.Assigned",
  Containers_PatientsInnerPage_CancelRequest = "Containers.PatientsInnerPage.CancelRequest",
  Containers_PatientsInnerPage_Header_Age = "Containers.PatientsInnerPage.Header.Age",
  Containers_PatientsInnerPage_Header_AssignToTrainer = "Containers.PatientsInnerPage.Header.AssignToTrainer",
  Containers_PatientsInnerPage_Header_Email = "Containers.PatientsInnerPage.Header.Email",
  Containers_PatientsInnerPage_Header_Height = "Containers.PatientsInnerPage.Header.Height",
  Containers_PatientsInnerPage_Header_HideDetails = "Containers.PatientsInnerPage.Header.HideDetails",
  Containers_PatientsInnerPage_Header_Pathology = "Containers.PatientsInnerPage.Header.Pathology",
  Containers_PatientsInnerPage_Header_ResendEmail = "Containers.PatientsInnerPage.Header.ResendEmail",
  Containers_PatientsInnerPage_Header_ShowMoreDetails = "Containers.PatientsInnerPage.Header.ShowMoreDetails",
  Containers_PatientsInnerPage_Header_Weight = "Containers.PatientsInnerPage.Header.Weight",
  Containers_PatientsInnerPage_Pending = "Containers.PatientsInnerPage.Pending",
  Containers_PatientsInnerPage_Tabs_MyoSuitSetup = "Containers.PatientsInnerPage.Tabs.MyoSuitSetup",
  Containers_PatientsInnerPage_Tabs_Sessions = "Containers.PatientsInnerPage.Tabs.Sessions",
  Containers_PatientsInnerPage_Unassign = "Containers.PatientsInnerPage.Unassign",
  Containers_PrepareSession_PatientsNotFit = "Containers.PrepareSession.PatientsNotFit",
  Containers_PrepareSession_Download = "Containers.PrepareSession.Download",
  Containers_PrepareSession_FullName = "Containers.PrepareSession.FullName",
  Containers_PrepareSession_SearchPatients = "Containers.PrepareSession.SearchPatients",
  Containers_PrepareSession_SelectMyoSuit = "Containers.PrepareSession.SelectMyoSuit",
  Containers_PrepareSession_SelectPatients = "Containers.PrepareSession.SelectPatients",
  Containers_PrepareSession_SelectedPatient = "Containers.PrepareSession.SelectedPatient",
  Containers_PrepareSession_SelectedPatients = "Containers.PrepareSession.SelectedPatients",
  Containers_PrepareSession_UploadToSDCardInstruction = "Containers.PrepareSession.UploadToSDCardInstruction",
  Containers_PrepareSession_UploadToSDCardSubtitle = "Containers.PrepareSession.UploadToSDCardSubtitle",
  Containers_PrepareSession_UploadToSDCardTitle = "Containers.PrepareSession.UploadToSDCardTitle",
  Containers_SessionsTab_OverallSessionsOverview_NoSessions = "Containers.SessionsTab.OverallSessionsOverview.NoSessions",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_ActiveTime = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.ActiveTime",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_NumberOfSteps = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.NumberOfSteps",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_OverallSessions = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.OverallSessions",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_Tooltip = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.Tooltip",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_TotalDistanceCovered = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalDistanceCovered",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_TotalTrainingCourses = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalTrainingCourses",
  Containers_SessionsTab_OverallSessionsOverview_OverallSessionsCard_TotalTrainingTime = "Containers.SessionsTab.OverallSessionsOverview.OverallSessionsCard.TotalTrainingTime",
  Containers_SessionsTab_OverallSessionsOverview_PersonalRecordsCard_LongestActiveTimeInARow = "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestActiveTimeInARow",
  Containers_SessionsTab_OverallSessionsOverview_PersonalRecordsCard_LongestDistanceWalked = "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestDistanceWalked",
  Containers_SessionsTab_OverallSessionsOverview_PersonalRecordsCard_LongestStepSequence = "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.LongestStepSequence",
  Containers_SessionsTab_OverallSessionsOverview_PersonalRecordsCard_PersonalRecords = "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.PersonalRecords",
  Containers_SessionsTab_OverallSessionsOverview_PersonalRecordsCard_Tooltip = "Containers.SessionsTab.OverallSessionsOverview.PersonalRecordsCard.Tooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_NoDate = "Containers.SessionsTab.SessionsChart.ChartFooter.NoDate",
  Containers_SessionsTab_SessionsChart_ChartFooter_NoSessions = "Containers.SessionsTab.SessionsChart.ChartFooter.NoSessions",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsText_SeatTransfers = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.SeatTransfers",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsText_StepNumbers = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.StepNumbers",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsText_StepSymmetry = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.StepSymmetry",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsText_SupportLevels = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsText.SupportLevels",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_ActiveTimeTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.ActiveTimeTooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_SeatTransfersTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.SeatTransfersTooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_Session = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.Session",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_StairsTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StairsTooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_StepNumbersTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StepNumbersTooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_StepSymmetryTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.StepSymmetryTooltip",
  Containers_SessionsTab_SessionsChart_ChartFooter_SessionsDataGridItemsTooltipText_SupportLevelsTooltip = "Containers.SessionsTab.SessionsChart.ChartFooter.SessionsDataGridItemsTooltipText.SupportLevelsTooltip",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_AllSessions = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.AllSessions",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_CustomPeriod = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.CustomPeriod",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_CustomSelect = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.CustomSelect",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last10Session = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.Last10Session",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last30days = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.Last30days",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_Last5Session = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.Last5Session",
  Containers_SessionsTab_SessionsChart_ChartHeader_SessionsSelect_LastSession = "Containers.SessionsTab.SessionsChart.ChartHeader.SessionsSelect.LastSession",
  Containers_SessionsTab_SessionsTable_HeaderColumns_ActiveRate = "Containers.SessionsTab.SessionsTable.HeaderColumns.ActiveRate",
  Containers_SessionsTab_SessionsTable_HeaderColumns_Date = "Containers.SessionsTab.SessionsTable.HeaderColumns.Date",
  Containers_SessionsTab_SessionsTable_HeaderColumns_DisplayId = "Containers.SessionsTab.SessionsTable.HeaderColumns.DisplayId",
  Containers_SessionsTab_SessionsTable_HeaderColumns_ProcessingStatus = "Containers.SessionsTab.SessionsTable.HeaderColumns.ProcessingStatus",
  Containers_SessionsTab_SessionsTable_HeaderColumns_SeatTransfers = "Containers.SessionsTab.SessionsTable.HeaderColumns.SeatTransfers",
  Containers_SessionsTab_SessionsTable_HeaderColumns_Stairs = "Containers.SessionsTab.SessionsTable.HeaderColumns.Stairs",
  Containers_SessionsTab_SessionsTable_HeaderColumns_StepSymmetry = "Containers.SessionsTab.SessionsTable.HeaderColumns.StepSymmetry",
  Containers_SessionsTab_SessionsTable_HeaderColumns_Steps = "Containers.SessionsTab.SessionsTable.HeaderColumns.Steps",
  Containers_SessionsTab_SessionsTable_HeaderColumns_SupportLevelL = "Containers.SessionsTab.SessionsTable.HeaderColumns.SupportLevelL",
  Containers_SessionsTab_SessionsTable_HeaderColumns_SupportLevelR = "Containers.SessionsTab.SessionsTable.HeaderColumns.SupportLevelR",
  Containers_SessionsTab_SessionsTable_HeaderColumns_Time = "Containers.SessionsTab.SessionsTable.HeaderColumns.Time",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_NoLogFileLocated = "Containers.SessionsTab.SessionsTable.ProcessingStatus.NoLogFileLocated",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_NoProcessingData = "Containers.SessionsTab.SessionsTable.ProcessingStatus.NoProcessingData",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_OldMyosuitFirmware = "Containers.SessionsTab.SessionsTable.ProcessingStatus.OldMyosuitFirmware",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingError = "Containers.SessionsTab.SessionsTable.ProcessingStatus.ProcessingError",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingOneOrMoreFiles = "Containers.SessionsTab.SessionsTable.ProcessingStatus.ProcessingOneOrMoreFiles",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingPending = "Containers.SessionsTab.SessionsTable.ProcessingStatus.ProcessingPending",
  Containers_SessionsTab_SessionsTable_ProcessingStatus_ProcessingSuccessful = "Containers.SessionsTab.SessionsTable.ProcessingStatus.ProcessingSuccessful",
  Containers_SyncMyoSuit_ClearSDCardInstructionForOperator = "Containers.SyncMyoSuit.ClearSDCardInstructionForOperator",
  Containers_SyncMyoSuit_ClearSDCardInstructionOperator4thStep = "Containers.SyncMyoSuit.ClearSDCardInstructionOperator4thStep",
  Containers_SyncMyoSuit_ClearSDCardInstructionPatient4thStep = "Containers.SyncMyoSuit.ClearSDCardInstructionPatient4thStep",
  Containers_SyncMyoSuit_ClearSDCardSubtitle = "Containers.SyncMyoSuit.ClearSDCardSubtitle",
  Containers_SyncMyoSuit_ClearSDCardTitle = "Containers.SyncMyoSuit.ClearSDCardTitle",
  Containers_SyncMyoSuit_ClickToUpload = "Containers.SyncMyoSuit.ClickToUpload",
  Containers_SyncMyoSuit_DropHere = "Containers.SyncMyoSuit.DropHere",
  Containers_SyncMyoSuit_FileDuplicate = "Containers.SyncMyoSuit.FileDuplicate",
  Containers_SyncMyoSuit_FileIgnored = "Containers.SyncMyoSuit.FileIgnored",
  Containers_SyncMyoSuit_FilePending = "Containers.SyncMyoSuit.FilePending",
  Containers_SyncMyoSuit_Home = "Containers.SyncMyoSuit.Home",
  Containers_SyncMyoSuit_InstructionsForPatient = "Containers.SyncMyoSuit.InstructionsForPatient",
  Containers_SyncMyoSuit_PageTitle = "Containers.SyncMyoSuit.PageTitle",
  Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart1 = "Containers.SyncMyoSuit.PatientWithNoSuitInstructionsPart1",
  Containers_SyncMyoSuit_PatientWithNoSuitInstructionsPart2 = "Containers.SyncMyoSuit.PatientWithNoSuitInstructionsPart2",
  Containers_SyncMyoSuit_PleasUploadMyo = "Containers.SyncMyoSuit.PleasUploadMyo",
  Containers_SyncMyoSuit_PrepareNextSession = "Containers.SyncMyoSuit.PrepareNextSession",
  Containers_SyncMyoSuit_Processing = "Containers.SyncMyoSuit.Processing",
  Containers_SyncMyoSuit_TipToSkip = "Containers.SyncMyoSuit.TipToSkip",
  Containers_SyncMyoSuit_UploadTip = "Containers.SyncMyoSuit.UploadTip",
  Containers_SyncMyoSuit_UploadedFiles = "Containers.SyncMyoSuit.UploadedFiles",
  Containers_SyncMyoSuit_WrongFileType = "Containers.SyncMyoSuit.WrongFileType",
  ErrorCodes_10000 = "ErrorCodes.10000",
  ErrorCodes_10001 = "ErrorCodes.10001",
  ErrorCodes_10002 = "ErrorCodes.10002",
  ErrorCodes_10003 = "ErrorCodes.10003",
  ErrorCodes_10004 = "ErrorCodes.10004",
  ErrorCodes_10005 = "ErrorCodes.10005",
  ErrorCodes_10006 = "ErrorCodes.10006",
  ErrorCodes_10007 = "ErrorCodes.10007",
  ErrorCodes_10008 = "ErrorCodes.10008",
  ErrorCodes_10009 = "ErrorCodes.10009",
  ErrorCodes_10010 = "ErrorCodes.10010",
  ErrorCodes_10011 = "ErrorCodes.10011",
  ErrorCodes_10012 = "ErrorCodes.10012",
  ErrorCodes_10013 = "ErrorCodes.10013",
  ErrorCodes_10014 = "ErrorCodes.10014",
  ErrorCodes_10015 = "ErrorCodes.10015",
  ErrorCodes_10016 = "ErrorCodes.10016",
  ErrorCodes_10017 = "ErrorCodes.10017",
  ErrorCodes_20000 = "ErrorCodes.20000",
  ErrorCodes_20001 = "ErrorCodes.20001",
  ErrorCodes_30000 = "ErrorCodes.30000",
  ErrorCodes_30001 = "ErrorCodes.30001",
  ErrorCodes_30002 = "ErrorCodes.30002",
  ErrorCodes_30003 = "ErrorCodes.30003",
  ErrorCodes_30004 = "ErrorCodes.30004",
  ErrorCodes_30005 = "ErrorCodes.30005",
  ErrorCodes_30006 = "ErrorCodes.30006",
  ErrorCodes_30007 = "ErrorCodes.30007",
  ErrorCodes_30008 = "ErrorCodes.30008",
  ErrorCodes_30009 = "ErrorCodes.30009",
  ErrorCodes_30010 = "ErrorCodes.30010",
  ErrorCodes_30011 = "ErrorCodes.30011",
  ErrorCodes_30012 = "ErrorCodes.30012",
  ErrorCodes_30013 = "ErrorCodes.30013",
  ErrorCodes_30014 = "ErrorCodes.30014",
  ErrorCodes_30015 = "ErrorCodes.30015",
  ErrorCodes_30016 = "ErrorCodes.30016",
  ErrorCodes_30017 = "ErrorCodes.30017",
  ErrorCodes_30018 = "ErrorCodes.30018",
  ErrorCodes_30019 = "ErrorCodes.30019",
  ErrorCodes_30020 = "ErrorCodes.30020",
  ErrorCodes_40000 = "ErrorCodes.40000",
  ErrorCodes_40001 = "ErrorCodes.40001",
  ErrorCodes_40002 = "ErrorCodes.40002",
  ErrorCodes_40003 = "ErrorCodes.40003",
  ErrorCodes_40004 = "ErrorCodes.40004",
  ErrorCodes_40005 = "ErrorCodes.40005",
  ErrorCodes_40006 = "ErrorCodes.40006",
  ErrorCodes_40007 = "ErrorCodes.40007",
  ErrorCodes_40008 = "ErrorCodes.40008",
  ErrorCodes_40009 = "ErrorCodes.40009",
  ErrorCodes_40010 = "ErrorCodes.40010",
  ErrorCodes_40011 = "ErrorCodes.40011",
  ErrorCodes_40012 = "ErrorCodes.40012",
  ErrorCodes_40013 = "ErrorCodes.40013",
  ErrorCodes_40014 = "ErrorCodes.40014",
  ErrorCodes_40015 = "ErrorCodes.40015",
  ErrorCodes_50000 = "ErrorCodes.50000",
  ErrorCodes_50001 = "ErrorCodes.50001",
  ErrorCodes_50002 = "ErrorCodes.50002",
  ErrorCodes_50003 = "ErrorCodes.50003",
  ErrorCodes_50004 = "ErrorCodes.50004",
  ErrorCodes_50005 = "ErrorCodes.50005",
  ErrorCodes_60000 = "ErrorCodes.60000",
  ErrorCodes_70000 = "ErrorCodes.70000",
  ErrorCodes_70001 = "ErrorCodes.70001",
  ErrorCodes_70002 = "ErrorCodes.70002",
  ErrorCodes_70003 = "ErrorCodes.70003",
  ErrorCodes_70004 = "ErrorCodes.70004",
  ErrorCodes_80000 = "ErrorCodes.80000",
  ErrorCodes_80001 = "ErrorCodes.80001",
  ErrorCodes_90000 = "ErrorCodes.90000",
  ErrorCodes_100000 = "ErrorCodes.100000",
  ErrorCodes_110000 = "ErrorCodes.110000",
  ErrorCodes_110001 = "ErrorCodes.110001",
  ErrorCodes_110002 = "ErrorCodes.110002",
  ErrorCodes_110003 = "ErrorCodes.110003",
  ErrorCodes_110004 = "ErrorCodes.110004",
  ErrorCodes_120000 = "ErrorCodes.120000",
  ErrorCodes_120001 = "ErrorCodes.120001",
  ErrorCodes_120002 = "ErrorCodes.120002",
  ErrorCodes_120003 = "ErrorCodes.120003",
  ErrorCodes_120004 = "ErrorCodes.120004",
  ErrorCodes_120005 = "ErrorCodes.120005",
  ErrorCodes_120006 = "ErrorCodes.120006",
  ErrorCodes_120007 = "ErrorCodes.120007",
  ErrorCodes_130000 = "ErrorCodes.130000",
  ErrorCodes_130001 = "ErrorCodes.130001",
  ErrorCodes_130002 = "ErrorCodes.130002",
  Global_FailedFileDownload = "Global.FailedFileDownload",
  Global_Tabt = "Global.Tabt",
  Global_Close = "Global.Close",
  Global_PatientSignedWrittenConsent = "Global.PatientSignedWrittenConsent",
  Global_QuestionnaireDone = "Global.QuestionnaireDone",
  Global_QuestionnaireNotDone = "Global.QuestionnaireNotDone",
  Global_PartOfTrial = "Global.PartOfTrial",
  Global_ActiveTime = "Global.ActiveTime",
  Global_Add = "Global.Add",
  Global_AddOperator = "Global.AddOperator",
  Global_AddUnit = "Global.AddUnit",
  Global_Address = "Global.Address",
  Global_Admin = "Global.Admin",
  Global_Administrator = "Global.Administrator",
  Global_Approve = "Global.Approve",
  Global_Back = "Global.Back",
  Global_Birthyear = "Global.Birthyear",
  Global_Cancel = "Global.Cancel",
  Global_ClinicCreatedSuccessfully = "Global.ClinicCreatedSuccessfully",
  Global_ClinicStatus = "Global.ClinicStatus",
  Global_Continue = "Global.Continue",
  Global_CurrentFirmwareVersion = "Global.CurrentFirmwareVersion",
  Global_DateOfBirth = "Global.DateOfBirth",
  Global_DeleteProfile = "Global.DeleteProfile",
  Global_Description = "Global.Description",
  Global_DisplayName = "Global.DisplayName",
  Global_DoYouReallyWantToDeleteThisProfile = "Global.DoYouReallyWantToDeleteThisProfile",
  Global_Done = "Global.Done",
  Global_DownloadProfile = "Global.DownloadProfile",
  Global_EditContactInfo = "Global.EditContactInfo",
  Global_EditClinic = "Global.EditClinic",
  Global_EditOperator = "Global.EditOperator",
  Global_EditProfile = "Global.EditProfile",
  Global_EditUnit = "Global.EditUnit",
  Global_Email = "Global.Email",
  Global_Error = "Global.Error",
  Global_Female = "Global.Female",
  Global_FutureFirmwareVersion = "Global.FutureFirmwareVersion",
  Global_Gender = "Global.Gender",
  Global_HardwareModel = "Global.HardwareModel",
  Global_ID = "Global.ID",
  Global_Invite = "Global.Invite",
  Global_InvitePersonToPortal = "Global.InvitePersonToPortal",
  Global_Male = "Global.Male",
  Global_Name = "Global.Name",
  Global_NewClinic = "Global.NewClinic",
  Global_NewOperator = "Global.NewOperator",
  Global_NewUnit = "Global.NewUnit",
  Global_Next = "Global.Next",
  Global_NexusProtocolVersion = "Global.NexusProtocolVersion",
  Global_No = "Global.No",
  Global_NoAvailableMyosuit = "Global.NoAvailableMyosuit",
  Global_NoConnection = "Global.NoConnection",
  Global_None = "Global.None",
  Global_NotVerified = "Global.NotVerified",
  Global_Ok = "Global.Ok",
  Global_Operational = "Global.Operational",
  Global_Operator = "Global.Operator",
  Global_OperatorCreatedSuccessfully = "Global.OperatorCreatedSuccessfully",
  Global_OperatorDeletedSuccessfully = "Global.OperatorDeletedSuccessfully",
  Global_OperatorUpdatedSuccessfully = "Global.OperatorUpdatedSuccessfully",
  Global_Operators = "Global.Operators",
  Global_Other = "Global.Other",
  Global_Patient = "Global.Patient",
  Global_Person = "Global.Person",
  Global_PhoneNumber = "Global.PhoneNumber",
  Global_PrepareYourProfile = "Global.PrepareYourProfile",
  Global_Private = "Global.Private",
  Global_Public = "Global.Public",
  Global_Reject = "Global.Reject",
  Global_Required = "Global.Required",
  Global_Save = "Global.Save",
  Global_Send = "Global.Send",
  Global_SerialNumber = "Global.SerialNumber",
  Global_Session = "Global.Session",
  Global_Skip = "Global.Skip",
  Global_Stairs = "Global.Stairs",
  Global_Steps = "Global.Steps",
  Global_Trainer = "Global.Trainer",
  Global_UnitCreatedSuccessfully = "Global.UnitCreatedSuccessfully",
  Global_UnitDeletedSuccessfully = "Global.UnitDeletedSuccessfully",
  Global_UnitStatus = "Global.UnitStatus",
  Global_UnitStatusCode = "Global.UnitStatusCode",
  Global_UnitStatusName = "Global.UnitStatusName",
  Global_UnitStatusSource = "Global.UnitStatusSource",
  Global_UnitStatusType = "Global.UnitStatusType",
  Global_UnitUpdatedSuccessfully = "Global.UnitUpdatedSuccessfully",
  Global_Units = "Global.Units",
  Global_Unknown = "Global.Unknown",
  Global_UpdateFirmwareText = "Global.UpdateFirmwareText",
  Global_UpdateFirmwareTitle = "Global.UpdateFirmwareTitle",
  Global_User = "Global.User",
  Global_Warning = "Global.Warning",
  Global_WarningToAssignToClinicOrBuySuit = "Global.WarningToAssignToClinicOrBuySuit",
  Global_Yes = "Global.Yes",
  Global_Clinics = "Global.Clinics",
  Global_ClinicUpdatedSuccessfully = "Global.ClinicUpdatedSuccessfully",
  Global_ClinicDeletedSuccessfully = "Global.ClinicDeletedSuccessfully",
  Messages_HeaderColumns_SentAt = "Messages.HeaderColumns.SentAt",
  Messages_HeaderColumns_Title = "Messages.HeaderColumns.Title",
  Options_OptionsPeriods_Months0to3 = "Options.OptionsPeriods.Months0to3",
  Options_OptionsPeriods_Months3to6 = "Options.OptionsPeriods.Months3to6",
  Options_OptionsPeriods_Months6to1 = "Options.OptionsPeriods.Months6to1",
  Options_OptionsPeriods_Years10Plus = "Options.OptionsPeriods.Years10Plus",
  Options_OptionsPeriods_Years1to2 = "Options.OptionsPeriods.Years1to2",
  Options_OptionsPeriods_Years2to5 = "Options.OptionsPeriods.Years2to5",
  Options_OptionsPeriods_Years5to10 = "Options.OptionsPeriods.Years5to10",
  Snackbar_SetDpaSuccess = "Snackbar.SetDpaSuccess",
  Snackbar_UpdateSessionCommentsSuccess = "Snackbar.UpdateSessionCommentsSuccess",
  Snackbar_SaveQuestionnaireSuccess = "Snackbar.SaveQuestionnaireSuccess",
  Snackbar_AddEmailSuccess = "Snackbar.AddEmailSuccess",
  Snackbar_ApproveUserSuccess = "Snackbar.ApproveUserSuccess",
  Snackbar_CancelRequestSuccess = "Snackbar.CancelRequestSuccess",
  Snackbar_ContactInfoUpdatedSuccessfully = "Snackbar.ContactInfoUpdatedSuccessfully",
  Snackbar_EmailWasSuccessfullySent = "Snackbar.EmailWasSuccessfullySent",
  Snackbar_MessageDeletedSuccessfully = "Snackbar.MessageDeletedSuccessfully",
  Snackbar_PatientAssignedSuccessfully = "Snackbar.PatientAssignedSuccessfully",
  Snackbar_ProfileDeletedSuccessfully = "Snackbar.ProfileDeletedSuccessfully",
  Snackbar_RejectUserSuccess = "Snackbar.RejectUserSuccess",
  Snackbar_UnassignFromClinic = "Snackbar.UnassignFromClinic",
  Snackbar_UserInvitedSuccessfully = "Snackbar.UserInvitedSuccessfully",
  Validations_EmailIsNotValid = "Validations.EmailIsNotValid",
  Validations_HeightIsNotValid = "Validations.HeightIsNotValid",
  Validations_HeightMustBeANumber = "Validations.HeightMustBeANumber",
  Validations_Length6 = "Validations.Length6",
  Validations_MinLength3 = "Validations.MinLength3",
  Validations_PhoneNumberOrEmail = "Validations.PhoneNumberOrEmail",
  Validations_Required = "Validations.Required",
  Validations_SelectFieldTypeError = "Validations.SelectFieldTypeError",
  Validations_WeightIsNotValid = "Validations.WeightIsNotValid",
  Validations_WeightMustBeANumber = "Validations.WeightMustBeANumber",
  Validations_WrongNumberFormat = "Validations.WrongNumberFormat",
  WarningCodes_0 = "WarningCodes.0",
  WarningCodes_1 = "WarningCodes.1",
  WarningCodes_2 = "WarningCodes.2",
  WarningCodes_3 = "WarningCodes.3",
  WarningCodes_4 = "WarningCodes.4",
  PageTermsOfAgreements_Contacts1 = "PageTermsOfAgreements.Contacts1",
  PageTermsOfAgreements_Contacts2 = "PageTermsOfAgreements.Contacts2",
  PageTermsOfAgreements_Contacts3 = "PageTermsOfAgreements.Contacts3",
  PageTermsOfAgreements_Contacts4 = "PageTermsOfAgreements.Contacts4",
  PageTermsOfAgreements_Contacts5 = "PageTermsOfAgreements.Contacts5",
  PageTermsOfAgreements_Contacts6 = "PageTermsOfAgreements.Contacts6",
  PageTermsOfAgreements_DPAAgreed = "PageTermsOfAgreements.DPAAgreed",
  PageTermsOfAgreements_DPADisagreed = "PageTermsOfAgreements.DPADisagreed",
  PageTermsOfAgreements_DisagreeModalPart1 = "PageTermsOfAgreements.DisagreeModalPart1",
  PageTermsOfAgreements_DisagreeModalPart2 = "PageTermsOfAgreements.DisagreeModalPart2",
  PageTermsOfAgreements_IAgree = "PageTermsOfAgreements.IAgree",
  PageTermsOfAgreements_IDisagree = "PageTermsOfAgreements.IDisagree",
  PageTermsOfAgreements_LinkNoLongerAvailable = "PageTermsOfAgreements.LinkNoLongerAvailable",
  PageTermsOfAgreements_Paragraph1 = "PageTermsOfAgreements.Paragraph1",
  PageTermsOfAgreements_Paragraph10 = "PageTermsOfAgreements.Paragraph10",
  PageTermsOfAgreements_Paragraph11 = "PageTermsOfAgreements.Paragraph11",
  PageTermsOfAgreements_Paragraph12 = "PageTermsOfAgreements.Paragraph12",
  PageTermsOfAgreements_Paragraph13 = "PageTermsOfAgreements.Paragraph13",
  PageTermsOfAgreements_Paragraph14 = "PageTermsOfAgreements.Paragraph14",
  PageTermsOfAgreements_Paragraph15 = "PageTermsOfAgreements.Paragraph15",
  PageTermsOfAgreements_Paragraph16 = "PageTermsOfAgreements.Paragraph16",
  PageTermsOfAgreements_Paragraph17 = "PageTermsOfAgreements.Paragraph17",
  PageTermsOfAgreements_Paragraph18 = "PageTermsOfAgreements.Paragraph18",
  PageTermsOfAgreements_Paragraph19 = "PageTermsOfAgreements.Paragraph19",
  PageTermsOfAgreements_Paragraph2 = "PageTermsOfAgreements.Paragraph2",
  PageTermsOfAgreements_Paragraph20 = "PageTermsOfAgreements.Paragraph20",
  PageTermsOfAgreements_Paragraph21 = "PageTermsOfAgreements.Paragraph21",
  PageTermsOfAgreements_Paragraph22 = "PageTermsOfAgreements.Paragraph22",
  PageTermsOfAgreements_Paragraph23 = "PageTermsOfAgreements.Paragraph23",
  PageTermsOfAgreements_Paragraph24 = "PageTermsOfAgreements.Paragraph24",
  PageTermsOfAgreements_Paragraph25 = "PageTermsOfAgreements.Paragraph25",
  PageTermsOfAgreements_Paragraph26 = "PageTermsOfAgreements.Paragraph26",
  PageTermsOfAgreements_Paragraph27 = "PageTermsOfAgreements.Paragraph27",
  PageTermsOfAgreements_Paragraph28 = "PageTermsOfAgreements.Paragraph28",
  PageTermsOfAgreements_Paragraph29 = "PageTermsOfAgreements.Paragraph29",
  PageTermsOfAgreements_Paragraph3 = "PageTermsOfAgreements.Paragraph3",
  PageTermsOfAgreements_Paragraph30 = "PageTermsOfAgreements.Paragraph30",
  PageTermsOfAgreements_Paragraph31 = "PageTermsOfAgreements.Paragraph31",
  PageTermsOfAgreements_Paragraph32 = "PageTermsOfAgreements.Paragraph32",
  PageTermsOfAgreements_Paragraph4 = "PageTermsOfAgreements.Paragraph4",
  PageTermsOfAgreements_Paragraph5 = "PageTermsOfAgreements.Paragraph5",
  PageTermsOfAgreements_Paragraph6 = "PageTermsOfAgreements.Paragraph6",
  PageTermsOfAgreements_Paragraph7 = "PageTermsOfAgreements.Paragraph7",
  PageTermsOfAgreements_Paragraph8 = "PageTermsOfAgreements.Paragraph8",
  PageTermsOfAgreements_Paragraph9 = "PageTermsOfAgreements.Paragraph9",
  PageTermsOfAgreements_Subparagraph1Part1 = "PageTermsOfAgreements.Subparagraph1Part1",
  PageTermsOfAgreements_Subparagraph1Part2 = "PageTermsOfAgreements.Subparagraph1Part2",
  PageTermsOfAgreements_Subparagraph2Part1 = "PageTermsOfAgreements.Subparagraph2Part1",
  PageTermsOfAgreements_Subparagraph2Part2 = "PageTermsOfAgreements.Subparagraph2Part2",
  PageTermsOfAgreements_Subparagraph3Part1 = "PageTermsOfAgreements.Subparagraph3Part1",
  PageTermsOfAgreements_Subparagraph3Part2 = "PageTermsOfAgreements.Subparagraph3Part2",
  PageTermsOfAgreements_Subparagraph4Part1 = "PageTermsOfAgreements.Subparagraph4Part1",
  PageTermsOfAgreements_Subparagraph4Part2 = "PageTermsOfAgreements.Subparagraph4Part2",
  PageTermsOfAgreements_Subparagraph5Part1 = "PageTermsOfAgreements.Subparagraph5Part1",
  PageTermsOfAgreements_Subparagraph5Part2 = "PageTermsOfAgreements.Subparagraph5Part2",
  PageTermsOfAgreements_Subparagraph6Part1 = "PageTermsOfAgreements.Subparagraph6Part1",
  PageTermsOfAgreements_Subparagraph6Part2 = "PageTermsOfAgreements.Subparagraph6Part2",
  PageTermsOfAgreements_Subparagraph7Part1 = "PageTermsOfAgreements.Subparagraph7Part1",
  PageTermsOfAgreements_Subparagraph7Part2 = "PageTermsOfAgreements.Subparagraph7Part2",
  PageTermsOfAgreements_Subtitle1 = "PageTermsOfAgreements.Subtitle1",
  PageTermsOfAgreements_Subtitle10 = "PageTermsOfAgreements.Subtitle10",
  PageTermsOfAgreements_Subtitle11 = "PageTermsOfAgreements.Subtitle11",
  PageTermsOfAgreements_Subtitle12 = "PageTermsOfAgreements.Subtitle12",
  PageTermsOfAgreements_Subtitle13 = "PageTermsOfAgreements.Subtitle13",
  PageTermsOfAgreements_Subtitle14 = "PageTermsOfAgreements.Subtitle14",
  PageTermsOfAgreements_Subtitle15 = "PageTermsOfAgreements.Subtitle15",
  PageTermsOfAgreements_Subtitle2 = "PageTermsOfAgreements.Subtitle2",
  PageTermsOfAgreements_Subtitle3 = "PageTermsOfAgreements.Subtitle3",
  PageTermsOfAgreements_Subtitle4 = "PageTermsOfAgreements.Subtitle4",
  PageTermsOfAgreements_Subtitle5 = "PageTermsOfAgreements.Subtitle5",
  PageTermsOfAgreements_Subtitle6 = "PageTermsOfAgreements.Subtitle6",
  PageTermsOfAgreements_Subtitle7 = "PageTermsOfAgreements.Subtitle7",
  PageTermsOfAgreements_Subtitle8 = "PageTermsOfAgreements.Subtitle8",
  PageTermsOfAgreements_Subtitle9 = "PageTermsOfAgreements.Subtitle9",
  PageTermsOfAgreements_Title = "PageTermsOfAgreements.Title",
  Electron_ConnectionLostModal_ConnectionLostMessage = "Electron.ConnectionLostModal.ConnectionLostMessage",
  Electron_ConnectionLostModal_ConnectionLostTitle = "Electron.ConnectionLostModal.ConnectionLostTitle",
  Electron_Containers_ClearSDCardModal_Download = "Electron.Containers.ClearSDCardModal.Download",
  Electron_Containers_ClearSDCardModal_FilesIgnored = "Electron.Containers.ClearSDCardModal.FilesIgnored",
  Electron_Containers_ClearSDCardModal_FilesToDownload = "Electron.Containers.ClearSDCardModal.FilesToDownload",
  Electron_Containers_ClearSDCardModal_Message = "Electron.Containers.ClearSDCardModal.Message",
  Electron_Containers_ClearSDCardModal_None = "Electron.Containers.ClearSDCardModal.None",
  Electron_Containers_ClearSDCardModal_Success = "Electron.Containers.ClearSDCardModal.Success",
  Electron_Containers_ClearSDCardModal_Title = "Electron.Containers.ClearSDCardModal.Title",
  Electron_Containers_ClearSDCardModal_TitleSuccess = "Electron.Containers.ClearSDCardModal.TitleSuccess",
  Electron_Containers_DriveList_MyoSDCardFoundMessage = "Electron.Containers.DriveList.MyoSDCardFoundMessage",
  Electron_Containers_DriveList_MyoSDCardFoundTitle = "Electron.Containers.DriveList.MyoSDCardFoundTitle",
  Electron_Containers_PrepareSession_UploadToSDCardTitleElectron = "Electron.Containers.PrepareSession.UploadToSDCardTitleElectron",
  Electron_Containers_SyncMyoSuit_ChangeDevice = "Electron.Containers.SyncMyoSuit.ChangeDevice",
  Electron_Containers_SyncMyoSuit_DataUploaded = "Electron.Containers.SyncMyoSuit.DataUploaded",
  Electron_Containers_SyncMyoSuit_DataUploading = "Electron.Containers.SyncMyoSuit.DataUploading",
  Electron_Containers_SyncMyoSuit_HeaderColumns_DeviceName = "Electron.Containers.SyncMyoSuit.HeaderColumns.DeviceName",
  Electron_Containers_SyncMyoSuit_HeaderColumns_Drive = "Electron.Containers.SyncMyoSuit.HeaderColumns.Drive",
  Electron_Containers_SyncMyoSuit_ReloadDevices = "Electron.Containers.SyncMyoSuit.ReloadDevices",
  Electron_Containers_SyncMyoSuit_SelectedDevice = "Electron.Containers.SyncMyoSuit.SelectedDevice",
  Electron_Containers_SyncMyoSuit_StorageDevices = "Electron.Containers.SyncMyoSuit.StorageDevices",
  Electron_Containers_SyncMyoSuit_TipToSkipElectron = "Electron.Containers.SyncMyoSuit.TipToSkipElectron",
  Electron_ErrorCodes_COULD_NOT_CLEAR_SD_CARD = "Electron.ErrorCodes.COULD_NOT_CLEAR_SD_CARD",
  Electron_ErrorCodes_COULD_NOT_READ_DISKS = "Electron.ErrorCodes.COULD_NOT_READ_DISKS",
  Electron_ErrorCodes_COULD_NOT_READ_FILES = "Electron.ErrorCodes.COULD_NOT_READ_FILES",
  Electron_ErrorCodes_COULD_NOT_UPDATE = "Electron.ErrorCodes.COULD_NOT_UPDATE",
  Electron_ErrorCodes_COULD_NOT_WRITE_FILES = "Electron.ErrorCodes.COULD_NOT_WRITE_FILES",
  Electron_ErrorCodes_DOWNLOAD_REQUEST_FAILED = "Electron.ErrorCodes.DOWNLOAD_REQUEST_FAILED",
  Electron_UpdateEvents_CHECKING_FOR_UPDATES = "Electron.UpdateEvents.CHECKING_FOR_UPDATES",
  Electron_UpdateEvents_UPDATE_AVAILABLE = "Electron.UpdateEvents.UPDATE_AVAILABLE",
  Electron_UpdateEvents_UPDATE_DOWNLOADED = "Electron.UpdateEvents.UPDATE_DOWNLOADED",
  Electron_UpdateEvents_UP_TO_DATE = "Electron.UpdateEvents.UP_TO_DATE",
  Electron_UpdateModal_FoundUpdateMessage = "Electron.UpdateModal.FoundUpdateMessage",
  Electron_UpdateModal_FoundUpdateTitle = "Electron.UpdateModal.FoundUpdateTitle"
}
