import { makeStyles } from "@material-ui/styles";
import { theme } from "../../styles/styles";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 190
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 100
  }
}));
