import { IOption } from "../types/formInputs/IOption";
import { LanguageEnum } from "../types/enums/LanguageEnum";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import frLocale from "date-fns/locale/fr";
import itLocale from "date-fns/locale/it";
import { ILanguageNumberPair } from "../types/ILanguageNumberPair";

export const languages: IOption[] = [
  {
    label: "Deutsch",
    value: LanguageEnum.De
  },
  {
    label: "English",
    value: LanguageEnum.En
  }
  //TODO: uncomment when translations are ready
  // {
  //   label: "Française",
  //   value: LanguageEnum.Fr
  // }
  //TODO: uncomment when translations are ready
  // {
  //   label: "Italiano",
  //   value: LanguageEnum.It
  // }
];

export const languagesDPA: IOption[] = [
  {
    label: "Deutsch",
    value: LanguageEnum.De
  },
  {
    label: "English",
    value: LanguageEnum.En
  }
  //TODO: uncomment when translations are ready
  // {
  //   label: "Française",
  //   value: LanguageEnum.Fr
  // }
  //TODO: uncomment when translations are ready
  // {
  //   label: "Italiano",
  //   value: LanguageEnum.It
  // }
];

export const localeMap = {
  en: enLocale,
  de: deLocale,
  fr: frLocale,
  gb: enLocale,
  it: itLocale
};

export const languageNumberPairs: ILanguageNumberPair[] = [
  {
    language: LanguageEnum.En,
    index: 0
  },
  {
    language: LanguageEnum.De,
    index: 1
  }
  //TODO: uncomment when translations are ready
  // {
  //   language: LanguageEnum.Fr,
  //   index: 2
  // },
  //TODO: uncomment when translations are ready
  // {
  //   language: LanguageEnum.It,
  //   index: 3
  // }
];
