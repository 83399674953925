import React from "react";
import clsx from "clsx";
import SnackbarComponent from "../../../Snackbar/SnackbarComponent";
import { Box, useMediaQuery, Theme } from "@material-ui/core";
import Header from "../../Header/Header";
import SideMenu from "../../Header/SideMenu/SideMenu";
import { useSelector } from "../../../state/store";
import PrivateRoutes from "../../../Routes/PrivateRoutes";
import { selectHeaderOpen } from "../../../utils/helpers/stateSelectorHelpers";
import { Route } from "react-router-dom";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import useStyles from "./PrivatePageStructure.styles";

const PrivatePageStructure: React.FC = () => {
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { isDrawerOpen } = useSelector(selectHeaderOpen);

  return (
    <Box className={classes.container}>
      {/* Should be used later, when public routes are introduced */}
      <Route path={[PathNamesEnum.Patient, PathNamesEnum.Home]}>
        <Header />
      </Route>
      <SideMenu />
      <Box
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen && matches
        })}
      >
        <PrivateRoutes />
        <SnackbarComponent />
      </Box>
    </Box>
  );
};

export default PrivatePageStructure;
