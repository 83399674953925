import { IPlatform } from "../../types/IPlatform";
import { PlatformEnum } from "../../types/enums/PlatformEnum";
import { IGithubAsset } from "../../types/github/IGithubAsset";
import { IRelease } from "../../types/IRelease";
import { useCallback, useEffect, useState } from "react";
import LinuxLogo from "../../assets/platforms/linux.png";
import WindowsLogo from "../../assets/platforms/windows.png";
import AppleLogo from "../../assets/platforms/apple.png";
import axios from "axios";
import { IGithubResponse } from "../../types/github/IGithubResponse";
import { currentPlatform } from "./platformHelpers";

const isInstaller = (asset: IGithubAsset) => {
  const extension: string = asset.name.split(".").pop() as string;
  return ["AppImage", "dmg", "exe"].includes(extension);
};

const getAssetPlatform = (name: string): IPlatform => {
  const extension = name.split(".").pop();
  switch (extension) {
    case "AppImage":
      return {
        logo: LinuxLogo,
        name: PlatformEnum.Linux
      };
    case "dmg":
      return {
        logo: AppleLogo,
        name: PlatformEnum.Mac
      };
    default:
    case "exe":
      return {
        logo: WindowsLogo,
        name: PlatformEnum.Windows
      };
  }
};

export const mapGithubAssetToReleaseObject = (version: string) => (
  asset: IGithubAsset
): IRelease => {
  return {
    downloadUrl: asset.browser_download_url,
    platform: getAssetPlatform(asset.name),
    name: asset.name,
    version
  };
};

export const useGithubReleases = () => {
  const [releases, setReleases] = useState<IRelease[]>([]);
  const [loading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [defaultRelease, setDefaultRelease] = useState<IRelease>();
  const githubUsername = process.env.REACT_APP_GITHUB_USERNAME;
  const githubRepository = process.env.REACT_APP_GITHUB_REPOSITORY;
  const githubUrl = `https://api.github.com/repos/${githubUsername}/${githubRepository}/releases/latest`;

  const isNotDefault = useCallback(
    (release: IRelease) => {
      return release.name !== defaultRelease?.name;
    },
    [defaultRelease]
  );

  useEffect(() => {
    axios
      .get(githubUrl)
      .then(res => res.data)
      .then((res: IGithubResponse) => {
        const releasesList = res.assets
          .filter(isInstaller)
          .map(mapGithubAssetToReleaseObject(res.tag_name));
        if (releasesList.length > 0) {
          const release = releasesList.find(
            r => r.platform.name === currentPlatform()
          );
          setReleases(releasesList);
          setDefaultRelease(release);
        } else {
          setFailedToLoad(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setFailedToLoad(true);
        setLoading(false);
      });
  }, [githubUrl]);

  return {
    defaultRelease: defaultRelease || releases[0],
    releases: releases.filter(isNotDefault),
    failedToLoad,
    loading
  };
};
