export const requiredValidator = (
  value: any,
  errorMessage?: string
): undefined | string => {
  if (
    value != null &&
    !(typeof value === "string" && value === "") &&
    !(typeof value === "object" && Object.keys(value).length === 0) &&
    !(Array.isArray(value) && value.length < 1)
  ) {
    return undefined;
  } else {
    return errorMessage || `This field is required`;
  }
};
