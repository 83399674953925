import gql from "graphql-tag";

export const setSessionGoalsMutation = gql`
  mutation setSessionGoals($values: Values!) {
    setSessionGoals(values: $values)
      @rest(
        type: "EditSessionGoalsDto"
        path: "sessions/goals"
        method: "PUT"
        bodyKey: "values"
      ) {
      goal
      goalDescription
      sessionGuid
    }
  }
`;
