import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";
import { maxContainerWidth } from "../../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    maxWidth: maxContainerWidth
  },
  paperContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  blue: {
    color: ColorEnum.Blue
  },
  tipText: {
    fontWeight: 400,
    marginLeft: theme.spacing(1)
  },
  tipContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.spacing(3)
  },
  button: {
    alignSelf: "flex-end",
    margin: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  header: {
    marginLeft: theme.spacing(3),
    marginRight: "auto"
  }
}));
