import gql from "graphql-tag";

export const createUnitMutation = gql`
  mutation createUnit($values: Values!) {
    createUnit(values: $values)
      @rest(
        type: "CreateUnitDto"
        path: "units"
        method: "POST"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const updateUnitMutation = gql`
  mutation updateUnit($values: Values!) {
    updateUnit(values: $values)
      @rest(
        type: "UpdateUnitDto"
        path: "units"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;

export const deleteUnitMutation = gql`
  mutation deleteUnitMutation($values: Values!) {
    deleteUnitMutation(values: $values)
      @rest(
        type: "DeleteUnit"
        path: "units/{args.values.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;
