import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  box: {
    background: ColorEnum.Blue,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    height: "100vh",
    flexDirection: "column"
  },
  logo: {
    width: 200
  },
  progress: {
    marginTop: theme.spacing(1.5),
    width: 200
  },
  text: {
    color: ColorEnum.White,
    marginTop: theme.spacing(2)
  },
  circularProgress: {
    color: ColorEnum.White,
    marginTop: theme.spacing(1.5)
  }
}));
