//synced with backend
export enum TimeSinceDiagnosisEnum {
  Months0_3 = 0,
  Months3_6 = 1,
  Months6_1 = 2,
  Years1_2 = 3,
  Years2_5 = 4,
  Years5_10 = 5,
  Years10 = 6
}
