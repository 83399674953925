import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";
import { theme } from "../../styles/styles";

export default makeStyles(() => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  warning: {
    color: ColorEnum.Orange,
    height: 14,
    position: "relative",
    top: 2,
    width: 18
  },
  button: {
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center"
  },
  rejectButton: {
    marginRight: 10,
    color: ColorEnum.Red
  },
  approveButton: {
    color: ColorEnum.Blue
  },
  mb1: {
    marginBottom: theme.spacing(1)
  },
  mr2: {
    marginRight: theme.spacing(2)
  }
}));
