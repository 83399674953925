import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";
import { IDisk } from "../../../types/IDisk";
import { IWindow } from "../../../types/IWindow";
import { useTranslation } from "react-i18next";
import useStyles from "./SyncMyoSuitElectron.styles";
import FilesList from "../reusable/FilesList/FilesList";
import clsx from "clsx";
import useHandleOnDrop from "../../../utils/helpers/dropzoneHelper";
import ErrorIcon from "@material-ui/icons/Error";
import { ILocalFile } from "../../../types/files/ILocalFile";
import { PathNamesEnum } from "../../../types/enums/PathNamesEnum";
import { useHistory } from "react-router-dom";
import DriveSelect from "../../DriveSelect/DriveSelect";
import ElectronErrorModal from "../../Modals/ElectronErrorModal/ElectronErrorModal";
import { RENDERER_EVENTS } from "../../../shared-constants/events";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../state/store";
import { actions as electronActions } from "../../../state/electron/actions";
import {
  selectSelectedDrive,
  selectUserProfile
} from "../../../utils/helpers/stateSelectorHelpers";
import { ClaimsEnum, TranslationsEnum } from "../../../types/enums";
import { actions as syncSuitActions } from "../../../state/syncSuit/actions";
import { IFile } from "../../../types/files/IFile";
import ClearSDCardModal from "../../Modals/ClearSDCardModal/ClearSDCardModal";
import { authProviderSignUpSignIn } from "../../../utils/configs/authProvider";
import { getSessionFiles } from "../../../api/sessionFiles";
import SessionCommentsModal from "../reusable/SessionCommentsModal";
import { IEditSessionGoalsDto } from "../../../types/requestDtos/IEditSessionGoalsDto";
import { useMutation } from "@apollo/react-hooks";
import { setSessionGoalsMutation } from "../../../mutations/sessions";
import { useErrorHandling } from "../../../utils/helpers/queryHelpers";

declare const window: IWindow;

const SyncMyoSuitElectron: React.FC = () => {
  const { selectedDrive } = useSelector(selectSelectedDrive);
  const { t } = useTranslation();
  const [showSessionCommentsModal, setShowSessionCommentsModal] = useState(
    false
  );
  const handleCloseSessionCommentsModal = () =>
    setShowSessionCommentsModal(false);
  const classes = useStyles();
  const {
    files,
    handleOnDrop,
    isFinishedUploading,
    infosOfUploadedFiles
  } = useHandleOnDrop();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userProfile } = useSelector(selectUserProfile);
  const [showSDCardClearModal, setShowSDCardClearModal] = useState(false);

  const setPreselectedUnit = useCallback(
    (unitSerialNumber: string | null) => {
      dispatch(syncSuitActions.setPreselectedUnit(unitSerialNumber));
    },
    [dispatch]
  );

  const updateDriveFilesInfo = useCallback(
    (files: Record<string, IFile>) => {
      dispatch(electronActions.updateFilesInfo(files));
    },
    [dispatch]
  );

  const setSelectedDrive = useCallback(
    (drive: IDisk) => {
      dispatch(electronActions.selectDriveAction(drive));
    },
    [dispatch]
  );

  const unselectDrive = useCallback(() => {
    dispatch(electronActions.unselectDriveAction());
  }, [dispatch]);

  const isUploadingFiles = files.filenames.length > 0;
  const isDoneButtonDisabled = !isFinishedUploading && isUploadingFiles;

  const downloadToDrive = useCallback(async (drive: IDisk) => {
    setLoading(true);
    const {
      idToken: { rawIdToken }
    } = await authProviderSignUpSignIn.getIdToken();

    const { data } = await getSessionFiles();
    window.downloadFiles({
      arrayBuffer: data,
      path: drive.path,
      token: `Bearer ${rawIdToken}`
    });
  }, []);

  const handleOnClick = useCallback(async () => {
    if (isUploadingFiles) {
      setShowSDCardClearModal(true);
      return;
    }
    if (userProfile?.claimsType === ClaimsEnum.Patient) {
      if (!userProfile?.patientHasUnit) {
        history.push(PathNamesEnum.NoSuitInstructions);
        return;
      }
      if (selectedDrive) {
        await downloadToDrive(selectedDrive);
      }
      return;
    }
    history.push(PathNamesEnum.PrepareSession);
  }, [history, downloadToDrive, isUploadingFiles, userProfile, selectedDrive]);

  const handleClose = useCallback(async () => {
    setShowSDCardClearModal(false);
  }, [setShowSDCardClearModal]);

  const handleDownload = useCallback(async () => {
    setShowSDCardClearModal(false);
    if (userProfile?.claimsType === ClaimsEnum.Patient) {
      if (!userProfile?.patientHasUnit) {
        history.push(PathNamesEnum.NoSuitInstructions);
        return;
      }
      if (selectedDrive) {
        await downloadToDrive(selectedDrive);
      }
      return;
    }
    history.push(PathNamesEnum.PrepareSession);
  }, [
    downloadToDrive,
    setShowSDCardClearModal,
    selectedDrive,
    userProfile,
    history
  ]);

  const handleDriveSelect = (drive: IDisk) => {
    setSelectedDrive(drive);
    setLoading(true);
    window.getLocalFiles(drive.path);
  };

  useEffect(() => {
    window.ipcRenderer.on(RENDERER_EVENTS.DOWNLOAD_FILES_REPLY, () => {
      unselectDrive();
      setLoading(false);
      history.push(PathNamesEnum.DownloadSuccessPage);
    });

    window.ipcRenderer.on(
      RENDERER_EVENTS.GET_LOCAL_FILES_REPLY,
      (event: Event, fileObjs: ILocalFile[]) => {
        const localFiles = fileObjs.map(
          file => new File([file.fileObject], file.filename)
        );
        setLoading(false);
        handleOnDrop(localFiles, []);
      }
    );

    return () => {
      window.ipcRenderer.removeAllListeners(
        RENDERER_EVENTS.DOWNLOAD_FILES_REPLY
      );
      window.ipcRenderer.removeAllListeners(
        RENDERER_EVENTS.GET_LOCAL_FILES_REPLY
      );
    };
  }, [unselectDrive, handleOnDrop, history]);

  useEffect(() => {
    const { filenames, filesByNames } = files;
    if (isFinishedUploading && filenames.length > 0) {
      updateDriveFilesInfo(filesByNames);
      setShowSessionCommentsModal(true);
      const unitSerialNumbers: string[] = filenames
        .filter(fileName => {
          const hasWarning =
            filesByNames[fileName].warning !== null &&
            filesByNames[fileName].warning !== undefined;
          return filesByNames[fileName].isHeader && !hasWarning;
        })
        .map(fileName => filesByNames[fileName].unitSerialNumber || "");
      const uniqueUnitSerialNumbers = Array.from(new Set(unitSerialNumbers));
      if (uniqueUnitSerialNumbers.length === 1) {
        const [unitSerialNumber] = uniqueUnitSerialNumbers;
        setPreselectedUnit(unitSerialNumber);
        return;
      }
      setPreselectedUnit(null);
    }
  }, [updateDriveFilesInfo, setPreselectedUnit, isFinishedUploading, files]);

  const [editSessionGoals, { error: setSessionGoalsError }] = useMutation(
    setSessionGoalsMutation,
    {
      onError: () => {}
    }
  );

  useErrorHandling(setSessionGoalsError);

  const handleSetSessionGoals = (sessionGoals: IEditSessionGoalsDto) => {
    editSessionGoals({
      variables: { values: sessionGoals }
    });
  };

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress size={110} color="primary" />
      </Box>
    );
  }

  return !selectedDrive ? (
    <DriveSelect onDriveSelect={handleDriveSelect} />
  ) : (
    <Box className={classes.container}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.selectedDevice}
      >
        <Typography variant="subtitle2">
          {t(TranslationsEnum.Electron_Containers_SyncMyoSuit_SelectedDevice)}:{" "}
          {selectedDrive.label}
        </Typography>
        <Button
          color="primary"
          onClick={unselectDrive}
          className={classes.changeBtn}
        >
          {t(TranslationsEnum.Electron_Containers_SyncMyoSuit_ChangeDevice)}
        </Button>
      </Box>
      <Paper className={classes.paperContainer}>
        {isUploadingFiles ? (
          <FilesList files={files} isFinishedUploading={isFinishedUploading} />
        ) : (
          <Box className={classes.tipContainer}>
            <ErrorIcon className={classes.blue} />
            <Typography
              variant="subtitle2"
              className={clsx(classes.blue, classes.tipText)}
            >
              {t(
                TranslationsEnum.Electron_Containers_SyncMyoSuit_TipToSkipElectron
              )}
            </Typography>
          </Box>
        )}
        <Button
          className={classes.button}
          variant={isDoneButtonDisabled ? "outlined" : "contained"}
          color="primary"
          type="submit"
          onClick={handleOnClick}
          disabled={isDoneButtonDisabled}
        >
          {isUploadingFiles
            ? t(TranslationsEnum.Global_Done)
            : t(TranslationsEnum.Global_Skip)}
        </Button>
      </Paper>
      <ElectronErrorModal />
      <SessionCommentsModal
        infosOfUploadedFiles={infosOfUploadedFiles}
        handleSetSessionGoals={handleSetSessionGoals}
        open={
          userProfile?.claimsType === ClaimsEnum.Operator &&
          showSessionCommentsModal &&
          infosOfUploadedFiles.filter(
            f => f.sessionGuid !== null && f.fileUploadWarning === null
          ).length > 0
        }
        handleClose={handleCloseSessionCommentsModal}
      />
      <ClearSDCardModal
        open={showSDCardClearModal}
        handleClose={handleClose}
        handleDownload={handleDownload}
      />
    </Box>
  );
};

export default SyncMyoSuitElectron;
