import { ActionsUnion } from "../../utils/typings/common";

// Action types
export const TOGGLE_DRAWER = "header/TOGGLE_DRAWER";

// Action creators
export const actions = {
  toggleDrawerAction: (isOpen: boolean) => ({
    type: TOGGLE_DRAWER as typeof TOGGLE_DRAWER,
    payload: isOpen
  })
};

export type ActionTypes = ActionsUnion<typeof actions>;
