import React from "react";
import { useState } from "react";
import {
  Dialog,
  Button,
  Fade,
  Stepper,
  Step,
  StepLabel,
  Grid,
  CircularProgress,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box
} from "@material-ui/core";
import { Form, Formik, FormikValues } from "formik";
import GeneralFirst from "./steps/GeneralFirst/GeneralFirst";
import GeneralSecond from "./steps/GeneralSecond/GeneralSecond";
import DeficiencyDetailsSwing from "./steps/DeficiencyDetailsSwing/DefeciencyDetailsSwing";
import DeficiencyMain from "./steps/DeficiencyMain/DeficiencyMain";
import DeficiencyDetailsStance from "./steps/DeficiencyDetailsStance/DeficiencyDetailsStance";
import DeficiencyDetailsUpperBody from "./steps/DeficiencyDetailsUpperBody/DeficiencyDetailsUpperBody";
import { IGeneralFirstForm } from "../../types/forms/Questionnaire/IGeneralFirstForm";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../types/enums";
import { IGeneralSecondForm } from "../../types/forms/Questionnaire/IGeneralSecondForm";
import { IDeficiencyMain } from "../../types/forms/Questionnaire/IDeficiencyMain";
import { DeficiencyDetailVariantEnum } from "../../types/enums/DeficiencyDetailVariantEnum";
import { IPatientForm } from "../../types/forms/IPatientForm";
import { useMutation } from "@apollo/react-hooks";
import { updatePatientMutation } from "../../mutations/patients";
import { IPatient } from "../../types/IPatient";
import { IDeficiencyDetail } from "../../types/forms/Questionnaire/IDeficiencyDetail";
import InclusionAlert from "./steps/Components/InclusionAlert";
import GaitPhaseExplanation from "./steps/Components/GaitPhaseExplanation";
import { ProfitLevelResultEnum } from "../../types/enums/ProfitLevelResultEnum";
import { useErrorHandling } from "../../utils/helpers/queryHelpers";
import { useDispatch } from "react-redux";
import { actions } from "../../state/snackbar/actions";
import { SnackbarTypeEnum } from "../../types/enums/SnackbarTypeEnum";

interface IProps {
  open: boolean;
  handleClose: () => void;
  patientForm: IPatientForm;
  patient?: IPatient;
  patientId: number;
  handleSetProfitLevel: (profitLevel: ProfitLevelResultEnum) => void;
  handleOpenResults: () => void;
}

interface QuestionnaireResults {
  details: string[];
  fitLevel: ProfitLevelResultEnum;
  isSuccess: boolean;
}

const Questionnaire: React.FC<IProps> = ({
  open,
  handleClose,
  patientForm,
  patientId,
  handleSetProfitLevel,
  handleOpenResults,
  patient
}) => {
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const handleCloseWithReset = () => {
    resetForm();
    handleClose();
    handleCloseInclusionAlert();
  };

  const handleOnCompleted = () => {
    handleCloseWithReset();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Snackbar_SaveQuestionnaireSuccess)
      )
    );
  };

  const [showInclusionAlert, setShowInclusionAlert] = useState(false);
  const handleOpenInclusionAlert = () => {
    setStep(0);
    setShowInclusionAlert(true);
  };

  const handleFirstStepValidation = (values: FormikValues) => {
    if (values.GeneralQuestionsFirst.pregnant.value) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_IsPregnant)
      );
    }

    if (
      trialInclusionConstants.minWeight >
        (values.GeneralQuestionsFirst.weight.value ?? 0) ||
      trialInclusionConstants.maxWeight <
        (values.GeneralQuestionsFirst.weight.value ?? 0)
    ) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_Weight)
      );
    }

    if (
      trialInclusionConstants.minHeight >
        (values.GeneralQuestionsFirst.height.value ?? 0) ||
      trialInclusionConstants.maxHeight <
        (values.GeneralQuestionsFirst.height.value ?? 0)
    ) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_Height)
      );
    }

    if (!values.GeneralQuestionsFirst.hearing.value) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_Hearing)
      );
    }

    if (values.GeneralQuestionsFirst.sagittalPlane.value) {
      values.errors.push(
        t(
          TranslationsEnum.Containers_Modals_Questionnaire_Results_SagittalPlane
        )
      );
    }

    if (!values.GeneralQuestionsFirst.canStand.value) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_CanStand)
      );
    }

    if (
      trialInclusionConstants.minReachScore >
      (values.GeneralQuestionsFirst.reachScore.value ?? 0)
    ) {
      values.errors.push(
        t(TranslationsEnum.Containers_Modals_Questionnaire_Results_ReachScore)
      );
    }
  };

  const handleDeficiencyDetailsValidation = (values: FormikValues) => {
    const details: IDeficiencyDetail[] = [];
    const deficiencyMainQuestions: IDeficiencyMain =
      values.DeficiencyMainQuestions;
    Object.values(deficiencyMainQuestions).forEach(element => {
      for (const key of Object.keys(element.questions)) {
        details.push(...element.questions[key].details);
      }
    });

    const darkGreyCount = details.filter(
      element =>
        element.value === true &&
        element.variant === DeficiencyDetailVariantEnum.dark
    ).length;
    const lightGreyCount = details.filter(
      element =>
        element.value === true &&
        element.variant === DeficiencyDetailVariantEnum.light
    ).length;
    if (darkGreyCount >= trialInclusionConstants.minDarkGreyBoxes) {
      questionnaireResults.isSuccess = true;
      questionnaireResults.fitLevel = ProfitLevelResultEnum.OptimalProfit;
      handleSetProfitLevel(ProfitLevelResultEnum.OptimalProfit);
    } else if (lightGreyCount >= trialInclusionConstants.minLightGreyBoxes) {
      questionnaireResults.isSuccess = true;
      questionnaireResults.fitLevel = ProfitLevelResultEnum.NormalProfit;
      handleSetProfitLevel(ProfitLevelResultEnum.NormalProfit);
    } else {
      questionnaireResults.isSuccess = false;
      questionnaireResults.fitLevel = ProfitLevelResultEnum.NoProfit;
      handleSetProfitLevel(ProfitLevelResultEnum.NoProfit);
    }
    values.details = details;
  };

  const handleCloseInclusionAlert = () => {
    setStep(0);
    setShowInclusionAlert(false);
  };

  const trialInclusionConstants = {
    minWeight: 45,
    maxWeight: 110,
    minHeight: 150,
    maxHeight: 195,
    minReachScore: 15.24,
    minDarkGreyBoxes: 1,
    minLightGreyBoxes: 3
  };

  const resetForm = () => {
    setStep(0);
  };
  const [showGaitPhaseExplanation, setShowGaitPhaseExplanation] = useState(
    true
  );

  const handleCloseGaitPhaseExplanation = () => {
    setShowGaitPhaseExplanation(false);
  };

  const { t } = useTranslation();

  const pages = [
    {
      page: GeneralFirst,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_Title
      ),
      maxWidth: "md"
    },
    {
      page: GeneralSecond,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_Title
      ),
      maxWidth: "md"
    },
    {
      page: DeficiencyMain,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Title
      ),
      maxWidth: "lg"
    },
    {
      page: DeficiencyDetailsSwing,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyStance_Name
      ),
      maxWidth: "lg"
    },
    {
      page: DeficiencyDetailsSwing,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencySwing_Name
      ),
      maxWidth: "lg"
    },
    {
      page: DeficiencyDetailsUpperBody,
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_Name
      ),
      maxWidth: "lg"
    }
  ];

  const handleFormSubmit = (values: IPatientForm) => {
    updatePatient({
      variables: { values }
    });
  };

  const [updatePatient, { error }] = useMutation(
    updatePatientMutation(patientId),
    {
      onError: () => {},
      onCompleted: handleOnCompleted
    }
  );

  useErrorHandling(error);

  const DeficiencyMainQuestions: IDeficiencyMain = {
    spasticity: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_Title
      ),
      questions: {
        hamstringSpasticity: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_HamstringSpasticity
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        hipFlexionsSpasticity: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_HipFlexionsSpasticity
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        kneeExtensionSpasticity: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_KneeExtensionSpasticity
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 10,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            {
              id: 11,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        plantarflexionSpasticity: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Spasticity_PlantarflexionSpasticity
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 16,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        }
      }
    },
    contracture: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Contracture_Title
      ),
      questions: {
        ankleFixed: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Contracture_AnkleFixed
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        kneeFlexionContracture: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Contracture_KneeFlexionContracture
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        hipFlexionContracture: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Contracture_HipFlexionContracture
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 16,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        }
      }
    },
    posturalControl: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_Title
      ),
      questions: {
        narrowStanceWidth: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_NarrowStanceWidth
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 20,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            }
          ]
        },
        asymmetricalWeightBearing: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_AsymmetricalWeightBearing
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.dark }
          ]
        },
        unevenLegLength: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_UnevenLegLength
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 15,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        wideStanceWidth: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_WideStanceWidth
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.dark }
          ]
        },
        circumductionHipHiking: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_PosturalControl_CircumductionHipHiking
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 10,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            {
              id: 11,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 15,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        }
      }
    },
    weakness: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_Title
      ),
      questions: {
        kneeExtensionDeficit: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_KneeExtensionDeficit
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 10,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.dark },
            {
              id: 13,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        plantarflexionWeakness: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_PlantarflexionWeakness
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        dorsiflexionWeakness: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_DorsiflexionWeakness
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.light },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 13,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 16,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        hipExtensionWeakness: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_HipExtensionWeakness
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        hipFlexionWeakness: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_HipFlexionWeakness
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 10,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            {
              id: 11,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.dark },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 15,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 18,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 19, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        },
        abductorWeakness: {
          main: {
            label: t(
              TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyMain_Weakness_AbductorWeakness
            ),
            value: false
          },
          details: [
            { id: 0, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 1, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 2, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 3, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 4, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 5, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 6, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 7, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 8, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 9, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 10, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 11, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 12, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 13, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 14, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 15, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 16, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 17, value: false, variant: DeficiencyDetailVariantEnum.none },
            { id: 18, value: false, variant: DeficiencyDetailVariantEnum.none },
            {
              id: 19,
              value: false,
              variant: DeficiencyDetailVariantEnum.light
            },
            { id: 20, value: false, variant: DeficiencyDetailVariantEnum.none }
          ]
        }
      }
    }
  };

  const GeneralQuestionsSecond: IGeneralSecondForm = {
    shufflingManner: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_ShufflingManner
      ),
      reminder: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_ShufflingMannerReminder
      ),
      value: false
    },
    hipFlexionContracture: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_HipFlexionContracture
      ),
      reminder: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_HipFlexionContractureReminder
      ),
      value: false
    },
    adductorContracture: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_AdductorContracture
      ),
      reminder: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralTwo_AdductorContractureReminder
      ),
      value: false
    }
  };

  const GeneralQuestionsFirst: IGeneralFirstForm = {
    height: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_Height
      ),
      value: patient?.height ?? patientForm?.height
    },
    weight: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_Weight
      ),
      value: patient?.weight ?? patientForm?.weight
    },
    reachScore: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_ReachScore
      ),
      value: 0
    },
    versusValgusDeformity: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_VersusValgusDeformity
      ),
      value: false
    },
    ankleContracture: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_AnkleContracture
      ),
      value: false
    },
    pregnant: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_IsPregnant
      ),
      value: false
    },
    hearing: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_Hearing
      ),
      value: false
    },
    sagittalPlane: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_SagittalPlane
      ),
      value: false
    },
    canStand: {
      label: t(
        TranslationsEnum.Containers_Modals_Questionnaire_GeneralOne_CanStand
      ),
      value: false
    }
  };

  function handleNext(values: FormikValues) {
    if (step === 0) {
      handleFirstStepValidation(values);
    }

    if (values.errors.length) {
      handleOpenInclusionAlert();
    } else {
      if (step + 1 <= pages.length - 1) {
        setStep(step + 1);
      } else {
        setShouldSubmit(true);
      }
    }
  }

  const handleBack = () => {
    setStep(step - 1);
  };

  const updatePatientFields = (values: FormikValues) => {
    values.errors = [];
    if (patient) {
      patientForm.height = parseInt(
        JSON.stringify(values.GeneralQuestionsFirst.height.value)
      );
      patientForm.weight = parseInt(
        JSON.stringify(values.GeneralQuestionsFirst.weight.value)
      );
      patientForm.name = patient.name ?? "";
      patientForm.pathologyId = parseInt(JSON.stringify(patient?.pathologyId));
      patientForm.suggestedPathology = JSON.stringify(
        patient?.suggestedPathology
      );
      patientForm.inSanitasTrial = patient.inSanitasTrial;
      patientForm.email = JSON.stringify(patient.email);
      patientForm.phoneNumber = JSON.stringify(patient.phoneNumber);
    }
  };

  const handleNextClick = (values: FormikValues) => () => {
    updatePatientFields(values);
    handleNext(values);
  };

  const handleSubmit = (values: FormikValues) => {
    updatePatientFields(values);
    if (shouldSubmit) {
      handleDeficiencyDetailsValidation(values);
      patientForm.questionnaire = JSON.stringify(values);
      patientForm.QuestionnaireResults = JSON.stringify(questionnaireResults);
      patientForm.isQuestionnaireDone = true;
      patientForm.weight = values.GeneralQuestionsFirst.weight.value;
      patientForm.height = values.GeneralQuestionsFirst.height.value;
      handleFormSubmit(patientForm);
      handleOpenResults();
    }
  };

  var questionnaireResults: QuestionnaireResults = {
    fitLevel: -1,
    details: [""],
    isSuccess: false
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWithReset}
      maxWidth={
        pages[step].maxWidth as
          | false
          | "md"
          | "lg"
          | "xs"
          | "sm"
          | "xl"
          | undefined
      }
    >
      <Formik
        initialValues={{
          GeneralQuestionsFirst,
          GeneralQuestionsSecond,
          DeficiencyMainQuestions,
          errors: [],
          showPages: [
            { index: 0, show: true },
            { index: 1, show: true },
            { index: 2, show: true },
            { index: 3, show: true },
            { index: 4, show: true },
            { index: 5, show: true }
          ]
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Fade in={true}>
            <Form autoComplete="off">
              <DialogTitle>
                <Stepper alternativeLabel activeStep={step}>
                  <Stepper alternativeLabel activeStep={step}>
                    {values.showPages
                      .filter(page => page.show)
                      .map((page: any) => {
                        return (
                          <Step key={pages[page.index].label}>
                            <StepLabel>{pages[page.index].label}</StepLabel>
                          </Step>
                        );
                      })}
                  </Stepper>
                </Stepper>
              </DialogTitle>
              <DialogContent>
                {step === 0 && (
                  <>
                    <GeneralFirst values={values} />
                    <InclusionAlert
                      handleBack={handleCloseInclusionAlert}
                      handleClose={handleCloseWithReset}
                      open={showInclusionAlert}
                      errors={values.errors}
                    />
                  </>
                )}
                {step === 1 && <GeneralSecond values={values} />}
                {step === 2 && <DeficiencyMain values={values} />}
                {step === 3 && showGaitPhaseExplanation && (
                  <GaitPhaseExplanation
                    open={showGaitPhaseExplanation}
                    handleClose={handleCloseGaitPhaseExplanation}
                  />
                )}
                {step === 3 && !showGaitPhaseExplanation && (
                  <DeficiencyDetailsStance values={values} />
                )}
                {step === 4 && <DeficiencyDetailsSwing values={values} />}
                {step === 5 && <DeficiencyDetailsUpperBody values={values} />}
              </DialogContent>
              <Box m={2}>
                <DialogActions>
                  <Grid container direction="row" spacing={2} justify="center">
                    {step > 0 ? (
                      <Grid item>
                        <Button
                          disabled={isSubmitting}
                          color="primary"
                          variant="contained"
                          onClick={handleBack}
                        >
                          {t(TranslationsEnum.Global_Back)}
                        </Button>
                      </Grid>
                    ) : null}
                    {!shouldSubmit ? (
                      <Grid item>
                        <Button
                          onClick={handleNextClick(values)}
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          variant="contained"
                          color="primary"
                        >
                          {t(TranslationsEnum.Global_Next)}
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {t(TranslationsEnum.Global_Save)}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </DialogActions>
              </Box>
            </Form>
          </Fade>
        )}
      </Formik>
    </Dialog>
  );
};

export default Questionnaire;
