import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center"
  },
  icon: {
    color: ColorEnum.LightGreyIcons,
    fontSize: 96,
    marginTop: 100
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 300,
    marginTop: 30,
    color: ColorEnum.DarkGrey
  },
  subHeading: {
    color: ColorEnum.DarkGrey,
    fontStyle: "normal",
    fontWeight: 300
  },
  container: {
    width: "50%",
    margin: "auto"
  },
  link: {
    marginLeft: theme.spacing(0.5)
  },
  form: {
    marginTop: theme.spacing(6),
    display: "flex"
  },
  textField: {
    width: "100%",
    marginRight: theme.spacing(2)
  },
  sendButton: {
    height: theme.spacing(5),
    marginTop: theme.spacing(1)
  }
}));
