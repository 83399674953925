import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, Grid, Typography } from "@material-ui/core";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  handleQuestionnaireOpen: () => void;
  handleClose: () => void;
}

const QuestionnaireExplanation: React.FC<IProps> = ({
  open,
  handleQuestionnaireOpen,
  handleClose
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box m={2}>
            <Typography variant="h5">
              {t(
                TranslationsEnum.Containers_Modals_QuestionnaireExplanation_Explanation
              )}
            </Typography>
          </Box>
          <Box m={2}>
            <Typography variant="h5">
              {t(
                TranslationsEnum.Containers_Modals_QuestionnaireExplanation_DoTestNow
              )}
            </Typography>
          </Box>
        </DialogContent>
        <Box m={2}>
          <DialogActions>
            <Grid container direction="row" spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  {t(
                    TranslationsEnum.Containers_Modals_QuestionnaireExplanation_Later
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleQuestionnaireOpen}
                  color="primary"
                  variant="contained"
                >
                  {t(
                    TranslationsEnum.Containers_Modals_QuestionnaireExplanation_StartNow
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default QuestionnaireExplanation;
