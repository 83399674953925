import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  lightGrey: {
    backgroundColor: ColorEnum.LightGrey
  },
  lightBlue: {
    backgroundColor: ColorEnum.LightBlue
  },
  lightGreen: {
    backgroundColor: ColorEnum.GreenLight
  },
  lightOrange: {
    backgroundColor: ColorEnum.OrangeLight
  },
  lightPurple: {
    backgroundColor: ColorEnum.PurpleLight
  }
}));
