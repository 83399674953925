import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    position: "relative"
  },
  arrowLeft: {
    position: "absolute",
    left: 0,
    top: "50%"
  },
  arrowRight: {
    position: "absolute",
    right: 0,
    top: "50%"
  },
  arrowIcon: {
    fontSize: theme.spacing(2),
    color: ColorEnum.Blue
  },
  rotate180: {
    transform: "rotate(180deg)"
  },
  disabledArrow: {
    color: "inherit"
  },
  circularProgress: {
    position: "absolute",
    left: "50%"
  }
}));
