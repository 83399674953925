import { useMutation, useQuery } from "@apollo/react-hooks";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateUnitMutation } from "../../../../mutations";
import {
  getFirmwareOptionsQuery,
  getUnitQuery,
  getUnitStatusOptionsQuery
} from "../../../../queries";
import { getClinicsOptionsQuery } from "../../../../queries/clinics";
import { actions } from "../../../../state/snackbar/actions";
import { TranslationsEnum } from "../../../../types/enums";
import { SnackbarTypeEnum } from "../../../../types/enums/SnackbarTypeEnum";
import { IFilterConfig } from "../../../../types/filters/IFilterConfig";
import { IOption } from "../../../../types/formInputs/IOption";
import { IUnitForm } from "../../../../types/forms/IUnitForm";
import { IPatient } from "../../../../types/IPatient";
import { IUnitDto } from "../../../../types/IUnitDto";
import { useErrorHandling } from "../../../../utils/helpers/queryHelpers";
import UnitForm from "../UnitForm/UnitForm";
import { unitFormSchema } from "../UnitForm/unitFormSchema";

interface IProps {
  open: boolean;
  unitId: number | null;
  handleClose: () => void;
  refetchUnits: (variables?: { filterConfig: IFilterConfig }) => void;
}

const EditUnitModal: React.FC<IProps> = ({
  open,
  unitId,
  refetchUnits,
  handleClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: unitData, error: unitError } = useQuery<{
    unit: IUnitDto;
  }>(getUnitQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !unitId,
    variables: {
      values: {
        id: unitId
      }
    }
  });

  useErrorHandling(unitError);
  const unit = unitData?.unit;

  const handleUpdateSuccess = () => {
    handleModalClose();
    refetchUnits();

    dispatch(
      actions.showSnackbar(
        SnackbarTypeEnum.Success,
        t(TranslationsEnum.Global_UnitUpdatedSuccessfully)
      )
    );
  };

  const [updateUnit, { loading, error }] = useMutation(updateUnitMutation, {
    onError: () => {},
    onCompleted: handleUpdateSuccess
  });

  useErrorHandling(error);

  const handleFormSubmit = (values: IUnitForm) => {
    if (isValid) {
      const { patient, ...rest } = values;
      updateUnit({
        variables: {
          values: {
            ...rest,
            patientId: patient?.id,
            id: unitId
          }
        }
      });
    }
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const { data: dataClinicsOptions, error: errorClinicsOptions } = useQuery<{
    clinicsOptions: IOption[];
  }>(getClinicsOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorClinicsOptions);

  const { data: dataFirmwareOptions, error: errorFirmwareOptions } = useQuery<{
    firmwareOptions: IOption[];
  }>(getFirmwareOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorFirmwareOptions);

  const {
    data: dataUnitStatusOptions,
    error: errorUnitStatusOptions
  } = useQuery<{
    unitStatusOptions: IOption[];
  }>(getUnitStatusOptionsQuery, {
    fetchPolicy: "cache-and-network"
  });

  useErrorHandling(errorUnitStatusOptions);

  const getUserLabel = useCallback((user: IPatient) => {
    if (!user) {
      return "";
    }

    if (!user.email) {
      return user.name + "";
    }
    return `${user.name} (${user.email})`;
  }, []);
  const initialValues: IUnitForm = {
    serialNumber: unit?.serialNumber || "",
    hardwareModel: unit?.hardwareModel || "",
    nexusProtocolVersion: unit?.nexusProtocolVersion || "",
    currentFirmwareVersion: unit?.currentFirmwareVersion || 0,
    futureFirmwareVersion: unit?.futureFirmwareVersion || 0,
    unitStatus: unit?.unitStatus || 0,
    unitStatusSource: unit?.unitStatusSource || 0,
    clinicId: unit?.clinicId || 0,
    patient: unit?.patient
      ? {
          ...unit?.patient,
          label: getUserLabel(unit?.patient)
        }
      : null
  };

  const clinicsOptions = !dataClinicsOptions?.clinicsOptions
    ? []
    : [
        ...dataClinicsOptions.clinicsOptions,
        {
          label: t(TranslationsEnum.Global_None),
          value: ""
        }
      ];

  const firmwareOptions = dataFirmwareOptions?.firmwareOptions || [];
  const unitStatusOptions = dataUnitStatusOptions?.unitStatusOptions || [];

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isValid,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema: unitFormSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  const props = {
    errors,
    values,
    open,
    loading,
    clinicsOptions,
    unitStatusOptions,
    firmwareOptions,
    formTitle: t(TranslationsEnum.Global_EditUnit),
    isEditModal: true,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleClose: handleModalClose
  };

  return <UnitForm {...props} />;
};

export default EditUnitModal;
