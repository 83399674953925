import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, Grid, Typography } from "@material-ui/core";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const GaitPhaseExplanation: React.FC<IProps> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogContent>
          <Box m={2}>
            <Typography color="primary" variant="h5">
              {t(
                TranslationsEnum.Containers_Modals_Questionnaire_GaitPhaseExplanation_Header
              )}
            </Typography>
          </Box>
          <Box m={2}>
            <Typography>
              {t(
                TranslationsEnum.Containers_Modals_Questionnaire_GaitPhaseExplanation_Body
              )}
            </Typography>
          </Box>
          <Box m={2}>
            <Typography>
              {t(
                TranslationsEnum.Containers_Modals_Questionnaire_GaitPhaseExplanation_Footer
              )}
            </Typography>
          </Box>
        </DialogContent>
        <Box m={2}>
          <DialogActions>
            <Grid container direction="row" spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  {t(TranslationsEnum.Global_Continue)}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default GaitPhaseExplanation;
