import React from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  FormControlProps
} from "@material-ui/core";
import { ColorEnum } from "../../types/enums/ColorEnum";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import useStyles from "./PhoneInputField.styles";

export interface ICountry {
  name: string;
  dialCode: number;
  countryCode: string;
  format: string;
}

interface IPhoneInputProps {
  value: string;
  country?: string;
  onlyCountries?: Array<string>;
  preferredCountries?: Array<string>;
  excludeCountries?: Array<string>;
  placeholder?: string;
  searchPlaceholder?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  autoFormat?: boolean;
  disabled?: boolean;
  disableDropdown?: boolean;
  disableCountryCode?: boolean;
  enableAreaCodes?: boolean;
  enableTerritories?: boolean;
  enableLongNumbers?: boolean;
  countryCodeEditable?: boolean;
  enableSearch?: boolean;
  disableSearchIcon?: boolean;
  regions?: Array<string>;
  preserveOrder?: Array<string>;
  localization?: Record<string, string>;
  defaultMask?: string;
  alwaysDefaultMask?: boolean;
  masks?: Record<string, string>;
  prefix?: string;
  copyNumbersOnly?: boolean;
  renderStringAsFlag?: string;
  autocompleteSearch?: boolean;
  jumpCursorToEnd?: boolean;
  tabIndex?: number;
  priority?: Record<string, number>;
  containerClass?: string;
  inputClass?: string;
  buttonClass?: string;
  dropdownClass?: string;
  searchClass?: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  searchStyle?: React.CSSProperties;
  onChange: (
    value: string,
    country?: ICountry,
    e?: React.SyntheticEvent,
    formattedValue?: any
  ) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isValid?: (
    inputNumber: string,
    country: string,
    countries: Array<string>
  ) => boolean;
}

interface IProps extends IPhoneInputProps {
  name: string;
  label: string;
  error?: string;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
}
const PhoneInputField = (props: IProps) => {
  const {
    name,
    label,
    error,
    FormControlProps,
    FormHelperTextProps,
    inputStyle,
    ...rest
  } = props;

  const classes = useStyles({ label, error });

  const getInputStyle = () =>
    error
      ? { ...inputStyle, borderColor: ColorEnum.ErrorRed }
      : { ...inputStyle };

  return (
    <FormControl
      {...FormControlProps}
      key={`formControl${name}`}
      className={clsx(
        error ? classes.formControlError : classes.formControl,
        FormControlProps?.className
      )}
      error={Boolean(error)}
    >
      <PhoneInput {...rest} inputStyle={{ ...getInputStyle() }} />
      <FormHelperText {...FormHelperTextProps} error={Boolean(error)}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};
export default PhoneInputField;
