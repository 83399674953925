import * as actions from "./actions";
import { IHeaderState } from "../../types/reducerStates/IHeaderState";

const initialState: IHeaderState = {
  isOpen: true
};

export const reducer = (
  state: IHeaderState = initialState,
  action: actions.ActionTypes
): IHeaderState => {
  switch (action.type) {
    case actions.TOGGLE_DRAWER:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      return state;
  }
};
