import * as React from "react";
import { ISvgProps } from "../../types/ISvgProps";

const StairsIcon = (props: ISvgProps) => {
  return (
    <svg width={36} height={36} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.473 3h-4.237a.527.527 0 00-.527.527v3.71H12a.527.527 0 00-.527.527v3.709h-3.71a.527.527 0 00-.527.527v3.709H3.527a.527.527 0 00-.527.527v4.237c0 .29.236.527.527.527h16.946a.527.527 0 00.527-.527V3.527A.527.527 0 0020.473 3z"
        fill="#195F93"
      />
    </svg>
  );
};

export default StairsIcon;
