import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
  clinicInfoRow: {
    display: "flex",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  paperContainer: {
    padding: theme.spacing(3)
  },
  icon: {
    color: ColorEnum.White,
    fontSize: 20
  },
  infoText: {
    fontStyle: "normal",
    fontWeight: 600,
    marginRight: "auto",
    paddingTop: 1,
    paddingLeft: 5
  },
  buttonText: {
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "uppercase",
    paddingTop: 1,

    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  colorWhite: {
    color: ColorEnum.White
  },
  colorDarkGrey: {
    color: ColorEnum.DarkGrey
  },
  colorMiddleBlack: {
    color: ColorEnum.MiddleBlack
  },
  orangeBackground: {
    background: ColorEnum.Orange
  },
  tealBlueBackground: {
    background: ColorEnum.TealBlueBackground
  }
}));
