import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  translationEN,
  translationDE,
  translationFR,
  translationIT
} from "./locales";
import { LanguageEnum } from "./types/enums/LanguageEnum";

const availableLanguages = [
  LanguageEnum.De,
  LanguageEnum.En,
  LanguageEnum.Fr,
  LanguageEnum.It
];

const resources = {
  [LanguageEnum.En]: {
    translation: translationEN
  },
  [LanguageEnum.De]: {
    translation: translationDE
  },
  [LanguageEnum.Fr]: {
    translation: translationFR
  },
  [LanguageEnum.It]: {
    translation: translationIT
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LanguageEnum.En,
    debug: true,
    whitelist: availableLanguages,
    react: {
      useSuspense: false
    }
  });

export default i18n;
