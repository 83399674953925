import * as Yup from "yup";
import i18n from "../../../../i18n";
import { TranslationsEnum } from "../../../../types/enums";

// TODO:  move general validations in one place
export const inviteUserToRegisterFormSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18n.t(TranslationsEnum.Validations_EmailIsNotValid))
      .required(i18n.t(TranslationsEnum.Validations_Required))
    // since phones in different countries have different length, we will use min 8 to say that it is required to enter phone
    // phoneNumber: Yup.string().test(
    //   "phoneNumber",
    //   i18n.t(TranslationsEnum.Validations_WrongNumberFormat),
    //   (val: string) => {
    //     // if nothing is inserted, default value = ""
    //     if (!val) {
    //       return true;
    //     }

    //     // if only the "+" and country code is inserted (maximum country code length = 3 in europe)
    //     if (val.length <= 4) {
    //       return true;
    //     }

    //     // if length is at least 8 characters (shortest phone number in the world according to wikipedia)
    //     return (
    //       val
    //         .replace("(", "")
    //         .replace(")", "")
    //         .replace(" ", "").length >= 8
    //     );
    //   }
    // ),
    // phoneNumberOrEmail: Yup.bool().when(["phoneNumber", "email"], {
    //   is: (phoneNumber: string, email: string) => {
    //     if (email) {
    //       return false;
    //     }

    //     if (phoneNumber && phoneNumber.length > 4) {
    //       return false;
    //     }

    //     return true;
    //   },
    //   then: Yup.bool().required(
    //     i18n.t(TranslationsEnum.Validations_PhoneNumberOrEmail)
    //   )
    // })
  });
