import { FormikValues, Field } from "formik";
import React from "react";
import { Checkbox } from "formik-material-ui";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Popover,
  Card,
  Container,
  Box,
  CardContent
} from "@material-ui/core";
import { DeficiencyDetailVariantEnum } from "../../../../types/enums/DeficiencyDetailVariantEnum";
import { IDeficiencyDetail } from "../../../../types/forms/Questionnaire/IDeficiencyDetail";
import useStyles from "../../QuestionnaireTable.Styles";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../../../types/enums/TranslationsEnum";
import InfoIcon from "@material-ui/icons/Info";

interface IProps {
  values: FormikValues;
}

const DeficiencyDetailsUpperBody: React.FC<IProps> = ({ values }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableTopRowBorder}>
              <TableCell align="center" colSpan={2}></TableCell>
              <TableCell
                className={classes.tableRightBorder}
                align="center"
                colSpan={3}
              >
                <Typography variant="h6">
                  {" "}
                  {t(
                    TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetails_UpperBody
                  )}
                </Typography>
              </TableCell>
              <TableCell
                className={classes.tableNoBorder}
                align="center"
                colSpan={1}
              >
                <Typography variant="h6">
                  {t(
                    TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetails_Coronal
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableNoBorder}>
              <TableCell align="center" colSpan={2}>
                {SimplePopover()}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                {t(
                  TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_AnteriorFlexion
                )}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                {t(
                  TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_PosteriorFlexion
                )}
              </TableCell>
              <TableCell
                className={classes.tableRightBorder}
                align="center"
                colSpan={1}
              >
                {t(
                  TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_FlexionLatera
                )}
              </TableCell>
              <TableCell
                className={classes.tableNoTopBorder}
                align="center"
                colSpan={1}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.DeficiencyMainQuestions.weakness.questions
              .kneeExtensionDeficit.main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .kneeExtensionDeficit.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .kneeExtensionDeficit.main.label,
                "DeficiencyMainQuestions.weakness.questions.kneeExtensionDeficit.details"
              )}
            {values.DeficiencyMainQuestions.weakness.questions
              .plantarflexionWeakness.main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .plantarflexionWeakness.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .plantarflexionWeakness.main.label,
                "DeficiencyMainQuestions.weakness.questions.plantarflexionWeakness.details"
              )}
            {values.DeficiencyMainQuestions.weakness.questions
              .dorsiflexionWeakness.main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .dorsiflexionWeakness.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .dorsiflexionWeakness.main.label,
                "DeficiencyMainQuestions.weakness.questions.dorsiflexionWeakness.details"
              )}
            {values.DeficiencyMainQuestions.weakness.questions
              .hipExtensionWeakness.main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .hipExtensionWeakness.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .hipExtensionWeakness.main.label,
                "DeficiencyMainQuestions.weakness.questions.hipExtensionWeakness.details"
              )}
            {values.DeficiencyMainQuestions.weakness.questions
              .hipFlexionWeakness.main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .hipFlexionWeakness.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .hipFlexionWeakness.main.label,
                "DeficiencyMainQuestions.weakness.questions.hipFlexionWeakness.details"
              )}
            {values.DeficiencyMainQuestions.weakness.questions.abductorWeakness
              .main.value &&
              Rows(
                values.DeficiencyMainQuestions.weakness.questions
                  .abductorWeakness.details,
                values.DeficiencyMainQuestions.weakness.questions
                  .abductorWeakness.main.label,
                "DeficiencyMainQuestions.weakness.questions.abductorWeakness.details"
              )}
            {values.DeficiencyMainQuestions.spasticity.questions
              .hamstringSpasticity.main.value &&
              Rows(
                values.DeficiencyMainQuestions.spasticity.questions
                  .hamstringSpasticity.details,
                values.DeficiencyMainQuestions.spasticity.questions
                  .hamstringSpasticity.main.label,
                "DeficiencyMainQuestions.spasticity.questions.hamstringSpasticity.details"
              )}
            {values.DeficiencyMainQuestions.spasticity.questions
              .hipFlexionsSpasticity.main.value &&
              Rows(
                values.DeficiencyMainQuestions.spasticity.questions
                  .hipFlexionsSpasticity.details,
                values.DeficiencyMainQuestions.spasticity.questions
                  .hipFlexionsSpasticity.main.label,
                "DeficiencyMainQuestions.spasticity.questions.hipFlexionsSpasticity.details"
              )}
            {values.DeficiencyMainQuestions.spasticity.questions
              .kneeExtensionSpasticity.main.value &&
              Rows(
                values.DeficiencyMainQuestions.spasticity.questions
                  .kneeExtensionSpasticity.details,
                values.DeficiencyMainQuestions.spasticity.questions
                  .kneeExtensionSpasticity.main.label,
                "DeficiencyMainQuestions.spasticity.questions.kneeExtensionSpasticity.details"
              )}
            {values.DeficiencyMainQuestions.spasticity.questions
              .plantarflexionSpasticity.main.value &&
              Rows(
                values.DeficiencyMainQuestions.spasticity.questions
                  .plantarflexionSpasticity.details,
                values.DeficiencyMainQuestions.spasticity.questions
                  .plantarflexionSpasticity.main.label,
                "DeficiencyMainQuestions.spasticity.questions.plantarflexionSpasticity.details"
              )}
            {values.DeficiencyMainQuestions.contracture.questions.ankleFixed
              .main.value &&
              Rows(
                values.DeficiencyMainQuestions.contracture.questions.ankleFixed
                  .details,
                values.DeficiencyMainQuestions.contracture.questions.ankleFixed
                  .main.label,
                "DeficiencyMainQuestions.contracture.questions.ankleFixed.details"
              )}
            {values.DeficiencyMainQuestions.contracture.questions
              .kneeFlexionContracture.main.value &&
              Rows(
                values.DeficiencyMainQuestions.contracture.questions
                  .kneeFlexionContracture.details,
                values.DeficiencyMainQuestions.contracture.questions
                  .kneeFlexionContracture.main.label,
                "DeficiencyMainQuestions.contracture.questions.kneeFlexionContracture.details"
              )}
            {values.DeficiencyMainQuestions.contracture.questions
              .hipFlexionContracture.main.value &&
              Rows(
                values.DeficiencyMainQuestions.contracture.questions
                  .hipFlexionContracture.details,
                values.DeficiencyMainQuestions.contracture.questions
                  .hipFlexionContracture.main.label,
                "DeficiencyMainQuestions.contracture.questions.hipFlexionContracture.details"
              )}
            {values.DeficiencyMainQuestions.posturalControl.questions
              .narrowStanceWidth.main.value &&
              Rows(
                values.DeficiencyMainQuestions.posturalControl.questions
                  .narrowStanceWidth.details,
                values.DeficiencyMainQuestions.posturalControl.questions
                  .narrowStanceWidth.main.label,
                "DeficiencyMainQuestions.posturalControl.questions.narrowStanceWidth.details"
              )}
            {values.DeficiencyMainQuestions.posturalControl.questions
              .asymmetricalWeightBearing.main.value &&
              Rows(
                values.DeficiencyMainQuestions.posturalControl.questions
                  .asymmetricalWeightBearing.details,
                values.DeficiencyMainQuestions.posturalControl.questions
                  .asymmetricalWeightBearing.main.label,
                "DeficiencyMainQuestions.posturalControl.questions.asymmetricalWeightBearing.details"
              )}
            {values.DeficiencyMainQuestions.posturalControl.questions
              .unevenLegLength.main.value &&
              Rows(
                values.DeficiencyMainQuestions.posturalControl.questions
                  .unevenLegLength.details,
                values.DeficiencyMainQuestions.posturalControl.questions
                  .unevenLegLength.main.label,
                "DeficiencyMainQuestions.posturalControl.questions.unevenLegLength.details"
              )}
            {values.DeficiencyMainQuestions.posturalControl.questions
              .wideStanceWidth.main.value &&
              Rows(
                values.DeficiencyMainQuestions.posturalControl.questions
                  .wideStanceWidth.details,
                values.DeficiencyMainQuestions.posturalControl.questions
                  .wideStanceWidth.main.label,
                "DeficiencyMainQuestions.posturalControl.questions.wideStanceWidth.details"
              )}
            {values.DeficiencyMainQuestions.posturalControl.questions
              .circumductionHipHiking.main.value &&
              Rows(
                values.DeficiencyMainQuestions.posturalControl.questions
                  .circumductionHipHiking.details,
                values.DeficiencyMainQuestions.posturalControl.questions
                  .circumductionHipHiking.main.label,
                "DeficiencyMainQuestions.posturalControl.questions.circumductionHipHiking.details"
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DeficiencyDetailsUpperBody;

function Rows(details: Array<IDeficiencyDetail>, label: String, path: String) {
  const cells = [];
  let checkboxCount: number = 0;
  const nameCell = (
    <TableCell align="center" colSpan={2}>
      {label}
    </TableCell>
  );

  for (let i = 17; i < 21; i++) {
    if (details[i].variant === DeficiencyDetailVariantEnum.none) {
      cells.push(<TableCell align="center" colSpan={1}></TableCell>);
    } else {
      checkboxCount++;
      const name = path + "[" + i + "].value";
      cells.push(
        <TableCell align="center" colSpan={1}>
          <Field
            name={name}
            color="primary"
            type="checkbox"
            component={Checkbox}
          />
        </TableCell>
      );
    }
  }
  return checkboxCount > 0 ? (
    <TableRow>{[nameCell, ...cells]}</TableRow>
  ) : (
    [<></>]
  );
}

export function SimplePopover() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoIcon color="primary" fontSize="large" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Card>
          <Container maxWidth="xs">
            <CardContent>
              <Box m={2}>
                <Typography color="primary" variant="h5">
                  {t(
                    TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoHeader
                  )}
                </Typography>
              </Box>
              <Box m={2}>
                <Typography>
                  {t(
                    TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoBody
                  )}
                </Typography>
              </Box>
              <Box m={2}>
                <Typography>
                  {t(
                    TranslationsEnum.Containers_Modals_Questionnaire_DeficiencyDetailsUpperBody_InfoFooter
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Container>
        </Card>
      </Popover>
    </Box>
  );
}
