import * as actions from "./actions";
import { IElectronState } from "../../types/reducerStates/IElectronState";
import { sessionLogsFilePrefix } from "../../shared-constants/files";
import { sessionHeaderFilePrefix } from "../../shared-constants/files";

const initialState: IElectronState = {
  selectedDrive: null
};

export const reducer = (
  state: IElectronState = initialState,
  action: actions.ActionTypes
): IElectronState => {
  switch (action.type) {
    case actions.SELECT_DRIVE:
      return {
        ...state,
        selectedDrive: action.payload
      };
    case actions.UNSELECT_DRIVE:
      return {
        ...state,
        selectedDrive: null
      };
    case actions.CLEAR_FILES:
      if (state.selectedDrive) {
        return {
          ...state,
          selectedDrive: {
            ...state.selectedDrive,
            myoFiles: []
          }
        };
      }
      return state;
    case actions.UPDATE_FILES_INFO:
      // Move to sagas if there are more similar cases
      if (state.selectedDrive && state.selectedDrive.myoFiles) {
        const myoFiles = state.selectedDrive.myoFiles.map(file => {
          const isSessionFile =
            file.filename.includes(sessionHeaderFilePrefix) ||
            file.filename.includes(sessionLogsFilePrefix);

          if (isSessionFile) {
            Object.assign(file, {
              success: !action.payload[file.filename].warning
            });
          }
          Object.assign(file, { isSessionFile });

          return file;
        });
        return {
          ...state,
          selectedDrive: {
            ...state.selectedDrive,
            myoFiles
          }
        };
      }
      return state;
    default:
      return state;
  }
};
