import * as Yup from "yup";
import i18n from "../../../../i18n";
import { TranslationsEnum } from "../../../../types/enums";

export const unitFormSchema = Yup.object().shape({
  serialNumber: Yup.string().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  hardwareModel: Yup.string().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  nexusProtocolVersion: Yup.string().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  currentFirmwareVersion: Yup.number().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  futureFirmwareVersion: Yup.number().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  unitStatus: Yup.number().required(
    i18n.t(TranslationsEnum.Validations_Required)
  ),
  unitStatusSource: Yup.number().required(
    i18n.t(TranslationsEnum.Validations_Required)
  )
});
