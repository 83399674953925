import React, { useCallback } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { useTranslation } from "react-i18next";
import { UploadStatusEnum } from "../../../../../types/enums/UploadStatusEnum";
import { IFile } from "../../../../../types/files/IFile";
import { useErrorDescription } from "../../../../../utils/helpers/translationHelpers";
import useStyles from "./FilesListItem.styles";
import {
  sessionHeaderFilePrefix,
  sessionLogsFilePrefix,
  sessionFilesExtension
} from "../../../../../shared-constants/files";

interface IProps {
  fileInfo: IFile;
}

const FilesListItem: React.FC<IProps> = ({ fileInfo }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const filename = fileInfo.file.name;
  // Get GUID only for files with a valid name
  const GUID = filename
    .replace(sessionHeaderFilePrefix, "")
    .replace(sessionLogsFilePrefix, "")
    .replace(sessionFilesExtension, "");

  const errorDescription = useErrorDescription(fileInfo.error, GUID);

  const warning =
    fileInfo.warning !== undefined &&
    fileInfo.warning !== null &&
    t(`WarningCodes.${fileInfo.warning}`, { GUID });

  const FileStatus = useCallback(() => {
    if (fileInfo.uploadStatus === UploadStatusEnum.Pending) {
      return (
        <Typography variant="subtitle2" className={classes.progress}>
          {t(`Containers.SyncMyoSuit.FilePending`)}
        </Typography>
      );
    } else if (fileInfo.uploadStatus === UploadStatusEnum.Uploading) {
      return (
        <>
          <Typography variant="subtitle2" className={classes.progress}>
            {fileInfo.uploadProgress !== 100
              ? `${fileInfo.uploadProgress || 0}%`
              : t(`Containers.SyncMyoSuit.Processing`)}
          </Typography>
          <CircularProgress
            variant="static"
            value={fileInfo.uploadProgress}
            size={20}
            thickness={6}
          />
        </>
      );
    } else if (
      fileInfo.uploadStatus === UploadStatusEnum.Resolved &&
      fileInfo.ignored
    ) {
      return (
        <>
          <Typography variant="subtitle2" className={classes.progress}>
            {t(`Containers.SyncMyoSuit.FileIgnored`)}
          </Typography>
          <CheckCircleIcon className={classes.checkMark} />
        </>
      );
    } else if (errorDescription && !fileInfo.ignored) {
      return (
        <>
          <Typography variant="subtitle2" className={classes.error}>
            {errorDescription}
          </Typography>
          <ErrorIcon className={classes.error} />
        </>
      );
    } else if (fileInfo.warning !== undefined && fileInfo.warning !== null) {
      return (
        <>
          <Typography variant="subtitle2" className={classes.warning}>
            {warning}
          </Typography>
          <WarningRoundedIcon className={classes.warning} />
        </>
      );
    } else if (fileInfo.uploadStatus === UploadStatusEnum.Resolved) {
      return (
        <>
          <Typography variant="subtitle2" className={classes.progress}>
            {fileInfo.isDuplicate
              ? t(`Containers.SyncMyoSuit.FileDuplicate`)
              : t(`Global.Done`)}
          </Typography>
          <CheckCircleIcon className={classes.checkMark} />
        </>
      );
    }
    return null;
  }, [classes, errorDescription, fileInfo, t, warning]);

  return (
    <Box key={fileInfo.file.name} className={classes.listItem}>
      <Box className={classes.infoContainer}>
        <Box className={classes.leftItemsContainer}>
          <Typography variant="subtitle2" className={classes.fileName}>
            {fileInfo.file.name}
          </Typography>
        </Box>
        <Box className={classes.rightItemsContainer}>
          <FileStatus />
        </Box>
      </Box>
    </Box>
  );
};

export default FilesListItem;
