export enum ColorEnum {
  ErrorRed = "#f44336",
  Red = "#F95457",
  DarkRed = "#c44143",
  Yellow = "#FFF200",
  OrangeHover = "#b26628",
  Orange = "#FF933A",
  OrangeDark = "#FBA16D",
  OrangeLight = "#FCC4A4",
  SuccessGreen = "#4bb543",
  SuccessDarkGreen = "#3c8f36",
  GreenDark = "#1DAFA4",
  GreenLight = "#80CBC6",
  PurpleDark = "#9547EC",
  PurpleLight = "#CAA7F2",
  Blue = "#195F93",
  LightBlue = "#D0EBFF",
  SecondaryBlue = "#80d8ff",
  TableBlue = "rgb(128, 216, 255, 0.08)",
  TableBlueHover = "rgba(128, 216, 255, 0.16)",
  TealBlueBackground = "rgba(11, 151, 255, 0.2)",
  White = "#fff",
  SecondaryWhite = "rgba(255, 255, 255, 0.2)",
  ThirdWhite = "rgba(255, 255, 255, 0.25)",
  BorderColor = "#78787880",
  DarkGrey = "#333333",
  DarkGreyLighter = "rgba(33, 33, 33, 0.1)",
  LightGrey = "#666666",
  LightGreyBorder = "rgba(51, 51, 51, 0.1)",
  LightGreyIcons = "#CACACA",
  DropZoneBackgroundColor = "#F5F5F5",
  LineSeparatorColor = "#F2F2F2",
  LightBlack = "rgba(0, 0, 0, 0)",
  MiddleBlack = "rgba(0, 0, 0, 0.5)",
  Black = "rgba(0, 0, 0)",
  PrimaryGrey = "rgba(208, 235, 255, 0.4)",
  HighlighterBlue = "rgba(25, 95, 147, 0.5)"
}
