import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../types/enums/ColorEnum";
import { maxContainerWidth } from "../../styles/stylesConstants";

export default makeStyles((theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  container: {
    maxWidth: maxContainerWidth,
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3)
  },
  paperContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column"
  },
  darkGrey: {
    color: ColorEnum.DarkGrey
  },
  header: {
    fontWeight: "normal",
    marginLeft: theme.spacing(3)
  },
  descriptionContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  description: {
    fontWeight: "normal"
  },
  button: {
    width: "auto",
    textTransform: "uppercase",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  buttonsContainer: {
    justifyContent: "space-between"
  },
  icon: {
    marginRight: 5
  }
}));
