import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ColorEnum } from "../../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  highlighted: {
    textShadow: "2px 2px 30px",
    color: ColorEnum.Yellow,
    transition: "color 0.5s"
  },
  notHighlighted: {
    transition: "color 0.5s"
  }
}));
