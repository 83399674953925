import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { maxContainerWidth } from "../../styles/stylesConstants";
import { ColorEnum } from "../../types/enums/ColorEnum";

export default makeStyles((theme: Theme) => ({
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 100
  },
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),
    maxWidth: maxContainerWidth
  },
  title: {
    alignItems: "center"
  },
  reloadIcon: {
    marginLeft: theme.spacing(2)
  },
  button: {
    float: "right",
    margin: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  syncBtn: {
    marginRight: theme.spacing(1)
  },
  syncIcon: {
    marginRight: 10
  },
  warningIcon: {
    color: ColorEnum.Orange,
    fontSize: 30
  }
}));
