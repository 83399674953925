import gql from "graphql-tag";

export const createMessageMutation = gql`
  mutation createMessage($message: Message!) {
    createMessage(message: $message)
      @rest(
        type: "CreateMessage"
        path: "messages"
        method: "POST"
        bodyKey: "message"
      ) {
      NoResponse
    }
  }
`;

export const editMessageMutation = gql`
  mutation editMessage($message: Message!) {
    editMessage(message: $message)
      @rest(
        type: "EditMessage"
        path: "messages"
        method: "PUT"
        bodyKey: "message"
      ) {
      NoResponse
    }
  }
`;

export const deleteMessageMutation = gql`
  mutation deleteMessageMutation($values: Values!) {
    deleteMessageMutation(values: $values)
      @rest(
        type: "DeleteMessage"
        path: "messages/{args.values.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`;

export const readMessageMutation = gql`
  mutation readMessageMutation($values: Values!) {
    readMessageMutation(values: $values)
      @rest(
        type: "ReadMessage"
        path: "messages/read/{args.values.id}"
        method: "PUT"
        bodyKey: "values"
      ) {
      NoResponse
    }
  }
`;
